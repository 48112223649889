import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getretailerlist,
  searchretailerlist,
  getdistributers,
  getsortlistretailer,
} from "../../actions/assetAction";
import { retailerlist } from "../../actions/userListAction";
import { retailer_export } from "../../actions/exportAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Drawer,
  Form,
  Row,
  Col,
  Select,
  Input,
  message,
} from "antd";
const { Option } = Select;
class Retailers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retailer: [],
      retlist: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingEPbtn: false,
      visible: false,
      distributor: [],
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
    };
  }

  componentWillMount() {
    this.props
      .getsortlistretailer()
      .then((res) => {
        console.log(res.data);
        this.setState({
          sort_list: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          sort_list: [],
        });
      });
    this.getdesignation({
      results: 10,
      page: 1,
    });
    this.props
      .getdistributers()
      .then((res) => {
        console.log(res);
        this.setState({
          distributor: this.props.data.distributor,
        });
      })
      .catch((err) => {
        this.setState({ loading: false });
      });
  }
  getdesignation(params = {}) {
    this.setState({ loading: true });
    this.props.getretailerlist(params).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.items.total;
      pagination.current = res.data.items.current_page;
      this.setState({
        loading: false,
        retailer: res.data.items.data,
        pagination,
      });
    });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(sorter.order);
    var status = filter?.status ?? [];
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      selectedstatus: status.toString(),
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchRetailer();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };
  showDrawer = (res) => {
    console.log(res);
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  searchRetailer = (reset) => {
    var status = this.state.selectedstatus;
    var search_text = this.state.name;
    var distributor = this.state.selectedItems;
    var page = reset ? 1 : this.state.pagination?.current ?? 1;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    this.setState({ loading: true, loadingbtn: true });
    this.props
      .searchretailerlist({
        status: status,
        search_text: search_text,
        distributor: distributor,
        page: page,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          retailer: res.data.items.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({ loadingbtn: false });
      });
  };

  clearSearch = () => {
    this.setState({
      status: undefined,
      name: "",
      agent: "",
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
    console.log(this.state.selectedItems);
  };
  getrefreshbtn = () => {
    this.clearSearch();
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.sort_list, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  getexportbtn = () => {
    var search_text = this.state.name;
    var distributor = this.state.selectedItems;
    var status = this.state.selectedstatus;
    this.setState({ loading: true, loadingEPbtn: true });
    this.props
      .retailer_export({ search_text: search_text, distributor: distributor, status:status })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  // ======================================================

  render() {
    const { retailer } = this.state;

    const columns = [
      {
        title: "Master",
        dataIndex: "master_agent_name",
        key: "master_agent_name",
        sorter: true,
      },
      {
        title: "Distributor",
        dataIndex: "distributor_name",
        key: "distributor_name",
        sorter: true,
      },
      {
        title: "Retailer",
        dataIndex: "name",
        key: "name",
        sorter: true,
      },
      {
        title: "User",
        dataIndex: "get_manager_info",
        key: "get_manager_info",
        sorter: true,
        render: (tags) => (
          <span>
            {tags.map((tag) => {
              return (
                <Tag color="volcano" key={tag}>
                  {tag.name}
                </Tag>
              );
            })}
          </span>
        ),
      },

      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },

      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        filters: [
          { text: "Active", value: 1 },
          { text: "Inactive", value: 0 },
        ],
        render: (tag) => (
          <span>
            <Tag color={tag === 1 ? "green" : "orange"}>
              {tag ? "Active" : "Inactive"}
            </Tag>
          </span>
        ),
      },
      // {
      //   title: "Action",
      //   key: "action",
      //   width: 100,
      //   render: (text, record) => (
      //     <span>
      //       <Button type="link" onClick={() => this.showDrawer(record)}>
      //         Edit
      //       </Button>
      //     </span>
      //   ),
      // },
    ];
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Retailers{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
              <Button
                type="default"
                className="mr5"
                onClick={() => this.getrefreshbtn()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>
              {/* {userpermission.Create ? (
                <Link to="/addmarketer">
                  <Button type="primary">Add IBO/Market</Button>
                </Link>
              ) : null} */}
            </div>
          </div>
          <div className="row padd20 bg-grey">
            {/* <div className="col">
              <Select
                placeholder="Select Status"
                style={{ width: "100%" }}
                value={
                  this.state.status !== undefined && this.state.status !== null
                    ? this.state.status === 1
                      ? "Active"
                      : "Inactive"
                    : undefined
                }
                onChange={(value) =>
                  this.setState({
                    status: value === "Active" ? 1 : 0,
                  })
                }
              >
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            </div> */}
            <div className="col">
              <label for="exampleFormControlInput1">Search Distributor</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderDistributor()}
              </Select>
            </div>

            {/* <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select field name"
                value={this.state.selectedfieldname}
                onChange={this.onchangefieldname}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderSorter()}
              </Select>
            </div> */}
            {/* <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select filter order"
                value={this.state.selectedfilter}
                onChange={this.onchangefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="ASC">ASC</Option>
                <Option value="DESC">DESC</Option>
              </Select>
            </div> */}
            <div className="col">
              <label for="exampleFormControlInput1">Search By Keyword</label>
              <Input
                placeholder="Type Keyword"
                value={this.state.name}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchRetailer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={retailer}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
    data: state.data,
  };
}

const App = Form.create()(Retailers);

export default connect(mapStateToProps, {
  getretailerlist,
  retailerlist,
  searchretailerlist,
  getdistributers,
  getsortlistretailer,
  retailer_export,
})(App);
