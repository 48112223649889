import React, { Component } from "react";
import { connect } from "react-redux";
import {
  detailed_legacy_data_report,
  detailed_Legacy_data_search,
} from "../../actions/salesAction";
import { detailed_legacy_data_export } from "../../actions/exportAction";
import { getofficelistname, getdevicetype ,getretailers } from "../../actions/assetAction";
import { getinventorystatus } from "../../actions/inventoryAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Select,
  DatePicker,
  message,
  Spin,
  Empty,
  Checkbox,
  Row,
  Col,
} from "antd";
import moment from "moment";
import axiosInstance from "../../actions/AxiosInstance";
import DemoQueryBuilder from "./QueryBuilder";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const CheckboxGroup1 = Checkbox.Group;
const CheckboxGroup2 = Checkbox.Group;



const defaultCheckedList = [""];
const defaultCheckedList1 = [""];
const defaultCheckedList2 = [""];
class LegacyData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: false,
      loadingbtn: false,
      loadingEPbtn: false,
      retailer: [],
      retailers:[],
      selectedItems: [],
      selectedItemsA: [],
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      inventorystatus: [],
      search_text: "",
      datefilter: [],
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      startdate1: "",
      enddate1: "",
      startValue1: null,
      endValue1: null,
      endOpen1: false,
      startdate2: "",
      enddate2: "",
      startValue2: null,
      endValue2: null,
      endOpen2: false,
      office: [],
      fdata: [],
      fAdata: [],
      sdsf: "",
      superrole: false,
      agentstatusname: [],
      agentstatus: [],
      checkedList: defaultCheckedList,
      checkedList1: defaultCheckedList1,
      indeterminate: false,
      checkAll: false,
      indeterminate1: false,
      checkAll1: false,
      checkedList2: defaultCheckedList2,
      indeterminate2: false,
      checkAll2: false,
      instock_status: [],
      lost_status: [],
      other_status: [],
      queryview: false,
      searchview: true,
      device: [],
      orderid: "",
      enrollid: "",
    };
  }

  componentWillMount() {
    // this.getOfficelist();
    this.props
      .getinventorystatus()
      .then((res) => {
        this.setState({
          agentstatus: res.data,
          instock_status: res.data.filter(
            (item) =>
              item.id === 9 ||
              item.id === 3 ||
              item.id === 4 ||
              item.id === 5 ||
              item.id === 6 ||
              item.id === 12
          ),
          lost_status: res.data.filter(
            (item) =>
              item.id === 10 ||
              item.id === 11 ||
              item.id === 15 ||
              item.id === 16 ||
              item.id === 18 ||
              item.id === 19
          ),
          other_status: res.data.filter(
            (item) =>
              (item.id !== 9) &
              (item.id !== 3) &
              (item.id !== 4) &
              (item.id !== 5) &
              (item.id !== 6) &
              (item.id !== 10) &
              (item.id !== 13) &
              (item.id !== 11) &
              (item.id !== 15) &
              (item.id !== 12) &
              (item.id !== 20) &
              (item.id !== 16) &
              (item.id !== 18) &
              (item.id !== 19) &
              (item.id !== 14)
          ),
        });
      })
      .catch((err) => {
        this.setState({
          agentstatus: [],
        });
      });
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    this.props.getdevicetype().then((res) => {
      this.setState({
        device: res.data,
      });
    });
    this.props.getretailers().then((res) => {
      this.setState({
        retailer: res.data,
      });
    });
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .detailed_legacy_data_report(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(sorter.order);

    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    this.setState({ selectedItems });
  };
  onchangeRoleA = (selectedItemsA) => {
    console.log(selectedItemsA);
    this.setState({ selectedItemsA });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };

  renderOffice() {
    return _.map(this.state.office, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  clearSearch = () => {
    this.setState({
      data: [],
      selectedItems: [],
      selectedItemsA: [],
      selectedfieldname: [],
      selectedfilter: [],
      name: "",
      carton: "",
      imei: "",
      device_type: [],
      retailers: [],
      startValue: null,
      endValue: null,
      startValue1: null,
      endValue1: null,
      startValue2: null,
      endValue2: null,
      fdata: [],
      fAdata: [],
      agentstatusname: [],
      queryview: false,
      searchview: true,
      checkedList: [],
      checkedList1: [],
      checkedList2: [],
      checkAll: false,
      checkAll1: false,
      checkAll2: false,
      indeterminate: false,
      indeterminate1: false,
      indeterminate2: false,
      query_string: "",
      orderid: "",
      enrollid: "",
      retailers: [],
          
    });
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true, loadingbtn: true });
    const mergedArray = this.state.checkedList
      .concat(this.state.checkedList1, this.state.checkedList2)
      .filter((obj) => Object(obj).length !== 0);
    //const resultString = JSON.stringify(mergedArray);
    var summary = this.state.selectedItems.key;
    var Agent = this.state.selectedItemsA.key;
    var status = mergedArray.toString();
    var search_text = this.state.name;
    var carton = this.state.carton;
    var imei = this.state.imei;
    var device_type = this.state.device_type;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var date_range_start1 = this.state.startValue1;
    var date_range_end1 = this.state.endValue1;
    var date_range_start2 = this.state.startValue2;
    var date_range_end2 = this.state.endValue2;
    var retailer_id = this.state.retailers;
    var query_string = this.state.query_string ?? "";
    var order_id = this.state.orderid;
    var enroll_id = this.state.enrollid;

    this.props
      .detailed_Legacy_data_search({
        summary: summary,
        search_text: search_text,
        date_range_start,
        date_range_end,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
        date_range_start1,
        date_range_end1,
        carton,
        imei,
        device_type,
        Agent,
        status,
        query_string,
        date_range_start2,
        date_range_end2,
        retailer_id,
        order_id,
        enroll_id

      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          data: res.data.items.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  disabledStartDate = (current) => {
    return current > moment().endOf("day");
  };

  disabledEndDate = (endValue) => {
    return endValue > moment().endOf("day");
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
    // Calculate the end date to be 90 days from the start date
    // const endDate =  value.clone().add(90, 'days');

    // Set the end date
    // this.setState({ endValue: endDate });
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  //================================================//
  disabledStartDate1 = (current) => {
    console.log(current);

    return current > moment().endOf("day");
  };

  disabledEndDate1 = (endValue) => {
    return endValue > moment().endOf("day");
  };
  onChange1 = (field, value) => {
    console.log(field, value);
    this.setState({
      [field]: value,
    });
  };
  onStartChange1 = (value) => {
    this.onChange1("startValue1", value);
    //const endDate = value.clone().add(90, "days");

    // Set the end date
    //this.setState({ endValue1: endDate });
  };

  onEndChange1 = (value) => {
    this.onChange1("endValue1", value);
  };
  handleStartOpenChange1 = (open) => {
    if (!open) {
      this.setState({ endOpen1: true });
    }
  };

  handleEndOpenChange1 = (open) => {
    this.setState({ endOpen1: open });
  };

  //=============================================================//

  disabledStartDate2 = (current) => {
    console.log(current);

    return current > moment().endOf("day");
  };

  disabledEndDate2 = (endValue) => {
    return endValue > moment().endOf("day");
  };
  onChange2 = (field, value) => {
    console.log(field, value);
    this.setState({
      [field]: value,
    });
  };
  onStartChange2 = (value) => {
    this.onChange1("startValue2", value);
    //const endDate =  value.clone().add(90, 'days');

    // Set the end date
    //this.setState({ endValue1: endDate });
  };

  onEndChange2 = (value) => {
    this.onChange2("endValue2", value);
  };
  handleStartOpenChange2 = (open) => {
    if (!open) {
      this.setState({ endOpen2: true });
    }
  };

  handleEndOpenChange2 = (open) => {
    this.setState({ endOpen2: open });
  };

  getexportbtn = () => {
    this.setState({
      loading: true,
      loadingEPbtn: true,
    });
    const mergedArray = this.state.checkedList
      .concat(this.state.checkedList1, this.state.checkedList2)
      .filter((obj) => Object(obj).length !== 0);

    var summary = this.state.selectedItems.key;
    var Agent = this.state.selectedItemsA.key;
    var status = mergedArray.toString();
    var search_text = this.state.name;
    var carton = this.state.carton;
    var imei = this.state.imei;
    var device_type = this.state.device_type;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var date_range_start1 = this.state.startValue1;
    var date_range_end1 = this.state.endValue1;
    var date_range_start2 = this.state.startValue2;
    var date_range_end2 = this.state.endValue2;
    var retailer_id = this.state.retailers;
    var query_string = this.state.query_string ?? "";
    var order_id = this.state.orderid;
    var enroll_id = this.state.enrollid;
    // this.setState({ loading: true });
    console.log(summary);
    this.props
      .detailed_legacy_data_export(
        summary,
        search_text,
        date_range_start,
        date_range_end,
        date_range_start1,
        date_range_end1,
        date_range_start2,
        date_range_end2,
        carton,
        imei,
        device_type,
        Agent,
        status,
        retailer_id,
        query_string,
        order_id,
        enroll_id
      )
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        message.error(err);
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  //Agent dropdwon
  fetchUserA = (value) => {
    console.log("fetchingA user", value);
    //
    if (value.length > 1) {
      this.setState({ fAdata: [], fetchingA: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fAdata = response.data.map((user) => ({
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fAdata, fetchingA: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onChangeCheck = (checkedList) => {
    console.log(checkedList.length === this.state.instock_status.length);
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length &&
        checkedList.length < this.state.instock_status.length,
      checkAll: checkedList.length === this.state.instock_status.length,
    });
  };

  onCheckAllChange = (e) => {
    console.log(e.target.checked);
    this.setState({
      checkedList: e.target.checked
        ? this.state.instock_status.map((option) => option.id)
        : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onChangeCheck1 = (checkedList1) => {
    this.setState({
      checkedList1,
      indeterminate1:
        !!checkedList1.length &&
        checkedList1.length < this.state.lost_status.length,
      checkAll1: checkedList1.length === this.state.lost_status.length,
    });
  };

  onCheckAllChange1 = (e) => {
    this.setState({
      checkedList1: e.target.checked
        ? this.state.lost_status.map((option) => option.id)
        : [],
      indeterminate1: false,
      checkAll1: e.target.checked,
    });
  };

  onChangeCheck2 = (checkedList2) => {
    this.setState({
      checkedList2,
      indeterminate2:
        !!checkedList2.length &&
        checkedList2.length < this.state.other_status.length,
      checkAll2: checkedList2.length === this.state.other_status.length,
    });
  };

  onCheckAllChange2 = (e) => {
    this.setState({
      checkedList2: e.target.checked
        ? this.state.other_status.map((option) => option.id)
        : [],
      indeterminate2: false,
      checkAll2: e.target.checked,
    });
  };
  enablequeryview = () => {
    this.setState({
      queryview: true,
      searchview: false,
    });
  };
  handleStateChange = (e) => {
    console.log(e);
    this.setState({ query_string: e });
  };
  handledevicechange = (value) => {
    console.log(value);
    this.setState({ device_type: value });
  };
  handleretailerchange = (value) => { 
    console.log(value)
    this.setState({ retailers: value }); 
  };
  // ======================================================

  render() {
    const {
      fetching,
      fetchingA,
      endOpen,
      fdata,
      fAdata,
      startValue,
      endValue,
      endOpen1,
      startValue1,
      endValue1,
      endOpen2,
      startValue2,
      endValue2,
      superrole,
    } = this.state;
    const columns = [
      {
        title: "Retailer Name",
        dataIndex: "retailer_name",
        key: "retailer_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "IBO / Markets",
        dataIndex: "ibo_name",
        key: "ibo_name",
        sorter: true,
      },
      {
        title: "Office ",
        dataIndex: "office_name",
        key: "office_name",
        sorter: true,
      },
      {
        title: "Agent Name",
        dataIndex: "agent_name",
        key: "agent_name",
        sorter: true,
      },

      {
        title: "ETC Code",
        dataIndex: "excess_code",
        key: "excess_code",
        sorter: true,
      },
      {
        title: "Enrollment Number",
        dataIndex: "enroll_id",
        key: "enroll_id",
        sorter: true,
      },
      {
        title: "Order ID",
        dataIndex: "inventory_request_order_id",
        key: "inventory_request_order_id",
        sorter: true,
      },
     
      {
        title: "Pallet",
        dataIndex: "pallet",
        key: "pallet",
      },
      {
        title: "Carton",
        dataIndex: "carton",
        key: "carton",
      },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        sorter: true,
      },
      {
        title: "Enrollment Type",
        dataIndex: "enroll_type",
        key: "enroll_type",
      },
      {
        title: "ESN",
        dataIndex: "esn",
        key: "esn",
      },
      // {
      //   title: "MDN",
      //   dataIndex: "mdn",
      //   key: "mdn",
      // },
      {
        title: "Order Date",
        dataIndex: "order_date",
        key: "order_date",
        sorter: true,
      },
      {
        title: "Activation Date",
        dataIndex: "activation_date",
        key: "activation_date",
        sorter: true,
      },
      {
        title: "Allocation Date",
        dataIndex: "allocation_date",
        key: "allocation_date",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        render: (text, record) => (
          <span>
            <Tag color="orange">{record.status_name}</Tag>
          </span>
        ),
      },
      // {
      //   title: "Received Date",
      //   dataIndex: "received_date",
      //   key: "received_date",
      //   sorter: true,
      // },
      {
        title: "Plan Name",
        dataIndex: "plan_name",
        key: "plan_name",
      },
      {
        title: "Device Type",
        dataIndex: "device_type",
        key: "device_type",
      },
    ].filter((item) => !item.hidden);
    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                {" "}
                Legacy Data{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              {/* <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.enablequeryview()}
              >
                <i className="uil uil-database" /> Add Query Filter
              </Button> */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          {this.state.searchview && (
            <>
            <div className="row padd20">
              <div className="col-2">
                <label for="exampleFormControlInput1">Activation From</label>
                <DatePicker
                  defaultValue={startValue}
                  value={startValue}
                  disabledDate={this.disabledStartDate}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={this.onStartChange}
                  onOpenChange={this.handleStartOpenChange}
                />
              </div>
              <div className="col-2">
                <label for="exampleFormControlInput1">Activation To</label>
                <DatePicker
                  value={endValue}
                  disabledDate={this.disabledEndDate}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={this.onEndChange}
                  open={endOpen}
                  onOpenChange={this.handleEndOpenChange}
                />
              </div>

              <div className="col-2">
                <label for="exampleFormControlInput1">Order From</label>
                <DatePicker
                  value={startValue1}
                  disabledDate={this.disabledStartDate1}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={this.onStartChange1}
                  onOpenChange={this.handleStartOpenChange1}
                />
              </div>
              <div className="col-2">
                <label for="exampleFormControlInput1">Order To</label>
                <DatePicker
                  value={endValue1}
                  disabledDate={this.disabledEndDate1}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={this.onEndChange1}
                  open={endOpen1}
                  onOpenChange={this.handleEndOpenChange1}
                />
              </div>
              <div className="col-2">
                <label for="exampleFormControlInput1">Allocation From</label>
                <DatePicker
                  value={startValue2}
                  disabledDate={this.disabledStartDate2}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={this.onStartChange2}
                  onOpenChange={this.handleStartOpenChange2}
                />
              </div>
              <div className="col-2">
                <label for="exampleFormControlInput1">Allocation To</label>
                <DatePicker
                  value={endValue2}
                  disabledDate={this.disabledEndDate2}
                  format="MM-DD-YYYY"
                  placeholder="Select Date"
                  onChange={this.onEndChange2}
                  open={endOpen2}
                  onOpenChange={this.handleEndOpenChange2}
                />
              </div>
              {this.state.superrole && ( <div className="col-3 mt-2">
                <label for="exampleFormControlInput1">Select Retailer</label>

                <Select
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                
                  placeholder="Select retailer"
                  style={{ width: "100%" }}
                  onChange={this.handleretailerchange}
                  value={this.state.retailers}
                >
                  {this.state.retailer.map((device, index) => {
                    return (
                      <Option key={index} value={device.id}>
                        {device.name}
                      </Option>
                    );
                  })}
                  
                </Select>
              </div>)}
             
              <div className="col-3 mt-2">
                <label for="exampleFormControlInput1">Select Office</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 2 characters to search..!"
                  notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                  filterOption={false}
                  onSearch={this.fetchUser}
                  defaultActiveFirstOption={false}
                  value={this.state.selectedItems}
                  onChange={this.onchangeRole}
                >
                  {fdata.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
              </div>
              <div className="col-3 mt-2">
                <label for="exampleFormControlInput1">Search Agent</label>

                <Select
                  showSearch
                  style={{ width: "100%" }}
                  labelInValue
                  placeholder="Type 2 characters to search..!"
                  notFoundContent={
                    fetchingA ? <Spin size="small" /> : <Empty />
                  }
                  filterOption={false}
                  onSearch={this.fetchUserA}
                  defaultActiveFirstOption={false}
                  value={this.state.selectedItemsA}
                  onChange={this.onchangeRoleA}
                >
                  {fAdata.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                  ))}
                </Select>
              </div>
              <div className="col-3 mt-2">
                <label for="exampleFormControlInput1">Search By Order ID</label>
                <Input
                  placeholder="Input Order ID"
                  value={this.state.orderid}
                  // ref={(node) => {
                  //   this.searchInput = node;
                  // }}
                  onChange={(e) =>
                    this.setState({
                      orderid: e.target.value,
                    })
                  }
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col-3 mt-2">
                <label for="exampleFormControlInput1">Search By Enrollment ID</label>
                <Input
                  placeholder="Input Enrollment ID"
                  value={this.state.enrollid}
                  // ref={(node) => {
                  //   this.searchInput = node;
                  // }}
                  onChange={(e) =>
                    this.setState({
                      enrollid: e.target.value,
                    })
                  }
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col-3 mt-2">
                <label for="exampleFormControlInput1">Search By Carton</label>
                <Input
                  placeholder="Type Carton Number"
                  value={this.state.carton}
                  // ref={(node) => {
                  //   this.searchInput = node;
                  // }}
                  onChange={(e) =>
                    this.setState({
                      carton: e.target.value,
                    })
                  }
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col-3 mt-2">
                <label for="exampleFormControlInput1">Search By IMEI</label>
                <Input
                  placeholder="Type IMEI"
                  value={this.state.imei}
                  // ref={(node) => {
                  //   this.searchInput = node;
                  // }}
                  onChange={(e) =>
                    this.setState({
                      imei: e.target.value,
                    })
                  }
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              <div className="col-3 mt-2">
                <label for="exampleFormControlInput1">Device Type</label>
                <Select
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.device_type}
                  placeholder="Select device type"
                  style={{ width: "100%" }}
                  onChange={this.handledevicechange}
                >
                  {this.state.device.map((device, index) => {
                    return (
                      <Option key={index} value={device.id}>
                        {device.name}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="col-6 mt-2">
                <label for="exampleFormControlInput1">Search By Keyword</label>
                <Input
                  placeholder="Type keyword"
                  value={this.state.name}
                  // ref={(node) => {
                  //   this.searchInput = node;
                  // }}
                  onChange={(e) =>
                    this.setState({
                      name: e.target.value,
                    })
                  }
                  style={{ width: "100%", display: "block" }}
                />
              </div>
              </div>
              <div className="row">
              <div className="col-4 mt-4">
                <div
                  class="card  mb-4"
                  style={{ borderRadius: "1.00rem", height: "200px" }}
                >
                  <div class="card-body">
                    <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                      <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                      >
                        INSTOCK
                      </Checkbox>
                    </div>
                    <br />
                    <CheckboxGroup
                      //options={this.state.instock_status}
                      value={this.state.checkedList}
                      onChange={this.onChangeCheck}
                    >
                      {this.state.instock_status.map((status, index) => {
                        return (
                          <Checkbox key={index} value={status.id}>
                            {status.name}
                          </Checkbox>
                        );
                      })}
                    </CheckboxGroup>
                  </div>
                </div>
              </div>

              <div className="col-4 mt-4">
                <div
                  class="card  mb-4"
                  style={{ borderRadius: "1.00rem", height: "200px" }}
                >
                  <div class="card-body">
                    <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                      <Checkbox
                        indeterminate={this.state.indeterminate1}
                        onChange={this.onCheckAllChange1}
                        checked={this.state.checkAll1}
                      >
                        LOST/DEFECTIVE
                      </Checkbox>
                    </div>
                    <br />
                    <CheckboxGroup1
                      // options={plainOptions1}
                      value={this.state.checkedList1}
                      onChange={this.onChangeCheck1}
                    >
                      {this.state.agentstatus.map((status, index) => {
                        if (
                          status.id === 10 ||
                          status.id === 11 ||
                          status.id === 15 ||
                          status.id === 16 ||
                          status.id === 18 ||
                          status.id === 19
                        ) {
                          // replace 'condition' with your condition
                          return (
                            <Checkbox key={index} value={status.id}>
                              {status.name}
                            </Checkbox>
                          );
                        }
                        return null; // Add this line to return null if the condition is not met
                      })}
                    </CheckboxGroup1>
                  </div>
                </div>
              </div>

              <div className="col-4 mt-4">
                <div
                  class="card  mb-4"
                  style={{ borderRadius: "1.00rem", height: "200px" }}
                >
                  <div class="card-body">
                    <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                      <Checkbox
                        indeterminate={this.state.indeterminate2}
                        onChange={this.onCheckAllChange2}
                        checked={this.state.checkAll2}
                      >
                        OTHERS
                      </Checkbox>
                    </div>
                    <br />
                    <CheckboxGroup1
                      // options={plainOptions2}
                      value={this.state.checkedList2}
                      onChange={this.onChangeCheck2}
                    >
                      {this.state.agentstatus.map((status, index) => {
                        if (
                          status.id !== 9 &&
                          status.id !== 3 &&
                          status.id !== 4 &&
                          status.id !== 5 &&
                          status.id !== 6 &&
                          status.id !== 10 &&
                          status.id !== 13 &&
                          status.id !== 11 &&
                          status.id !== 15 &&
                          status.id !== 12 &&
                          status.id !== 20 &&
                          status.id !== 21 &&
                          status.id !== 22 &&
                          status.id !== 16 &&
                          status.id !== 18 &&
                          status.id !== 19 &&
                          status.id !== 14
                        ) {
                          // replace 'condition' with your condition
                          return (
                            <Checkbox key={index} value={status.id}>
                              {status.name}
                            </Checkbox>
                          );
                        }
                        return null; // Add this line to return null if the condition is not met
                      })}
                    </CheckboxGroup1>
                  </div>
                </div>
              </div>
            </div>
            </>
          )}
          {this.state.queryview && (
            <div className="row padd20">
              <div className="col-12">
                <p>
                  Press the 'Add Rule' button to begin constructing queries,
                  then click the 'Search' button.
                </p>
                <DemoQueryBuilder onStateChange={this.handleStateChange} />
              </div>
            </div>
          )}

          <div className="col mb-3">
            <Button
              type="primary"
              loading={this.state.loadingbtn}
              className="mr5"
              onClick={() => this.searchMarketer("reset")}
            >
              Search
            </Button>
            <Button type="default" onClick={() => this.clearSearch()}>
              Clear
            </Button>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  detailed_legacy_data_report,
  detailed_Legacy_data_search,
  getofficelistname,
  detailed_legacy_data_export,
  getinventorystatus,
  getdevicetype,
  getretailers
})(LegacyData);
