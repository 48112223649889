import React, { Component } from "react";
import { connect } from "react-redux";
import {
  detailed_sales_report,
  detailed_sales_report_search,
} from "../../actions/salesAction";
import { detailed_sales_export } from "../../actions/exportAction";
import { getofficelistname } from "../../actions/assetAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Select,
  DatePicker,
  message,
  Spin,
  Empty,
} from "antd";
import moment from "moment";
import axiosInstance from "../../actions/AxiosInstance";

const { Option } = Select;

class SalesSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingEPbtn: false,
      retailer: [],
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      inventorystatus: [],
      search_text: "",
      datefilter: [],
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      startdate1: "",
      enddate1: "",
      startValue1: null,
      endValue1: null,
      endOpen1: false,
      office: [],
      fdata: [],
      sdsf:"",
      superrole: false,
    };
  }

  componentWillMount() {
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    const urlString = this.props.location.urlpath;
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
      //console.log(paramsobj);
    });
    //console.log(moment(paramsobj["date_range_start"]));

    // console.log(paramsobj["date_range_end"]);
    // console.log(paramsobj["date_range_end"] ===undefined ? null:"")
    let datec = paramsobj["date_range_end"];
    this.setState({
      sdsf: datec
    })
    this.setState({
      
      startValue: paramsobj["date_range_start"]===undefined ? null: moment(paramsobj["date_range_start"]  ),
      endValue: paramsobj["date_range_end"] ===undefined ? null: moment(paramsobj["date_range_end"] ),
    });
    this.getdesignation({
      url: this.props.location.urlpath ?? "",
      results: 10,
      page: 1,
    });
    // this.setColumns();
    // this.props.getofficelistname().then((res) => {
    //   console.log(res.data);
    //   this.setState({
    //     office: res.data,
    //   });
    // });
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .detailed_sales_report(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(sorter.order);

    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    this.setState({ selectedItems });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };

  renderOffice() {
    return _.map(this.state.office, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      name: "",
      startValue: null,
      endValue: null,
      startValue1: null,
      endValue1: null,
      fdata: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true, loadingbtn: true });
    var summary = this.state.selectedItems.key;
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var date_range_start1 = this.state.startValue1;
    var date_range_end1 = this.state.endValue1;

    this.props
      .detailed_sales_report_search({
        summary: summary,
        search_text: search_text,
        date_range_start,
        date_range_end,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
        date_range_start1,
        date_range_end1,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          data: res.data.items.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  };

  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  disabledStartDate = (startValue) => {
    // const { endValue } = this.state;
    // if (!startValue || !endValue) {
    //   return false;
    // }
    // return startValue.valueOf() > endValue.valueOf();/
    //return startValue && startValue > moment().endOf("day");
    const weekStart = moment().subtract(31, "days");
    const weekEnd = moment().endOf("day");
    return !(
      weekStart.isSameOrBefore(startValue) && weekEnd.isAfter(startValue)
    );
  };

  disabledEndDate = (endValue) => {
    // const { startValue } = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue.valueOf();
    // return endValue && endValue > moment().add(-31, "days");
    const weekStart = moment().subtract(31, "days");
    const weekEnd = moment().endOf("day");
    return !(weekStart.isSameOrBefore(endValue) && weekEnd.isAfter(endValue));
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  //================================================//
  disabledStartDate1 = (startValue) => {
    // const { endValue1 } = this.state;
    // if (!startValue || !endValue1) {
    //   return false;
    // }
    //return startValue.valueOf() > endValue1.valueOf();
    // return startValue && startValue > moment().endOf("day");
    const weekStart = moment().subtract(31, "days");
    const weekEnd = moment().endOf("day");
    return !(
      weekStart.isSameOrBefore(startValue) && weekEnd.isAfter(startValue)
    );
  };

  disabledEndDate1 = (endValue) => {
    // const { startValue1 } = this.state;
    // if (!endValue || !startValue1) {
    //   return false;
    // }
    // return endValue.valueOf() <= startValue1.valueOf();
    // return endValue && endValue > moment().endOf("day");
    const weekStart = moment().subtract(31, "days");
    const weekEnd = moment().endOf("day");
    return !(weekStart.isSameOrBefore(endValue) && weekEnd.isAfter(endValue));
  };
  onChange1 = (field, value) => {
    console.log(field, value);
    this.setState({
      [field]: value,
    });
  };
  onStartChange1 = (value) => {
    this.onChange1("startValue1", value);
  };

  onEndChange1 = (value) => {
    this.onChange1("endValue1", value);
  };
  handleStartOpenChange1 = (open) => {
    if (!open) {
      this.setState({ endOpen1: true });
    }
  };

  handleEndOpenChange1 = (open) => {
    this.setState({ endOpen1: open });
  };

  getexportbtn = () => {
    this.setState({
      loading: true,
      loadingEPbtn: true,
    });
    var summary = this.state.selectedItems.key;
    var search_text = this.state.name;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var date_range_start1 = this.state.startValue1;
    var date_range_end1 = this.state.endValue1;
    // this.setState({ loading: true });
    console.log(summary);
    this.props
      .detailed_sales_export(
        summary,
        search_text,
        date_range_start,
        date_range_end,
        date_range_start1,
        date_range_end1
      )
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // ======================================================

  render() {
    const {
      fetching,
      endOpen,
      fdata,
      startValue,
      endValue,
      endOpen1,
      startValue1,
      endValue1,
      superrole
    } = this.state;
    const columns = [
      {
        title: "Retailer Name",
        dataIndex: "retailer_name",
        key: "retailer_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "IBO / Markets",
        dataIndex: "ibo_name",
        key: "ibo_name",
        sorter: true,
      },
      {
        title: "Office ",
        dataIndex: "office_name",
        key: "office_name",
        sorter: true,
      },
      {
        title: "Agent Name",
        dataIndex: "agent_name",
        key: "agent_name",
        sorter: true,
      },

      {
        title: "ETC Code",
        dataIndex: "excess_code",
        key: "excess_code",
        sorter: true,
      },
      {
        title: "Enrollment Number",
        dataIndex: "enroll_id",
        key: "enroll_id",
        sorter: true,
      },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        sorter: true,
      },
      {
        title: "Pallet",
        dataIndex: "pallet",
        key: "pallet",
      },
      {
        title: "Carton",
        dataIndex: "carton",
        key: "carton",
      },
      {
        title: "Enrollment Type",
        dataIndex: "enroll_type",
        key: "enroll_type",
      },
      {
        title: "ESN",
        dataIndex: "esn",
        key: "esn",
      },
      // {
      //   title: "MDN",
      //   dataIndex: "mdn",
      //   key: "mdn",
      // },
      {
        title: "Order Date",
        dataIndex: "order_date",
        key: "order_date",
        sorter: true,
      },
      {
        title: "Activation Date",
        dataIndex: "activation_date",
        key: "activation_date",
        sorter: true,
      },
      {
        title: "Allocation Date",
        dataIndex: "allocation_date",
        key: "allocation_date",
        sorter: true,
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        render: (text, record) => (
          <span>
            <Tag color="orange">{record.status_name}</Tag>
          </span>
        ),
      },
      // {
      //   title: "Received Date",
      //   dataIndex: "received_date",
      //   key: "received_date",
      //   sorter: true,
      // },
      {
        title: "Plan Name",
        dataIndex: "plan_name",
        key: "plan_name",
      },
      {
        title: "Device Type",
        dataIndex: "device_type",
        key: "device_type",
      },
    ].filter((item) => !item.hidden);
    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                {" "}
                Detailed Report{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <div className="row padd20">
            <div className="col-3">
              <label for="exampleFormControlInput1">Activation From</label>
              <DatePicker
                defaultValue={startValue}
                value={startValue}
                disabledDate={this.disabledStartDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
            </div>
            <div className="col-3">
              <label for="exampleFormControlInput1">Activation To</label>
              <DatePicker
                value={endValue}
                disabledDate={this.disabledEndDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>

            <div className="col-3">
              <label for="exampleFormControlInput1">Order From</label>
              <DatePicker
                value={startValue1}
                disabledDate={this.disabledStartDate1}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onStartChange1}
                onOpenChange={this.handleStartOpenChange1}
              />
            </div>
            <div className="col-3">
              <label for="exampleFormControlInput1">Order To</label>
              <DatePicker
                value={endValue1}
                disabledDate={this.disabledEndDate1}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onEndChange1}
                open={endOpen1}
                onOpenChange={this.handleEndOpenChange1}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-3">
              <label for="exampleFormControlInput1">Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={this.fetchUser}
                defaultActiveFirstOption={false}
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
              >
                {fdata.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
            </div>
            {/* <div className="col-3">
              <label for="exampleFormControlInput1">Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderOffice()}
              </Select> */}
            {/* </div> */}
            <div className="col-3">
              <label for="exampleFormControlInput1">Search By Keyword</label>
              <Input
                placeholder="Type keyword"
                value={this.state.name}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col mt-6">
              <Button
                type="primary"
                loading={this.state.loadingbtn}
                className="mr5"
                onClick={() => this.searchMarketer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  detailed_sales_report,
  detailed_sales_report_search,
  getofficelistname,
  detailed_sales_export,
})(SalesSummary);
