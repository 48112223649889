import React, { Component } from "react";
import { connect } from "react-redux";
import {
  searchagentsummarylist,
  exportagentsummarylist,
  agent_summary_report,
  sendform,
  downloadform,
  updateformstatus,
  agentstatupload,
  agentofficemapingupload,
  viewcommentsuseraction,
  addcommentsuseraction,
  agentstatusaction,
  change_agent_status,
  getretailersonboard,
  getretaileroffice,
} from "../../actions/assetAction";
import _ from "lodash";

import {
  Button,
  Table,
  Tag,
  Input,
  Form,
  DatePicker,
  Tooltip,
  message,
  Modal,
  Radio,
  Divider,
  Upload,
  Icon,
  Avatar,
  List,
  Drawer,
  Col,
  Row,
  Select,
} from "antd";
import moment from "moment";
import FormItem from "antd/lib/form/FormItem";
import { status } from "nprogress";
import { values } from "lodash";
import { Link } from "react-router-dom";
import createHistory from "history/createBrowserHistory";

const RadioGroup = Radio.Group;
const { Dragger } = Upload;
const { Option } = Select;
const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px",
};
class AgentSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingCMdbtn: false,
      loadingEPbtn: false,
      retailer: [],
      selectedstatus: [],
      inventorystatus: [],
      search_text: "",
      datefilter: [],
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      office: [],
      agent: [],
      detailform: {},
      statusval: [],
      selectedRowKeys: [],
      hasSelected: false,
      retailview: false,
      downloadLoading: false,
      visible: false,
      record: null,
      fileList: [],
      fileList2: [],
      uploading: false,
      uploading1: false,
      superrole: "",
      cvisible: false,
      Listdata1: [],
      comvisible: false,
      visibleedit: false,
      filteredInfo: null,
      urlString: "",
      redirect_key: "",
      agentstatus: [],
      agentstat: false,
      agentstatusname: ["1"],
      visibleTemplate: false,
      telgooStatus: [],
      current_agent_status: "",
      new_agent_status: "",
    };
  }

  componentWillMount() {
    const history = createHistory();
    const location = history.location;
    console.log(location.pathname);
    localStorage.setItem("currentpath", location.pathname);
    this.props.getretailersonboard().then((res) => {
      this.setState({
        retailer: this.props.data.retailer,
      });
    });
   

    this.props
      .agentstatusaction()
      .then((res) => {
        this.setState({
          agentstatus: res.data,
        });
      })
      .catch((err) => {
        this.setState({
          agentstatus: [],
        });
      });
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }

    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 5) ?? [];
    console.log(`Retailer`, Retailer);
    console.log("this.props?.user?.role_id", this.props?.user?.role_id);
    if (Retailer?.length !== 0) {
      this.setState({
        retailview: true,
      });
    }
    const urlString = this.props.location?.state?.urlpath ?? "";
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
    });
    console.log(paramsobj["redirect_key"]);
    this.setState({
      redirect_key: paramsobj["redirect_key"] ?? "",
      urlString,
    });

    console.log(urlString);
    this.getdesignation({
      url: urlString ?? "",
      results: 10,
      page: 1,
    });
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .agent_summary_report(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.data.total;
        pagination.current = res.data.data.current_page;
        this.setState({
          data: res.data.data.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filters, sorter, option) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    var telgoo_status = values?.telgoo_status ?? [];
    const order =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    const fieldName = sorter.order === undefined ? "" : sorter.field;
    this.setState({
      pagination: pager,
      selectedstatus: telgoo_status.toString(),
      sortField: fieldName,
      sortOrder: order,
    });
    setTimeout(() => {
      this.searchDetailInventory(fieldName, order, pager);
    }, 100);
  };

  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
    console.log(this.state.selectedItems);
  };

  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  // disabledStartDate = (startValue) => {
  //   // const { endValue } = this.state;
  //   // if (!startValue || !endValue) {
  //   //   return false;
  //   // }
  //   // return startValue.valueOf() > endValue.valueOf();/
  //   //return startValue && startValue > moment().endOf("day");
  //   const weekStart = moment().subtract(31,"days");
  //   const weekEnd = moment().endOf("day");
  //   return !(
  //     weekStart.isSameOrBefore(startValue) && weekEnd.isAfter(startValue)
  //   );
  // };
  disabledStartDate = (current) => {
    const currentDate = moment();
    //const fiveMonthsFromNow = moment().add(25, 'week');
    return current && current > currentDate.endOf("day");
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  // disabledEndDate = (endValue) => {
  //   // const { startValue } = this.state;
  //   // if (!endValue || !startValue) {
  //   //   return false;
  //   // }
  //   // return endValue.valueOf() <= startValue.valueOf();
  //   // return endValue && endValue > moment().add(-31, "days");
  //   const weekStart = moment().subtract(31,"days");
  //   const weekEnd = moment().endOf("day");
  //   return !(weekStart.isSameOrBefore(endValue) && weekEnd.isAfter(endValue));
  // };
  disabledEndDate = (current) => {
    const currentDate = moment();
    //const fiveMonthsFromNow = moment().add(25, 'week');
    return current && current > currentDate.endOf("day");
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  clearSearch = () => {
    this.setState({
      name: "",
      startValue: null,
      endValue: null,
      filteredInfo: null,
      urlString: "",
      redirect_key: "",
      agentstatusname: [],
      telgooStatus: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  searchDetailInventory = (sortName, order, pager, reset) => {
    this.setState({ loading: true, loadingbtn: true });
    console.log(this.state.pagination?.current);
    var status = this.state.agentstatusname;
    var telgoo_status = this.state.telgooStatus;
    var redirect_key = this.state.redirect_key;
    var urlString = this.state.urlString;
    var search_text = this.state.name;
    var sort = sortName ?? this.state.sortField;
    var sort_by = order ?? this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var page =
      reset === "reset"
        ? 1
        : pager?.current ?? this.state.pagination?.current ?? 1;
    this.props
      .searchagentsummarylist({
        status: status,
        telgoo_status,
        redirect_key,
        search_text: search_text,
        page: page ?? 1,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        urlString,
      })
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.data.total;
        pagination.current = res.data.data.current_page;
        this.setState({
          data: res.data.data.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingbtn: false,
        });
        message.error(err);
      });
  };

  onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    if (
      newSelectedRowKeys.length === this.state.data.length &&
      this.state.hasSelected
    ) {
      this.setState({
        selectedRowKeys: [],
        hasSelected: false,
      });
    } else {
      const tempdata = this.state.data;
      var selRow = [];
      newSelectedRowKeys.map((selectedRow) => {
        var index = tempdata.findIndex((res) => res.id === selectedRow);
        if (index >= 0) {
          if (
            tempdata[index].form_status === undefined ||
            tempdata[index].form_status === null
          ) {
            selRow.push(selectedRow);
          }
        }
        return selRow;
      });
      this.setState({
        selectedRowKeys: selRow,
        hasSelected: selRow.length > 0,
      });
    }
  };

  handleSendDocument = (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    var ids = this.state.selectedRowKeys?.map(
      (selectedRow, index) => selectedRow
    );
    const params = { type: "agent", ids: ids };
    console.log(`params`, params, "\nids", ids);
    this.props
      .sendform(params)
      .then((res) => {
        message.success(res.data?.message ?? "Document sent successfully");
        this.setState({
          loading: false,
          selectedRowKeys: [],
          hasSelected: false,
        });
        this.clearSearch();
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
        message.error(err);
      });
  };

  handleDownload = (e, record) => {
    e.preventDefault();
    this.setState({ downloadLoading: true });
    this.props
      .downloadform(record.id)
      .then((response) => {
        console.log("download form", response);

        window.open(response?.data?.url ?? "", "_blank");
        // myWindow.document.write(responseHtml);
        this.setState({ downloadLoading: false });
      })
      .catch((ex) => {
        console.log(`error downloading form`, ex);
        this.setState({ downloadLoading: false });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form: { validateFields, setFields, validateFieldsAndScroll },
    } = this.props;

    validateFieldsAndScroll(["IsAccept", "reason"], (err, values) => {
      if (!err) {
        const isAccept = this.props.form.getFieldValue("IsAccept");
        const reason = this.props.form.getFieldValue("reason");
        console.log(
          `reason`,
          this.props.form.getFieldValue("IsAccept"),
          this.props.form.getFieldValue("reason")
        );
        var params = { id: this.state.record.id, status: isAccept ? 4 : 3 };
        if (!isAccept) {
          params.reason = reason;
        }
        this.props.updateformstatus(params).then((response) => {
          console.log("update form", response);
          message.success(
            response.data?.message ?? "Form updated successfully"
          );
          this.setState({
            loading: false,
            selectedRowKeys: [],
            hasSelected: false,
            visible: false,
          });
          this.clearSearch();
        });
      } else {
        setFields({
          reason: {
            value: this.state.reason,
            errors: [new Error("Enter reason for rejecting")],
          },
        });
      }
    });
  };

  showModal = ({ record }) => {
    this.setState({
      visible: true,
      record: record,
    });
    this.props.form.resetFields();
  };

  onClose1 = () => {
    this.setState({
      cvisible: false,
    });
  };
  onClose2 = () => {
    this.setState({
      comvisible: false,
    });
  };
  onClose3 = () => {
    this.setState({new_agent_status:"", activeagent: "", agentstat: false,  });
    this.props.form.resetFields();
  };

  onCloseEdit = () => {
    this.setState({
      visibleedit: false,
    });
  };

  editcommentsuser = (params) => {
    this.setState({ loading: true });
    this.props
      .viewcommentsuseraction(params)
      .then((res) => {
        console.log(res.data.items);
        this.setState({ Listdata1: res.data.items });
        message.success(res.data.message);
        this.setState({ loading: false, cvisible: true });
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };

  addcommentsuser = (params) => {
    this.setState({ comvisible: true, activeagent: params });
  };

  handleCommentSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["admin_comments"],
      (err, values) => {
        console.log(values);
        if (!err) {
          this.setState({ loading: true, loadingCMdbtn: true });
          var obj_arr1 = {
            candidate_id: this.state.activeagent.id,
            comments: values.admin_comments,
          };

          this.props
            .addcommentsuseraction(obj_arr1)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                comvisible: false,
                loading: false,
                loadingCMdbtn: false,
              });
              this.props.form.resetFields();
              this.searchDetailInventory();
            })
            .catch((error) => {
              message.error(error);
              console.log(error);
              this.setState({ loading: false, loadingCMdbtn: false });
            });
        }
      }
    );
  };

  handleSubmit1 = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(["file"], (err, values) => {
      if (!err) {
        this.setState({
          uploading: true,
        });
      }
      if (!err) {
        const { fileList } = this.state;
        console.log(fileList[0]);
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append("upload_file", file);
        });

        this.props
          .agentstatupload(formData)
          .then((res) => {
            console.log(formData);
            this.props.form.resetFields();
            console.log(res);
            message.success(res.data.message);
            this.setState({
              uploading: false,
              fileList: [],
              visibleTemplate: false,
            });
            this.searchDetailInventory();
          })
          .catch((err) => {
            message.error(err);
            this.props.form.resetFields();
            this.setState({
              uploading: false,
              fileList: [],
              // visibleTemplate: false,
            });
          });
      }
    });
  };

  handleSubmit2 = (e) => {
    e.preventDefault();
    e.preventDefault();
    this.props.form.validateFields(["file2"], (err, values) => {
      if (!err) {
        this.setState({
          uploading1: true,
        });
      }
      if (!err) {
        const { fileList2 } = this.state;
        console.log(fileList2[0]);
        const formData = new FormData();
        fileList2.forEach((file2) => {
          formData.append("upload_file", file2);
        });

        this.props
          .agentofficemapingupload(formData)
          .then((res) => {
            console.log(formData);
            this.props.form.resetFields();
            console.log(res);
            message.success(res.data.message);
            this.setState({
              uploading1: false,
              fileList2: [],
            });
            this.searchDetailInventory();
          })
          .catch((err) => {
            message.error(err);
            this.setState({
              uploading1: false,
              fileList2: [],
            });
          });
      }
    });
  };
  handleAgentStat = (e) => {
    e.preventDefault();
    this.props.form.validateFields(
      ["agentstatus", "status_comments", "office_id", "retailer_id"],
      (err, values) => {
        if (!err) {
          this.setState({
            uploading: true,
            loadingCMdbtn: true,
          });
          
          values.agent_id = this.state.activeagent.id;
          console.log(values);
          this.props
            .change_agent_status(values)
            .then((res) => {
              console.log(res);
              message.success(res.data.message);
              this.setState({
                activeagent: "",
                agentstat: false,
                uploading: false,
                loadingCMdbtn: false,
              });
              this.props.form.resetFields();
              this.searchDetailInventory();
              // if( res.data.candidate_info !== null)
              // {
              //   this.props.history.push({
              //     pathname: "/ReactiveAgent",
              //     state: {
              //       agent_info: res.data.candidate_info,
              //     },
              //   });
              // }
             
            })
            .catch((err) => {
              message.error(err);
              this.props.form.resetFields();
              this.setState({
                activeagent: "",
                agentstat: false,
                uploading: false,
                loadingCMdbtn: false,
              });
              this.searchDetailInventory();
            });
        }
      }
    );
  };
  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      fileList: [],
      filters: null,
      filteredInfo: null,
    });
  };
  handleReset2 = () => {
    this.props.form.resetFields();
    this.setState({
      fileList2: [],
    });
  };
  normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    console.log(this.state.fileList);
    return e && e.fileList;
  };
  getrefreshbtn = () => {
    this.setState({
      name: "",
      startValue: null,
      endValue: null,
      urlString: "",
      redirect_key: "",
      filteredInfo: null,
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  getexportbtn = () => {
    this.setState({ loading: true, loadingEPbtn: true });
    var status = this.state.agentstatusname;
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var urlString = this.state.urlString;
    var telgoo_status = this.state.telgooStatus;
    var redirect_key = this.state.redirect_key;

    this.props
      .exportagentsummarylist({
        status: status,
        search_text: search_text,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        urlString,
        telgoo_status,
        redirect_key
      })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  agentstatuscheck = (e) => {
    console.log(e);
    this.setState({ loading: true});
    this.props.getretaileroffice(e.retailer_id).then((res) => {
      this.setState({
        office: res.data,
        activeagent: e, 
        agentstat: true ,
        loading: false,
      });
    })
    .catch((err) => {
     message.error(err);
     this.setState({
       loading: false,
     })
   });
   
  };

  //Modal Button show in agent file upload
  showAgentFilebtn = () => {
    this.setState({
      visibleTemplate: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visibleTemplate: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.props.form.resetFields();
    this.setState({
      visibleTemplate: false,
    });
  };
  //Telgoo status dropdown
  handleChange = (e) => {
    console.log(e.target.value)
    this.setState({
      new_agent_status: e.target.value,
    });
  };

  renderOffice() {
    return _.map(this.state.agentstatus, (master, index) => {
      if (master.id !== -1 && master.id !== 8) {
        // console.log(master.id, master.name);
        return (
          <Radio style={radioStyle} value={master.id} key={index}>
            {master.name}
          </Radio>
          // <Option value={master.id} key={index}>
          //   {master.name}
          // </Option>
        );
      }
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  handleRetailerChange = (retailer_id) => {
    console.log(retailer_id);
    this.props.getretaileroffice(retailer_id).then((res) => {
      console.log(res);
      this.setState({
        office: res.data,
      });
    });
  };
  // ======================================================

  render() {
    let { filteredInfo } = this.state;
    filteredInfo = filteredInfo || {};
    const { fileList, fileList2, superrole } = this.state;
    const propsthumb = {
      multiple: false,

      accept: ".xlsx,.csv",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const propsthumb2 = {
      multiple: false,
      fileList: fileList2,
      accept: ".xlsx,.csv",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file2) => {
        this.setState((state) => {
          const index = state.fileList2.indexOf(file2);
          const newFileList = state.fileList2.slice();
          newFileList.splice(index, 1);
          return {
            fileList2: newFileList,
          };
        });
        console.log(this.state.fileList2);
      },
      beforeUpload: (file2) => {
        console.log(file2);
        this.setState((state) => ({
          fileList2: [...state.fileList2, file2],
        }));
        return false;
      },
      fileList2,
    };

    const {
      endOpen,
      startValue,
      endValue,
      selectedRowKeys,
      hasSelected,
      retailview,
      downloadLoading,
    } = this.state;
    const { getFieldDecorator } = this.props.form;

    const rowSelection = retailview
      ? {
          selectedRowKeys,
          onChange: this.onSelectChange,
        }
      : {};

    const columns = [
      {
        title: "Retailer Name",
        dataIndex: "user_retailer_name",
        key: "user_retailer_name",
        hidden: superrole === true ? false : true,
      },
      {
        title: "IBOs / Markets",
        dataIndex: "user_ibo_market_name",
        key: "user_ibo_market_name",
      },
      {
        title: "Office",
        dataIndex: "office_number",
        key: "office_number",
        sorter: true,
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: true,
      },
      {
        title: "Middle Name",
        dataIndex: "middle_name",
        key: "middle_name",
        sorter: true,
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Mobile number",
        dataIndex: "mobile_number",
        key: "mobile_number",
      },
      {
        title: "RAD ID",
        dataIndex: "rad",
        key: "rad",
      },
      {
        title: "ETC Code",
        dataIndex: "excess_code",
        key: "excess_code",
      },
      {
        title: "Profile Picture",
        dataIndex: "candidate_info",
        align: "center",
        key: "candidate_info",
        render: (_, record) => (
          <span>
            {record?.photo_copy_url !== null && (
              <a
                href={record?.photo_copy_url ?? ""}
                target="_blank"
                rel="noreferrer"
              >
                <Avatar
                  shape="square"
                  size={64}
                  src={record?.photo_copy_url ?? ""}
                />
              </a>
            )}
          </span>
        ),
      },
      {
        title: "Government ID",
        dataIndex: "Government Document",
        key: "Government Document",
        align: "center",
        render: (_, record) => (
          <span>
            {record?.government_document_url !== null && (
              <a
                href={record?.government_document_url ?? ""}
                target="_blank"
                rel="noreferrer"
              >
                <Avatar
                  shape="square"
                  size={64}
                  src={record?.government_document_url ?? ""}
                />
              </a>
            )}
          </span>
        ),
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },

      {
        title: "City",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "Zip",
        dataIndex: "zip",
        key: "zip",
      },
      // {
      //   title: "Telgoo status updated",
      //   dataIndex: "updated_at",
      //   key: "updated_at",
      // },

      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },
      // {
      //   title: "Comments",
      //   dataIndex: "status_comments",
      //   key: "status_comments",
      // },
      {
        title: "Comments Action",
        dataIndex: "caction",
        key: "caction",
        fixed: "right",
        align: "center",
        render: (_, record) => (
          <span>
            {record.comments_exists === 0 ? (
              <>
                <Tooltip title="View Log">
                  <Button
                    shape="circle"
                    icon="eye"
                    disabled
                    type="primary"
                  ></Button>
                </Tooltip>
                <Divider type="vertical" />
              </>
            ) : (
              <>
                <Tooltip title="View Log">
                  <Button
                    shape="circle"
                    icon="eye"
                    onClick={() => this.editcommentsuser(record)}
                    type="primary"
                  ></Button>
                </Tooltip>
                <Divider type="vertical" />
              </>
            )}
            {superrole && (
              <Tooltip title="Add Comments">
                <Button
                  shape="circle"
                  icon="message"
                  onClick={() => this.addcommentsuser(record)}
                  type="primary"
                ></Button>
              </Tooltip>
            )}
          </span>
        ),
        // hidden: superrole === true ? false : true,
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        fixed: "right",
        width: 100,
        render: (text, record) => (
          <span>
            <Tag color={record.status === 1 ? "green" : "orange"}>
              {/* {record.status === 1 ? "Active" : "Inactive"} */}
              {record.status_text}
            </Tag>
          </span>
        ),
      },
      // {
      //   title: "Telgoo Status",
      //   dataIndex: "telgoo_status",
      //   key: "telgoo_status",
      //   fixed: "right",
      //   width: 100,
      //   render: (text, record) => (
      //     <span>
      //       <Tag
      //         color={record.telgoo_status_id === 1 ? "green" : "orange"}
      //       >
      //         {record.telgoo_status}
      //       </Tag>
      //     </span>
      //   ),
      // },
      // {
      //   title: "Form Status",
      //   dataIndex: "form_status",
      //   key: "form_status",

      //   fixed: "right",
      //   width: 100,
      //   hidden: !retailview,
      //   render: (text, record) => (
      //     <span>
      //       <Tag
      //         color={
      //           record.form_status === undefined || record.form_status === null
      //             ? "red"
      //             : record.form_status?.toLowerCase() === "completed"
      //             ? "green"
      //             : "orange"
      //         }
      //       >
      //         {record.form_status === undefined || record.form_status === null
      //           ? "Not-Started"
      //           : record.form_status}
      //       </Tag>
      //     </span>
      //   ),
      // },
      {
        title: "Action",
        key: "action",
        width: 80, //: 80,
        fixed: "right",
        hidden: !superrole,
        render: (_, record) => (
          <div>
            <div>
              <span>
                {record.status !== 9 && record.status !== 10 ? (   
                <Tooltip
                  title="Change Status"
                  className={"padd5 mr5"}
                  style={{ marginRight: 10 }}
                >
                  <Button
                    type="primary"
                    shape="circle"
                    icon="user" //disabled={downloadLoading}
                    onClick={(e) => {
                      this.agentstatuscheck(record);
                      //console.log("first",record)
                    }}
                  />
                </Tooltip>) : (<Button
                    type="primary"
                    shape="circle"
                    icon="user" //disabled={downloadLoading}
                    disabled
                  />)}
               
               
              </span>
            </div>
          </div>
        ),
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey mb-3">
            <div className="col-sm-4">
              <h4>
                Agent Summary{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              <Button
                type="default"
                className="mr5"
                onClick={() => this.getrefreshbtn()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>

              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>

              {/* {this.state.superrole === true && (
                <Button type="primary" onClick={this.showAgentFilebtn}>
                  Bulk Update Agent Status
                </Button>
              )} */}
            </div>
            {/* Update Agent Status Modal button view */}
            {this.state.superrole === true && (
              <>
                <div>
                  {/* <Button type="primary" onClick={this.showAgentFilebtn}>
                    Update Agent Status
                  </Button> */}
                  <Modal
                    title="Upload Agent Status"
                    visible={this.state.visibleTemplate}
                    // onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={
                      [
                        //   <FormItem>
                        //   <Button
                        //   className="mt-3"
                        //   type="primary"
                        //   htmlType="submit"
                        //   loading={this.state.uploading}
                        // >
                        //   Upload
                        // </Button>
                        // <Button
                        //   style={{ marginLeft: 8 }}
                        //   onClick={this.handleReset}
                        //   >
                        //   Clear
                        // </Button>
                        // </FormItem>
                      ]
                    }
                  >
                    <div className="text-right col-12">
                      <a
                        href="https://wieserp.s3.amazonaws.com/Sample+Template/sample_template_agent_status_update.csv"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          icon="file-excel"
                          type="default"
                          className="samplefilebtn"
                        >
                          Agent Template
                        </Button>
                      </a>
                    </div>
                    <Form className="col-12" onSubmit={this.handleSubmit1}>
                      <Form.Item label="Upload Agent Status">
                        {getFieldDecorator("file", {
                          rules: [
                            {
                              required: true,
                              message: "Please select file",
                            },
                          ],
                        })(
                          <Dragger {...propsthumb}>
                            <p className="ant-upload-drag-icon">
                              <Icon type="inbox" />
                            </p>
                            <p className="ant-upload-text">
                              Click or drag file to this area to upload
                            </p>
                            <p className="ant-upload-hint">
                              Support for a single or bulk upload. Strictly
                              prohibit from uploading company data or other band
                              files
                            </p>
                          </Dragger>
                        )}
                      </Form.Item>

                      <Form.Item>
                        <Button
                          className="mt-3"
                          type="primary"
                          htmlType="submit"
                          loading={this.state.uploading}
                        >
                          Upload
                        </Button>
                        <Button
                          style={{ marginLeft: 8 }}
                          onClick={this.handleReset}
                        >
                          Clear
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>
                </div>
              </>
            )}
          </div>
          {/* {this.state.superrole === true && (
            <>
              <div className="row" style={{ justifyContent: "space-between" }}>
                <div className="text-right col-6">
                  <a
                    href="https://wieserp.s3.amazonaws.com/Sample+Template/sample_template_agent_status_update.csv"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      icon="file-excel"
                      type="default"
                      className="samplefilebtn"
                    >
                      Agent Template
                    </Button>
                  </a>
                </div>

                <div className="text-right col-6">
                  <a
                    href="https://wieserp.s3.amazonaws.com/Sample+Template/sample_template_agent_office_mapping.csv"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      icon="file-excel"
                      type="default"
                      className="samplefilebtn"
                    >
                      Office Template
                    </Button>
                  </a>
                </div>
              </div>
              <div className="row">
                <Form className="col-6" onSubmit={this.handleSubmit1}>
                  <Form.Item label="Upload Agent Status">
                    {getFieldDecorator("file", {
                      rules: [
                        {
                          required: true,
                          message: "Please select file",
                        },
                      ],
                    })(
                      <Dragger {...propsthumb}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          Support for a single or bulk upload. Strictly prohibit
                          from uploading company data or other band files
                        </p>
                      </Dragger>
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="mt-3"
                      type="primary"
                      htmlType="submit"
                      loading={this.state.uploading}
                    >
                      Upload
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      onClick={this.handleReset}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Form>

                <Form className="col-6" onSubmit={this.handleSubmit2}>
                  <Form.Item label="Upload Office Mapping">
                    {getFieldDecorator("file2", {
                      rules: [
                        {
                          required: true,
                          message: "Please select file",
                        },
                      ],
                    })(
                      <Dragger {...propsthumb2}>
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag file to this area to upload
                        </p>
                        <p className="ant-upload-hint">
                          Support for a single or bulk upload. Strictly prohibit
                          from uploading company data or other band files
                        </p>
                      </Dragger>
                    )}
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="mt-3"
                      type="primary"
                      htmlType="submit"
                      loading={this.state.uploading1}
                    >
                      Upload
                    </Button>
                    <Button
                      style={{ marginLeft: 8 }}
                      onClick={this.handleReset2}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </>
          )} */}

          <div className="row padd20 bg-grey">
            <div className="col">
              <label for="exampleFormControlInput1">From</label>
              <DatePicker
                value={startValue}
                disabledDate={this.disabledStartDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
            </div>
            <div className="col">
              <label for="exampleFormControlInput1">To</label>
              <DatePicker
                value={endValue}
                disabledDate={this.disabledEndDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>
            <div className="col">
              <label for="exampleFormControlInput1">Agent Status</label>
              <Select
                showSearch
                mode="multiple"
                value={this.state.agentstatusname}
                onChange={(e) =>
                  this.setState({
                    agentstatusname: e,
                  })
                }
                style={{ width: "100%" }}
                placeholder="Select Option"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.agentstatus.map((d) => (
                  <Option key={d.id}>{d.name}</Option>
                ))}
              </Select>
            </div>

            {/* <div className="col">
              <label for="exampleFormControlInput1">Telgoo Status</label>
              <Select
                showSearch
                mode="multiple"
                value={this.state.telgooStatus}
                onChange={(e) =>
                  this.setState({
                    telgooStatus: e,
                  })
                }
                style={{ width: "100%" }}
                placeholder="Select Option"
              >
                <option value="1">Active</option>
                <option value="0">InActive</option>
                <option value="2">Others</option>
              </Select>
            </div> */}
            <div className="col">
              <label for="exampleFormControlInput1">Search By keyword</label>
              <Input
                placeholder="Type keyword"
                value={this.state.name}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>

            <div className="col mt-6">
              <Button
                type="primary"
                className="mr2"
                loading={this.state.loadingbtn}
                onClick={() =>
                  this.searchDetailInventory("", null, null, "reset")
                }
              >
                Search
              </Button>
              <Button
                type="default"
                className="mr-1"
                style={{ marginLeft: 8 }}
                onClick={() => this.clearSearch()}
              >
                Clear
              </Button>
              {hasSelected ? (
                <Button
                  type="primary"
                  className="login-form-button"
                  loading={this.state.loading}
                  onClick={this.handleSendDocument}
                >
                  Send Document
                </Button>
              ) : null}
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // rowSelection={retailview ? rowSelection : undefined}
              // size="small"
            />
          </div>
        </div>
        <div className="row padd20 bg-grey">
          <Modal
            title={"Accept/Reject documents"}
            visible={this.state.visible}
            // onOk={this.handleSubmit}
            onCancel={() => {
              this.setState({
                visible: false,
                record: null,
              });
            }}
            footer={null}
          >
            <Form className="" onSubmit={this.handleSubmit}>
              <Form.Item
                label="Accept/Reject"
                className="col-md-2 col-sm-3 col-xs-12 paddlr15"
              >
                {getFieldDecorator("IsAccept", {
                  initialValue: true,
                  rules: [
                    {
                      required: true,
                      message: "Please input is accept!",
                    },
                  ],
                })(
                  <RadioGroup>
                    <Radio value={true}>Accept</Radio>
                    <Radio value={false}>Reject</Radio>
                  </RadioGroup>
                )}
              </Form.Item>
              <Form.Item label="Enter reason to reject">
                {getFieldDecorator("reason", {
                  rules: [
                    {
                      required: this.props.form.getFieldValue("IsAccept")
                        ? false
                        : true,
                      message: "Please enter reason!",
                    },
                  ],
                })(
                  <Input
                    placeholder="Enter reason to reject"
                    disabled={this.props.form.getFieldValue("IsAccept")}
                  />
                )}
              </Form.Item>
              <div className="paddlr15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  // loading={loading}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Modal>
          <Drawer
            title="View Comments"
            placement="right"
            closable={true}
            width={420}
            onClose={this.onClose1}
            visible={this.state.cvisible}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.Listdata1}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.created_at}
                    description={
                      <Tag color="orange">
                        {item.comment_with_username}
                      </Tag>
                    }
                  />
                </List.Item>
              )}
            />
          </Drawer>
          <Drawer
            title="Add Comments"
            placement="right"
            closable={true}
            width={540}
            onClose={this.onClose2}
            visible={this.state.comvisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleCommentSubmit}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Comments">
                    {getFieldDecorator("admin_comments", {
                      rules: [
                        {
                          required: true,
                          message: "Please input comments",
                        },
                      ],
                    })(
                      <Input.TextArea
                        rows={8}
                        placeholder="Write something..."
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onCloseEdit} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={this.state.loadingCMdbtn}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>
          <Drawer
            title={`Update Agent Status - ${
              this.state.activeagent?.name ?? ""
            }`}
            placement="right"
            closable={true}
            width={540}
            onClose={this.onClose3}
            visible={this.state.agentstat}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleAgentStat}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Agent Status">
                    {getFieldDecorator("agentstatus", {
                      initialValue: this.state.activeagent?.status,
                      rules: [
                        {
                          required: true,
                          message: "Please select any status",
                        },
                      ],
                    })(
                      <Radio.Group onChange={this.handleChange}>
                        {this.renderOffice()}
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                {this.state.new_agent_status === 10 && (
                  <>
                   <Col span={24}>
                  <Form.Item label="Retailer">
                    {getFieldDecorator("retailer_id", {
                      initialValue: this.state.activeagent?.retailer_id,
                      rules: [
                        {
                          required: true,
                          message: "Please select retailer",
                        },
                      ],
                    })(
                      <Select
                        onChange={this.handleRetailerChange}
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Option"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.renderRetailer()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                
                {this.state.office.length > 0 && (
                  <Col span={24}>
                    <Form.Item label="Office">
                      {getFieldDecorator("office_id", {
                        // initialValue: this.state.activeagent?.office_id,
                        rules: [
                          {
                            required: true,
                            message: "Please select office",
                          },
                        ],
                      })(
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select Option"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {this.state.office.map((d) => (
                            <Option key={d.id}>{d.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                )}
                  </>
                )}
               

                <Col span={24} className="mt-3">
                  <Form.Item label="Comments">
                    {getFieldDecorator("status_comments", {
                      rules: [
                        {
                          required: false,
                          message: "Please input comments",
                        },
                      ],
                    })(
                      <Input.TextArea
                        rows={8}
                        placeholder="Write something..."
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={24} className="mt-5">
                  <Button onClick={this.onClose3} style={{ marginRight: 8 }}>
                    Cancel
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={this.state.loadingCMdbtn}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
    userinfo: state.userinfo,
  };
}

const WrappedAdvancedSearchForm = Form.create({ name: "advanced_search" })(
  AgentSummary
);

export default connect(mapStateToProps, {
  searchagentsummarylist,
  exportagentsummarylist,
  agent_summary_report,
  sendform,
  downloadform,
  updateformstatus,
  agentstatupload,
  agentofficemapingupload,
  viewcommentsuseraction,
  addcommentsuseraction,
  agentstatusaction,
  change_agent_status,
  getretailersonboard,
  getretaileroffice,
})(WrappedAdvancedSearchForm);