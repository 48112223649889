import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { inventory_dashboard } from "../../actions/inventoryAction";
import _ from "lodash";
import { Button, Table, Tag, Input, Icon, Spin, Select } from "antd";
import moment from "moment-timezone";
// import moment from 'moment';
import { getApiPath } from "./constants";
const { Option } = Select;
//const rolepath = getApiPath();
class InventoryOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventory_stats: [],
      aged_inventory: [],
      loading: true,
      rolepath: {},
      startdate: "",
      end7date: "",
      end30date: "",
      dashdata: "",
    };
  }

  componentWillMount() {
    console.log(
      moment().subtract(1, "days").tz("America/New_York").format("MM-DD-YYYY")
    );
    console.log(
      moment().subtract(7, "days").tz("America/New_York").format("MM-DD-YYYY")
    );
    console.log(
      moment().subtract(30, "days").tz("America/New_York").format("MM-DD-YYYY")
    );
    this.getdesignation();
    //console.log(rolepath);
    this.setState({
      rolepath: getApiPath(),
      startdate: moment().subtract(1, "days").format("MM-DD-YYYY"),
      end7date: moment().subtract(7, "days").format("MM-DD-YYYY"),
      end30date: moment().subtract(30, "days").format("MM-DD-YYYY"),
    });
    console.log("role-path", getApiPath());
  }

  getdesignation() {
    this.props
      .inventory_dashboard()
      .then((res) => {
        this.setState({
          inventory_stats: res.data.items.inventory_stats,
          aged_inventory: res.data.items.aged_inventory,
          dashdata: res.data.items.last_run_date,
          loading: false,
        });
        console.log(this.state.inventory_stats);
        console.log(res.data.items.last_run_date)
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }

  // ======================================================

  render() {
    const { inventory_stats, aged_inventory, rolepath, dashdata } = this.state;
    console.log(dashdata);
    return (
      <div>
        <Spin spinning={this.state.loading}>
          <div className="container-fluid">
            <div className="row padd20 bg-grey">
              <div className="col-sm-4">
                <h4>Dashboard</h4>
              </div>
            
              <div className="col-md-8">
                <h6 className="mb-4 text-right" style={{ fontSize: 13 }}>
                  <Icon type="clock-circle" /> Last Updated at:{" "}
                  {dashdata ?? ""}
                </h6>
              </div>
            </div>
          

            <div className="padd0">
              <div className="container-fluid">
                <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
                  {this.props?.user?.role_id[0] === 1 && (
                    <div className="col">
                      <Link
                        to={{
                          pathname: "/detailinventory",
                          urlpath: `/api/v1/detailed_inventory_report?status=14`,
                        }}
                      >
                        <div className="card radius-10 border-start border-0 border-3 border-info">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div>
                                <p className="mb-0 text-secondary">
                                  Total Stock
                                </p>
                                <h3 className="my-1 text-info">
                                  {inventory_stats.total_stock}
                                </h3>
                                <p className="mb-0 font-13 visible-none">
                                  +2.5% from last week
                                </p>
                              </div>
                              <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                                <i className="fa fa-cubes"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )}
                  {/* pending allocation */}
                  {this.props?.user?.role_id[0] === 1 && (
                    <div className="col">
                      <Link
                        to={{
                          pathname: "/detailinventory",
                          urlpath: `/api/v1/detailed_inventory_report?status=0`,
                        }}
                      >
                        <div className="card radius-10 border-start border-0 border-3 border-warning">
                          <div className="card-body">
                            <div className="d-flex align-items-center">
                              <div>
                                <p className="mb-0 text-secondary">
                                  Pending Allocation
                                </p>
                                <h3 className="my-1 text-warning">
                                  {inventory_stats.pending_allocation}
                                </h3>
                                <p className="mb-0 font-13 visible-none">
                                  -4.5% from last week
                                </p>
                              </div>
                              <div className="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto">
                                <i className="fa fa-cubes font-55"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )}
                  <Link
                    to={{
                      pathname: "/detailinventory",
                      urlpath: `/api/v1/detailed_inventory_report?status=13`,
                    }}
                  >
                    <div className="card radius-10 border-start border-0 border-3 border-info">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div>
                            <p className="mb-0 text-secondary">In-Stock</p>
                            <h3 className="my-1 text-info">
                              {inventory_stats.instock}
                            </h3>
                            <p className="mb-0 font-13 visible-none">
                              +2.5% from last week
                            </p>
                          </div>
                          <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                            <i className="fa fa-cubes"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                  <div className="col">
                    <Link
                      to={{
                        pathname: "/salessummaryreport",
                        urlpath: `/api/v1/detailed_sales_report?date_range_start=${this.state.end7date}&date_range_end=${this.state.startdate}`,
                      }}
                    >
                      <div className="card radius-10 border-start border-0 border-3 border-success">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">Sold</p>
                              <h3 className="my-1 text-success">
                                {" "}
                                {inventory_stats.last_week_sold}
                              </h3>
                              <p className="mb-0 font-13 text-secondary">
                                Last 7 days
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                              <i className="fa fa-mobile font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                  <Link
                        to={{
                          pathname: "/detailinventory",
                          urlpath: `/api/v1/detailed_inventory_report?status=9`,
                        }}
                      >
                      <div className="card radius-10 border-start border-0 border-3 border-warning">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">
                                Manager Pending
                              </p>
                              <h3 className="my-1 text-warning">
                                {" "}
                                {inventory_stats.manager_pending}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                +5.4% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto">
                              <i className="fa fa-clock-o font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col">
                  <Link
                        to={{
                          pathname: "/detailinventory",
                          urlpath: `/api/v1/detailed_inventory_report?status=3`,
                        }}
                      >
                      <div className="card radius-10 border-start border-0 border-3 border-success">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">
                                Manager Received
                              </p>
                              <h3 className="my-1 text-success">
                                {" "}
                                {inventory_stats.manager_assigned}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                -4.5% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                              <i className="fa fa-cubes font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                  <Link
                        to={{
                          pathname: "/detailinventory",
                          urlpath: `/api/v1/detailed_inventory_report?status=6`,
                        }}
                      >
                      <div className="card radius-10 border-start border-0 border-3 border-success">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">
                                Agent Transfer
                              </p>
                              <h3 className="my-1 text-success">
                                {inventory_stats.agent_transfer}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                -4.5% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                              <i className="fa fa-history font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                    <Link
                      to={{
                        pathname: "/salessummaryreport",
                        urlpath: `/api/v1/detailed_sales_report?date_range_start=${this.state.end30date}&date_range_end=${this.state.startdate}`,
                      }}
                    >
                      <div className="card radius-10 border-start border-0 border-3 border-success">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">Sold</p>
                              <h3 className="my-1 text-success">
                                {inventory_stats.last_month_sold}
                              </h3>
                              <p className="mb-0 font-13 text-secondary">
                                {" "}
                                Last 30 days
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                              <i className="fa fa-mobile font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                  <Link
                        to={{
                          pathname: "/detailinventory",
                          urlpath: `/api/v1/detailed_inventory_report?status=4`,
                        }}
                      >
                      <div className="card radius-10 border-start border-0 border-3 border-warning">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">
                                Agent Pending
                              </p>
                              <h3 className="my-1 text-warning">
                                {inventory_stats.agent_pending}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                -4.5% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto">
                              <i className="fa fa-cube"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                  <Link
                        to={{
                          pathname: "/detailinventory",
                          urlpath: `/api/v1/detailed_inventory_report?status=5`,
                        }}
                      >
                      <div className="card radius-10 border-start border-0 border-3 border-success">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">
                                Agent Received
                              </p>
                              <h3 className="my-1 text-success">
                                {inventory_stats.agent_assigned}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                -4.5% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                              <i className="fa fa-cubes font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                    <Link
                      to={{
                        pathname: "/detailinventory",
                        urlpath: `/api/v1/detailed_inventory_report?status=11`,
                      }}
                    >
                      <div className="card radius-10 border-start border-0 border-3 border-warning">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">Pending RMA</p>
                              <h3 className="my-1 text-warning">
                                {" "}
                                {inventory_stats.pending_rma}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                -4.5% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-blooker text-white ms-auto">
                              <i className="fa fa-clock-o font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                    <Link
                      to={{
                        pathname: "/detailinventory",
                        urlpath: `/api/v1/detailed_inventory_report?status=10`,
                      }}
                    >
                      <div className="card radius-10 border-start border-0 border-3 border-danger">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">RMA</p>
                              <h3 className="my-1 text-danger">
                                {" "}
                                {inventory_stats.rma}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                +2.5% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                              <i className="fa fa-mobile font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                    <Link
                      to={{
                        pathname: "/detailinventory",
                        urlpath: `/api/v1/detailed_inventory_report?status=15`,
                      }}
                    >
                      <div className="card radius-10 border-start border-0 border-3 border-danger">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">Device Swap</p>
                              <h3 className="my-1 text-danger">
                                {" "}
                                {inventory_stats.device_swap}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                +2.5% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                              <i className="fa fa-mobile font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                  <div className="col">
                    <Link
                      to={{
                        pathname: "/detailinventory",
                        urlpath: `/api/v1/detailed_inventory_report?status=12`,
                      }}
                    >
                      <div className="card radius-10 border-start border-0 border-3 border-success">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div>
                              <p className="mb-0 text-secondary">
                                Manager Not Received
                              </p>
                              <h3 className="my-1 text-success">
                                {" "}
                                {inventory_stats.manager_not_received}
                              </h3>
                              <p className="mb-0 font-13 visible-none">
                                -4.5% from last week
                              </p>
                            </div>
                            <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                              <i className="fa fa-mobile font-55"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="bg-grey">
                  <h4 className="mb-2">Inventory Summary</h4>
                </div>

                <div className="row mt-4">
                  <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <div className="border shadow-sm mb-2">
                      <div className="bg-primary p-2">
                        <h5 className="mb-0 text-white text-center">
                          1-15 DAYS
                        </h5>
                      </div>
                      <div className="p-3 bg-white">
                        <h4 className="text-center mb-0">
                          {aged_inventory.LT15}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <div className="border shadow-sm mb-2">
                      <div className="bg-primary p-2">
                        <h5 className="mb-0 text-white text-center">
                          16-30 DAYS
                        </h5>
                      </div>
                      <div className="p-3 bg-white">
                        <h4 className="text-center mb-0">
                          {aged_inventory.LT30}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <div className="border shadow-sm mb-2">
                      <div className="bg-primary pt-2 pb-2">
                        <h5 className="mb-0 text-white text-center">
                          31-45 DAYS
                        </h5>
                      </div>
                      <div className="p-3 bg-white">
                        <h4 className="text-center mb-0">
                          {aged_inventory.LT45}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <div className="border shadow-sm mb-2">
                      <div className="bg-primary p-2">
                        <h5 className="mb-0 text-white text-center">
                          46-90 DAYS
                        </h5>
                      </div>
                      <div className="p-3 bg-white">
                        <h4 className="text-center mb-0">
                          {aged_inventory.LT90}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <div className="border shadow-sm mb-2">
                      <div className="bg-primary p-2">
                        <h5 className="mb-0 text-white text-center">
                          {" "}
                          {"\u003E"}90 DAYS
                        </h5>
                      </div>
                      <div className="p-3 bg-white">
                        <h4 className="text-center mb-0">
                          {aged_inventory.GT90}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-4 col-md-3 col-lg-2">
                    <div className="border shadow-sm mb-2">
                      <div className="bg-dark pt-2 pb-2">
                        <h5 className="mb-0 text-white text-center">
                          Total Inventory
                        </h5>
                      </div>
                      <div className="p-3 bg-white">
                        <h4 className="text-center mb-0">
                          {aged_inventory.Grand_Total}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  inventory_dashboard,
})(InventoryOrder);
