import axiosInstance from "./AxiosInstance";
import {
  GET_DISTRIBUTOR,
  GET_MASTER,
  GET_RETAILER,
  GET_IBO_MARKET,
  GET_OFFICE,
  GET_DOCUMENTS,
} from "../actionTypes";
import moment from "moment";


// Reset password link
export function getModules() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/modules")
        .then(function (response) {
          console.log(response);
          const newArr1 = response.data.map((v) => ({
            ...v,
            key: v.id,
          }));
          resolve(newArr1);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Reset password link
export function getrolelist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/roles?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function createRoles(values, permission) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        name: values.desgRole,
        status: values.IsActive,
        desc: values.Description,
        permission,
        level: "1",
      };
      axiosInstance
        .post("/api/v1/roles", obj_arr)
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function editRoleList(values, permission, roleid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        name: values.desgRole,
        status: values.IsActive === "true" ? "1" : "0",
        desc: values.Description,
        permission,
        level: "1",
        _method: "PUT",
      };
      axiosInstance
        .post("/api/v1/roles/" + roleid.id, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getretailers() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/retailers")
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_RETAILER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//onboarding retailer dropdown
export function getretailersonboard() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/retailers")
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_RETAILER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getdistributers() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/distributors")
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_DISTRIBUTOR,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getmaster() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/master_agents")
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function createibomarket(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        name: values.iboname,
        retailer_id: values?.RetailerName ?? "",
        manager_id: values?.manager_id ?? "",
        supervisor_id: values?.supervisor_id ?? "",
      };
      axiosInstance
        .post("/api/v1/ibo_marketers", obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function updateibomarket(values, market) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        name: values.iboname,
        retailer_id: values?.RetailerName ?? "",
        _method: "PUT",
        manager_id: values?.manager_id ?? "",
        supervisor_id: values?.supervisor_id ?? "",
      };
      axiosInstance
        .post("/api/v1/ibo_marketers/" + market.id, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function createoffice(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        state_short_name: values.state_short_name,
        ibo_marketer_id:
          values.ibo_marketer_id === undefined ? "" : values.ibo_marketer_id,
        manager_id:
          Array.isArray(values.manager_id) || values.manager_id === undefined
            ? ""
            : values.manager_id,
        supervisor_id:
          values.supervisor_id === undefined ? "" : values.supervisor_id,
      };
      console.log(obj_arr);
      axiosInstance
        .post("/api/v1/offices", obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function updateoffice(values) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(values);
      var obj_arr = {
        name: values.officename,
        ibo_marketer_id:
          values.ibo_marketer_id === undefined ? "" : values.ibo_marketer_id,
        _method: "PUT",
        manager_id: values?.manager_id ?? "",
        supervisor_id:
          values.supervisor_id === undefined ? "" : values.supervisor_id,
        agent_id: [],
      };
      console.log(obj_arr);
      axiosInstance
        .post("/api/v1/offices/" + values.id, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getibomarketlist() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/ibo_marketers")
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_IBO_MARKET,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function getibomarketlistv2(retailer_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/get_ibo_info_v2/${retailer_id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_IBO_MARKET,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getibomarketlistnew(retailer) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/ibo_marketers/" + retailer)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_IBO_MARKET,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}


export function getibomarket(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/ibo_marketers?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_IBO_MARKET,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchibomarket({
  status,
  search_text,
  retailer,
  page,
  sort,
  sort_by,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/ibo_marketers?status=${status ?? ""}&search_text=${
        search_text ?? ""
      }&retailer=${retailer ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&page=${page ?? 1}`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getmasterlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/master_agents?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchmasterlist({ status, search_text, page, sort, sort_by }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(sort, sort_by);
      var url = `/api/v1/master_agents?status=${status ?? ""}&search_text=${
        search_text ?? ""
      }&sort=${sort ?? ""}&sort_by=${sort_by ?? ""}&page=${page ?? 1}`;
      console.log(`Master url: ${url}`);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getdistributorlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/distributors?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_DISTRIBUTOR,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchdistributorlist({
  status,
  search_text,
  master_agent,
  page,
  sort,
  sort_by,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/distributors?status=${status ?? ""}&search_text=${
        search_text ?? ""
      }&master_agent=${master_agent ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&page=${page ?? 1}`;
      console.log(`distributors url: ${url}`);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchofficelist({
  status,
  ibo_marketer,
  search_text,
  page,
  sort,
  sort_by,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/offices?status=${status ?? ""}&search_text=${
        search_text ?? ""
      }&ibo_marketer=${ibo_marketer ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&page=${page ?? 1}`;
      console.log(`offices url: ${url}`);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getretailerlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/retailers?page=" + params.page)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_RETAILER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchretailerlist({
  status,
  search_text,
  distributor,
  page,
  sort,
  sort_by,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/retailers?status=${status ?? ""}&search_text=${
        search_text ?? ""
      }&distributor=${distributor ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&page=${page ?? 1}`;
      console.log(`Retailers url: ${url}`);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getofficelist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/offices?page=" + params?.page ?? 1)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_OFFICE,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getmarketerdoclist(user_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // resolve({
      //   "data":
      //     [
      //       {
      //         "form_name": "Application 1", "field_1": "Name 1", "field_2": "Designation 1",
      //         "field_3": "Address 1", "field_4": "City 1", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 2", "field_1": "Name 2", "field_2": "Designation 2",
      //         "field_3": "Address 2", "field_4": "City 2", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 3", "field_1": "Name 3", "field_2": "Designation 3",
      //         "field_3": "Address 3", "field_4": "City 3", "update_at": "Today", "status": "Active"
      //       },
      //       {
      //         "form_name": "Application 4", "field_1": "Name 4", "field_2": "Designation 4",
      //         "field_3": "Address 4", "field_4": "City 4", "update_at": "Today", "status": "Active"
      //       },
      //     ]
      // })
      axiosInstance
        .get(`/api/v1/get_document_list/${user_id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_DOCUMENTS,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function cleardatadb() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/truncate_inventory_data")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getsortlistoffice() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/office_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getsortlistmaster() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/master_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getsortlistmarket() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/ibo_marketer_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// Get roles list
export function getsortlistdistributor() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/distributor_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
// Get roles list
export function getsortlistretailer() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/retailer_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getofficelistname() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/offices")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function getagentlistname() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchdetailinventorylist(
  val, 
  page, 
  sort, 
  sort_by,
  office_id,
  agent,
  // status,
  search_text,
  activation_date_range_start,
  activation_date_range_end,
  date_range_start,date_range_end,
  retailer_id,
  order_id,
  enroll_id,
  carton,
  imei,
  device_type,
  status,
  market,
  rma_order_id,

  ) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(val);
      if (date_range_start && date_range_end) {
        var StartDate = moment(date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(date_range_end).format("MM-DD-YYYY");
      }

      if (activation_date_range_start && activation_date_range_end) {
        var StartAllocateDate = moment(activation_date_range_start).format("MM-DD-YYYY");
        var EndAllocateDate = moment(activation_date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      console.log(StartAllocateDate, EndAllocateDate);
      var url = `/api/v1/detailed_inventory_report?keyword=${
        search_text ?? ""
      }&agent_id=${agent ?? ""}&office_id=${
        office_id ?? ""
      }&date_range_start=${
        StartDate ?? ""
      }&date_range_end=${EndDate ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&page=${page ?? 1}
      &activation_date_range_start=${
        StartAllocateDate ?? ""
      }&activation_date_range_end=${EndAllocateDate ?? ""}&retailer_id=${
        retailer_id ?? ""
      }&order_id=${
        order_id ?? ""
      }&enroll_id=${
        enroll_id ?? ""
      }&carton=${
        carton ?? ""
      }&imei=${
        imei ?? ""
      }&device_type=${
        device_type ?? ""
      }&status=${
        status.toString() ?? ""
      }&ibo_marketer_id=${
        market ?? ""
      }&rma_order_id=${rma_order_id ?? ""}`;
      
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
});
};
}

// Get roles list
export function getsortlistinventory() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/inventory_summary_reports_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function getsortlistinventoryreport() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/inventory_report_sort_keys")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Inventory Summary Report Search

export function searchinventorysummary({ retailer, page, sort, sort_by }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports/retailer?retailer_id=${
        retailer ?? ""
      }&sort=${sort ?? ""}&sort_by=${sort_by ?? ""}&page=${page ?? 1}`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function sendinviteuser(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/send_invite", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Send Agent/IBO Marketer/Office Documents
export function sendform(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`sendform`, params);
      // resolve("Success");
      axiosInstance
        .post("/api/v1/senddocument", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Download Agent/Marketer/Office Forms
export function downloadform(user_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`user_id`, user_id);
      // resolve("<html><head><title>My header</title></head><body>Hello World</body></html>")
      // const config = {
      //   headers: {
      //     'Content-Type': 'application/x-www-form-urlencoded'
      //   }
      // }
      axiosInstance
        .get(`/api/v1/get_document/${user_id}`)
        .then((response) => {
          console.log(`response`, response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Update Agent/Marketer/Office Forms status
export function updateformstatus(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`updateformstatus`, params);
      axiosInstance
        .post(`/api/v1/accept_reject`, params)
        .then((response) => {
          console.log(`response`, response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function onboarding_summary_report(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.startValue) {
        var StartDate = moment(params.startValue).format("MM-DD-YYYY");
       
      }
      if (params.endValue) {
     
        var EndDate = moment(params.endValue).format("MM-DD-YYYY");
      }
      console.log(`params?.url`, params?.url);
      axiosInstance
        .get(params?.url ? params?.url : `/api/v1/onboarding_summary_report?keyword=${
          params.name ?? ""
        }&office_id=${params.selectedoffice ?? ""}&status=${
          params.selectedstatus ?? ""
        }&sort=${params.sortField ?? ""}&sort_key=${
          params.sortOrder ?? ""
        }&date_range_start=${StartDate ?? ""}&date_range_end=${
          EndDate ?? ""
        }&page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//profile completed list
export function Profile_completed_list(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.startValue) {
        var StartDate = moment(params.startValue).format("MM-DD-YYYY");
       
      }
      if (params.endValue) {
       
        var EndDate = moment(params.endValue).format("MM-DD-YYYY");
      }
      console.log(`params?.url`, params?.url);
      axiosInstance
        .get(params?.url ? params?.url : `/api/v1/onboarding_summary_report?status=3&keyword=${
          params.name ?? ""
        }&office_id=${params.selectedoffice ?? ""}&sort=${params.sortField ?? ""}&sort_key=${
          params.sortOrder ?? ""
        }&date_range_start=${StartDate ?? ""}&date_range_end=${
          EndDate ?? ""
        }&page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Search profile completed list
export function searchprofile_completed_List(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_report?status=3&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}&onboarding_source=${
        params.source_status ?? ""
      }`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Export profile completed list
export function export_profil_completed(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_export?status=3&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${EndDate ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}


//profile update list
export function profile_update_list(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.startValue && params.endValue) {
        var StartDate = moment(params.startValue).format("MM-DD-YYYY");
        var EndDate = moment(params.endValue).format("MM-DD-YYYY");
      }
      console.log(`params?.url`, params?.url);
      axiosInstance
        .get(params?.url ? params?.url : `/api/v1/onboarding_summary_report?status=25&keyword=${
          params.name ?? ""
        }&office_id=${params.selectedoffice ?? ""}&sort=${params.sortField ?? ""}&sort_key=${
          params.sortOrder ?? ""
        }&date_range_start=${StartDate ?? ""}&date_range_end=${
          EndDate ?? ""
        }&page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Search profile update list
export function search_profile_Update(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_report?status=25&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}&onboarding_source=${
        params.source_status ?? ""
      }`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Export profile update list
export function export_profil_updated(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_export?status=25&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${EndDate ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Link rad id list
export function link_rad_id_list(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.startValue && params.endValue) {
        var StartDate = moment(params.startValue).format("MM-DD-YYYY");
        var EndDate = moment(params.endValue).format("MM-DD-YYYY");
      }
      console.log(`params?.url`, params?.url);
      axiosInstance
        .get(params?.url ? params?.url : `/api/v1/onboarding_summary_report?status=23&keyword=${
          params.name ?? ""
        }&office_id=${params.selectedoffice ?? ""}&sort=${params.sortField ?? ""}&sort_key=${
          params.sortOrder ?? ""
        }&date_range_start=${StartDate ?? ""}&date_range_end=${
          EndDate ?? ""
        }&page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Search Link rad id 
export function search_Link_rad_id(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_report?status=23&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}&onboarding_source=${
        params.source_status ?? ""
      }`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Export Link rad id 
export function export_link_rad_id(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_export?status=23&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${EndDate ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

//Rad id update
export function rad_id_failures_update_list(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.startValue && params.endValue) {
        var StartDate = moment(params.startValue).format("MM-DD-YYYY");
        var EndDate = moment(params.endValue).format("MM-DD-YYYY");
      }
      console.log(`params?.url`, params?.url);
      axiosInstance
        .get(params?.url ? params?.url : `/api/v1/onboarding_summary_report?status=26&keyword=${
          params.name ?? ""
        }&office_id=${params.selectedoffice ?? ""}&sort=${params.sortField ?? ""}&sort_key=${
          params.sortOrder ?? ""
        }&date_range_start=${StartDate ?? ""}&date_range_end=${
          EndDate ?? ""
        }&page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Search Rad id update
export function search_Rad_id_update(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_report?status=26&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Export Rad id update
export function export_Rad_id_Update(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_export?status=26&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${EndDate ?? ""}`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//BGC Review list
export function bgc_review_list(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.startValue && params.endValue) {
        var StartDate = moment(params.startValue).format("MM-DD-YYYY");
        var EndDate = moment(params.endValue).format("MM-DD-YYYY");
      }
      console.log(`params?.url`, params?.url);
      axiosInstance
        .get(params?.url ? params?.url : `/api/v1/onboarding_summary_report?status=6&keyword=${
          params.name ?? ""
        }&office_id=${params.selectedoffice ?? ""}
        &sort=${params.sortField ?? ""}&sort_key=${
          params.sortOrder ?? ""
        }&date_range_start=${StartDate ?? ""}&date_range_end=${
          EndDate ?? ""
        }&page=${params.page ?? 1}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Search BGC Review 
export function search_BGC_Review(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_report?status=6&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}&onboarding_source=${
        params.source_status ?? ""
      }`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//export BGC Review 
export function export_BGC_Review(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_export?status=6&keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${EndDate ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function rad_link_issues(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(`params?.url`, params?.url);
      axiosInstance
        .get(
          params?.url
            ? params?.url
            : "/api/v1/rad_id_link_failures_candidate_report"
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function onboarding_teelgo_report() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/onboarding_summary_report?status=12")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function telgoo_id_pending_candidates_export({
  search_text,
  date_range_start,
  date_range_end,
  source_status,
}) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (date_range_start && date_range_end) {
        var StartDate = moment(date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(date_range_end).format("MM-DD-YYYY");
      }
      axiosInstance
        .get(
          `/api/v1/telgoo_id_pending_candidates_export?&keyword=${
            search_text ?? ""
          }&date_range_start=${StartDate ?? ""}&date_range_end=${EndDate ?? ""}&onboarding_source=${
            source_status ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list
export function assigntelgooid(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/telgoo_id_mapping", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };
}

// Get roles list
export function agent_summary_report(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(params?.url ? params?.url : "/api/v1/agent_summary_report")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get roles list

export function onboarding_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/onboarding_status_dropdown")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get sorce list
export function onboarding_status_sorce() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/onboarding_source")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function onboarding_email_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/onboarding_status_for_custom_email")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function rad_issues_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/rad_issue_status_dropdown")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// API to get BGC issues
export function bgc_issues_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/bgc_issue_status_dropdown")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Handle Rad Verfication Submit Form
export function submitRadVerify(radParams) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/update_rad_verification_status_v2", radParams)
        .then(function (response) {
          console.log("rad", response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Handle Rad Verfication Submit Form
export function submittransferVerify(radParams) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/update_rad_verification_status_v2", radParams)
        .then(function (response) {
          console.log("rad", response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function linkRadVerify(radParams) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/link_rad_id", radParams)
        .then(function (response) {
          console.log("rad", response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Handle BGC Update Submit Form
export function submitBgcVerify(bgcParams) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/update_bgc_status", bgcParams)
        .then(function (response) {
          console.log("bgc", response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get rma devices list
export function rma_device_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/rma_device_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get rma order list
export function rma_order_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/rma_order_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get inventory order list
export function inventory_order_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/inventory_order_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get inventory order approval list
export function inventory_order_approval_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/inventory_order_approval_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchonboardingreport(val, page) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(val, page);
      if (val.date_range_start) {
        var StartDate = moment(val.date_range_start).format("MM-DD-YYYY");
        
      }
      if (val.date_range_end) {
       
        var EndDate = moment(val.date_range_end).format("MM-DD-YYYY");
      }
      console.log(StartDate, EndDate);
      var url = `/api/v1/detailed_inventory_report?keyword=${
        val.keyword ?? ""
      }&agent_id=${val.agent_id ?? ""}&office_id=${
        val.office_id ?? ""
      }&status=${val.status ?? ""}&date_range_start=${
        StartDate ?? ""
      }&date_range_end=${EndDate ?? ""}&page=${page ?? 1}`;
      console.log(`distributors url: ${url}`);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchonboardingreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
       
      }
      if ( params.date_range_end) {
     
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_report?keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&status=${
        params.status ?? ""
      }&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}& retailer_id=${
        params.retailer ?? ""
      }&ibo_marketer_id=${params.ibo_market ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchradreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
     
      }
      if (params.date_range_end) {
      
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }
      console.log(StartDate, EndDate);
      var url = `/api/v1/rad_id_link_failures_candidate_report?keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&status=${
        params.status ?? ""
      }&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}&onboarding_source=${
        params.source_status ?? ""
      }`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchtelgoolist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_report?status=12&keyword=${
        params.search_text ?? ""
      }&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}&onboarding_source=${
        params.source_status ?? ""
      }`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function searchagentsummarylist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/agent_summary_report?keyword=${
        params.search_text ?? ""
      }&status=${params.status ?? ""}&telgoo_status=${params.telgoo_status ?? ""}&redirect_key=${
        params.redirect_key ?? ""
      }&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${
        EndDate ?? ""
      }&page=${params.page ?? 1}`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function officedeleteaction(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      var obj_arr = {
        status: stat,
      };
      axiosInstance
        .post(`/api/v1/offices/action/${id}`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function marketerdeleteaction(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      var obj_arr = {
        status: stat,
      };
      axiosInstance
        .post(`/api/v1/ibo_marketers/action/${id}`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

//Delete invite
export function send_invite_delete_action(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(id);
      // var obj_arr = {
      //   status: stat,
      // };
      axiosInstance
        .delete(`/api/v1/candidate/onboarding/${id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };
}

export function short_us_states(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/short_us_states")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function resendinviteuser(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/candidate/resend_invite/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };
}
export function resendinviteusernew(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/candidate/send_email_remainders/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };
}
export function resendreminderuser(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/candidate/resent_remainders/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };
}

export function searchagedinventorysummary({ retailer, page, sort, sort_by }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/aged_inventory_summary_reports/retailer?retailer_id=${
        retailer ?? ""
      }&sort=${sort ?? ""}&sort_by=${sort_by ?? ""}&page=${page ?? 1}`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
          dispatch({
            type: GET_MASTER,
            payload: response.data,
          });
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function onboardinglog(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/candidate_onboarding_log/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function get_inventory_count(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/offices/get_inventory_count/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function agentstatupload(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/import_agent_status", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };
}

export function agentofficemapingupload(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/import_agent_office_mapping", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.message);
        });
    });
  };
}

export function updatecandidateuser(obj, params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/update_candidate_info/" + params, obj)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function updateraduser(obj, params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/candidate/update_rad_verification_status_v2/" + params, obj)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error.response.data.error);
          reject(error.response.data.error);
        });
    });
  };
}

export function addcommentsuseraction(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        comments: params.comments,
      };

      axiosInstance
        .post("api/v1/update_agent_comments/" + params.candidate_id, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function viewcommentsuseraction(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/agent_comments_log/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function agentstatusaction() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/agent_status")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}


// Get rma order list
export function rma_imei_upload(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/upload_rma_devices_as_received", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get rma order list
export function rma_imei_received(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post("/api/v1/update_rma_to_received_status", params)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function addtrackbgc(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/candidate/run_bgc_training_job")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportonboardingreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        
      }
      if (params.date_range_end) {
       
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }
      console.log(StartDate, EndDate);
      var url = `/api/v1/onboarding_summary_export?keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&status=${
        params.status ?? ""
      }&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${EndDate ?? ""}& retailer_id=${
        params.retailer ?? ""
      }&ibo_marketer_id=${params.ibo_market ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportradreportlist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/rad_link_failures_candidate_summary_export?keyword=${
        params.search_text ?? ""
      }&office_id=${params.office_id ?? ""}&status=${
        params.status ?? ""
      }&sort=${params.sort ?? ""}&sort_key=${
        params.sort_by ?? ""
      }&date_range_start=${StartDate ?? ""}&date_range_end=${EndDate ?? ""}&onboarding_source=${
        params.source_status ?? ""
      }`;
      console.log(`url`, url);
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function exportagentsummarylist(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params);
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(params.date_range_end).format("MM-DD-YYYY");
      }

      console.log(StartDate, EndDate);
      var url = `/api/v1/agent_export?keyword=${params.search_text ?? ""}&status=${
            params.status ?? ""
          }&telgoo_status=${params.telgoo_status ?? ""}&sort=${params.sort ?? ""}&sort_key=${
            params.sort_by ?? ""
          }&date_range_start=${StartDate ?? ""}&date_range_end=${
            EndDate ?? ""
          }&redirect_key=${
            params.redirect_key ?? ""
          }`;

      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Get rma order list
export function send_custom_email_user(params, agentid) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          `/api/v1/candidate/send_custom_email_to_candidate/${agentid}`,
          params
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function addcommentsuserinventoryaction(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        comments: params.comments,
      };

      axiosInstance
        .post(
          "api/v1/add_comments_for_inventory_order/" +
            params.inventory_order_id,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function viewcommentsuserinventoryaction(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/inventory_order_comments_log/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function addcomments_user_Onboarding_candidates_action(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        comments: params.comments,
      };

      axiosInstance
        .post(
          "api/v1/candidate/add_comments_for_candidate/" + params.cand_id,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function viewcomments_user_Onboarding_candidates_action(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/candidate/candidate_comments_log/${params.id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function onboarding_email_status_text() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/onboarding_email_status_text")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Reset password link
export function change_agent_status(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      
      var obj_arr = {
        status: params.agentstatus,
        comments: params?.status_comments?? "",
        office_id: params.office_id ?? "",
        retailer_id: params.retailer_id ?? "",
      };
      axiosInstance
        .post(`/api/v1/change_agent_status_v2/${params.agent_id}`, obj_arr)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error ?? error.response.data.message);
        });
    });
  };
}
// Reset password link
export function change_status_for_candidate(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        comments: params.comments,
      };
      axiosInstance
        .post(
          `/api/v1/candidate/change_status_for_candidate/${params.cand_id}`,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Reset password link
export function rad_pii_issue_status() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
     
      axiosInstance
        .get(
          `/api/v1/rad_pii_issue_status`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

//onboarding retailer dropdown
export function getretaileroffice(retailer_id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/get_office_info_v2/${retailer_id}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
         
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

//Leagacy Data device type
export function getdevicetype() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/reference/device_type`)
        .then(function (response) {
          console.log(response);
          resolve(response);
         
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Update status Submit
export function Update_Status_submit(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var obj_arr = {
        comments: params.comments,
        reject_expiry_status: params.reject_expiry_status,
      };

      axiosInstance
        .post(
          "api/v1/candidate/update_status_for_candidate/" + params.cand_id,
          obj_arr
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
});
};
}

//status update dropdown
export function short_us_statusupdate(id, stat) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/update_candidate_status_dropdown")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
});
};
}

export function us_states_lifeline() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/reference/us_states_lifeline")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
});
};
}