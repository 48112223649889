import moment from "moment";
import axiosInstance from "./AxiosInstance";
// Reset password link
export function office_export(search_text,status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/office_export?search_text=${search_text ?? ""}&status=${status ?? ""}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function master_export(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/master_agent_export?search_text=${params.search_text ?? ""}&status=${params.status ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function retailer_export(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/retailer_export?search_text=${
            params.search_text ?? ""
          }&distributor=${params.distributor ?? ""}&status=${params.status ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function distributor_export(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/distributor_export?master_agent=${
            params.master_agent ?? ""
          }&search_text=${params.search_text ?? ""}&status=${params.status ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function ibo_market_export(search_text,status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`/api/v1/ibo_market_export?search_text=${search_text ?? ""}&status=${status ?? ""}`)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function user_export(search_text, rolename,status) {
  return (dispatch) => {
    console.log("first",status)
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/user_export?search_text=${search_text ?? ""}&role=${
            rolename ? rolename : ""
          }&status=${
            status ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_export(
  summary,
  search_text,
  date_range_start,
  date_range_end,
  date_range_start1,
  date_range_end1
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (date_range_start && date_range_end) {
        var StartDate = moment(date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(date_range_end).format("MM-DD-YYYY");
      }
      if (date_range_start1 && date_range_end1) {
        var StartDate1 = moment(date_range_start1).format("MM-DD-YYYY");
        var EndDate1 = moment(date_range_end1).format("MM-DD-YYYY");
      }
      axiosInstance
        .get(
          `/api/v1/detailed_sales_export?summary=${summary ?? ""}&search_text=${
            search_text ?? ""
          }&date_range_start=${StartDate ?? ""}&date_range_end=${
            EndDate ?? ""
          }&order_date_range_start=${StartDate1 ?? ""}&order_date_range_end=${
            EndDate1 ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
//Legacy data export
export function detailed_legacy_data_export(
  summary,
  search_text,
  date_range_start,
  date_range_end,
  date_range_start1,
  date_range_end1,
  date_range_start2,
  date_range_end2,
  carton,
  imei,
  device_type,
  Agent,
  status,
  retailer_id,
  query_string,
  order_id,
  enroll_id
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (date_range_start && date_range_end) {
        var StartDate = moment(date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(date_range_end).format("MM-DD-YYYY");
      }
      if (date_range_start1 && date_range_end1) {
        var StartDate1 = moment(date_range_start1).format("MM-DD-YYYY");
        var EndDate1 = moment(date_range_end1).format("MM-DD-YYYY");
      }
      if (date_range_start2 && date_range_end2) {
        var StartDate2 = moment(date_range_start2).format("MM-DD-YYYY");
        var EndDate2 = moment(date_range_end2).format("MM-DD-YYYY");
      }
      axiosInstance
        .get(
          `/api/v1/legacy_detailed_sales_export?summary=${summary ?? ""}&search_text=${
            search_text ?? ""
          }&date_range_start=${StartDate ?? ""}&date_range_end=${
            EndDate ?? ""
          }&order_date_range_start=${StartDate1 ?? ""}&order_date_range_end=${
            EndDate1 ?? ""
          }&carton=${
            carton ?? ""
          }&imei=${
            imei ?? ""
          }&device_type=${
            device_type ?? ""
          }&retailer_id=${
            retailer_id ?? ""
          }&allocation_date_range_start=${
            StartDate2 ?? ""
          }&allocation_date_range_end=${
            EndDate2 ?? ""
          }&query_string=${
            query_string ?? ""
          }&inventory_request_order_id=${
            order_id ?? ""
          }&enroll_id=${
            enroll_id ?? ""
          }&agent_id=${Agent ?? ""}&status=${
            status ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_inventory_export(val, sort, 
  sort_by,
  search_text,
  agent,
  office_id,
  date_range_start,
  date_range_end
  ,activation_date_range_start,
  activation_date_range_end,
  retailer_id,
  order_id,
  enroll_id,
  carton,
  imei,
  device_type,
  status,
  market,
  rma_order_id,
  ) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(status,val, sort, sort_by);
      if (date_range_start && date_range_end) {
        var StartDate = moment(date_range_start).format("MM-DD-YYYY");
        var EndDate = moment(date_range_end).format("MM-DD-YYYY");
      }
      if (activation_date_range_start && activation_date_range_end) {
        var StartAllocateDate = moment(activation_date_range_start).format("MM-DD-YYYY");
        var EndAllocateDate = moment(activation_date_range_end).format("MM-DD-YYYY");
      }
      var url = `/api/v1/detailed_inventory_export?keyword=${
        search_text ?? ""
      }&agent_id=${agent ?? ""}&office_id=${
        office_id ?? ""
      }&date_range_start=${
        StartDate ?? ""
      }&date_range_end=${EndDate ?? ""}&sort=${sort ?? ""}&sort_by=${
        sort_by ?? ""
      }&activation_date_range_start=${
        StartAllocateDate ?? ""
      }&activation_date_range_end=${EndAllocateDate ?? ""}&retailer_id=${
        retailer_id ?? ""
      }&order_id=${
        order_id ?? ""
      }&enroll_id=${
        enroll_id ?? ""
      }&carton=${
        carton ?? ""
      }&imei=${
        imei ?? ""
      }&device_type=${
        device_type ?? ""
      }&status=${
        status.toString() ?? ""
      }&ibo_marketer_id=${
        market ?? ""
      }&rma_order_id=${rma_order_id ?? ""}`;

      console.log(url);
      axiosInstance
        .get(url)
        .then(function (response) {
          //console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
});
};
}

export function detailed_sales_export_agent(params ) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/agent?agent_id=${params.agent ?? ""}&office_id=${params.office_id ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_export_manager({ manager }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/manager?manager_id=${
            manager ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_sales_export_office(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/office?office_id=${
            params.office ?? ""
          }&ibo_marketer_id=${
            params.ibo_marketer_id ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function detailed_sales_export_ibo_marketer(params ) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/ibo_marketer?ibo_marketer_id=${
            params.market ?? ""
          }&retailer_id=${
            params.retailer_id ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_export_retailer({ retailer }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/sales_summary_reports_export/retailer?retailer_id=${
            retailer ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function aged_by_agent_export( params ) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/agent?agent_id=${
            params.agent ?? ""
          }&office_id=${
            params.office_id ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function aged_by_manager_export({ manager }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/manager?manager_id=${
            manager ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function aged_by_retailer_export({ retailer }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/retailer?retailer_id=${
            retailer ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function aged_by_market_export(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      console.log(params)
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/ibo_marketer?ibo_marketer_id=${
            params.market ?? ""}&retailer_id=${params.retailer ?? ""}`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function aged_by_office_export( params ) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/aged_inventory_summary_reports_export/office?office_id=${
            params.summary ?? ""}&ibo_marketer_id=${params.ibo_marketer_id ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function detailed_sales_summary_export() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get("/api/v1/detailed_sales_summary_export")
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventory_summary_retailer({ retailer }) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(
          `/api/v1/inventory_summary_reports_export/retailer?retailer_id=${
            retailer ?? ""
          }`
        )
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventory_summary_ibo_marketer(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/ibo_marketer?ibo_marketer_id=${
        val?.retailer ?? ""
      }retailer_id=${
        val?.retailer_id ?? ""
      }`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

export function inventory_summary_office(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/office?office_id=${
        val?.retailer ?? ""
      }&ibo_marketer_id=${
        val?.ibo_marketer_id ?? ""
      }`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function inventory_summary_manager(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/manager?manager_id=${
        val?.retailer ?? ""
      }`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}
export function inventory_summary_agent(val) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      var url = `/api/v1/inventory_summary_reports_export/agent?agent_id=${
        val?.retailer ?? ""
      }&office_id=${
        val?.office_id ?? ""
      }`;
      axiosInstance
        .get(url)
        .then(function (response) {
          console.log(response);
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error.response.data.error);
        });
    });
  };
}

// Export Payroll Data
export function export_payroll_data(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (params.date_range_start && params.date_range_end) {
        var StartDate = moment(params.date_range_start).format("YYYY-MM-DD");
        var EndDate = moment(params.date_range_end).format("YYYY-MM-DD");
      }
      axiosInstance
        .get(
          `/api/v1/export_payroll_data?office_id=${
            params.office_id ?? ""
          }&agent_code=${params.search_text ?? ""}&week_type=${
            params.week_type ?? ""
          }&payroll_type=${params.payroll_type ?? ""}&start_date=${
            StartDate ?? ""
          }&end_date=${EndDate ?? ""}&term=${params.term ?? ""}`
        )
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error.response.data.error);
        });
    });
  };
}
