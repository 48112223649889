import React, { Component } from "react";
import { connect } from "react-redux";
// Axios
// import axios from "axios";
import {
  getinventoryorder,
  getrmarequestaccept,
  inventorylog,
  createinventoryorder,
  getapproveorder,
  updatetrackinginfo,
  inventory_order_summary,
  getfulfillorder,
  exportinventoryorder,
  get_rmarequest_edit,
  resubmit_request_order,
} from "../../actions/inventoryAction";
import {
  getretailers,
  getofficelistname,
  inventory_order_approval_status,
  inventory_order_status,
  addcommentsuserinventoryaction,
  viewcommentsuserinventoryaction,
  short_us_states,
} from "../../actions/assetAction";
import _, { values } from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Select,
  Divider,
  Modal,
  Icon,
  Form,
  Drawer,
  Row,
  Col,
  Tooltip,
  message,
  List,
  Spin,
  Empty,
  InputNumber,
  DatePicker,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import axiosInstance from "../../actions/AxiosInstance";
import moment from "moment";
const { Option } = Select;
const { confirm } = Modal;
// let id = 0;

class RMAOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {pageSize: 25},
      loading: true,
      loadingbtn: false,
      loadingUDbtn: false,
      loadingSMTbtn: false,
      loadingRQSMTbtn: false,
      retailer: [],
      selectedItems: [],
      order_approval: [],
      order_status: [],
      retailers: [],
      offices: [],
      selectedapprovalstatus: [],
      selectedoffice: [],
      search_key: null,
      sortField: null,
      sortOrder: null,
      selectedretailer: [],
      selectedorderstatus: [],
      open: false,
      record: null,
      visible: false,
      retailerrole: false,
      marketrole: false,
      superrole: false,
      superadmin: false,
      isapprove: false,
      orderopen: false,
      addressOpen: false,
      activeorder: "",
      dateover:"",
      dashdata: [],
      // addLine1: "",
      // options: [],
      name: "",
      comvisible: false,
      visibleedit: false,
      loadingCMdbtn: false,
      Listdata1: [],
      cvisible: false,
      shortstate: [],
      startValue1: null,
      endValue1: null,
      endOpen1: false,
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 25,
      page: 1,
    });

    this.props.short_us_states().then((res) => {
      console.log(res);
      this.setState({
        shortstate: res.data,
      });
    });
    console.log(this.props.location.agent_info);

    this.props
      .inventory_order_summary()
      .then((res) => {
        console.log(res.data.items);
        this.setState({
          dashdata: res.data.items,
        });
      })
      .catch((err) => {});
    this.getOfficelist();
    this.getRetailers();
    this.getApprovalStatus();
    this.getOrderStatus();
    const superadmin = this.props.user.role_id.filter((word) => word === 10);
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    const Retailer = this.props.user.role_id.filter((word) => word === 5);
    const marketer = this.props.user.role_id.filter(
      (word) => word === 4 || word === 8
    );

    if (Retailer.length !== 0) {
      this.setState({
        retailerrole: true,
      });
    }
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    if (superadmin.length !== 0) {
      this.setState({
        superadmin: true,
      });
    }
  }

  getdesignation(params = {}) {
    this.props
      .getinventoryorder(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page
        this.setState({
          data: res.data.items.data,
          loading: false,
          loadingbtn: false,
          loadingSMTbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  }

  getApprovalStatus() {
    this.props.inventory_order_approval_status().then((res) => {
      console.log(res.data);
      this.setState({
        order_approval: res.data,
      });
    });
  }

  getOrderStatus() {
    this.props.inventory_order_status().then((res) => {
      console.log(res.data);
      this.setState({
        order_status: res.data,
      });
    });
  }

  getRetailers() {
    this.props.getretailers().then((res) => {
      console.log(res.data);
      this.setState({
        retailers: res.data,
      });
    });
  }

  getOfficelist() {
    if (this.state.offices === undefined || this.state.offices.length <= 0) {
      this.props
        .getofficelistname()
        .then((res) => {
          console.log(res);
          this.setState({
            offices: res.data,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            offices: [],
          });
          // nprogress.done();
        });
    }
  }

  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    const order =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    const fieldName = sorter.order === undefined ? "" : sorter.field;
    this.setState({
      pagination: pager,
      sortField: fieldName,
      sortOrder: order,
    });
    this.searchOrders(fieldName, order, pager);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  searchOrders = (sortName, order, pager, reset) => {
    this.setState({ loading: true, loadingbtn: true });
    var retailer_id = this.state.selectedretailer;
    var approvalstatus = this.state.selectedapprovalstatus;
    var orderstatus = this.state.selectedorderstatus;
    var office_id = this.state.selectedoffice.key;
    var search_text = this.state.name;
    var sort = sortName ?? this.state.sortField;
    var sort_by = order ?? this.state.sortOrder;
    var page = reset === "reset" ? 1 : pager?.current ?? this.state.pagination?.current ?? 1;
    var date_range_start1 = this.state.startValue1;
    var date_range_end1 = this.state.endValue1;
      console.log(this.state.pagination)
    this.fetch({
      results: 25,
      page: page ?? 1,
      office_id: office_id,
      retailer_id: retailer_id,
      approval_status: approvalstatus,
      status: orderstatus,
      search_text: search_text,
      sort: sort,
      sort_by: sort_by,
      date_range_start1:date_range_start1,
      date_range_end1:date_range_end1,
      
    });
  };

  getexportbtn = () => {
    var retailer_id = this.state.selectedretailer;
    var approvalstatus = this.state.selectedapprovalstatus;
    var orderstatus = this.state.selectedorderstatus;
    var office_id = this.state.selectedoffice.key;
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    // var summary = this.state.selectedItems.key;
    var date_range_start1 = this.state.startValue1;
    var date_range_end1 = this.state.endValue1;
    this.setState({ loading: true, loadingEPbtn: true });
    this.props
      .exportinventoryorder({
        // summary,
        office_id: office_id,
        retailer_id: retailer_id,
        approval_status: approvalstatus,
        status: orderstatus,
        search_text: search_text,
        sort: sort,
        sort_by: sort_by,
        date_range_start1:date_range_start1,
        date_range_end1:date_range_end1,

      })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };

  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedretailer: undefined,
      selectedapprovalstatus: undefined,
      selectedorderstatus: undefined,
      selectedoffice: [],
      selectedItems: [],
      startValue1:null,
      endValue1:null,
      fdata: [],
      // selectedItems:[],
      name: "",
      search_key: null,
      sortField: null,
      sortOrder: null,
    });
    this.getdesignation({
      results: 25,
      page: 1,
    });
  };

  onchangeapprovalfilter = (selectedapprovalstatus) => {
    this.setState({ selectedapprovalstatus });
  };

  onchangeorderfilter = (selectedorderstatus) => {
    this.setState({ selectedorderstatus });
  };

  onchangeoffice = (selectedoffice) => {
    this.setState({ selectedoffice });
  };

  onchangeretailer = (selectedretailer) => {
    this.setState({ selectedretailer });
  };

  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  // onchangeRole = (selectedItems) => {
  //   this.setState({ selectedItems });
  //   console.log(this.state.selectedItems);
  // };

  onClose = () => {
    confirm({
      icon: <Icon type="exclamation-circle" />,
      title: "Are you sure you want to cancel the order?",
      // content: "To cancel approving/rejecting order?",
      onOk: () => {
        this.setState({ open: false, record: null });
        this.props.form.resetFields();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  //Edit Drawer onclose
  onCloseE = () => {
    confirm({
      icon: <Icon type="exclamation-circle" />,
      title: "Are you sure you want to cancel ?",
      // content: "To cancel approving/rejecting order?",
      onOk: () => {
        this.setState({ editopen: false,resubmitopen:false, record: null });
        this.props.form.resetFields();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  onCloseLog = () => {
    this.setState({ visible: false });
  };

  onCloseAddress = () => {
    this.setState({ addressOpen: false, loading: false });
  };

  handleSubmit = (e) => {
    console.log("Success:", e);
    e.preventDefault();
    console.log(this.state.loadingSMTbtn);
    try {
      console.log(this.state.loadingSMTbtn);
      this.props.form.validateFields(
        [
          "devices",
          "tablets",
          "admin_comments",
          "ship_to_name1",
          "address11",
          "address21",
          // "contact_name1",
          "company_name1",
          "city1",
          "state1",
          "zip1",
          "contact_phone_number1",
          "email"
        ],
        (err, values) => {
          if (!err) {

            let devices_approved = values.devices === "" ? 0 : parseInt(values.devices) ?? 0;
            let tablets_approved = values.tablets === "" ? 0 : parseInt(values.tablets) ?? 0;
            if(this.state.isapprove === true)
              {
            // Validate that at least one device is requested
            if (devices_approved === 0 && tablets_approved === 0) {
              message.error(" Phone or Tablet value should not be zero");
              return; // Return early to prevent further execution
            }
          }

            console.log(`setting order`);
            console.log(this.state.loadingSMTbtn);
            this.setState({ loadingSMTbtn: true, loading: true });
            this.setOrderStatus(this.state.isapprove, {
              id: this.state.record.id,
              // devices_approved: values.devices,
              // tablets_approved: values.tablets,
              devices_approved: devices_approved,
              tablets_approved: tablets_approved,
              admin_comments: values?.admin_comments ?? "",
              ship_to_name: values.ship_to_name1,
              address: values.address11,
              address2: values?.address21 ?? "",
              // contact_name: values?.contact_name1 ?? "",
              company_name: values?.company_name1 ?? "",
              city: values.city1,
              state: values.state1,
              zip: values.zip1,
              contact_phone_number: values.contact_phone_number1.toString(),
              email:values?.email ?? "",
            });
          } else {
            console.log(`error in setting order`);
            console.log(this.state.loadingSMTbtn);
          }
        }
      );
    } catch (ex) {
      console.log(`error: `, ex);
      console.log(this.state.loadingSMTbtn);
      this.setState({ loadingSMTbtn: false, loading: false });
    }
  };
  //Edit Order HandlesubitE
  handleSubmitE = (e) => {
    console.log("Success:", e);

    e.preventDefault();
    console.log(this.state.loadingSMTbtn);
    try {
      console.log(this.state.loadingSMTbtn);
      this.props.form.validateFields(
        [
          "devices1",
          "tablets1",
          "admin_comments1",
          "ship_to_name2",
          "address12",
          "address22",
          //"contact_name2",
          "company_name2",
          "city2",
          "state2",
          "zip2",
          "contact_phone_number2",
          // "ship_date"
          "email",
        ],
        (err, values) => {
          if (!err) {
            let devices_approved = values.devices1 === "" ? 0 : parseInt(values.devices1) ?? 0;
            let tablets_approved = values.tablets1 === "" ? 0 : parseInt(values.tablets1) ?? 0;
           
            // Validate that at least one device is requested
            if (devices_approved === 0 && tablets_approved === 0) {
              message.error("Phone or Tablet value should not be zero");
              return; // Return early to prevent further execution
            }
          
            console.log( values.ship_date === null);
            console.log(this.state.loadingSMTbtn);
            this.setState({ loadingSMTbtn: true, loading: true });
            this.setOrderStatusE({
              id: this.state.record.id,
              // devices_approved: values.devices1,
              // tablets_approved: values.tablets1,
              devices_approved: devices_approved,
              tablets_approved: tablets_approved,
              admin_comments: values.admin_comments1,
              ship_to_name: values.ship_to_name2,
              address: values.address12,
              address2: values?.address22 ?? "",
              //contact_name: values?.contact_name2 ?? "",
              company_name: values?.company_name2 ?? "",
              city: values.city2,
              state: values.state2,
              zip: values.zip2,
              // ship_date: values.ship_date === null || values.ship_date === undefined  ? null : moment(values?.ship_date).format("MM-DD-YYYY"),
              contact_phone_number: values.contact_phone_number2.toString(),
              email:values?.email ?? "",
            });
          } else {
            console.log(`error in setting order`);
            console.log(this.state.loadingSMTbtn);
          
          }
        }
      );
    } catch (ex) {
      console.log(`error: `, ex);
      console.log(this.state.loadingSMTbtn);
      this.setState({ loadingSMTbtn: false, loading: false });
    }
  };

  //Re-submit Order Handlesubit
  handleSubmitResubmit = (e) => {
   
    e.preventDefault();
    try {
      this.props.form.validateFields(
        [
          "devices2",
          "tablets2",
          "admin_comments2",
          "ship_to_name3",
          "address13",
          "address23",
          "company_name3",
          "city3",
          "state3",
          "zip3",
          "contact_phone_number3",
          "email1",
        ],
        (err, values) => {
          if (!err) {
            let devices_approved = values.devices2 === "" ? 0 : parseInt(values.devices2) ?? 0  ;
            let tablets_approved = values.tablets2 === "" ? 0 : parseInt(values.tablets2) ?? 0  ;
            
            // Validate that at least one device is requested
            if (devices_approved === 0 && tablets_approved === 0) {
              message.error(" Phone or Tablet value should not be zero");
              return;
            }
          
           
            this.setState({
               loadingSMTbtn: true,
               loading: true });
            this.setOrderStatusResubmit({
              id: this.state.record.id,
              devices_approved:devices_approved,
              tablets_approved:tablets_approved,
              // devices_approved: values.devices2,
              // tablets_approved: values.tablets2,
              admin_comments: values.admin_comments2,
              ship_to_name: values.ship_to_name3,
              address: values.address13,
              address2: values?.address23 ?? "",
              //contact_name: values?.contact_name2 ?? "",
              company_name: values?.company_name3 ?? "",
              city: values.city3,
              state: values.state3,
              zip: values.zip3,
              // ship_date: values.ship_date === null || values.ship_date === undefined  ? null : moment(values?.ship_date).format("MM-DD-YYYY"),
              contact_phone_number: values.contact_phone_number3.toString(),
              email:values?.email1 ?? "",
            });
          } else {
            console.log(`error in setting order`);
            console.log(this.state.loadingSMTbtn);
          
          }
        }
      );
    } catch (ex) {
      console.log(`error: `, ex);
      console.log(this.state.loadingSMTbtn);
      this.setState({
         loadingSMTbtn: false,
          loading: false });
    }
  };

  onFinishFailed = (e) => {
    console.log("error in form submit", e);
    // this.setState({ record: null });
  };

  showDrawer = (e, stat) => {
    console.log(e, stat);
    this.setState({ record: e });
    this.setState({ record: e });
    if (stat) {
      Modal.confirm({
        title: "Are you sure you want to verify the order?",
        // content: "Do you want to reject RMA",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          console.log(`Ok`);
          this.setState({ open: true, record: e, isapprove: stat });
          this.props.form.setFieldsValue({
            devices:
              e.devices_approved !== undefined && e.devices_approved !== null
                ? e.devices_approved
                : e.devices_requested,
            tablets:
              e.tablets_approved !== undefined && e.tablets_approved !== null
                ? e.tablets_approved
                : e.tablets_requested,
          });
        },
      });
    } else {
      Modal.confirm({
        title: "Are you sure you want to reject the order?",
        // content: "Do you want to reject RMA",
        okText: "Yes",
        cancelText: "No",
        onOk: () => {
          console.log(`Ok`);
          this.setState({ open: true, record: e, isapprove: stat });
          this.props.form.setFieldsValue({
            devices:
              e.devices_approved !== undefined && e.devices_approved !== null
                ? e.devices_approved
                : e.devices_requested,
            tablets:
              e.tablets_approved !== undefined && e.tablets_approved !== null
                ? e.tablets_approved
                : e.tablets_requested,
          });
        },
      });

  
    }
  };
  //Edit Order Drawer
  showDrawer1 = (e, stat) => {
    this.setState({ editopen: true, record: e, isapprove: stat });
    this.props.form.setFieldsValue({
      devices:
        e.devices_approved !== undefined && e.devices_approved !== null
          ? e.devices_approved
          : e.devices_requested,
      tablets:
        e.tablets_approved !== undefined && e.tablets_approved !== null
          ? e.tablets_approved
          : e.tablets_requested,
    });
    // Modal.confirm({
    //   title: "Are you sure you want to Edit the order?",
    //   // content: "Do you want to reject RMA",
    //   okText: "Yes",
    //   cancelText: "No",
    //   onOk: () => {
    //     console.log(`Ok`);
    //     console.log(e)
    //     this.setState({ editopen: true, record: e, isapprove: stat });
    //     this.props.form.setFieldsValue({
    //       devices:
    //         e.devices_approved !== undefined && e.devices_approved !== null
    //           ? e.devices_approved
    //           : e.devices_requested,
    //       tablets:
    //         e.tablets_approved !== undefined && e.tablets_approved !== null
    //           ? e.tablets_approved
    //           : e.tablets_requested,
    //     });
    //   },
    // });
  };
// RE-Submit Drawer
  showDrawer2 = (e, stat) => {
    Modal.confirm({
      title: "Are you sure you want to Resubmit the order?",
      // content: "Do you want to reject RMA",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        console.log(`Ok`);
        console.log(e)
        this.setState({
          // editopen: true,
          resubmitopen:true,
          record: e,
          isapprove: stat });
      },
    });
  };

  setOrderStatus = (
    stat,
    {
      id,
      devices_approved,
      tablets_approved,
      admin_comments,
      ship_to_name,
      address,
      address2,
      company_name,
      //contact_name,
      city,
      state,
      zip,
      contact_phone_number,
      email,
    }
  ) => {
    var obj_arr = {
      req_action: stat === true ? 2 : 3,
      admin_comments: admin_comments,
      ship_to_name,
      address,
      address2,
      company_name,
      //contact_name,
      city,
      state,
      zip,
      contact_phone_number,
      email,
    };
    if (stat) {
      obj_arr.devices_approved = devices_approved ?? 0;
      obj_arr.tablets_approved = tablets_approved ?? 0;
    }
    console.log(`obj_arr`, obj_arr);
    this.props
      .getrmarequestaccept(id, obj_arr)
      .then((res) => {
        console.log(res);
        message.success(`Success order ${stat ? "Accepted" : "Rejected"}`);
        this.props.form.resetFields();
        this.setState({
          record: null,
          open: false,
          loadingSMTbtn: false,
          loading: false,
        });
        this.searchOrders();
      })
      .catch((err) => {
        message.error(err);
        console.log(err);
        this.setState({ loadingSMTbtn: false, loading: false, open: false });
      });
  };
  //EditDrawer API
  setOrderStatusE = ({
    id,
    devices_approved,
    tablets_approved,
    admin_comments,
    ship_to_name,
    address,
    address2,
    company_name,
    //contact_name,
    city,
    state,
    zip,
    contact_phone_number,
    ship_date,
    email,
  }) => {
    var obj_arr = {
      admin_comments: admin_comments,
      ship_to_name,
      address,
      address2,
      company_name,
      ship_date,
      city,
      state,
      zip,
      contact_phone_number,
      email,
    };


    obj_arr.devices_approved = devices_approved ?? 0;
    obj_arr.tablets_approved = tablets_approved ?? 0;

    console.log(`obj_arr`, obj_arr);
    this.props
      .get_rmarequest_edit(id, obj_arr)
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        this.props.form.resetFields();
        this.setState({
          record: null,
          editopen: false,
          loadingSMTbtn: false,
          loading: false,
        });
        this.searchOrders();
      })
      .catch((err) => {
        message.error(err);
        console.log(err);
        this.setState({
          loadingSMTbtn: false,
          loading: false,
          editopen: false,
        });
      });
  };

   //Re-submit Drawer API
   setOrderStatusResubmit = ({
    id,
    devices_approved,
    tablets_approved,
    admin_comments,
    ship_to_name,
    address,
    address2,
    company_name,
    //contact_name,
    city,
    state,
    zip,
    contact_phone_number,
    ship_date,
    email,
  }) => {
    var obj_arr = {
      admin_comments: admin_comments,
      ship_to_name,
      address,
      address2,
      company_name,
      ship_date,
      city,
      state,
      zip,
      contact_phone_number,
      email,
    };


    obj_arr.devices_approved = devices_approved ?? 0;
    obj_arr.tablets_approved = tablets_approved ?? 0;

    console.log(`obj_arr`, obj_arr);
    this.props
      .resubmit_request_order(id, obj_arr)
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        this.props.form.resetFields();
        this.setState({
          record: null,
          resubmitopen: false,
          loadingSMTbtn: false,
          loading: false,
        });
        this.searchOrders();
      })
      .catch((err) => {
        message.error(err);
        console.log(err);
        this.setState({
          loadingSMTbtn: false,
          loading: false,
          resubmitopen: false,
        });
      });
  };

  resetform = () => {
    console.log(`resetform called`);
    this.props.form.resetFields();
    this.props.form.setFieldsValue({
      devices:
        this.state.record.devices_approved !== undefined &&
        this.state.record.devices_approved !== null &&
        this.state.record.devices_approved !== 0
          ? this.state.record.devices_approved
          : this.state.record.devices_requested,
      tablets:
        this.state.record.tablets_approved !== undefined &&
        this.state.record.tablets_approved !== null &&
        this.state.record.tablets_approved !== 0
          ? this.state.record.tablets_approved
          : this.state.record.tablets_requested,
    });
  };

  resetorderform = () => {
    this.props.form.resetFields();
  };

  rendercanlog = (params) => {
    this.setState({ loading: true });
    this.props
      .inventorylog(params)
      .then((res) => {
        console.log(res.data.items);
        this.setState({ Listdata: res.data.items });
        message.success(res.data.message);
        this.setState({ loading: false, visible: true });
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };

  validatePhone = (rule, value, callback) => {
    const form = this.props.form;
    if (!form.getFieldValue("devices") && !form.getFieldValue("tablets")) {
      return callback(
        "At least one of number of phones or tablets is required!"
      );
    } else {
      const phoneCount =
        this.state.record?.devices_approved !== undefined &&
        this.state.record?.devices_approved !== null &&
        this.state.record?.devices_approved !== 0
          ? this.state.record?.devices_approved
          : this.state.record?.devices_requested;
      console.log("phoneCount", phoneCount);
      if (value < 0 || (phoneCount >= 0 && value > phoneCount)) {
        callback(
          `Phones approved has to be ${
            phoneCount <= 0
              ? "greater than or equal to 1"
              : "between 1 and " + phoneCount
          }`
        );
      } else {
        callback();
      }
    }
  };

  validateTablet = (rule, value, callback) => {
    const form = this.props.form;
    console.log(
      `form.getFieldValue('devices')`,
      form.getFieldValue("devices"),
      form.getFieldValue("tablets")
    );
    if (!form.getFieldValue("devices") && !form.getFieldValue("tablets")) {
      return callback(
        "At least one of number of phones or tablets is required!"
      );
    } else {
      console.log(`validateTablet this.state.record`, this.state.record);
      try {
        const tabletCount =
          this.state.record?.tablets_approved !== undefined &&
          this.state.record?.tablets_approved !== null &&
          this.state.record?.tablets_approved !== 0
            ? this.state.record?.tablets_approved
            : this.state.record?.tablets_requested;
        console.log("tabletCount", tabletCount);
        if (value < 0 || (tabletCount >= 0 && value > tabletCount)) {
          callback(
            `Tablets approved has to be ${
              tabletCount <= 0
                ? "greater than or equal to 1"
                : "between 1 and " + tabletCount
            }`
          );
        } else {
          callback();
        }
      } catch (ex) {
        console.log(`error validating`, ex);
      }
    }
  };

  validateOrderPhone = (rule, value, callback) => {
    const form = this.props.form;
    if (!form.getFieldValue("phones") && !form.getFieldValue("no_tablets")) {
      return callback(
        "At least one of number of phones or tablets is required!"
      );
    } else {
      // const phoneCount = this.state.record?.devices_instock !== undefined && this.state.record?.devices_instock !== null && this.state.record?.devices_instock !== 0 ? this.state.record?.devices_instock : this.state.record?.devices_requested;
      // console.log('phoneCount', phoneCount)
      if (value < 0) {
        //|| value > phoneCount) {
        callback(`Phones approved has to be greater than or equal to 1`); // and ${phoneCount}`);
      } else {
        callback();
      }
    }
  };

  validateOrderTablet = (rule, value, callback) => {
    const form = this.props.form;
    if (!form.getFieldValue("phones") && !form.getFieldValue("no_tablets")) {
      return callback(
        "At least one of number of phones or tablets is required!"
      );
    } else {
      try {
        if (value < 0) {
          callback(`Tablets approved has to be greater than or equal to 1`);
        } else {
          callback();
        }
      } catch (ex) {
        console.log(`error validating`, ex);
      }
    }
  };

  showOrderDrawer = () => {
    this.setState({ orderopen: true });
  };

  onOrderClose = () => {
    confirm({
      icon: <Icon type="exclamation-circle" />,
      title: "Are you sure you want to cancel the order?",
      // content: " To cancel placing order?",
      onOk: () => {
        this.setState({ orderopen: false });
        this.props.form.resetFields();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  verifyorderaction = (params) => {
    console.log(params);
    confirm({
      icon: <Icon type="exclamation-circle" />,
      title: "Are you sure you want to approve the order?",
      // content: " To approve this order?",
      onOk: () => {
        this.setState({ loading: true });
        this.props
          .getapproveorder(params)
          .then((res) => {
            console.log(res.data.items);

            message.success(res.data.message);
            this.setState({ loading: false });
            this.searchOrders();
          })
          .catch((err) => {
            console.log(err);
            message.error(err.data.message);
            this.setState({ loading: false });
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  fulfillorderaction = (params) => {
    console.log(params);
    confirm({
      icon: <Icon type="exclamation-circle" />,
      title: "Are you sure?",
      content: " To fulfill this order?",
      onOk: () => {
        this.setState({ loading: true });
        this.props
          .getfulfillorder(params)
          .then((res) => {
            console.log(res.data.items);

            message.success(res.data.message);
            this.setState({ loading: false });
            this.searchOrders();
          })
          .catch((err) => {
            console.log(err);
            message.error(err.data.message);
            this.setState({ loading: false });
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  onFinish = (e) => {
    console.log("Success:", e);

    e.preventDefault();
    try {
      this.props.form.validateFields(
        [
          "phones",
          "no_tablets",
          "office",
          "Delivery",
          "address",
          "address2",
          //"contact_name",
          "company_name",
          "city",
          "state",
          "zip",
          "ship_to_name",
          "description",
          "contact_phone_number",
          "email",
        ],
        (err, values) => {
          console.log("Success:", values);

          if (!err) {

            let devices_requested = values.phones === "" ? 0 : values.phones ?? 0;
            let tablets_requested = values.no_tablets === "" ? 0 : values.no_tablets ?? 0;
  
            // Validate that at least one device is requested
            if (parseInt(devices_requested) === 0 && parseInt(tablets_requested) === 0) {
              message.error("You must request at least one Phone or Tablet.");
              return;
            }
  

            this.setState({ loadingRQSMTbtn: true });
            var obj_arr = {
              // devices_requested: values.phones ?? 0,
              // tablets_requested: values.no_tablets ?? 0,
              // tablets_requested: values.no_tablets === "" ? 0 : values.no_tablets ?? 0,
              // devices_requested: values.phones === "" ? 0 :values.phones ?? 0,
              devices_requested: parseInt(devices_requested),
              tablets_requested: parseInt(tablets_requested),
              method_of_delivery: values.Delivery,
              address: values.address !== undefined ? values.address : "",
              city: values.city !== undefined ? values.city : "",
              state: values.state !== undefined ? values.state : "",
              zip: values.zip !== undefined ? values.zip : "",
              office_id: values.office,
              comments: values?.description ?? "",
              ship_to_name: values.ship_to_name,
              company_name: values?.company_name ?? "",
              //contact_name: values?.contact_name ?? "",
              address2: values.address2 ,
              contact_phone_number:
                values?.contact_phone_number.toString() ?? "",
                email:values?.email ?? "",
            };

            console.log(obj_arr);
            this.setState({ loading: true });
            this.props
              .createinventoryorder(obj_arr)
              .then((res) => {
                console.log(res);
                this.props.form.resetFields();
                this.setState({
                  orderopen: false,
                  loading: false,
                  loadingRQSMTbtn: false,
                });
                this.searchOrders();
                message.success("Order successfully updated");
              })
              .catch((err) => {
                console.log("error", err);
                message.error(err);
                this.setState({ loading: false, loadingRQSMTbtn: false });
              });
          }
        }
      );
    } catch (ex) {
      console.log(`error: `, ex);
    }
  };

  renderOffice() {
    // console.log(`this.state.offices`, this.state.offices)
    return _.map(this.state.offices, (dep, index) => {
      // console.log(`dep`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
      // });
    });
  }

  renderorderapproval() {
    return _.map(this.state.order_approval, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  renderorderstatus() {
    return _.map(this.state.order_status, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  renderretailer() {
    return _.map(this.state.retailers, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  officeOnChange = (e) => {
    var fdesgnd = _.filter(this.state.offices, ["Offices", e]);
    this.setState({
      filteredoffices: fdesgnd,
    });
    console.log(e, fdesgnd);
    // this.props.form.setFields({
    //   offices: {
    //     value: "",
    //   },
    // });
  };

  renderupdatetracking = (e) => {
    this.setState({ addressOpen: true, activeorder: e, dateover:e, });
    console.log(e);
    console.log("state,",this.state.activeorder)
  };
  
  onFinishTrack = (e) => {
    console.log("state,",this.state.activeorder)
    e.preventDefault();
    try {
      this.props.form.validateFields(["tracking_number","ship_date"], (err, values) => {
        if (!err) {
          var obj_arr = {
            tracking_number: values.tracking_number,
            ship_date: values.ship_date === null || values.ship_date === undefined  ? null : moment(values?.ship_date).format("MM-DD-YYYY"),
          };

          this.setState({ loading: true, loadingUDbtn: true });
          this.props
            .updatetrackinginfo(this.state.activeorder.id, obj_arr)
            .then((res) => {
              console.log(res);
              this.props.form.resetFields();
              this.setState({
                addressOpen: false,
                loading: false,
                loadingUDbtn: false,
              });
              this.searchOrders();
              message.success("Order successfully updated");
            })
            .catch((err) => {
              console.log("error", err);
              this.setState({
                loading: false,
                loadingUDbtn: false,
                addressOpen: false,
              });
            });
        }
      });
    } catch (ex) {
      console.log(`error: `, ex);
    }
  };
  resettrackform = () => {
    console.log(`resetform called`);
    this.props.form.resetFields();
    this.setState({ addressOpen: false });
  };

  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  //=========================================
  disabledStartDate1 = (startValue) => {
    
    return startValue > moment().endOf("day");
  };

  disabledEndDate1 = (endValue) => {
   
    return endValue > moment().endOf("day");
  };

  onChange1 = (field, value) => {
    console.log(field, value);
    this.setState({
      [field]: value,
    });
  };
  onStartChange1 = (value) => {
    this.onChange1("startValue1", value);
  };

  onEndChange1 = (value) => {
    this.onChange1("endValue1", value);
  };
  handleStartOpenChange1 = (open) => {
    if (!open) {
      this.setState({ endOpen1: true });
    }
  };

  handleEndOpenChange1 = (open) => {
    this.setState({ endOpen1: open });
  };
  // ======================================================

  // Event Handler to search by text
  // handleSearch = (searchText) => {
  //   axios
  //     .get(
  //       `https://expressentry.melissadata.net/web/ExpressAddress?id=KEUUp78ysH-cZ8LcCMMZHM**&line1=${searchText}&maxrecords=5&country=US&format=json`
  //     )
  //     .then((res) => {
  //       const resp = res.data.Results;
  //       const addressLine = resp.map((item) => item.Address.AddressLine1);
  //       console.log("resp", addressLine);
  //       const filteredOptions = addressLine.filter((option) =>
  //         option.toLowerCase().includes(searchText.toLowerCase())
  //       );
  //       this.setState({ options: filteredOptions });
  //     })
  //     .catch((err) => console.log(err));
  // };
  onSelect = (data) => {
    console.log("onSelect", data);
    this.setState({ addLine1: data });
  };

  // remove = k => {
  //   const { form } = this.props;
  //   // can use data-binding to get
  //   const keys = form.getFieldValue('keys');
  //   // We need at least one passenger
  //   if (keys.length === 1) {
  //     return;
  //   }

  //   // can use data-binding to set
  //   form.setFieldsValue({
  //     keys: keys.filter(key => key !== k),
  //   });
  // };

  // add = () => {
  //   const { form } = this.props;
  //   // can use data-binding to get
  //   const keys = form.getFieldValue('keys');
  //   const nextKeys = keys.concat(id++);
  //   // can use data-binding to set
  //   // important! notify form to detect changes
  //   form.setFieldsValue({
  //     keys: nextKeys,
  //   });
  // };

  // handleSubmit = e => {
  //   e.preventDefault();
  //   this.props.form.validateFields((err, values) => {
  //     if (!err) {
  //       const { keys, names } = values;
  //       console.log('Received values of form: ', values);
  //       console.log('Merged values:', keys.map(key => names[key]));
  //     }
  //   });
  // };

  // UserCommand Function
  editcommentsuser = (params) => {
    this.setState({ loading: true });
    this.props
      .viewcommentsuserinventoryaction(params)
      .then((res) => {
        console.log(res.data.items);
        this.setState({ Listdata1: res.data.items });
        message.success(res.data.message);
        this.setState({ loading: false, cvisible: true });
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };
  onCloseEdit = () => {
    this.setState({
      visibleedit: false,
      comvisible: false,
    });
    this.props.form.resetFields();
  };
  onClose1 = () => {
    this.setState({
      cvisible: false,
    });
    this.props.form.resetFields();
  };
  onClose2 = () => {
    this.setState({
      comvisible: false,
    });
    this.props.form.resetFields();
  };
  addcommentsuser = (params) => {
    this.setState({ comvisible: true, inventory_order_id: params });
  };
  handleCommentSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["admin_comments"],
      (err, values) => {
        console.log(values);
        console.log("first", this.state.inventory_order_id.id);
        if (!err) {
          this.setState({ loading: true, loadingCMdbtn: true });
          var obj_arr1 = {
            inventory_order_id: this.state.inventory_order_id.id,
            comments: values.admin_comments,
          };

          this.props
            .addcommentsuserinventoryaction(obj_arr1)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                comvisible: false,
                loading: false,
                loadingCMdbtn: false,
              });
              this.props.form.resetFields();
              this.searchOrders();
            })
            .catch((error) => {
              message.error(error);
              console.log(error);
              this.setState({ loading: false, loadingCMdbtn: false });
            });
        }
      }
    );
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };
  disabledStartDate = (startValue) => {
  
    return startValue && startValue > moment().endOf("day");
  };

  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  render() {
    const {
      open,
      orderopen,
      record,
      visible,
      superrole,
      superadmin,
      retailerrole,
      marketrole,
      isapprove,
      addressOpen,
      dashdata,
      fetching,
      fdata,
      startValue,
      endValue,
      endOpen1,
      startValue1,
      endValue1,
      // options,
      // addLine1,
    } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    // console.log(`this.state.record?.devices_requested`, this.state.record?.devices_requested)
    // console.log(`this.state.offices`, this.state.offices)

    
    const columns = [
      {
        title: "Order ID",
        dataIndex: "id",
        key: "id",
        align: "center",
        sorter: true,
      },
      {
        title: "Order Action",
        dataIndex: "action",
        key: "action",
        align: "center",
        hidden: superrole === true ? false : true,
        render: (text, record_item) => (
          <>
            {record_item?.status >= 4 || record_item?.status === 6 ? (
              <Button
                disabled={
                  marketrole || retailerrole === true
                    ? true
                    : record_item?.status !== 6
                    ? true
                    : false
                }
                onClick={() => this.verifyorderaction(record_item)}
                type="primary"
              >
                Approve Order
              </Button>
            ) : (
              <Button
                disabled={
                  marketrole || retailerrole === true
                    ? true
                    : record_item?.status === 6 ||
                      record_item?.approval_status === 6
                    ? true
                    : false
                }
                onClick={() => this.verifyorderaction(record_item)}
                type="primary"
              >
                Approve Order
              </Button>
            )}
          </>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (text, record) => (
          <span>
            {record.status === 10 &&  <Tag color="orange"> {record.status_text}</Tag>}
            {record.status === 9 &&  <Tag color="orange"> {record.status_text}</Tag>}
            {record.status === 1 &&  <Tag color="orange"> {record.status_text}</Tag>}
            {record.status === 6 &&  <Tag color="#87d068"> {record.status_text}</Tag>}
            {record.status === 8 &&  <Tag color="green">Admin</Tag>}
            {record.status === 2 &&  <Tag color="geekblue">IBO Manager</Tag>}
            {record.status === 4 &&  <Tag color="blue">Retailer</Tag>}
            {record.status === 7 &&  <Tag color="volcano">Admin</Tag>}
            {record.status === 3 &&  <Tag color="volcano">IBO Manager</Tag>}
            {record.status === 5 &&  <Tag color="volcano">Retailer</Tag>}
            {/* <Tag
              color={
                record?.status === null ||
                record?.status === undefined ||
                record?.status === 7 ||
                record?.status === 3 ||
                record?.status === 5
                  ? "red"
                  : record?.status === 1
                  ? "orange"
                  : record?.status === 2 ||
                    record?.status === 6 ||
                    record?.status === 9
                  ? "green"
                  : record?.status === 4
                  ? "geekblue"
                  : "grey"
              }
            >
              {record.status_text}
            </Tag> */}
          </span>
        ),
      },
      {
        title: "Retailer",
        dataIndex: "retailer_name",
        key: "retailer_name",
        align: "center",
        hidden: superrole === true ? false : true,
      },
    
      {
        title: "IBO Name",
        dataIndex: "ibo_marketer_name",
        key: "ibo_marketer_name",
        align: "center",
        sorter: true,
     },
      {
        title: "Office",
        dataIndex: "office_number",
        key: "office_number",
        align: "center",
        sorter: true,
        width:50
      },
      {
        title: "Tablets Approved",
        dataIndex: "tablets_approved",
        key: "tablets_approved",
        align: "center",
        sorter: true,
      },
      {
        title: "Phones Approved",
        dataIndex: "devices_approved",
        key: "devices_approved",
        align: "center",
        sorter: true,
      },
      {
        title: "Delivery Method",
        dataIndex: "method_of_delivery_text",
        key: "method_of_delivery_text",
        align: "center",
        sorter: true,
      },
      {
        title: "Tracking No",
        dataIndex: "tracking_number",
        key: "tracking_number",
        align: "center",
        // render: (text, record,record_item) => (
        // <Button
        // onClick={() => this.renderupdatetracking(record_item)}
        // type="primary"
        // >
        // <span>
        //   <Tag>{record.tracking_number}</Tag>
        // </span>
        // </Button>

        // superrole && (
        //   <>
        //       <Button

        //         onClick={() => this.renderupdatetracking(record_item)}
        //         type="primary"
        //       >
        //         <Tag>{record.tracking_number}</Tag>
        //       </Button>
        //   </>
        // )

        // ),
      },
      // {
      //   title: "Approval Status",
      //   dataIndex: "approval_status",
      //   key: "approval_status",
      //   render: (text, record) => (
      //     <>
      //       <Tag
      //         color={
      //           record?.approval_status === null ||
      //           record?.approval_status === undefined ||
      //           record?.approval_status === 3 ||
      //           record?.approval_status === 5 ||
      //           record?.approval_status === 7
      //             ? "red"
      //             : record?.approval_status === 1
      //             ? "orange"
      //             : record?.approval_status === 6
      //             ? "darkgreen"
      //             : record?.approval_status === 2
      //             ? "lightgreen"
      //             : record?.approval_status === 4
      //             ? "green"
      //             : "geekblue"
      //         }
      //       >
      //         {" "}
      //         {record?.approval_status_text?.toUpperCase()}
      //       </Tag>
      //     </>
      //   ),
      // },
     
      {
        title: "Ship Name",
        dataIndex: "ship_to_name",
        key: "ship_to_name",
        align: "ship_to_name",
      },
      {
        title: "Ship Date",
        dataIndex: "ship_date",
        key: "ship_date",
        align: "ship_date",
        hidden: superrole === true ? false : true,
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
        align: "center",
        sorter: true,
      },
      {
        title: "Address 2",
        dataIndex: "address2",
        key: "address2",
        align: "center",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        align: "center",
        sorter: true,
      },
      {
        title: "Contact Phone Number",
        dataIndex: "contact_phone_number",
        key: "contact_phone_number",
        align: "center",
        sorter: true,
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
        align: "center",
        sorter: true,
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
        align: "center",
        sorter: true,
      },
      {
        title: "Zipcode",
        dataIndex: "zip",
        key: "zip",
        align: "center",
        sorter: true,
      },
     
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },

      {
        title: "Requested By",
        dataIndex: "manager_name",
        key: "manager_name",
        align: "center",
        sorter: true,
      },
      {
        title: "Phones In-stock",
        dataIndex: "phones_instock",
        key: "phones_instock",
        align: "center",
      },
      {
        title: "Phones Requested",
        dataIndex: "devices_requested",
        key: "devices_requested",
        align: "center",
        sorter: true,
      },

      {
        title: "Tablets In-stock",
        dataIndex: "tablets_instock",
        key: "tablets_instock",
        align: "center",
      },
      {
        title: "Tablets Requested",
        dataIndex: "tablets_requested",
        key: "tablets_requested",
        align: "center",
        sorter: true,
      },
      
      {
        title: "Action",
        key: "status_text",
        width: 450,
        fixed: "right",
        render: (text, record_item) => (
          <div>
            <span>
            {record_item.comments_exists === 0 ? (
              <>
              <Tooltip title="View Comments">
                <Button
                  shape="circle"
                  icon="eye"
                  disabled
                  type="primary"
                ></Button>
              </Tooltip>
              <Divider type="vertical" />
              </>):( <>
              <Tooltip title="View Comments">
                <Button
                  shape="circle"
                  icon="eye"
                  onClick={() => this.editcommentsuser(record_item)}
                  type="primary"
                ></Button>
              </Tooltip>
              <Divider type="vertical" />
              </>)}
              {superrole && (
                <>
                  <Tooltip title="Add Comments">
                    <Button
                      shape="circle"
                      icon="message"
                      onClick={() => this.addcommentsuser(record_item)}
                      type="primary"
                    ></Button>
                  </Tooltip>
                </>
              )}
              {superrole && (
                <>
                
                  <Divider type="vertical" />
                  <Tooltip title="Update Tracking Number">
                    <Button
                      shape="circle"
                      icon="barcode"
                      onClick={() => this.renderupdatetracking(record_item)}
                      type="primary"
                    ></Button>
                  </Tooltip>
                  <Divider type="vertical" />
                </>
              )}
              <Tooltip title="Verify Order">
                <Button
                  type="primary"
                  // className="padd5 mr5"
                  shape="circle"
                  icon="shop"
                  style={{ cursor: "pointer" }}
                  disabled={
                    !(
                      (record_item?.status === undefined ||
                        record_item?.status === null ||
                        record_item?.status < 7) &&
                      (((superrole || superadmin) &&
                        // record_item?.status !== undefined && record_item?.status !== null && record_item?.status < 4&&
                        record_item?.status !== null &&
                        record_item?.status !== undefined &&
                        record_item?.status === 4) ||
                        (retailerrole &&
                          (record_item?.approval_status === null ||
                            record_item?.approval_status === undefined ||
                            record_item?.approval_status <= 2)) ||
                        (marketrole &&
                          (record_item?.approval_status === null ||
                            record_item?.approval_status === undefined ||
                            record_item?.approval_status === 1)))
                    )
                  }
                  onClick={() => {
                    this.showDrawer(record_item, true);
                  }}
                ></Button>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Reject Order">
                <Button
                  type="danger"
                  shape="circle"
                  icon="delete"
                  // className="padd5 mr5"
                  disabled={
                    !(
                      (record_item?.status === undefined ||
                        record_item?.status === null ||
                        record_item?.status < 7) &&
                      (((superrole || superadmin) &&
                        // record_item?.status !== undefined && record_item?.status !== null && record_item?.status < 4&&
                        record_item?.approval_status !== null &&
                        record_item?.approval_status !== undefined &&
                        record_item?.approval_status === 4) ||
                        (retailerrole &&
                          (record_item?.approval_status === null ||
                            record_item?.approval_status === undefined ||
                            record_item?.approval_status <= 2)) ||
                        (marketrole &&
                          (record_item?.approval_status === null ||
                            record_item?.approval_status === undefined ||
                            record_item?.approval_status === 1)))
                    )
                  }
                  style={{ cursor: "pointer" }}
                  onClick={() => this.showDrawer(record_item, false)}
                ></Button>
              </Tooltip>
              <Divider type="vertical" />
              {/* </>
                   : null
                 } */}
              <Tooltip title="View log">
                <Button
                  shape="circle"
                  icon="history"
                  onClick={() => this.rendercanlog(record_item.id)}
                  type="primary"
                ></Button>
              </Tooltip>
              {/* {superrole === true && record_item.approval_status !== 7 ? ( */}
              {superrole === true ? (
                <>
                  <Divider type="vertical" />
                  <Tooltip title="Edit Order">
                    <Button
                      type="primary"
                      shape="circle"
                      icon="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.showDrawer1(record_item);
                      }}
                    ></Button>
                  </Tooltip>
                </>
               ) : ( null
                // <>
                //   <Divider type="vertical" />
                //   <Button
                //     type="primary"
                //     shape="circle"
                //     icon="edit"
                //     style={{ cursor: "pointer" }}
                //     disabled
                //   ></Button>
                // </>
              )} 
               <Divider type="vertical" />
              {
              // retailerrole === true &&
               record_item.approval_status === 7  ? (
                <>
                  <Tooltip title="Resubmit Order">
                    <Button
                      type="primary"
                      shape="circle"
                      icon="sync"
                      // icon="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.showDrawer2(record_item);
                      }}
                    ></Button>
                  </Tooltip>
                </>
               ) : ( null
               )}  
            </span>
            
          </div>
        ),
      },
    ].filter((item) => !item.hidden);

    // const formItemLayout = {
    //   labelCol: {
    //     xs: { span: 24 },
    //     sm: { span: 4 },
    //   },
    //   wrapperCol: {
    //     xs: { span: 24 },
    //     sm: { span: 20 },
    //   },
    // };
    // const formItemLayoutWithOutLabel = {
    //   wrapperCol: {
    //     xs: { span: 24, offset: 0 },
    //     sm: { span: 20, offset: 4 },
    //   },
    // };
    // getFieldDecorator('keys', { initialValue: [] });
    // const keys = getFieldValue('keys');
    // const formItems = keys.map((k, index) => (
    //   <Form.Item
    //     {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
    //     label={index === 0 ? 'Tracking number' : ''}
    //     required={false}
    //     key={k}
    //   >
    //     {getFieldDecorator(`names[${k}]`, {
    //       validateTrigger: ['onChange', 'onBlur'],
    //       rules: [
    //         {
    //           required: true,
    //           whitespace: true,
    //           message: "Please input Tracking number or delete this field.",
    //         },
    //       ],
    //     })(<Input placeholder="Tracking number" style={{ width: '60%', marginRight: 8 }} />)}
    //     {keys.length > 1 ? (
    //       <Icon
    //         className="dynamic-delete-button"
    //         type="minus-circle-o"
    //         onClick={() => this.remove(k)}
    //       />
    //     ) : null}
    //   </Form.Item>

    // ));
    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-6">
              <h4>
                Order Management{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-6 text-right">
              {retailerrole || marketrole ? (
                <Button
                  type="primary"
                  className="login-form-button mr-2"
                  // loading={this.state.loading}
                  onClick={this.showOrderDrawer}
                >
                  Request Order
                </Button>
              ) : null}
              <Button
                type="default"
                className="mr5"
                onClick={() => this.clearSearch()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>

              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
         

          <div className="row padd20 bg-grey">
            {retailerrole || marketrole ? null : (
              <div className="col-3">
                <label for="exampleFormControlInput1">Select Retailer</label>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select Option"
                  value={this.state.selectedretailer}
                  onChange={this.onchangeretailer}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.renderretailer()}
                </Select>
              </div>
            )}
            <div className="col-3">
              <label for="exampleFormControlInput1">Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={this.fetchUser}
                defaultActiveFirstOption={false}
                value={this.state.selectedoffice}
                onChange={this.onchangeoffice}
              >
                {fdata?.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
              {/* <label for="exampleFormControlInput1">Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedoffice}
                onChange={this.onchangeoffice}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="0">Any</Option>
                {this.renderOffice()}
              </Select> */}
            </div>
            {/* <div className="col-3">
              <label for="exampleFormControlInput1">
                Select Approval Status
              </label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedapprovalstatus}
                onChange={this.onchangeapprovalfilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderorderapproval()}
              </Select>
            </div> */}
          
            <div className="col-3">
              <label for="exampleFormControlInput1">Order From</label>
              <DatePicker
                value={startValue1}
                disabledDate={this.disabledStartDate1}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onStartChange1}
                onOpenChange={this.handleStartOpenChange1}
              />
            </div>
            <div className="col-3 ">
              <label for="exampleFormControlInput1">Order To</label>
              <DatePicker
                value={endValue1}
                disabledDate={this.disabledEndDate1}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onEndChange1}
                open={endOpen1}
                onOpenChange={this.handleEndOpenChange1}
              />
            </div>
            {retailerrole ? (
            <div className="col-3">
              <label for="exampleFormControlInput1">Select Order Status</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedorderstatus}
                onChange={this.onchangeorderfilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderorderstatus()}
              </Select>
            </div>
            ):(
              <div className="col-3 mt-3">
              <label for="exampleFormControlInput1">Select Order Status</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedorderstatus}
                onChange={this.onchangeorderfilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderorderstatus()}
              </Select>
            </div>

            )}
            <div className="col-3 mt-3">
              <label for="exampleFormControlInput1">Search By keyword</label>
              <Input
                placeholder="Type keyword"
                value={this.state.name}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col-3 mt-5">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchOrders("", null, null, "reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>

          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
          {/* <div className="row mt-5">
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Allocated By Admin
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_allocated_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_allocated_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Partially Allocated By Admin
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_partially_allocated_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_partially_allocated_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Approved By Admin
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_approved_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_approved_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Verified By Admin
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_verified_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_verified_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Rejected By Admin
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_rejected_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_rejected_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Approved By Retailer
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_approved_by_retailer ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_approved_by_retailer ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Rejected By Retailer
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_rejected_by_retailer ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_rejected_by_retailer ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Approved By IBO
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_approved_by_ibo ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_approved_by_ibo ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Rejected By IBO
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_allocated_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_allocated_by_admin ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-12">
              <div class="card  mb-4" style={{ borderRadius: "1.25rem" }}>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-12">
                      <p class="text-sm mb-3 text-info text-uppercase font-weight-bold text-center">
                        Pending
                      </p>
                    </div>
                    <div
                      class="col-6"
                      style={{ borderRight: "1px solid #ccc" }}
                    >
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.phones_pending ?? 0}
                        </h5>
                        <p class="mb-0">Phones</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="numbers text-center">
                        <h5 class="font-weight-bolder">
                          {" "}
                          {dashdata?.inventory_order_summary_data
                            ?.tablets_pending ?? 0}
                        </h5>
                        <p class="mb-0">Tablets</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
         {/* Verify Drawer */}
        <Drawer
          width={680}
          onClose={this.onClose}
          visible={this.state.open}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Form
            onSubmit={this.handleSubmit}
            autoComplete="off"
            name="basic"
            layout="vertical"
          >
            {isapprove ? (
              <>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="No of Phones">
                      {getFieldDecorator("devices", {
                        initialValue:
                          record?.devices_approved !== undefined &&
                          record?.devices_approved !== null
                            ? record?.devices_approved
                            : record?.devices_requested,
                        rules: [
                          {
                            // required: true,
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "Please input number of phones",
                            
                          },
                          // {
                          //   type: "number",
                          //   pattern: new RegExp(/^[0-9]+$/),
                          //   message: "Please input number of phones",
                          //   validator: this.validatePhone,
                          //   message: [`Phones approved has to be between 1 and  ${record?.phones_instock !== undefined && record?.phones_instock !== null && record?.phones_instock !== 0 ? record?.phones_instock : record?.devices_requested}`],
                          // },
                        ],
                      })(<Input type="number"
                       min="0" 
                       />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="No of Tablets">
                      {getFieldDecorator("tablets", {
                        initialValue:
                          record?.tablets_approved !== undefined &&
                          record?.tablets_approved !== null
                            ? record?.tablets_approved
                            : record?.tablets_requested,
                        rules: [
                          {
                            // required: true,
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "Please input number of tablets",
                          },
                          // {
                          //   type: "number",

                          //   validator: this.validateTablet,
                          //   message: [`Tablets approved has to be between 1 and ${record?.tablets_instock !== undefined && record?.tablets_instock !== null && record?.tablets_instock !== 0 ? record?.tablets_instock : record?.tablets_requested}`],
                          // },
                        ],
                      })(<Input type="number"
                       min="0"
                       />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="Ship To">
                      {getFieldDecorator("ship_to_name1", {
                        initialValue: this.state.record?.ship_to_name ?? "",
                        rules: [
                          {
                            required: true,
                            message: "Please input name",
                          },
                        ],
                      })(<Input placeholder="name" />)}
                    </Form.Item>
                  </Col>
                
                  <Col span={12}>
                    <Form.Item label="Company  Name">
                      {getFieldDecorator("company_name1", {
                        initialValue: this.state.record?.company_name ?? "",
                        rules: [
                          {
                            required: false,
                            message: "Please input company name",
                          },
                        ],
                      })(<Input placeholder="name" />)}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Street Address">
                      {getFieldDecorator("address11", {
                        initialValue: this.state.record?.address ?? "",
                        rules: [
                          {
                            required: true,
                            message: "Please input Address",
                          },
                        ],
                      })(<Input placeholder="Address" />)}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Street Address1">
                      {getFieldDecorator("address21", {
                        initialValue: this.state.record?.address2 ?? "",
                        rules: [
                          {
                            required: false,
                            message: "Please input Address",
                          },
                        ],
                      })(<Input placeholder="Address" />)}
                    </Form.Item>
                  </Col>
                 
                  {/* </Row>
          <Row gutter={16}> */}
                  <Col span={24}>
                    <Form.Item label="City">
                      {getFieldDecorator("city1", {
                        initialValue: this.state.record?.city ?? "",
                        rules: [
                          {
                            required: true,
                            message: "Please input City",
                          },
                        ],
                      })(<Input placeholder="City" />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="State">
                      {getFieldDecorator("state1", {
                        initialValue: this.state.record?.state ?? "",
                        rules: [
                          {
                            required: true,
                            message: "Please select State",
                          },
                        ],
                      })(
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Select State"
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {/* <Option value="AL">Alabama</Option>
                          <Option value="AK">Alaska</Option>
                          <Option value="AZ">Arizona</Option>
                          <Option value="AR">Arkansas</Option>
                          <Option value="CA">California</Option>
                          <Option value="CO">Colorado</Option>
                          <Option value="CT">Connecticut</Option>
                          <Option value="DE">Delaware</Option>
                          <Option value="DC">District Of Columbia</Option>
                          <Option value="FL">Florida</Option>
                          <Option value="GA">Georgia</Option>
                          <Option value="HI">Hawaii</Option>
                          <Option value="ID">Idaho</Option>
                          <Option value="IL">Illinois</Option>
                          <Option value="IN">Indiana</Option>
                          <Option value="IA">Iowa</Option>
                          <Option value="KS">Kansas</Option>
                          <Option value="KY">Kentucky</Option>
                          <Option value="LA">Louisiana</Option>
                          <Option value="ME">Maine</Option>
                          <Option value="MD">Maryland</Option>
                          <Option value="MA">Massachusetts</Option>
                          <Option value="MI">Michigan</Option>
                          <Option value="MN">Minnesota</Option>
                          <Option value="MS">Mississippi</Option>
                          <Option value="MO">Missouri</Option>
                          <Option value="MT">Montana</Option>
                          <Option value="NE">Nebraska</Option>
                          <Option value="NV">Nevada</Option>
                          <Option value="NH">New Hampshire</Option>
                          <Option value="NJ">New Jersey</Option>
                          <Option value="NM">New Mexico</Option>
                          <Option value="NY">New York</Option>
                          <Option value="NC">North Carolina</Option>
                          <Option value="ND">North Dakota</Option>
                          <Option value="OH">Ohio</Option>
                          <Option value="OK">Oklahoma</Option>
                          <Option value="OR">Oregon</Option>
                          <Option value="PA">Pennsylvania</Option>
                          <Option value="RI">Rhode Island</Option>
                          <Option value="SC">South Carolina</Option>
                          <Option value="SD">South Dakota</Option>
                          <Option value="TN">Tennessee</Option>
                          <Option value="TX">Texas</Option>
                          <Option value="UT">Utah</Option>
                          <Option value="VT">Vermont</Option>
                          <Option value="VA">Virginia</Option>
                          <Option value="WA">Washington</Option>
                          <Option value="WV">West Virginia</Option>
                          <Option value="WI">Wisconsin</Option>
                          <Option value="WY">Wyoming</Option> */}
                           {this.renderstateshort()}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  {/* </Row>
          <Row gutter={16}> */}
                  <Col span={12}>
                    <Form.Item label="ZIP">
                      {getFieldDecorator("zip1", {
                        initialValue: this.state.record?.zip ?? "",
                        rules: [
                          {
                            required: true,
                            pattern: new RegExp(/^[0-9]+$/),
                            message: "Please input ZIP",
                          },
                        ],
                      })(<Input maxLength={5} placeholder="ZIP" />)}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Contact Number">
                {getFieldDecorator("contact_phone_number1", {
                  initialValue: this.state.record?.contact_phone_number,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]{10,10}$/),
                      message: "Please input mobile number",
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="Enter Mobile Number"
                    minLength={10}
                    style={{ width: "100%" }}
                    maxLength={10}
                    min={0}
                    step={0}
                    autoComplete="new-password"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
                    <Form.Item label="Email">
                      {getFieldDecorator("email", {
                        initialValue: this.state.record?.email ?? "",
                        rules: [
                          {
                            required: true,
                            message: "Please input Email",
                          },
                        ],
                      })(<Input placeholder="Email" />)}
                    </Form.Item>
                  </Col>
             </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Comment">
                  {getFieldDecorator("admin_comments", {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={4}
                      placeholder="please enter comment"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
              className="mt-4"
            >
              {" "}
              {isapprove ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loadingSMTbtn}
              >
                Verify
              </Button>
              ):(
                <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loadingSMTbtn}
              >
                Reject
              </Button>

              )}
              <Button
                type="dashed"
                style={{ marginLeft: 8 }}
                onClick={this.onClose}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
        {/* Edit Drawer */}
        <Drawer
          width={680}
          onClose={this.onCloseE}
          visible={this.state.editopen}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Form
            onSubmit={this.handleSubmitE}
            autoComplete="off"
            name="basic"
            layout="vertical"
          >
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="No of Phones">
                    {getFieldDecorator("devices1", {
                      initialValue:
                        record?.devices_approved !== undefined &&
                        record?.devices_approved !== null
                          ? record?.devices_approved
                          : record?.devices_requested,
                      rules: [
                        {
                          // required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input number of phones",
                        },
                      ],
                    })(<Input type="number" min="0"/>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="No of Tablets">
                    {getFieldDecorator("tablets1", {
                      initialValue:
                        record?.tablets_approved !== undefined &&
                        record?.tablets_approved !== null
                          ? record?.tablets_approved
                          : record?.tablets_requested,
                      rules: [
                        {
                          // required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input number of tablets",
                        },
                        // {
                        //   type: "number",

                        //   validator: this.validateTablet,
                        //   message: [`Tablets approved has to be between 1 and ${record?.tablets_instock !== undefined && record?.tablets_instock !== null && record?.tablets_instock !== 0 ? record?.tablets_instock : record?.tablets_requested}`],
                        // },
                      ],
                    })(<Input type="number" min="0"/>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Ship To">
                    {getFieldDecorator("ship_to_name2", {
                      initialValue: this.state.record?.ship_to_name ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please input name",
                        },
                      ],
                    })(<Input placeholder="name" />)}
                  </Form.Item>
                </Col>
                 {/* <Col span={12}>
                    <Form.Item label="Ship Date">
                      {getFieldDecorator("ship_date", {
                          initialValue:
                          this.state.record?.ship_date  === undefined ||  this.state.record?.ship_date  === null
                            ? null
                            : moment(this.state.record?.ship_date ?? null),
                       
                        rules: [
                          {
                            required: false,
                            message: "Please input ship date",
                          },
                        ],
                      })( <DatePicker
                        disabledDate={this.disabledStartDate}
                        format="MM-DD-YYYY"
                        placeholder="Select Date"
                        onChange={this.onStartChange}
                      />)}
                    </Form.Item>
                  </Col> */}
                <Col span={12}>
                  <Form.Item label="Company  Name">
                    {getFieldDecorator("company_name2", {
                      initialValue: this.state.record?.company_name ?? "",
                      rules: [
                        {
                          required: false,
                          message: "Please input company name",
                        },
                      ],
                    })(<Input placeholder="name" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Street Address">
                    {getFieldDecorator("address12", {
                      initialValue: this.state.record?.address ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please input Address",
                        },
                      ],
                    })(<Input placeholder="Address" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Street Address1">
                    {getFieldDecorator("address22", {
                      initialValue: this.state.record?.address2 ?? "",
                      rules: [
                        {
                          required: false,
                          message: "Please input Address",
                        },
                      ],
                    })(<Input placeholder="Address" />)}
                  </Form.Item>
                </Col>
               
                {/* </Row>
          <Row gutter={16}> */}
                <Col span={24}>
                  <Form.Item label="City">
                    {getFieldDecorator("city2", {
                      initialValue: this.state.record?.city ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please input City",
                        },
                      ],
                    })(<Input placeholder="City" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="State">
                    {getFieldDecorator("state2", {
                      initialValue: this.state.record?.state ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please select State",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select State"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <Option value="AL">Alabama</Option>
                        <Option value="AK">Alaska</Option>
                        <Option value="AZ">Arizona</Option>
                        <Option value="AR">Arkansas</Option>
                        <Option value="CA">California</Option>
                        <Option value="CO">Colorado</Option>
                        <Option value="CT">Connecticut</Option>
                        <Option value="DE">Delaware</Option>
                        <Option value="DC">District Of Columbia</Option>
                        <Option value="FL">Florida</Option>
                        <Option value="GA">Georgia</Option>
                        <Option value="HI">Hawaii</Option>
                        <Option value="ID">Idaho</Option>
                        <Option value="IL">Illinois</Option>
                        <Option value="IN">Indiana</Option>
                        <Option value="IA">Iowa</Option>
                        <Option value="KS">Kansas</Option>
                        <Option value="KY">Kentucky</Option>
                        <Option value="LA">Louisiana</Option>
                        <Option value="ME">Maine</Option>
                        <Option value="MD">Maryland</Option>
                        <Option value="MA">Massachusetts</Option>
                        <Option value="MI">Michigan</Option>
                        <Option value="MN">Minnesota</Option>
                        <Option value="MS">Mississippi</Option>
                        <Option value="MO">Missouri</Option>
                        <Option value="MT">Montana</Option>
                        <Option value="NE">Nebraska</Option>
                        <Option value="NV">Nevada</Option>
                        <Option value="NH">New Hampshire</Option>
                        <Option value="NJ">New Jersey</Option>
                        <Option value="NM">New Mexico</Option>
                        <Option value="NY">New York</Option>
                        <Option value="NC">North Carolina</Option>
                        <Option value="ND">North Dakota</Option>
                        <Option value="OH">Ohio</Option>
                        <Option value="OK">Oklahoma</Option>
                        <Option value="OR">Oregon</Option>
                        <Option value="PA">Pennsylvania</Option>
                        <Option value="RI">Rhode Island</Option>
                        <Option value="SC">South Carolina</Option>
                        <Option value="SD">South Dakota</Option>
                        <Option value="TN">Tennessee</Option>
                        <Option value="TX">Texas</Option>
                        <Option value="UT">Utah</Option>
                        <Option value="VT">Vermont</Option>
                        <Option value="VA">Virginia</Option>
                        <Option value="WA">Washington</Option>
                        <Option value="WV">West Virginia</Option>
                        <Option value="WI">Wisconsin</Option>
                        <Option value="WY">Wyoming</Option> */}
                         {this.renderstateshort()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {/* </Row>
          <Row gutter={16}> */}
                <Col span={12}>
                  <Form.Item label="ZIP">
                    {getFieldDecorator("zip2", {
                      initialValue: this.state.record?.zip ?? "",
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input ZIP",
                        },
                      ],
                    })(<Input maxLength={5} placeholder="ZIP" />)}
                  </Form.Item>
                </Col>
              </Row>
            </>
            <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Contact Number">
                {getFieldDecorator("contact_phone_number2", {
                  initialValue: this.state.record?.contact_phone_number,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]{10,10}$/),
                      message: "Please input mobile number",
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="Enter Mobile Number"
                    minLength={10}
                    style={{ width: "100%" }}
                    maxLength={10}
                    min={0}
                    step={0}
                    autoComplete="new-password"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
                    <Form.Item label="Email">
                      {getFieldDecorator("email", {
                        initialValue: this.state.record?.email ?? "",
                        rules: [
                          {
                            required: true,
                            message: "Please input Email",
                          },
                        ],
                      })(<Input placeholder="Email" />)}
                    </Form.Item>
                  </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Comment">
                  {getFieldDecorator("admin_comments1", {
                    rules: [
                      {
                        required: false,
                        message: "Please input Comment",
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={4}
                      placeholder="please enter comment"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
              className="mt-4"
            >
              {" "}
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loadingSMTbtn}
              >
                Update
              </Button>
              <Button
                type="dashed"
                style={{ marginLeft: 8 }}
                onClick={this.onCloseE}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Drawer>

         {/* Resubmit Drawer */}
         <Drawer
          width={680}
          onClose={this.onCloseE}
          visible={this.state.resubmitopen}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
            <Form
            onSubmit={this.handleSubmitResubmit}
            autoComplete="off"
            name="basic"
            layout="vertical"
          >
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="No of Phones">
                    {getFieldDecorator("devices2", {
                      // initialValue:
                      //   record?.devices_approved !== undefined &&
                      //   record?.devices_approved !== null
                      //     ? record?.devices_approved
                      //     : record?.devices_requested,
                      initialValue:
                      record?.devices_requested !== undefined &&
                      record?.devices_requested !== null
                        ? record?.devices_requested
                        : record?.devices_requested,
                      rules: [
                        {
                          // required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input number of phones",
                        },
                      ],
                    })(<Input type="number"
                     min="0"
                     />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="No of Tablets">
                    {getFieldDecorator("tablets2", {
                      // initialValue:
                      //   record?.tablets_approved !== undefined &&
                      //   record?.tablets_approved !== null
                      //     ? record?.tablets_approved
                      //     : record?.tablets_requested,
                      initialValue:
                        record?.tablets_requested !== undefined &&
                        record?.tablets_requested !== null
                          ? record?.tablets_requested
                          : record?.tablets_requested,
                      rules: [
                        {
                          // required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input number of tablets",
                        },
                        // {
                        //   type: "number",

                        //   validator: this.validateTablet,
                        //   message: [`Tablets approved has to be between 1 and ${record?.tablets_instock !== undefined && record?.tablets_instock !== null && record?.tablets_instock !== 0 ? record?.tablets_instock : record?.tablets_requested}`],
                        // },
                      ],
                    })(<Input type="number"
                     min="0"
                     />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="Ship To">
                    {getFieldDecorator("ship_to_name3", {
                      initialValue: this.state.record?.ship_to_name ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please input name",
                        },
                      ],
                    })(<Input placeholder="name" />)}
                  </Form.Item>
                </Col>
                 {/* <Col span={12}>
                    <Form.Item label="Ship Date">
                      {getFieldDecorator("ship_date", {
                          initialValue:
                          this.state.record?.ship_date  === undefined ||  this.state.record?.ship_date  === null
                            ? null
                            : moment(this.state.record?.ship_date ?? null),
                       
                        rules: [
                          {
                            required: false,
                            message: "Please input ship date",
                          },
                        ],
                      })( <DatePicker
                        disabledDate={this.disabledStartDate}
                        format="MM-DD-YYYY"
                        placeholder="Select Date"
                        onChange={this.onStartChange}
                      />)}
                    </Form.Item>
                  </Col> */}
                <Col span={12}>
                  <Form.Item label="Company  Name">
                    {getFieldDecorator("company_name3", {
                      initialValue: this.state.record?.company_name ?? "",
                      rules: [
                        {
                          required: false,
                          message: "Please input company name",
                        },
                      ],
                    })(<Input placeholder="name" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Street Address">
                    {getFieldDecorator("address13", {
                      initialValue: this.state.record?.address ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please input Address",
                        },
                      ],
                    })(<Input placeholder="Address" />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Street Address1">
                    {getFieldDecorator("address23", {
                      initialValue: this.state.record?.address2 ?? "",
                      rules: [
                        {
                          required: false,
                          message: "Please input Address",
                        },
                      ],
                    })(<Input placeholder="Address" />)}
                  </Form.Item>
                </Col>
               
                {/* </Row>
          <Row gutter={16}> */}
                <Col span={24}>
                  <Form.Item label="City">
                    {getFieldDecorator("city3", {
                      initialValue: this.state.record?.city ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please input City",
                        },
                      ],
                    })(<Input placeholder="City" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="State">
                    {getFieldDecorator("state3", {
                      initialValue: this.state.record?.state ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please select State",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select State"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                         {this.renderstateshort()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {/* </Row>
          <Row gutter={16}> */}
                <Col span={12}>
                  <Form.Item label="ZIP">
                    {getFieldDecorator("zip3", {
                      initialValue: this.state.record?.zip ?? "",
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input ZIP",
                        },
                      ],
                    })(<Input maxLength={5} placeholder="ZIP" />)}
                  </Form.Item>
                </Col>
              </Row>
            </>
            <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Contact Number">
                {getFieldDecorator("contact_phone_number3", {
                  initialValue: this.state.record?.contact_phone_number,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]{10,10}$/),
                      message: "Please input mobile number",
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="Enter Mobile Number"
                    minLength={10}
                    style={{ width: "100%" }}
                    maxLength={10}
                    min={0}
                    step={0}
                    autoComplete="new-password"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
                    <Form.Item label="Email">
                      {getFieldDecorator("email1", {
                        initialValue: this.state.record?.email ?? "",
                        rules: [
                          {
                            required: true,
                            message: "Please input Email",
                          },
                        ],
                      })(<Input placeholder="Email" />)}
                    </Form.Item>
                  </Col>
            </Row> 
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Comment">
                  {getFieldDecorator("admin_comments2", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Comment",
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={4}
                      placeholder="please enter comment"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
              className="mt-4"
            >
              {" "}
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loadingSMTbtn}
              >
                Resubmit
              </Button>
              <Button
                type="dashed"
                style={{ marginLeft: 8 }}
                onClick={this.onCloseE}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>

         </Drawer>  

        <Drawer
          title="View Log"
          placement="right"
          closable={true}
          width={420}
          onClose={this.onCloseLog}
          visible={visible}
        >
          <List
            itemLayout="horizontal"
            dataSource={this.state.Listdata}
            renderItem={(item) => (
              <List.Item>
                {/* <List.Item.Meta
                  title={item.created_at}
                  // description={`Order Id: ${item?.inventory_request_order_id ?? ""}\n
                  // Requested number of Phones: ${item.requested_phones_count ?? ""}\n
                  // Approved number of Phones: ${item.updated_phones_count ?? ""}\n
                  // Requested number of Tablets: ${item.requested_tablets_count ?? ""}\n
                  // Approved number of Tablets: ${item.updated_tablets_count ?? ""}\n
                  // Approved by: ${item.approved_user_name ?? ""}`}
                /> */}
                <div className="row">
                  <div className="col-12">
                    <List.Item.Meta
                      title={`Order Id : ${
                        item?.inventory_request_order_id ?? ""
                      }`}
                      // description={item.comments ?? ""}
                    />
                  </div>
                  <div className="col-4 mt-2y">
                    <Tag>{item.created_at}</Tag>
                  </div>
                  {/* </div> */}
                  {/* <div className="row"> */}
                  {item.description !== undefined &&
                  item.description !== null &&
                  item.description !== "" ? (
                    <div className="col-12">
                      Description: {item.description ?? ""}
                    </div>
                  ) : null}
                  {item.requested_phones_count !== undefined &&
                  item.requested_phones_count !== null &&
                  item.requested_phones_count !== "" ? (
                    <div className="col-12">
                      Requested number of Phones:{" "}
                      {item.requested_phones_count ?? ""}
                    </div>
                  ) : null}
                  {item.updated_phones_count !== undefined &&
                  item.updated_phones_count !== null &&
                  item.updated_phones_count !== "" ? (
                    <div className="col-12">
                      Approved number of Phones:{" "}
                      {item.updated_phones_count ?? ""}
                    </div>
                  ) : null}
                  {item.requested_tablets_count !== undefined &&
                  item.requested_tablets_count !== null &&
                  item.requested_tablets_count !== "" ? (
                    <div className="col-12">
                      Requested number of Tablets:
                      {item.requested_tablets_count ?? ""}
                    </div>
                  ) : null}
                  {item.updated_tablets_count !== undefined &&
                  item.updated_tablets_count !== null &&
                  item.updated_tablets_count !== "" ? (
                    <div className="col-12">
                      Approved number of Tablets:{" "}
                      {item.updated_tablets_count ?? ""}
                    </div>
                  ) : null}
                  {item.ship_to_name !== undefined &&
                  item.ship_to_name !== null &&
                  item.ship_to_name !== "" ? (
                    <div className="col-12">
                      Ship to Name:
                      {item.ship_to_name ?? ""}
                    </div>
                  ) : null}
                  {item.updated_address !== undefined &&
                  item.updated_address !== null &&
                  item.updated_address !== "" ? (
                    <div className="col-12">
                      Address: {item.updated_address ?? ""}
                    </div>
                  ) : null}
                   {item.email !== undefined &&
                  item.email !== null &&
                  item.email !== "" ? (
                    <div className="col-12">
                      Email: {item.email ?? ""}
                    </div>
                  ) : null}
                   {item.contact_phone_number !== undefined &&
                  item.contact_phone_number !== null &&
                  item.contact_phone_number !== "" ? (
                    <div className="col-12">
                      ContactÂ PhoneÂ Number: {item.contact_phone_number ?? ""}
                    </div>
                  ) : null}
                  {item.approved_user_name !== undefined &&
                  item.approved_user_name !== null &&
                  item.approved_user_name !== "" ? (
                    <div className="col-12">
                      Action taken by: {item.approved_user_name ?? ""}
                    </div>
                  ) : null}
                  {item.comments !== undefined &&
                  item.comments !== null &&
                  item.comments !== "" ? (
                    <div className="col-12">
                      Approver comments: {item.comments ?? ""}
                    </div>
                  ) : null}
                  {item.status_text !== undefined &&
                  item.status_text !== null &&
                  item.status_text !== "" ? (
                    <div className="col-12">
                      <Tag> Order status: {item.status_text ?? "NA"}</Tag>
                    </div>
                  ) : null}
                </div>
              </List.Item>
            )}
          />
        </Drawer>
        <Drawer
          width={680}
          onClose={this.onOrderClose}
          visible={orderopen}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Form
            onSubmit={this.onFinish}
            autoComplete="off"
            name="basic"
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  // name="office"
                  label="Office"
                >
                  {getFieldDecorator("office", {
                    rules: [
                      {
                        required: true,
                        message: "Please select office",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Office Number"
                      onChange={this.officeOnChange}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.renderOffice()}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  // name="Delivery"
                  label="Delivery Method"
                >
                  {getFieldDecorator("Delivery", {
                    rules: [
                      {
                        required: true,
                        message: "Please select delivery method",
                      },
                    ],
                  })(
                    <Select placeholder="Please choose delivery method">
                      <Option value="3">Shipping to FedEx Location</Option>
                      <Option value="4">Shipping to Business Location</Option>
                      <Option value="2">Pickup</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  // name="phones"
                  label="No of Phones"
                >
                  {getFieldDecorator("phones", {
                    rules: [
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input no. of phones",
                      },
                      // { validator: this.validateOrderPhone },
                    ],
                  })(<Input type={"number"} min="0" />)}
                </Form.Item>
              </Col>
              {/* </Row>
          <Row gutter={16}> */}
              <Col span={12}>
                <Form.Item
                  // name="tablets"
                  label="No of Tablets"
                >
                  {getFieldDecorator("no_tablets", {
                    rules: [
                      {
                        required: false,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input no. of tablets",
                      },
                      // { validator: this.validateOrderTablet },
                    ],
                  })(<Input type={"number" } min="0" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  // name="description"
                  label="Comments"
                >
                  {getFieldDecorator("description", {
                    rules: [
                      {
                        required: false,
                        message: "Please input comment",
                      },
                    ],
                  })(
                    <Input.TextArea
                      rows={4}
                      placeholder="please enter comment"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Ship To">
                  {getFieldDecorator("ship_to_name", {
                    rules: [
                      {
                        required: true,
                        message: "Please input name",
                      },
                    ],
                  })(<Input placeholder="Ship name" />)}
                </Form.Item>
              </Col>
              
              {/* <Col span={12}>
                <Form.Item label="Contact Name">
                  {getFieldDecorator("contact_name", {
                    rules: [
                      {
                        required: false,
                        message: "Please input contact name",
                      },
                    ],
                  })(<Input placeholder="name" />)}
                </Form.Item>
              </Col> */}
              <Col span={12}>
                <Form.Item label="Company  Name">
                  {getFieldDecorator("company_name", {
                    rules: [
                      {
                        required: false,
                        message: "Please input company name",
                      },
                    ],
                  })(<Input placeholder=" Company name" />)}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label="Street Address">
                  {getFieldDecorator("address", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Address",
                      },
                    ],
                  })(<Input placeholder="Address" />)}
                </Form.Item>
              </Col>
             
              <Col span={24}>
                <Form.Item label="Street Address1">
                  {getFieldDecorator("address2", {
                    rules: [
                      {
                        required: false,
                        message: "Please input Address",
                      },
                    ],
                  })(<Input placeholder="Address" />)}
                </Form.Item>
              </Col>

             
             
              <Col span={24}>
                <Form.Item label="City">
                  {getFieldDecorator("city", {
                    rules: [
                      {
                        required: true,
                        message: "Please input City",
                      },
                    ],
                  })(<Input placeholder="City" />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="State">
                  {getFieldDecorator("state", {
                    rules: [
                      {
                        required: true,
                        message: "Please select State",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select State"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {/* <Option value="AL">Alabama</Option>
                      <Option value="AK">Alaska</Option>
                      <Option value="AZ">Arizona</Option>
                      <Option value="AR">Arkansas</Option>
                      <Option value="CA">California</Option>
                      <Option value="CO">Colorado</Option>
                      <Option value="CT">Connecticut</Option>
                      <Option value="DE">Delaware</Option>
                      <Option value="DC">District Of Columbia</Option>
                      <Option value="FL">Florida</Option>
                      <Option value="GA">Georgia</Option>
                      <Option value="HI">Hawaii</Option>
                      <Option value="ID">Idaho</Option>
                      <Option value="IL">Illinois</Option>
                      <Option value="IN">Indiana</Option>
                      <Option value="IA">Iowa</Option>
                      <Option value="KS">Kansas</Option>
                      <Option value="KY">Kentucky</Option>
                      <Option value="LA">Louisiana</Option>
                      <Option value="ME">Maine</Option>
                      <Option value="MD">Maryland</Option>
                      <Option value="MA">Massachusetts</Option>
                      <Option value="MI">Michigan</Option>
                      <Option value="MN">Minnesota</Option>
                      <Option value="MS">Mississippi</Option>
                      <Option value="MO">Missouri</Option>
                      <Option value="MT">Montana</Option>
                      <Option value="NE">Nebraska</Option>
                      <Option value="NV">Nevada</Option>
                      <Option value="NH">New Hampshire</Option>
                      <Option value="NJ">New Jersey</Option>
                      <Option value="NM">New Mexico</Option>
                      <Option value="NY">New York</Option>
                      <Option value="NC">North Carolina</Option>
                      <Option value="ND">North Dakota</Option>
                      <Option value="OH">Ohio</Option>
                      <Option value="OK">Oklahoma</Option>
                      <Option value="OR">Oregon</Option>
                      <Option value="PA">Pennsylvania</Option>
                      <Option value="RI">Rhode Island</Option>
                      <Option value="SC">South Carolina</Option>
                      <Option value="SD">South Dakota</Option>
                      <Option value="TN">Tennessee</Option>
                      <Option value="TX">Texas</Option>
                      <Option value="UT">Utah</Option>
                      <Option value="VT">Vermont</Option>
                      <Option value="VA">Virginia</Option>
                      <Option value="WA">Washington</Option>
                      <Option value="WV">West Virginia</Option>
                      <Option value="WI">Wisconsin</Option>
                      <Option value="WY">Wyoming</Option> */}
                       {this.renderstateshort()}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {/* </Row>
          <Row gutter={16}> */}
              <Col span={12}>
                <Form.Item label="ZIP">
                  {getFieldDecorator("zip", {
                    rules: [
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]+$/),
                        message: "Please input ZIP",
                      },
                    ],
                  })(<Input maxLength={5} placeholder="ZIP" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contact_phone_number", {
                    rules: [
                      {
                        required: true,
                        pattern: new RegExp(/^[0-9]{10,10}$/),
                        message: "Please input mobile number",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter Mobile Number"
                      minLength={10}
                      style={{ width: "100%" }}
                      maxLength={10}
                      min={0}
                      step={0}
                      autoComplete="new-password"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                    <Form.Item label="Email">
                      {getFieldDecorator("email", {
                        initialValue: this.state.record?.email ?? "",
                        rules: [
                          {
                            required: true,
                            message: "Please input Email",
                          },
                        ],
                      })(<Input placeholder="Email" />)}
                    </Form.Item>
                  </Col>
            </Row>
            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
            >
              {/* <Space> */}{" "}
              <Button
                type="primary"
                htmlType="submit"
                className="mt-4"
                loading={this.state.loadingRQSMTbtn}
              >
                Submit
              </Button>
              <Button type="dashed" onClick={this.resetorderform}>
                Clear
              </Button>
              {/* </Space> */}
            </Form.Item>
          </Form>
        </Drawer>
        <Drawer
          width={540}
          onClose={this.onCloseAddress}
          visible={addressOpen}
          bodyStyle={{
            paddingBottom: 80,
          }}
          title="Tracking Information"
        >
          <Form
            onSubmit={this.onFinishTrack}
            autoComplete="off"
            name="basic"
            layout="vertical"
          >
            <>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Tracking number">
                    {/* {formItems}
                    <Form.Item {...formItemLayoutWithOutLabel}>
                      <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                        <Icon type="plus" /> Add field
                      </Button>
                    </Form.Item> */}
                    {getFieldDecorator("tracking_number", {
                      initialValue:
                        this.state.activeorder?.tracking_number ?? "",
                      rules: [
                        {
                          required: true,
                          message: "Please input number",
                          // pattern: new RegExp(/^[0-9]+$/),
                        },
                      ],
                    })(<Input placeholder="Tracking Number" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Ship Date">
                      {getFieldDecorator("ship_date", {
                          initialValue:
                          this.state.dateover?.ship_date  === undefined ||  this.state.dateover?.ship_date  === null
                            ? null
                            : moment(this.state.dateover?.ship_date),
                       
                        rules: [
                          {
                            required: false,
                            message: "Please input ship date",
                          },
                        ],
                      })( <DatePicker
                        
                        format="MM-DD-YYYY"
                        placeholder="Select Date"
                        onChange={this.onStartChange}
                      />)}
                    </Form.Item>
                  </Col>
                {/* ----------------------------------------------- */}
                {/* </Row>
          <Row gutter={16}> */}
                {/* <Col span={24}>
                  <Form.Item label="City">
                    {getFieldDecorator("city1", {
                      rules: [
                        {
                          required: true,
                          message: "Please input City",
                        },
                      ],
                    })(<Input placeholder="City" />)}
                  </Form.Item>
                </Col> */}
              </Row>
              {/* <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="State">
                    {getFieldDecorator("state1", {
                      rules: [
                        {
                          required: true,
                          message: "Please select State",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select State"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="AL">Alabama</Option>
                        <Option value="AK">Alaska</Option>
                        <Option value="AZ">Arizona</Option>
                        <Option value="AR">Arkansas</Option>
                        <Option value="CA">California</Option>
                        <Option value="CO">Colorado</Option>
                        <Option value="CT">Connecticut</Option>
                        <Option value="DE">Delaware</Option>
                        <Option value="DC">District Of Columbia</Option>
                        <Option value="FL">Florida</Option>
                        <Option value="GA">Georgia</Option>
                        <Option value="HI">Hawaii</Option>
                        <Option value="ID">Idaho</Option>
                        <Option value="IL">Illinois</Option>
                        <Option value="IN">Indiana</Option>
                        <Option value="IA">Iowa</Option>
                        <Option value="KS">Kansas</Option>
                        <Option value="KY">Kentucky</Option>
                        <Option value="LA">Louisiana</Option>
                        <Option value="ME">Maine</Option>
                        <Option value="MD">Maryland</Option>
                        <Option value="MA">Massachusetts</Option>
                        <Option value="MI">Michigan</Option>
                        <Option value="MN">Minnesota</Option>
                        <Option value="MS">Mississippi</Option>
                        <Option value="MO">Missouri</Option>
                        <Option value="MT">Montana</Option>
                        <Option value="NE">Nebraska</Option>
                        <Option value="NV">Nevada</Option>
                        <Option value="NH">New Hampshire</Option>
                        <Option value="NJ">New Jersey</Option>
                        <Option value="NM">New Mexico</Option>
                        <Option value="NY">New York</Option>
                        <Option value="NC">North Carolina</Option>
                        <Option value="ND">North Dakota</Option>
                        <Option value="OH">Ohio</Option>
                        <Option value="OK">Oklahoma</Option>
                        <Option value="OR">Oregon</Option>
                        <Option value="PA">Pennsylvania</Option>
                        <Option value="RI">Rhode Island</Option>
                        <Option value="SC">South Carolina</Option>
                        <Option value="SD">South Dakota</Option>
                        <Option value="TN">Tennessee</Option>
                        <Option value="TX">Texas</Option>
                        <Option value="UT">Utah</Option>
                        <Option value="VT">Vermont</Option>
                        <Option value="VA">Virginia</Option>
                        <Option value="WA">Washington</Option>
                        <Option value="WV">West Virginia</Option>
                        <Option value="WI">Wisconsin</Option>
                        <Option value="WY">Wyoming</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
               
                <Col span={12}>
                  <Form.Item label="ZIP">
                    {getFieldDecorator("zip1", {
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input ZIP",
                        },
                      ],
                    })(<Input maxLength={5} placeholder="ZIP" />)}
                  </Form.Item>
                </Col>
              </Row> */}
            </>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.loadingUDbtn}
                className="mt-4"
              >
                Update
              </Button>
              <Button
                style={{ marginLeft: 15 }}
                type="dashed"
                onClick={this.resettrackform}
              >
                Cancel
              </Button>
            </Form.Item>
          </Form>
        </Drawer>

        <Drawer
          title="View Comments"
          placement="right"
          closable={true}
          width={420}
          onClose={this.onClose1}
          visible={this.state.cvisible}
        >
          <List
            itemLayout="horizontal"
            dataSource={this.state.Listdata1}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={item.created_at}
                  description={item.comments}
                />
                commented by :<Tag color="orange">{item.commented_by_name}</Tag>
              </List.Item>
            )}
          />
        </Drawer>
        <Drawer
          title="Add Comments"
          placement="right"
          closable={true}
          width={540}
          onClose={this.onClose2}
          visible={this.state.comvisible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Form layout="vertical" onSubmit={this.handleCommentSubmit}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Comments">
                  {getFieldDecorator("admin_comments", {
                    rules: [
                      {
                        required: false,
                        message: "Please input comments",
                      },
                    ],
                  })(
                    <Input.TextArea rows={8} placeholder="Write something..." />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right",
              }}
            >
              <Button onClick={this.onCloseEdit} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                loading={this.state.loadingCMdbtn}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

const WrappedRMAOrder = Form.create({ name: "RMAOrder" })(RMAOrder);

export default connect(mapStateToProps, {
  inventory_order_approval_status,
  inventory_order_status,
  getretailers,
  getinventoryorder,
  getofficelistname,
  getrmarequestaccept,
  inventorylog,
  createinventoryorder,
  getapproveorder,
  updatetrackinginfo,
  inventory_order_summary,
  getfulfillorder,
  exportinventoryorder,
  addcommentsuserinventoryaction,
  viewcommentsuserinventoryaction,
  get_rmarequest_edit,
  short_us_states,
  resubmit_request_order,
})(WrappedRMAOrder);