import React, { Component } from "react";
import { connect } from "react-redux";
import { get_payroll_data, get_payroll_date } from "../../actions/salesAction";
import { export_payroll_data } from "../../actions/exportAction";
import _ from "lodash";
import {
  Button,
  Table,
  Input,
  Select,
  DatePicker,
  message,
  Spin,
  Empty,
  Tag,
} from "antd";
import moment from "moment";
import axiosInstance from "../../actions/AxiosInstance";

const { Option } = Select;

class RetailerPayroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingTGbtn: false,
      loadingEPbtn: false,
      retailer: [],
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      inventorystatus: [],
      search_text: "",
      datefilter: [],
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      office: [],
      fdata: [],
      dateRange: null,
      retailerPayroll: 1,
      defaultWeek: 1,
      weekType: 1,
      payroll_date: [],
      keyword: "",
    };
  }

  componentWillMount() {
    const urlString = this.props.location.urlpath;
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
    });
    this.getdesignation({
      results: 10,
      page: 1,
      payroll_type: this.state.retailerPayroll,
      week_type: this.state.defaultWeek,
    });
    this.setState({ dateRange: this.getWeekRange() });
  }

  getWeekRange() {
    const previousWeekEnd = moment().subtract(4, "weeks").endOf("week");
    const lastWeekStart = moment().subtract(3, "weeks").startOf("week");
    const lastWeekEnd = moment().subtract(3, "weeks").endOf("week");
    const thisWeekStart = moment().subtract(2, "weeks").startOf("week");
    const thisWeekEnd = moment().subtract(2, "weeks").endOf("week");
    return {
      previousWeekEnd,
      lastWeekStart,
      lastWeekEnd,
      thisWeekStart,
      thisWeekEnd,
    };
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .get_payroll_date()
      .then((res) => {
        this.setState({ payroll_date: res });
      })
      .catch((err) => console.log(err));
    this.props
      .get_payroll_data(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.total;
        pagination.current = res.current_page;
        this.setState({
          data: res.data,
          loading: false,
          pagination,
          loadingbtn: false,
          loadingTGbtn: false,
        });
      })
      .catch((err) => {
        message.error(err);
        this.setState({
          loading: false,
          loadingbtn: false,
          loadingTGbtn: false,
        });
      });
  }

  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(sorter.order);
    // console.log("first,",this.state.sortField);
    // console.log("page",pagination)

    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };
  // fetch = (params = {}) => {
  //   console.log("params:", params);
  //   this.getdesignation(params);
  // };

  onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    this.setState({ selectedItems });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };

  renderOffice() {
    return _.map(this.state.office, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      name: "",
      startValue: null,
      endValue: null,
      fdata: [],
      keyword: "",
      weekType:this.state.defaultWeek,
    });
    this.getdesignation({
      results: 10,
      page: 1,
      payroll_type: this.state.retailerPayroll,
      week_type: this.state.defaultWeek,
    });
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true, loadingbtn: true });
    var office_id = this.state.selectedItems.key;
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var term = this.state.keyword;
    var page= reset ? 1 : this.state.pagination?.current ?? 1;

    this.getdesignation({
      office_id: office_id,
      search_text: search_text,
      date_range_start,
      date_range_end,
      payroll_type: this.state.retailerPayroll,
      week_type: this.state.weekType,
      page: page,
      sort,
      sort_by,
      term: term,
    });
  };

  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  disabledStartDate = (startValue) => {
    const weekStart = moment().subtract(31, "days");
    const weekEnd = moment().endOf("day");
    return !(
      weekStart.isSameOrBefore(startValue) && weekEnd.isAfter(startValue)
    );
  };

  disabledEndDate = (endValue) => {
    const weekStart = moment().subtract(31, "days");
    const weekEnd = moment().endOf("day");
    return !weekStart.isSameOrBefore(endValue);
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  //================================================//
  getexportbtn = () => {
  
    this.setState({
      loading: true,
      loadingEPbtn: true,
    });
    var office_id = this.state.selectedItems.key;
    var search_text = this.state.name;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var term = this.state.keyword;
    // this.setState({ loading: true });
    this.props
      .export_payroll_data({
        office_id,
        search_text,
        date_range_start,
        date_range_end,
        payroll_type: this.state.retailerPayroll,
        week_type: this.state.weekType,
        term: term,
      })
      .then((res) => {
        console.log(res);
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.url) 
          // message.success(res.original.message);
          window.open(res.url, "_blank");
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  getWeekDetails(value,reset) {
    console.log("first>>>",this.state.page)
    var office_id = this.state.selectedItems.key;
    var search_text = this.state.name;
    this.setState({ loadingTGbtn: true, weekType: value });
    this.getdesignation({
      office_id: office_id,
      search_text: search_text,
      payroll_type: this.state.retailerPayroll,
      week_type: value,
      // page: this.state.pagination?.current ?? 1,
      
    });
  }
  // ======================================================

  render() {
    const {
      fetching,
      endOpen,
      fdata,
      startValue,
      endValue,
      weekType,
      loadingTGbtn,
      payroll_date,
      data,
    } = this.state;
    const { user } = this.props;
    const columns = [
      {
        title: "Product Type",
        dataIndex: "product_type",
        key: "product_type",
      },
      {
        title: "Make Model",
        dataIndex: "make_model",
        key: "make_model",
      },
      {
        title: "Order ID",
        dataIndex: "enrollment_id",
        key: "enrollment_id",
      },
      {
        title: "Agent Name",
        dataIndex: "agent_name",
        key: "agent_name",
      },
      {
        title: "Excess Login ID",
        dataIndex: "excess_login_id",
        key: "excess_login_id",
      },
      {
        title: "Office",
        dataIndex: "office",
        key: "office",
      },
      {
        title: "Retailer",
        dataIndex: "retailer",
        key: "retailer",
      },
      {
        title: "Rate",
        dataIndex: "excess_retailer_rate",
        key: "excess_retailer_rate",
      },
      {
        title: "Pre Paid Bonus",
        dataIndex: "pre_paid_bonus",
        key: "pre_paid_bonus",
      },
      {
        title: "Lifeline Activation Date",
        dataIndex: "lifeline_activation_date_format",
        key: "lifeline_activation_date_format",
      },
      {
        title: "Billable",
        dataIndex: "billable",
        key: "billable",
        render: (text, record) => (
          <span>
            <Tag color={record.billable === "TRUE" ? "green" : "orange"}>
              {record.billable}
            </Tag>
          </span>
        ),
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
      },
      {
        title: "Customer ID",
        dataIndex: "customer_id",
        key: "customer_id",
      },
      {
        title: "Enroll Type",
        dataIndex: "enroll_type",
        key: "enroll_type",
      },
      {
        title: "Deferred Activation Fee",
        dataIndex: "deferred_activation_fee",
        key: "deferred_activation_fee",
      },
      {
        title: "Payment Type",
        dataIndex: "payment_type",
        key: "payment_type",
      },
      {
        title: "NLAD Subscriber ID",
        dataIndex: "nlad_subsciber_id",
        key: "nlad_subsciber_id",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "MDN",
        dataIndex: "mdn",
        key: "mdn",
      },
      {
        title: "Non Usage",
        dataIndex: "non_usage",
        key: "non_usage",
      },
      {
        title: "De Enrolled Suspended",
        dataIndex: "de_enrolled_suspended",
        key: "de_enrolled_suspended",
      },
      {
        title: "Nlad Inactive",
        dataIndex: "nlad_inactive",
        key: "nlad_inactive",
      },
      {
        title: "Winback",
        dataIndex: "winback",
        key: "winback",
      },
      {
        title: "Compliance",
        dataIndex: "compliance",
        key: "compliance",
      },
      {
        title: "Subscriber Status",
        dataIndex: "subscriber_status",
        key: "subscriber_status",
      },
      {
        title: "ESN Number",
        dataIndex: "esn_number",
        key: "esn_number",
      },
      {
        title: "Plan",
        dataIndex: "plan",
        key: "plan",
      },
      {
        title: "Invoice Plan",
        dataIndex: "invoice_plan",
        key: "invoice_plan",
      },
      {
        title: "IMEI",
        dataIndex: "device_id",
        key: "device_id",
      },
      {
        title: "Enrollment Type",
        dataIndex: "enrollment_type",
        key: "enrollment_type",
      },
      {
        title: "Disconnection Date",
        dataIndex: "disconnection_date",
        key: "disconnection_date",
      },
      
      {
        title: "Disconnect Reason",
        dataIndex: "disconnect_reason",
        key: "disconnect_reason",
      },
      {
        title: "Reconnection Date",
        dataIndex: "reconnection_date",
        key: "reconnection_date",
      },
      {
        title: "Suspension Date",
        dataIndex: "suspension_date",
        key: "suspension_date",
      },


//===============================================
      // {
      //   title: "Enrollment ID",
      //   dataIndex: "enrollment_id",
      //   key: "enrollment_id",
      // },
      // {
      //   title: "Disconnected By",
      //   dataIndex: "disconnected_by",
      //   key: "disconnected_by",
      // },
      // {
      //   title: "IBO / Markets",
      //   dataIndex: "ibo",
      //   key: "ibo",
      // },
      // {
      //   title: "Channel",
      //   dataIndex: "channel",
      //   key: "channel",
      // },
      // {
      //   title: "Retailer",
      //   dataIndex: "retailer",
      //   key: "retailer",
      // },
      // {
      //   title: "Retailer ID",
      //   dataIndex: "retailer_id",
      //   key: "retailer_id",
      // },
      // {
      //   title: "Application Activation Date",
      //   dataIndex: "application_activation_date",
      //   key: "application_activation_date",
      // },
      // {
      //   title: "First Name",
      //   dataIndex: "first_name",
      //   key: "first_name",
      // },
      // {
      //   title: "Last Name",
      //   dataIndex: "last_name",
      //   key: "last_name",
      // },
      
      // {
      //   title: "Lifeline Activation Date",
      //   dataIndex: "lifeline_activation_date_format",
      //   key: "lifeline_activation_date_format",
      // },
     
      
      // {
      //   title: "reconnected By",
      //   dataIndex: "reconnected_by",
      //   key: "reconnected_by",
      // },
     
      // {
      //   title: "Suspended By",
      //   dataIndex: "suspended_by",
      //   key: "suspended_by",
      // },
      
      // {
      //   title: "Device ID",
      //   dataIndex: "device_id",
      //   key: "device_id",
      // },
      // {
      //   title: "Excess Retailer Rate",
      //   dataIndex: "excess_retailer_rate",
      //   key: "excess_retailer_rate",
      // },
      // {
      //   title: "Override",
      //   dataIndex: "override",
      //   key: "override",
      // },
     
      
      // {
      //   title: "Missing Cash Copay",
      //   dataIndex: "missing_cash_copay",
      //   key: "missing_cash_copay",
      // },
      // {
      //   title: "Sim Enrollment",
      //   dataIndex: "sim_enrollment",
      //   key: "sim_enrollment",
      // },
      // {
      //   title: "Override Master",
      //   dataIndex: "override_master",
      //   key: "override_master",
      // },
      // {
      //   title: "Device Payment",
      //   dataIndex: "device_payment",
      //   key: "device_payment",
      // },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                {" "}
                Retailer Payroll{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              <Button
                type="primary"
                className="mr5"
                disabled={data?.length === 0}
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <div className="row padd20">
            <div className="col-3">
              <label for="exampleFormControlInput1">
                From &#40;Lifeline Activation&#41;
              </label>
              <DatePicker
                value={startValue}
                // disabledDate={this.disabledStartDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
            </div>
            <div className="col-3">
              <label for="exampleFormControlInput1">
                To &#40;Lifeline Activation&#41;
              </label>
              <DatePicker
                value={endValue}
                // disabledDate={this.disabledEndDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>
            <div className="col-3">
              <label for="exampleFormControlInput1">Select Office</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={this.fetchUser}
                defaultActiveFirstOption={false}
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
              >
                {fdata.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
            </div>
            <div className="col-3">
              <label for="exampleFormControlInput1">Search By Agent</label>
              <Input
                placeholder="Type Agent Name"
                value={this.state.name}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col-3 mt-2">
              <label for="exampleFormControlInput1">Search by Keyword</label>
              <Input
                placeholder="Search by Keyword"
                value={this.state.keyword}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) =>
                  this.setState({
                    keyword: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col mt-2">
              <Button
                type="primary"
                loading={this.state.loadingbtn}
                className="mr5"
                onClick={() => this.searchMarketer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="text-left mb-1">
            {payroll_date.this_week_retailer_payroll && (
              <Button
                type={weekType === 1 ? "danger" : "primary"}
                className="mr5"
                loading={weekType === 1 ? loadingTGbtn : null}
                onClick={() => this.getWeekDetails(1)}
              >
                {payroll_date.this_week_retailer_payroll}
              </Button>
            )}
            {payroll_date.last_week_retailer_payroll && (
              <Button
                type={weekType === 2 ? "danger" : "primary"}
                className="mr5"
                loading={weekType === 2 ? loadingTGbtn : null}
                onClick={() => this.getWeekDetails(2)}
              >
                {payroll_date.last_week_retailer_payroll}
              </Button>
            )}
            {payroll_date.previous_week_retailer_payroll && (
              <Button
                type={weekType === 3 ? "danger" : "primary"}
                className="mr5"
                loading={weekType === 3 ? loadingTGbtn : null}
                onClick={() => this.getWeekDetails(3)}
              >
                {payroll_date.previous_week_retailer_payroll}
              </Button>
            )}
          </div>
          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  get_payroll_data,
  get_payroll_date,
  export_payroll_data,
})(RetailerPayroll);
