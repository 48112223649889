/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import FreshChat from "react-freshchat";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tag, Result, Button } from "antd";
import Loginimg from "../styles/img/undraw_taken_re_yn20.svg";


class ErrorDenied extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      loading: false,
      appversion: "",
    };
  }
  componentWillMount() {
    localStorage.removeItem("user");
    //window.location.reload();
  }
  componentDidMount() {
    document.body.style.backgroundColor = "black";
    document.body.style.height = "100vh";
}
componentWillUnmount() {
  document.body.style.backgroundColor = "";
}
 refreshpage = () =>{
    localStorage.removeItem("user");
    this.props.history.push("/");
 }
  render() {
    return (
      <>
        <div className="container py-5 text-white">
          <div className="row justify-content-center">
               <div className="col-2 align-self-center">
               <img src={Loginimg} className="loginimg" alt="WiESSystem" />
               </div>
               <div className="col-12 text-center mt-5">
                    <h3 className="text-white">Access Denied !!!!</h3>
                    <p>Sorry, your access is refused due to security reasons of our server and also our sensitive data.<br/>Please close the developer panel and Press refresh button to continue working.</p>
                    <button onClick={()=> this.refreshpage()} class="btn btn-danger">Refresh</button>
               </div>
          </div>
     </div>

    
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}

export default connect(mapStateToProps,"")(ErrorDenied);
