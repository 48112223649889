import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  InputNumber,
  Radio,
  DatePicker,
  Checkbox,
  Col,
  Row,
  Spin,
} from "antd";
import _ from "lodash";
import moment from "moment";
import {
  getRolelist,
  getMarketerslist,
  getGenderlist,
  editEmployee,
  getofficelistname,
} from "../../actions/employeeAction";
import {
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlist,
  short_us_states
} from "../../actions/assetAction";

const { Option } = Select;
const RadioGroup = Radio.Group;

class EditEmployee extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      offices: [],
      officeview: false,
      disableoffice: true,
      roles: [],
      filteredroles: [],
      marketers: [],
      filteredmarketers: [],
      dob: Date(),
      application_date: Date(),
      hubs: [],
      designation: [],
      fileList: [],
      loading: false,
      off_disable: false,
      showUpload: false,
      showImg: true,
      allhubs: [],
      isFieldChampion: false,
      pwdsettings: "",
      pwdstatus: "",
      pwdinfo: "",
      asshubs: [],
      disable: false,
      emailexists: true,
      distributor: [],
      master: [],
      retailer: [],
      showcase: true,
      masterview: false,
      distributorview: false,
      retailerview: false,
      retailerrole: false,
      marketrole: false,
      superrole: false,
      superadmin: false,
      officedata: [],
      rolefinfo: [],
      agentview: false,
      officeuser: false,
      shortstate: [],
    };
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  componentWillMount() {
    this.props.short_us_states().then((res) => {
      console.log(res);
      this.setState({
        shortstate: res.data,
      });
    });
    console.log(this.props.location.agent_info);

    // console.log(this.props.user.uid);
    console.log(this.props.location.employee);
    // console.log(this.props.location.employee.ibo_marketer_id)
    if (this.props.location.employee === undefined) {
      this.props.history.push("/employees");
    } else {
      var role_filter = this.props.location.employee.role_info.filter(
        (person) => person.role_id !== 2
      );
      this.setState({ rolefinfo: role_filter });
      this.getMarketerslistoff();
      this.getGenderlist();
      this.getOfficelist(this.props.location.employee.ibo_marketer_id);
      this.getRolelist();
      // this.getMarketerslist();
      this.props.getretailers().then((res) => {
        this.props.getdistributers().then((res) => {
          this.props.getmaster().then((res) => {
            this.setState({
              officedata: this.props.location.employee.role_info[0],
              distributor: this.props.data.distributor,
              master: this.props.data.master,
              retailer: this.props.data.retailer,
              showcase: false,
            });
          });
        });
      });
      const superadmin = this.props.user.role_id.filter((word) => word === 10);
      const masteruser = this.props.user.role_id.filter((word) => word === 1);
      const Retailer = this.props.user.role_id.filter((word) => word === 5);
      const marketer = this.props.user.role_id.filter(
        (word) => word === 4 || word === 8
      );
      const office_manager = this.props.location.employee.role_info.filter(
        (person) => person.role_id === 3
      );
      const agent_user = this.props.location.employee.role_info.filter(
        (person) => person.role_id === 2
      );
      if (Retailer.length !== 0) {
        this.setState({
          retailerrole: true,
        });
      }
      if (marketer.length !== 0) {
        this.setState({
          marketrole: true,
        });
      }
      if (masteruser.length !== 0) {
        this.setState({
          superrole: true,
        });
      }
      if (superadmin.length !== 0) {
        this.setState({
          superadmin: true,
        });
      }
      if (office_manager.length !== 0) {
        this.setState({
          officeuser: true,
          officeview: true,
          disableoffice: true,
        });
      }
      if (agent_user.length !== 0) {
        this.setState({
          agentview: true,
        });
      }
      var masterusers = this.props.location.employee.role_info.filter(
        (role) => role.role_name === "Master Agent"
      );

      var distributeusers = this.props.location.employee.role_info.filter(
        (role) => role.role_name === "Distributor"
      );

      var retaileusers = this.props.location.employee.role_info.filter(
        (role) =>
          role.role_name === "Office Manager" ||
          role.role_name === "IBO Manager"
      );
      console.log(retaileusers);
      if (masterusers.length !== 0) {
        this.setState({
          masterview: true,
        });
      } else {
        this.setState({
          masterview: false,
        });
      }
      if (distributeusers.length !== 0) {
        this.setState({
          distributorview: true,
        });
      } else {
        this.setState({
          distributorview: false,
        });
      }
      if (retaileusers.length !== 0) {
        this.setState({
          retailerview: true,
        });
      } else {
        this.setState({
          retailerview: false,
        });
      }
      // var managerIndex = this.props.location.employee.role_info.filter(
      //   (o) => o.role_name === "IBO Manager"
      // );

      // console.log(managerIndex);
      // if (managerIndex.length !== 0) {
      //   this.setState({
      //     marketview: true,
      //   });
      // } else {
      //   this.setState({
      //     marketview: false,
      //   });
      // }
    }
  }

  getGenderlist() {
    // nprogress.start();
    if (this.state.gender === undefined || this.state.gender.length <= 0) {
      this.props
        .getGenderlist()
        .then((res) => {
          console.log(res);
          this.setState({
            gender: res.data,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            gender: [],
          });
          // nprogress.done();
        });
    }
  }

  getMarketerslist(role) {
    // nprogress.start();
    this.props
      .getMarketerslist(
        role === 4 ? "M" : "IM",
        this.props.location.employee.id
      )
      .then((res) => {
        console.log("marketers", res);
        this.setState({
          marketers: res,
        });
        // nprogress.done();
      })
      .catch((err) => {
        this.setState({
          marketers: [],
          showcase: false,
        });
        // nprogress.done();
      });
  }

  getMarketerslistoff() {
    this.setState({
      marketers: [],
    });
    this.props
      .getibomarketlist()
      .then((res) => {
        this.props.form.setFields({
          ibo_marketer_id: [],
        });
        console.log("marketers", res);
        this.setState({
          marketers: res.data,
        });
        // nprogress.done();
      })
      .catch((err) => {
        this.setState({
          marketers: [],
        });
        // nprogress.done();
      });
  }

  getOfficelist(e) {
    if (this.state.offices === undefined || this.state.offices.length <= 0) {
      // nprogress.start();
      this.props
        .getofficelistname(e)
        .then((res) => {
          console.log(res);
          this.setState({
            offices: res.data,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            offices: [],
          });
          // nprogress.done();
        });
    }
  }

  getRolelist() {
    if (this.state.roles === undefined || this.state.roles.length <= 0) {
      // nprogress.start();
      this.props
        .getRolelist()
        .then((res) => {
          // console.log('getRolelist', res);
          this.setState({
            roles: _.sortBy(res.data.items, (o) => o.level),
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            roles: [],
          });
          // nprogress.done();
        });
    }
  }

  renderOffice() {
    return _.map(this.state.offices, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  renderGender() {
    // console.log(`this.state.gender`, this.state.gender)
    return _.map(this.state.gender, (dep, index) => {
      // console.log(`dep`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={dep.id}>
          {dep.name}
        </Option>
      );
      // });
    });
  }

  renderRole() {
    // console.log('this.state.roles', this.state.roles)
    return _.map(this.state.roles, (dep, index) => {
      if (this.state.superadmin === true) {
        return (
          <Col span={8}>
            <Radio value={dep.id}>
              {dep.name}
              {/* Market Supervisor */}
            </Radio>
          </Col>
        );
      }
      if (this.state.superrole === true) {
        if (dep.id === 5 || dep.id === 6 || dep.id === 7) {
          const value = dep.id === 6 ? true : dep.id === 7 ? true : false;
          return (
            <Col span={24}>
              <Radio disabled={value} value={dep.id}>
                {dep.name}
                {/* Market Supervisor */}
              </Radio>
            </Col>
          );
        }
      }
      if (this.state.marketrole === true) {
        if (dep.id === 3 || dep.id === 9) {
          return (
            <Col span={24}>
              <Radio value={dep.id}>
                {dep.name}
                {/* Market Supervisor */}
              </Radio>
            </Col>
          );
        }
      }
      if (this.state.retailerrole === true) {
        if (dep.id === 3 || dep.id === 11 || dep.id === 12 || dep.id === 14) {
          //console.log(mappedData);
          
          const filteredData =
            this.props.location.employee.role_info;
         
          return (
            <Col span={12}>
              <Radio key={dep.id} value={dep.id} disabled={(filteredData.length === 1 && (dep.id === 14 || dep.id === 3 )) && ( this.props.location.employee.role_info[0].role_id === 12 ||  this.props.location.employee.role_info[0].role_id === 11 || this.props.location.employee.role_info[0].role_id === 3)  ? true : false}>
                {dep.name}
                {/* Market Supervisor */}

              </Radio>
            </Col>
          );
        }
      }
    });
  }
  renderMarketers() {
    return _.map(this.state.marketers, (dep, index) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  onChangeRole = (e) => {
    var checkedValues = [e.target.value];
    console.log("checked = ", checkedValues);
    if (e.target.value === 3) {
      this.setState({ off_disable: true });
      this.getMarketerslistoff();
    }
    if (e.target.value === 3) {
      this.setState({
        officeview: true,
        disableoffice: true,
      });
    } else {
      this.setState({
        officeview: false,
      });
    }
    var masterIndex = this.state.roles.findIndex(
      (role) => role.name === "Master Agent"
    );
    var DistributorIndex = this.state.roles.findIndex(
      (role) => role.name === "Distributor"
    );
    var RetailerIndex = this.state.roles.findIndex(
      (role) => role.name === "Retailer"
    );
    var OffManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "Office Manager"
    );

    var IBOManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "IBO Manager"
    );
    // var IBOSuperIndex = this.state.roles.findIndex(
    //   (role) => role.name === "IBO Add-on Manager"
    // );
    // var IBOInventIndex = this.state.roles.findIndex(
    //   (role) => role.name === "IBO Inventory Manager"
    // );
    var masterusers = checkedValues.filter(
      (obj) => obj === this.state.roles[masterIndex].id
    );
    var distributeusers = checkedValues.filter(
      (obj) => obj === this.state.roles[DistributorIndex].id
    );
    var retaileusers = checkedValues.filter(
      (obj) =>
        obj === this.state.roles[RetailerIndex].id ||
        obj === this.state.roles[OffManagerIndex].id ||
        obj === this.state.roles[IBOManagerIndex].id
    );

    console.log(retaileusers);

    if (masterusers.length !== 0) {
      this.setState({
        masterview: true,
      });
    } else {
      this.setState({
        masterview: false,
      });
    }
    if (distributeusers.length !== 0) {
      this.setState({
        distributorview: true,
      });
    } else {
      this.setState({
        distributorview: false,
      });
    }
    if (retaileusers.length !== 0) {
      this.setState({
        retailerview: true,
      });
    } else {
      this.setState({
        retailerview: false,
      });
    }
  };

  unSelectRole = () => {
    this.props.form.setFieldsValue({ role_id: "" });
  };
  officeOnChange = (e) => {
    var fdesgnd = _.filter(this.state.offices, ["Offices", e]);
    this.setState({
      filteredoffices: fdesgnd,
    });
    console.log(e, fdesgnd);
    // this.props.form.setFields({
    //   offices: {
    //     value: "",
    //   },
    // });
  };

  roleOnChange = (e) => {
    var fdesgnd = _.filter(this.state.roles, ["Role", e]);
    this.setState({
      filteredroles: fdesgnd,
    });
    console.log(e, fdesgnd);
  };

  marketerOnChange = (e) => {
    this.setState({
      loading: true,
    });
    console.log(e);
    this.props
      .getofficelistname(e)
      .then((res) => {
        console.log("marketers", res);
        this.props.form.setFieldsValue({
          office_id: [],
        });
        this.setState({
          offices: res.data,
          disableoffice: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.props.form.setFieldsValue({
          office_id: [],
        });
        console.log(err);
        this.setState({
          offices: [],
          disableoffice: true,
          loading: false,
        });
      });
  };

  designationOnChange = (e) => {
    console.log(e);
    if (e === 10) this.setState({ isFieldChampion: true });
    else this.setState({ isFieldChampion: false });
  };

  disabledDOB(current) {
    return moment().add(0, "days") <= current;
  }
  disabledDOJ(current) {
    return moment().add(-1, "days") >= current;
  }

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.form.validateFields((err, values) => {
          console.log(`values`, values);
          if (!err) {
            this.setState({
              loading: true,
            });
            console.log(this.state.rolefinfo[0]);
            var employee = {
              _method: "PUT",
              first_name: values.first_name,
              middle_name: values.middle_name,
              last_name: values.last_name,
              rad: values.rad,
              promo_code: values.promo_code,
              excess_code: values.excess_code,
              mobile_number:
                values.mobile_number !== undefined ||
                values.mobile_number !== null
                  ? values.mobile_number.toString()
                  : "",
              gender_id: values.gender_id, //this.state.filteredgender.id,
              office_id:
                values.role_id === 14
                  ? this.props.location.employee.role_info.length === 2 &&
                    values.role_id === 14
                    ? [this.props.location.employee.office_id]
                    : values?.office_id ?? []
                  : values.role_id === "" ? this.props.location.employee.office_id : values?.office_id ?? [],

              ibo_marketer_id:
                values.ibo_marketer_id !== null &&
                values.ibo_marketer_id !== undefined
                  ? values.ibo_marketer_id
                  : this.props.location.employee?.ibo_marketer_id ?? "",
              dob: moment(this.state.dob).format("YYYY-MM-DD"),
              application_date: moment(this.state.application_date).format(
                "YYYY-MM-DD"
              ),
              address:
                values.address !== undefined || values.address !== null
                  ? values.address
                  : "",
              city:
                values.city !== undefined || values.city !== null
                  ? values.city
                  : "",
              state:
                values.state !== undefined || values.state !== null
                  ? values.state
                  : "",
              zip:
                values.zip === undefined || values.zip === null
                  ? ""
                  : values.zip.toString(),
              email: values.email,
              role_id:
                values.role_id === 14
                  ? this.props.location.employee.role_info.length === 2 &&
                    values.role_id === 14
                    ? ["2"]
                    : [values.role_id]
                  : [values.role_id === "" ? "2" :values.role_id], //[9, 2],
              retailer_id:
                values.RetailerName !== undefined ? values.RetailerName : "",
              distributor_id:
                values.DistributorName !== undefined
                  ? values.DistributorName
                  : "",
              master_agent_id: values.Master !== undefined ? values.Master : "",
            };
            console.log(`employee`, employee);

            this.props
              .editEmployee(employee, this.props.location.employee.id)
              .then((res) => {
                message.success("User updated successfully");
                this.props.form.resetFields();
                this.setState({
                  // fileList: [],
                  loading: false,
                  // pwdstatus: "",
                });
                this.props.history.push("/employees");
              })
              .catch((err) => {
                message.error(err);
                console.log(err);
                this.setState({
                  loading: false,
                });
              });
          }
        });
      }
    });
  };
  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  render() {
    // eslint-disable-next-line
  
    const { getFieldDecorator } = this.props.form;
    const { loading, showcase } = this.state;

    return (
      <Spin spinning={showcase}>
        <div className="container-fluid">
          <div class="row justify-content-between padd20 bg-grey">
            <div class="col-4">
              <h4 className="mt-1">Edit User</h4>
            </div>
            <div class="col-4">
              <Link to="/employees" className="float-right">
                <Button type="default">Back</Button>
              </Link>
            </div>
          </div>

          <div className=" bg-white padd15">
            <Form onSubmit={this.handleSubmit} className="addbookform row">
              <Form.Item
                label="First Name"
                className="col-md-3 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("first_name", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.first_name,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input first name",
                    },
                  ],
                })(<Input placeholder="Enter First Name" />)}
              </Form.Item>
              <Form.Item
                label="Middle Name"
                className="col-md-3 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("middle_name", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.middle_name,
                  rules: [
                    {
                      required: false,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input middle name",
                    },
                  ],
                })(<Input placeholder="Enter Middle Name" />)}
              </Form.Item>
              <Form.Item
                label="Last Name"
                className="col-md-3 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("last_name", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.last_name,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input last name",
                    },
                  ],
                })(<Input placeholder="Enter Last Name" />)}
              </Form.Item>
              <Form.Item
                label="Email"
                className="col-md-3 col-sm-6 col-xs-12  "
              >
                {getFieldDecorator("email", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.email,
                  rules: [
                    {
                      required: true,
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Enter valid email address",
                    },
                  ],
                })(
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    autoComplete="new-password"
                    // onBlur={(e) => this.checkEmail(e.target.value)}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("mobile_number", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.mobile_number,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]{10,10}$/),
                      message: "Please input mobile number",
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="Enter Mobile Number"
                    minLength={10}
                    maxLength={10}
                    min={0}
                    step={0}
                    autoComplete="new-password"
                  />
                )}
              </Form.Item>
              {/* <Form.Item
                label="Excess Code"
                className="col-md-3 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("excess_code", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.excess_code,
                  rules: [
                    {
                      required: true,
                      // pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input Excess Code",
                    },
                  ],
                })(<Input placeholder="Enter Excess Code" />)}
              </Form.Item> */}

              {/* <Form.Item
                label="Rad ID"
                className="col-md-3 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("rad", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.rad,
                  rules: [
                    {
                      required: true,
                      // pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input Rad ID",
                    },
                  ],
                })(<Input placeholder="Enter Rad ID" />)}
              </Form.Item> */}

              {/* {(this.state.marketrole || this.state.retailerrole) &&
              !this.state.off_disable ? (
                this.state.officedata.role_id === 2 ? (
                  <Form.Item
                    label="Office Number"
                    className="col-md-3 col-sm-6 col-xs-12"
                  >
                    {getFieldDecorator("office_id", {
                      initialValue:
                        this.props.location.employee.office_id === undefined ||
                        this.props.location.employee.office_id === null
                          ? this.props.location.employee.office_mapping_info !==
                              undefined &&
                            this.props.location.employee.office_mapping_info !==
                              null &&
                            this.props.location.employee.office_mapping_info
                              .length > 0
                            ? this.props.location.employee
                                .office_mapping_info[0].office_id
                            : ""
                          : this.props.location.employee.office_id,
                      rules: [
                        {
                          required: true,
                          message: "Please input Office Number!",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Office Number"
                        disabled
                        // onChange={this.officeOnChange}
                        // filterOption={(input, option) =>
                        //   option.props.children
                        //     .toLowerCase()
                        //     .indexOf(input.toLowerCase()) >= 0
                        // }
                      >
                        {this.renderOffice()}
                      </Select>
                    )}
                  </Form.Item>
                ) : null
              ) : null} */}

              <Form.Item
                label="Role"
                className="col-md-12 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("role_id", {
                  initialValue:
                    this.props.location.employee === undefined ||
                    this.props.location.employee.role_info === undefined
                      ? ""
                      : this.state.rolefinfo[0]?.role_id ?? "",
                  rules: [
                    {
                      required: false,
                      message: "Please input Role",
                    },
                  ],
                })(
                  <Radio.Group onChange={this.onChangeRole}>
                    <Row> {this.renderRole()}</Row>
                  </Radio.Group>
                )}
              </Form.Item>

              {this.state.masterview && (
                <Form.Item
                  label="Master"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("Master", {
                    initialValue:
                      this.props.location.employee.master_agent_info === null
                        ? ""
                        : this.props.location.employee.master_agent_info
                            .master_agent_id,
                    rules: [
                      { required: true, message: "Please Select Master" },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Master"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }

                      // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                    >
                      {this.renderMaster()}
                    </Select>
                  )}
                </Form.Item>
              )}
              {this.state.distributorview && (
                <Form.Item
                  label="Distributor"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("DistributorName", {
                    initialValue:
                      this.props.location.employee.distributor_info === null
                        ? ""
                        : this.props.location.employee.distributor_info
                            .distributor_id,
                    rules: [
                      {
                        required: true,
                        message: "Please input Distributor",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Distributor"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                    >
                      {this.renderDistributor()}
                    </Select>
                  )}
                </Form.Item>
              )}
              {
                // this.state.retailerview &&
                this.state.superrole ? (
                  <Form.Item
                    label="Retailer"
                    className="col-md-4 col-sm-6 col-xs-12"
                  >
                    {getFieldDecorator("RetailerName", {
                      initialValue:
                        this.props.location.employee.retailer_info === null
                          ? ""
                          : this.props.location.employee.retailer_info
                              .retailer_id,
                      rules: [
                        {
                          required: true,
                          message: "Please input Retailer",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select Retailer"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                      >
                        {this.renderRetailer()}
                      </Select>
                    )}
                  </Form.Item>
                ) : null
              }
              {this.state.retailerview && !this.state.superrole ? (
                <Form.Item
                  label="IBO / Market"
                  className="col-md-6 col-sm-6 col-xs-12 mt-2"
                >
                  {getFieldDecorator("ibo_marketer_id", {
                    initialValue:
                      this.props.location.employee.ibo_marketer_id === null
                        ? ""
                        : this.props.location.employee.ibo_marketer_id,
                    rules: [
                      { required: true, message: "Please input IBO / Market" },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select IBO / Market"
                      onChange={this.marketerOnChange}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // disabled={this.state.marketrole === true  ? true : false}
                      disabled
                    >
                      {this.renderMarketers()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}

              {this.state.retailerview &&
              this.state.officeview &&
              !this.state.marketrole ? (
                <Form.Item
                  label="Office"
                  className="col-md-6 col-sm-6 col-xs-12 mt-2"
                >
                  {getFieldDecorator("office_id", {
                    initialValue:
                      this.state.officeuser === true
                        ? this.props.location.employee.office_mapping_info.map(
                            (market) => market.office_id
                          )
                        : this.state.agentview === true
                        ? [this.props.location.employee.office_id]
                        : [this.props.location.employee.office_id],
                    rules: [{ required: true, message: "Please input office" }],
                  })(
                    <Select
                      showSearch
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select office"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.renderOffice()}
                    </Select>
                  )}
                </Form.Item>
              ) : null}

              <Form.Item
                label="Street Address"
                className="col-md-3 col-sm-6 col-xs-12 mt-2"
              >
                {getFieldDecorator("address", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.address,
                  rules: [{ required: false, message: "Please input 	Address" }],
                })(<Input placeholder="Address" />)}
              </Form.Item>
              <Form.Item
                label="City"
                className="col-md-3 col-sm-6 col-xs-12 mt-2"
              >
                {getFieldDecorator("city", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.city,
                  rules: [{ required: false, message: "Please input 	City" }],
                })(<Input placeholder="City" />)}
              </Form.Item>
              <Form.Item
                label="State"
                className="col-md-3 col-sm-6 col-xs-12 mt-2"
              >
                {getFieldDecorator("state", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.state,
                  rules: [{ required: false, message: "Please input 	State" }],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select State"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {/* <Option value="AL">Alabama</Option>
                    <Option value="AK">Alaska</Option>
                    <Option value="AZ">Arizona</Option>
                    <Option value="AR">Arkansas</Option>
                    <Option value="CA">California</Option>
                    <Option value="CO">Colorado</Option>
                    <Option value="CT">Connecticut</Option>
                    <Option value="DE">Delaware</Option>
                    <Option value="DC">District Of Columbia</Option>
                    <Option value="FL">Florida</Option>
                    <Option value="GA">Georgia</Option>
                    <Option value="HI">Hawaii</Option>
                    <Option value="ID">Idaho</Option>
                    <Option value="IL">Illinois</Option>
                    <Option value="IN">Indiana</Option>
                    <Option value="IA">Iowa</Option>
                    <Option value="KS">Kansas</Option>
                    <Option value="KY">Kentucky</Option>
                    <Option value="LA">Louisiana</Option>
                    <Option value="ME">Maine</Option>
                    <Option value="MD">Maryland</Option>
                    <Option value="MA">Massachusetts</Option>
                    <Option value="MI">Michigan</Option>
                    <Option value="MN">Minnesota</Option>
                    <Option value="MS">Mississippi</Option>
                    <Option value="MO">Missouri</Option>
                    <Option value="MT">Montana</Option>
                    <Option value="NE">Nebraska</Option>
                    <Option value="NV">Nevada</Option>
                    <Option value="NH">New Hampshire</Option>
                    <Option value="NJ">New Jersey</Option>
                    <Option value="NM">New Mexico</Option>
                    <Option value="NY">New York</Option>
                    <Option value="NC">North Carolina</Option>
                    <Option value="ND">North Dakota</Option>
                    <Option value="OH">Ohio</Option>
                    <Option value="OK">Oklahoma</Option>
                    <Option value="OR">Oregon</Option>
                    <Option value="PA">Pennsylvania</Option>
                    <Option value="RI">Rhode Island</Option>
                    <Option value="SC">South Carolina</Option>
                    <Option value="SD">South Dakota</Option>
                    <Option value="TN">Tennessee</Option>
                    <Option value="TX">Texas</Option>
                    <Option value="UT">Utah</Option>
                    <Option value="VT">Vermont</Option>
                    <Option value="VA">Virginia</Option>
                    <Option value="WA">Washington</Option>
                    <Option value="WV">West Virginia</Option>
                    <Option value="WI">Wisconsin</Option>
                    <Option value="WY">Wyoming</Option> */}
                     {this.renderstateshort()}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="ZIP"
                className="col-md-3 col-sm-6 col-xs-12 mt-2"
              >
                {getFieldDecorator("zip", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.zip,
                  rules: [
                    {
                      required: false,
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input 	ZIP",
                    },
                  ],
                })(<Input maxLength={5} placeholder="ZIP" />)}
              </Form.Item>

              {/* <Form.Item label="DOB" className="col-md-3 col-sm-6 col-xs-12">
                {getFieldDecorator("dob", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : moment(this.props.location.employee.dob),
                  rules: [
                    { required: true, message: "Please input Date of Birth!" },
                  ],
                })(
                  <DatePicker
                    format="YYYY-MMM-DD"
                    disabledDate={this.disabledDOB}
                    onChange={(date) => {
                      this.setState({ dob: new Date(date) });
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Application Date"
                className="col-md-3 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("application_date", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : moment(this.props.location.employee.application_date),
                  rules: [
                    {
                      required: true,
                      message: "Please input Application Date",
                    },
                  ],
                })(
                  <DatePicker
                    format="YYYY-MMM-DD"
                    disabledDate={this.disabledDOJ}
                    onChange={(date) => {
                      this.setState({ application_date: new Date(date) });
                    }}
                  />
                )}
              </Form.Item> */}

              {/* <Form.Item label="Zone" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("Zone", {
                rules: [{ required: false, message: "Please input Zone!" }],
              })(
                <Select
                  // disabled={this.state.disable}
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Select zone"
                  // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                >
                  <Option value="NORTH">NORTH</Option>
                  <Option value="SOUTH">SOUTH</Option>
                  <Option value="EAST">EAST</Option>
                  <Option value="WEST">WEST</Option>
                </Select>
              )}
            </Form.Item> */}
              {/* <Form.Item
              label="Profile Picture"
              className="col-md-6 col-sm-12 col-xs-12 paddlr15"
            >
              {getFieldDecorator("Documents", {
                getValueFromEvent: this.normFile,
                rules: [
                  {
                    required: false,
                    message: "Please upload required documents!",
                  },
                ],
              })(
                <Upload name="Documents" {...propsthumb}>
                  {0 >= imglength ? (
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  ) : null}
                </Upload>
              )}
            </Form.Item>
            <Form.Item
              label="ID Picture"
              className="col-md-6 col-sm-12 col-xs-12 paddlr15"
            >
              {getFieldDecorator("Documents", {
                getValueFromEvent: this.normFile,
                rules: [
                  {
                    required: false,
                    message: "Please upload required documents!",
                  },
                ],
              })(
                <Upload name="Documents" {...propsthumb}>
                  {0 >= imglength ? (
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  ) : null}
                </Upload>
              )}
            </Form.Item> */}
              <br />
              <br />
              <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Update User
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}
const WrappedEditEmployee = Form.create({ name: "EditEmployee" })(EditEmployee);
export default connect(mapStateToProps, {
  getRolelist,
  getMarketerslist,
  getofficelistname,
  getGenderlist,
  editEmployee,
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlist,
  short_us_states
})(WrappedEditEmployee);
