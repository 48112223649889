import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  InputNumber,
  Radio,
  Row,
  Col,
} from "antd";
import _ from "lodash";
import {
  getRolelist,
  getMarketerslist,
  getOfficelist,
  getGenderlist,
  createEmployee,
  getofficelistname,
} from "../../actions/employeeAction";
import {
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlist,
  short_us_states,
} from "../../actions/assetAction";

import moment from "moment";
const { Option } = Select;

class AddEmployee extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      offices: [],
      officeview: false,
      disableoffice: true,
      roles: [],
      filteredroles: [],
      marketers: [],
      filteredmarketers: [],
      dob: Date(),
      application_date: Date(),
      hubs: [],
      designation: [],
      fileList: [],
      loading: false,
      pwdsettings: "",
      pwdstatus: "",
      pwdinfo: "",
      allhubs: [],
      emailexists: true,
      vehicleexists: true,
      isFieldChampion: false,
      departments: [],
      asshubs: [],
      disable: false,
      marketview: false,
      distributor: [],
      master: [],
      retailer: [],
      masterview: false,
      distributorview: false,
      retailerrole: false,
      marketrole: false,
      superrole: false,
      superadmin: false,
      shortstate: [],
    };
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    // this.props.form.setFieldsValue({
    //   MobileNo: value.match(phoneno) ? value : "000",
    // });
    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };

  // checkEmail(email) {
  //   console.log(email);
  //   this.props
  //     .checkEmail(email)
  //     .then((res) => {
  //       this.setState({
  //         emailexists: false,
  //       });
  //       this.props.form.setFields({
  //         Email: {
  //           value: email,
  //           errors: [new Error("Email already exists!")],
  //         },
  //       });
  //     })
  //     .catch((err) => {
  //       this.setState({
  //         emailexists: true,
  //       });
  //     });
  // }

  pwdvalidation = (e) => {
    // console.log(e.target.value, this.state.pwdsettings);
    var value = e.target.value;
    var maxlength = this.state.pwdsettings.MaxLength;
    var isnum = this.state.pwdsettings.isNumAllowed;
    var issplchar = this.state.pwdsettings.isSplCharAllowed;
    var isvalid = "";
    if (isnum && issplchar) {
      // console.log("Cond 1")
      isvalid =
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*?&.])[A-Za-z\d$@$!%*?&.]{2,}$/;
    } else if (issplchar) {
      // console.log("Cond 2 Spl char ")
      isvalid = /^(?=.*[A-Za-z])(?=.*[$@$!%*?&.])[A-Za-z\d$@$!%*?&.]{2,}$/;
    } else if (isnum) {
      // console.log("Cond 3 only num ")
      isvalid = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*?&.]{2,}$/;
    }

    this.setState({
      pwdstatus: "validating",
    });
    if (value.match(isvalid) && maxlength <= value.length) {
      this.props.form.setFieldsValue({
        Password: value,
      });
      this.setState({
        pwdstatus: "success",
      });
    } else {
      this.setState({
        pwdstatus: "error",
      });
    }
  };

  componentWillMount() {
    this.props.short_us_states().then((res) => {
      console.log(res);
      this.setState({
        shortstate: res.data,
      });
    });
    console.log(this.props.location.agent_info);

    this.getGenderlist();
    // this.getOfficelist();
    this.getRolelist();
    this.getMarketerslist();
    this.props.getretailers().then((res) => {
      this.props.getdistributers().then((res) => {
        this.props.getmaster().then((res) => {
          this.setState({
            distributor: this.props.data.distributor,
            master: this.props.data.master,
            retailer: this.props.data.retailer,
          });
        });
      });
    });
    const superadmin = this.props.user.role_id.filter((word) => word === 10);
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    const Retailer = this.props.user.role_id.filter((word) => word === 5);
    const marketer = this.props.user.role_id.filter(
      (word) => word === 4 || word === 8
    );
    console.log("Retailerview", Retailer);
    console.log("marketer", marketer);
    if (Retailer.length !== 0) {
      this.setState({
        retailerrole: true,
      });
    }
    if (marketer.length !== 0) {
      this.setState({
        marketrole: true,
      });
    }
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    if (superadmin.length !== 0) {
      this.setState({
        superadmin: true,
      });
    }
    // console.log(this.props.user.uid);
  }

  genderOnChange = (e) => {
    var fdesgnd = _.filter(this.state.gender, ["Gender", e]);
    this.setState({
      filteredgender: fdesgnd,
    });
    console.log(e, fdesgnd);
  };
  disabledDOB(current) {
    return moment().add(0, "days") <= current;
  }
  disabledDOJ(current) {
    return moment().add(0, "days") <= current;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(`values`, e);
    // if (this.state.emailexists) {
    // if (this.state.vehicleexists) {
    this.props.form.validateFields((err, values) => {
      console.log(`values`, values);
      if (!err) {
        this.setState({
          loading: true,
        });
        var employee = {
          first_name: values.first_name,
          middle_name: values?.middle_name ?? "",
          last_name: values.last_name,
          rad: values.rad,
          promo_code: values.promo_code,
          excess_code: values.excess_code,
          mobile_number: values.mobile_number.toString(),
          gender_id: values.gender_id, //this.state.filteredgender.id,
          office_id: values.office_id,
          ibo_marketer_id:
            values.ibo_marketer_id !== undefined
              ? values?.ibo_marketer_id.toString() ?? ""
              : "",
          dob: moment(this.state.dob).format("YYYY-MM-DD"),
          application_date: moment(this.state.application_date).format(
            "YYYY-MM-DD"
          ),
          address: values.address !== undefined ? values.address : "",
          city: values.city !== undefined ? values.city : "",
          state: values.state !== undefined ? values.state : "",
          zip: values.zip !== undefined ? values.zip : "",
          email: values.email,
          role_id: [values.role_id], //[9, 2],
          retailer_id:
            values.RetailerName !== undefined ? values.RetailerName : "",
          distributor_id:
            values.DistributorName !== undefined ? values.DistributorName : "",
          master_agent_id: values.Master !== undefined ? values.Master : "",
        };
        console.log(`employee`, employee);

        this.props
          .createEmployee(employee)
          .then((res) => {
            console.log("res", res);
            message.success("User created successfully");
            this.props.form.resetFields();
            this.setState({
              // fileList: [],
              loading: false,
              // pwdstatus: "",
            });
            this.props.history.push("/employees");
          })
          .catch((err) => {
            message.error(err);
            this.setState({
              // fileList: [],
              loading: false,
              // pwdstatus: "",
            });
            console.log(err);
          });
      }
    });
  };

  renderOffice() {
    // console.log(`this.state.offices`, this.state.offices)
    return _.map(this.state.offices, (dep, index) => {
      // console.log(`dep`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
      // });
    });
  }

  renderGender() {
    // console.log(`this.state.gender`, this.state.gender)
    return _.map(this.state.gender, (dep, index) => {
      // console.log(`dep`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
      // });
    });
  }

  renderRole() {
    // console.log('this.state.roles', this.state.roles)
    return _.map(this.state.roles, (dep, index) => {
      if (this.state.superadmin === true) {
        return (
          <Col span={8} key={index}>
            <Radio value={dep.id} key={index}>
              {dep.name}
              {/* Market Supervisor */}
            </Radio>
          </Col>
        );
      }

      if (this.state.superrole === true) {
        // console.log(dep);
        if (dep.id === 5 || dep.id === 6 || dep.id === 7) {
          const value = dep.id === 6 ? true : dep.id === 7 ? true : false;
          return (
            <Col span={24}>
              {this.state.superrole ? (
                <Radio
                  disabled={value}
                  defaultValue={this.props.filter}
                  // autoFocus={true}
                  // defaultChecked={!value ? true : false}
                  // checked
                  value={dep.id}
                >
                  {dep.name}{" "}
                </Radio>
              ) : (
                <Col span={24}>
                  <Radio value={dep.id}>{dep.name} </Radio>
                </Col>
              )}
            </Col>
          );
        }
      }
      if (this.state.marketrole === true) {
        if (dep.id === 3 || dep.id === 9) {
          return (
            <Col span={24}>
              <Radio value={dep.id}>
                {dep.name}
                {/* Market Supervisor */}
              </Radio>
            </Col>
          );
        }
      }
      if (this.state.retailerrole === true) {
        if ( dep.id === 11 || dep.id === 12) {
          return (
            <Col span={12}>
              <Radio
                value={dep.id}
                // disabled={dep.id === 6 || dep.id === 7 ? false : true}
                
                // ( remove dep.id ===3 (office manager))
              >
                {dep.name}
                {/* Market Supervisor */}
              </Radio>
            </Col>
          );
        }
      }
    });
  }

  renderMarketers() {
    return _.map(this.state.marketers, (dep, index) => {
      // console.log(`marketers`, dep)
      // dep.map((genderData) => {
      return (
        <Option value={dep.id} key={index}>
          {dep.name}
        </Option>
      );
    });
  }

  onChangeRole = (e) => {
    var checkedValues = [e.target.value];
    console.log("checkvalue", checkedValues);
    console.log("checked = ", checkedValues);
    if (e.target.value === 3) {
      this.getMarketerslistoff();
    }
    if (e.target.value === 3) {
      this.props.form.setFieldsValue({
        office_id: [],
      });
      this.setState({
        officeview: true,
        disableoffice: true,
      });
    } else {
      this.setState({
        officeview: false,
      });
    }
    var masterIndex = this.state.roles.findIndex(
      (role) => role.name === "Master Agent"
    );
    var DistributorIndex = this.state.roles.findIndex(
      (role) => role.name === "Distributor"
    );
    var RetailerIndex = this.state.roles.findIndex(
      (role) => role.name === "Retailer"
    );
    var OffManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "Office Manager"
    );
    var IBOManagerIndex = this.state.roles.findIndex(
      (role) => role.name === "IBO Manager"
    );
    // var IBOSuperIndex = this.state.roles.findIndex(
    //   (role) => role.name === "IBO Add-on Manager"
    // );
    // var IBOInventIndex = this.state.roles.findIndex(
    //   (role) => role.name === "IBO Manager"
    // );
    var masterusers = checkedValues.filter(
      (obj) => obj === this.state.roles[masterIndex].id
    );
    var distributeusers = checkedValues.filter(
      (obj) => obj === this.state.roles[DistributorIndex].id
    );
    var retaileusers = checkedValues.filter(
      (obj) =>
        obj === this.state.roles[RetailerIndex].id ||
        obj === this.state.roles[OffManagerIndex].id ||
        obj === this.state.roles[IBOManagerIndex].id
    );

    console.log(retaileusers);

    if (masterusers.length !== 0) {
      this.setState({
        masterview: true,
      });
    } else {
      this.setState({
        masterview: false,
      });
    }
    if (distributeusers.length !== 0) {
      this.setState({
        distributorview: true,
      });
    } else {
      this.setState({
        distributorview: false,
      });
    }
    if (retaileusers.length !== 0) {
      this.setState({
        retailerview: true,
      });
    } else {
      this.setState({
        retailerview: false,
      });
    }
  };

  officeOnChange = (e) => {
    var fdesgnd = _.filter(this.state.offices, ["Offices", e]);
    this.setState({
      filteredoffices: fdesgnd,
    });
    console.log(e, fdesgnd);
    // this.props.form.setFields({
    //   offices: {
    //     value: "",
    //   },
    // });
  };

  roleOnChange = (e) => {
    var fdesgnd = _.filter(this.state.roles, ["Role", e]);
    this.setState({
      filteredroles: fdesgnd,
    });
    console.log(e, fdesgnd);
    // this.props.form.setFields({
    //   roles: {
    //     value: "",
    //   },
    // });
  };

  marketerOnChange = (e) => {
    this.setState({
      loading: true,
    });
    console.log(e);
    this.props
      .getofficelistname(e)
      .then((res) => {
        console.log("marketers", res);
        this.props.form.setFieldsValue({
          office_id: [],
        });
        this.setState({
          offices: res.data,
          disableoffice: false,
          loading: false,
        });
      })
      .catch((err) => {
        this.props.form.setFieldsValue({
          office_id: [],
        });
        console.log(err);
        this.setState({
          offices: [],
          disableoffice: true,
          loading: false,
        });
      });
  };

  getGenderlist() {
    // nprogress.start();
    if (this.state.gender === undefined || this.state.gender.length <= 0) {
      this.props
        .getGenderlist()
        .then((res) => {
          console.log(res);
          this.setState({
            gender: res.data,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            gender: [],
          });
          // nprogress.done();
        });
    }
  }

  getMarketerslist(role) {
    console.log(role);

    this.props
      .getMarketerslist(role === 4 ? "M" : "IM", 0)
      .then((res) => {
        this.props.form.setFields({
          ibo_marketer_id: [],
        });
        console.log("marketers", res);
        this.setState({
          marketers: res,
        });
      })
      .catch((err) => {
        this.setState({
          marketers: [],
        });
      });
  }

  getMarketerslistoff() {
    this.setState({
      marketers: [],
    });
    this.props
      .getibomarketlist()
      .then((res) => {
        this.props.form.setFields({
          ibo_marketer_id: [],
        });
        console.log("marketers", res);
        this.setState({
          marketers: res.data,
        });
        // nprogress.done();
      })
      .catch((err) => {
        this.setState({
          marketers: [],
        });
        // nprogress.done();
      });
  }

  getOfficelist() {
    if (this.state.offices === undefined || this.state.offices.length <= 0) {
      // nprogress.start();
      this.props
        .getOfficelist()
        .then((res) => {
          console.log(res);
          this.setState({
            offices: res,
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            offices: [],
          });
          // nprogress.done();
        });
    }
  }

  getRolelist() {
    if (this.state.roles === undefined || this.state.roles.length <= 0) {
      // nprogress.start();
      this.props
        .getRolelist()
        .then((res) => {
          console.log("getRolelist", res);
          this.setState({
            roles: _.sortBy(res.data.items, (o) => o.level),
          });
          // nprogress.done();
        })
        .catch((err) => {
          this.setState({
            roles: [],
          });
          // nprogress.done();
        });
    }
  }
  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  render() {
    // eslint-disable-next-line
    const mailformat = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;

    return (
      <div className="container-fluid">
        <div class="row justify-content-between padd20 bg-grey">
          <div class="col-4">
            <h4 className="mt-1">Add User</h4>
          </div>
          <div class="col-4">
            <Link to="/employees" className="float-right">
              <Button type="default">Back</Button>
            </Link>
          </div>
        </div>
        {/* <div className="row padd20 bg-grey">
          <div className="col-xs-6">
            <h4>Add User</h4>
          </div>
          <div className="col-xs-6 text-right">
            <Link to="/employees">
              <Button type="default">Back</Button>
            </Link>
          </div>
        </div> */}

        <div className=" bg-white padd15">
          <Form onSubmit={this.handleSubmit} className="addbookform row">
            <Form.Item
              label="First Name"
              className="col-md-4 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("first_name", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                    message: "Please input first name",
                  },
                ],
              })(<Input placeholder="Enter First Name" />)}
            </Form.Item>
            <Form.Item
              label="Middle Name"
              className="col-md-4 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("middle_name", {
                rules: [
                  {
                    required: false,
                    pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                    message: "Please input middle name",
                  },
                ],
              })(<Input placeholder="Enter Middle Name" />)}
            </Form.Item>
            <Form.Item
              label="Last Name"
              className="col-md-4 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("last_name", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                    message: "Please input last name",
                  },
                ],
              })(<Input placeholder="Enter Last Name" />)}
            </Form.Item>
            <Form.Item
              label="Email"
              className="col-md-4 col-sm-6 col-xs-12 mt-2"
            >
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(
                      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                    ),
                    message: "Enter valid email address",
                  },
                ],
              })(
                <Input
                  type="email"
                  placeholder="Enter Email Address"
                  autoComplete="new-password"
                  // onBlur={(e) => this.checkEmail(e.target.value)}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Mobile Number"
              className="col-md-4 col-sm-6 col-xs-12 mt-2"
            >
              {getFieldDecorator("mobile_number", {
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,10}$/),
                    message: "Please input mobile number",
                  },
                ],
              })(
                <InputNumber
                  placeholder="Enter Mobile Number"
                  minLength={10}
                  maxLength={10}
                  min={0}
                  step={0}
                  autoComplete="new-password"
                />
              )}
            </Form.Item>

            <Form.Item
              label="Role"
              className="col-md-12 col-sm-6 col-xs-12 mt-2"
            >
              {getFieldDecorator("role_id", {
                rules: [
                  {
                    required: true,
                    message: "Please input Role",
                  },
                ],
              })(
                <Radio.Group onChange={this.onChangeRole} defaultChecked>
                  <Row> {this.renderRole()}</Row>
                </Radio.Group>
              )}
            </Form.Item>
            {/* {this.state.masterview && (
              <Form.Item label="Master" className="col-md-4 col-sm-6 col-xs-12">
                {getFieldDecorator("Master", {
                  rules: [{ required: true, message: "Please Select Master" }],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Master"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }

                    // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                  >
                    {this.renderMaster()}
                  </Select>
                )}
              </Form.Item>
            )} */}
            {/* {this.state.distributorview && (
              <Form.Item
                label="Distributor"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("DistributorName", {
                  rules: [
                    {
                      required: true,
                      message: "Please input Distributor",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select Distributor"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                  >
                    {this.renderDistributor()}
                  </Select>
                )}
              </Form.Item>
            )} */}
            {
              // this.state.retailerview &&
              this.state.superrole ? (
                <Form.Item
                  label="Retailer"
                  className="col-md-4 col-sm-6 col-xs-12"
                >
                  {getFieldDecorator("RetailerName", {
                    rules: [
                      {
                        required: true,
                        message: "Please input Retailer",
                      },
                    ],
                  })(
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select Retailer"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                    >
                      {this.renderRetailer()}
                    </Select>
                  )}
                </Form.Item>
              ) : null
            }
            {this.state.retailerview &&
            !this.state.marketrole &&
            !this.state.superrole ? (
              <Form.Item
                label="IBO / Market"
                className="col-md-6 col-sm-6 col-xs-12 "
              >
                {getFieldDecorator("ibo_marketer_id", {
                  rules: [
                    { required: true, message: "Please input IBO / Market" },
                  ],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select IBO / Market"
                    onChange={this.marketerOnChange}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.renderMarketers()}
                  </Select>
                )}
              </Form.Item>
            ) : null}

            {this.state.retailerview &&
            this.state.officeview &&
            !this.state.marketrole ? (
              <Form.Item
                label="Office"
                className="col-md-6 col-sm-6 col-xs-12 mt-2"
              >
                {getFieldDecorator("office_id", {
                  rules: [{ required: true, message: "Please input office" }],
                })(
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ width: "100%" }}
                    placeholder="Select office"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={this.state.disableoffice}
                  >
                    {this.renderOffice()}
                  </Select>
                )}
              </Form.Item>
            ) : null}

            <Form.Item
              label="Street Address"
              className="col-md-3 col-sm-6 col-xs-12 "
            >
              {getFieldDecorator("address", {
                rules: [{ required: false, message: "Please input address" }],
              })(<Input placeholder="Address" />)}
            </Form.Item>
            <Form.Item
              label="City"
              className="col-md-3 col-sm-6 col-xs-12 "
            >
              {getFieldDecorator("city", {
                rules: [{ required: false, message: "Please input city" }],
              })(<Input placeholder="City" />)}
            </Form.Item>
            <Form.Item
              label="State"
              className="col-md-3 col-sm-6 col-xs-12 "
            >
              {getFieldDecorator("state", {
                rules: [{ required: false, message: "Please input state" }],
              })(
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select State"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.renderstateshort()}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="ZIP" className="col-md-3 col-sm-6 col-xs-12 ">
              {getFieldDecorator("zip", {
                rules: [
                  {
                    required: false,
                    pattern: new RegExp(/^[0-9]+$/),
                    message: "Please input 	ZIP",
                  },
                ],
              })(<Input maxLength={5} placeholder="ZIP" />)}
            </Form.Item>

            {/* <Form.Item label="DOB" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("dob", {
                rules: [
                  { required: true, message: "Please input Date of Birth!" },
                ],
              })(
                <DatePicker
                  format="YYYY-MMM-DD"
                  disabledDate={this.disabledDOB}
                  onChange={(date) => {
                    this.setState({ dob: new Date(date) });
                  }}
                />
              )}
            </Form.Item> */}
            {/* <Form.Item
              label="Application Date"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("application_date", {
                rules: [
                  { required: true, message: "Please input Application Date" },
                ],
              })(
                <DatePicker
                  format="YYYY-MMM-DD"
                  disabledDate={this.disabledDOJ}
                  onChange={(date) => {
                    this.setState({ application_date: new Date(date) });
                  }}
                />
              )}
            </Form.Item> */}

            {/* <Form.Item label="Zone" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("Zone", {
                rules: [{ required: false, message: "Please input Zone!" }],
              })(
                <Select
                  // disabled={this.state.disable}
                  disabled={true}
                  style={{ width: "100%" }}
                  placeholder="Select zone"
                  // onChange={(z) => this.setState({ hubs: _.filter(this.state.allhubs, ["Zone", z]) })}
                >
                  <Option value="NORTH">NORTH</Option>
                  <Option value="SOUTH">SOUTH</Option>
                  <Option value="EAST">EAST</Option>
                  <Option value="WEST">WEST</Option>
                </Select>
              )}
            </Form.Item> */}
            {/* <Form.Item
              label="Profile Picture"
              className="col-md-6 col-sm-12 col-xs-12 paddlr15"
            >
              {getFieldDecorator("Documents", {
                getValueFromEvent: this.normFile,
                rules: [
                  {
                    required: false,
                    message: "Please upload required documents!",
                  },
                ],
              })(
                <Upload name="Documents" {...propsthumb}>
                  {0 >= imglength ? (
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  ) : null}
                </Upload>
              )}
            </Form.Item>
            <Form.Item
              label="ID Picture"
              className="col-md-6 col-sm-12 col-xs-12 paddlr15"
            >
              {getFieldDecorator("Documents", {
                getValueFromEvent: this.normFile,
                rules: [
                  {
                    required: false,
                    message: "Please upload required documents!",
                  },
                ],
              })(
                <Upload name="Documents" {...propsthumb}>
                  {0 >= imglength ? (
                    <div>
                      <Icon type="plus" />
                      <div className="ant-upload-text">Upload</div>
                    </div>
                  ) : null}
                </Upload>
              )}
            </Form.Item> */}
            <br />
            <br />
            <div className="col-md-12 col-sm-12 col-xs-12 padd15">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Create User
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}
const WrappedAddEmployee = Form.create({ name: "AddEmployee" })(AddEmployee);
export default connect(mapStateToProps, {
  getRolelist,
  getMarketerslist,
  getOfficelist,
  getGenderlist,
  createEmployee,
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlist,
  getofficelistname,
  short_us_states
})(WrappedAddEmployee);
