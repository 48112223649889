import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  InputNumber,
  Spin,
} from "antd";
import _ from "lodash";
import moment from "moment";
import { editAdminEmployee } from "../../actions/employeeAction";
import { short_us_states } from "../../actions/assetAction";

const { Option } = Select;

class EditAdminEmployee extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      offices: [],
      officeview: false,
      disableoffice: true,
      roles: [],
      filteredroles: [],
      marketers: [],
      filteredmarketers: [],
      dob: Date(),
      application_date: Date(),
      hubs: [],
      designation: [],
      fileList: [],
      loading: false,
      off_disable: false,
      showUpload: false,
      showImg: true,
      allhubs: [],
      isFieldChampion: false,
      pwdsettings: "",
      pwdstatus: "",
      pwdinfo: "",
      asshubs: [],
      disable: false,
      emailexists: true,
      distributor: [],
      master: [],
      retailer: [],
      showcase: false,
      masterview: false,
      distributorview: false,
      retailerview: false,
      retailerrole: false,
      marketrole: false,
      superrole: false,
      superadmin: false,
      officedata: [],
      rolefinfo: [],
      agentview: false,
      officeuser: false,
      shortstate: [],
    };
  }

  componentWillMount() {
    this.props.short_us_states().then((res) => {
      console.log(res);
      this.setState({
        shortstate: res.data,
      });
    });
   
    if (this.props.location.employee === undefined) {
      this.props.history.push("/employees");
    } else {
      console.log(this.props.location.agent_info);
      console.log(this.props.location.employee);
    }
    
  }

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.form.validateFields((err, values) => {
          console.log(`values`, values);
          if (!err) {
            this.setState({
              loading: true,
            });
            let roleIds = this.props.location.employee.role_info.map(employee => employee.role_id);
            console.log(roleIds)
            var employee = {
              //   _method: "PUT",
              first_name: values.first_name,
              middle_name: values?.middle_name?? "",
              last_name: values.last_name,
              rad: values.rad,
              promo_code: values.promo_code,
              excess_code: values.excess_code,
              mobile_number:
                values.mobile_number !== undefined ||
                values.mobile_number !== null
                  ? values.mobile_number.toString()
                  : "",
              gender_id: values.gender_id, //this.state.filteredgender.id,

              dob: moment(this.state.dob).format("YYYY-MM-DD"),

              address:
                values.address !== undefined || values.address !== null
                  ? values.address
                  : "",
              city:
                values.city !== undefined || values.city !== null
                  ? values.city
                  : "",
              state:
                values.state !== undefined || values.state !== null
                  ? values.state
                  : "",
              zip:
                values.zip === undefined || values.zip === null
                  ? ""
                  : values.zip.toString(),
              email: values.email,
              role_id: roleIds.toString(),
            };
            console.log(`employee`, employee);

            this.props
              .editAdminEmployee(employee, this.props.location.employee.id)
              .then((res) => {
                message.success("User updated successfully");
                this.props.form.resetFields();
                this.setState({
                  // fileList: [],
                  loading: false,
                  // pwdstatus: "",
                });
                this.props.history.push("/employees");
              })
              .catch((err) => {
                message.error(err);
                console.log(err);
                this.setState({
                  loading: false,
                });
              });
          }
        });
      }
    });
  };

  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  render() {
    // eslint-disable-next-line

    const { getFieldDecorator } = this.props.form;
    const { loading, showcase } = this.state;

    return (
      <Spin spinning={showcase}>
        <div className="container-fluid">
          <div class="row justify-content-between padd20 bg-grey">
            <div class="col-4">
              <h4 className="mt-1">Edit User</h4>
            </div>
            <div class="col-4">
              <Link to="/employees" className="float-right">
                <Button type="default">Back</Button>
              </Link>
            </div>
          </div>

          <div className=" bg-white padd15">
            <Form onSubmit={this.handleSubmit} className="addbookform row">
              <Form.Item
                label="First Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("first_name", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.first_name,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input first name",
                    },
                  ],
                })(<Input placeholder="Enter First Name" />)}
              </Form.Item>
              <Form.Item
                label="Middle Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("middle_name", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.middle_name,
                  rules: [
                    {
                      required: false,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input middle name",
                    },
                  ],
                })(<Input placeholder="Enter Middle Name" />)}
              </Form.Item>
              <Form.Item
                label="Last Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("last_name", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.last_name,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input last name",
                    },
                  ],
                })(<Input placeholder="Enter Last Name" />)}
              </Form.Item>
              <Form.Item
                label="Email"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("email", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.email,
                  rules: [
                    {
                      required: true,
                      pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      message: "Enter valid email address",
                    },
                  ],
                })(
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    autoComplete="new-password"
                    // onBlur={(e) => this.checkEmail(e.target.value)}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("mobile_number", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.mobile_number,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]{10,10}$/),
                      message: "Please input mobile number",
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="Enter Mobile Number"
                    minLength={10}
                    maxLength={10}
                    min={0}
                    step={0}
                    autoComplete="new-password"
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Excess Code"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("excess_code", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.excess_code,
                  rules: [
                    {
                      required: true,
                      // pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input Excess Code",
                    },
                  ],
                })(<Input placeholder="Enter Excess Code" />)}
              </Form.Item>

              <Form.Item
                label="RAD ID"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("rad", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.rad,
                  rules: [
                    {
                      required: true,
                      // pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input Rad ID",
                    },
                  ],
                })(<Input placeholder="Enter Rad ID" />)}
              </Form.Item>

              <Form.Item
                label="Street Address"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("address", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.address,
                  rules: [{ required: true, message: "Please input 	Address" }],
                })(<Input placeholder="Address" />)}
              </Form.Item>
              <Form.Item
                label="City"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("city", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.city,
                  rules: [{ required: true, message: "Please input 	City" }],
                })(<Input placeholder="City" />)}
              </Form.Item>
              <Form.Item
                label="State"
                className="col-md-3 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("state", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.state,
                  rules: [{ required: true, message: "Please input 	State" }],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select State"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.renderstateshort()}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="ZIP"
                className="col-md-3 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("zip", {
                  initialValue:
                    this.props.location.employee === undefined
                      ? ""
                      : this.props.location.employee.zip,
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input 	ZIP",
                    },
                  ],
                })(<Input maxLength={5} placeholder="ZIP" />)}
              </Form.Item>

              <br />
              <br />
              <div className="col-md-12 col-sm-12 col-xs-12 padd15">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Update User
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}
const WrappedEditEmployee = Form.create({ name: "EditEmployee" })(
  EditAdminEmployee
);
export default connect(mapStateToProps, {
  editAdminEmployee,

  short_us_states,
})(WrappedEditEmployee);
