import React, {Component} from 'react';

import { Query, Builder, Utils as QbUtils } from '@react-awesome-query-builder/fluent';
import { FluentUIConfig } from '@react-awesome-query-builder/fluent';
import '@react-awesome-query-builder/fluent/css/styles.css';
const InitialConfig = FluentUIConfig;

const config = {
  ...InitialConfig,
  fields: {
    imei: {
      label: 'IMEI',
      type: 'text',
      fieldSettings: {
        min: 0,
      },
      valueSources: ['value'],
      preferWidgets: ['text'],
    },
    carton: {
        label: 'Carton',
        type: 'text',
        fieldSettings: {
          min: 0,
        },
        valueSources: ['value'],
        preferWidgets: ['text'],
      },
     

  }
};

// Initial state of the query builder
const queryValue = {
  id: QbUtils.uuid(),
  type: 'group',
  properties: {
    conjunction: 'AND',
  },
  children1: {
    [QbUtils.uuid()]: {
      type: 'rule',
      properties: {
        field: 'imei',
        operator: 'equal',
        value: ['ABCD'],
      },
    },
    [QbUtils.uuid()]: {
      type: 'rule',
      properties: {
        field: 'carton',
        operator: 'equal',
        value: ['ABCD'],
      },
    },
  },
};

class DemoQueryBuilder extends Component {
  state = {
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config
  };
  
  render = () => (
    <div>
      <Query
        {...config} 
        value={this.state.tree}
        onChange={this.onChange}
        renderBuilder={this.renderBuilder}
      />
      {/* {this.renderResult(this.state)} */}
    </div>
  )

  renderBuilder = (props) => (
    <div className="query-builder-container">
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  )

//   renderResult = ({tree: immutableTree, config}) => (
//     <div className="query-builder-result">
//       <div>Query string: <pre>{JSON.stringify(QbUtils.queryString(immutableTree, config))}</pre></div>
//       <div>MongoDb query: <pre>{JSON.stringify(QbUtils.mongodbFormat(immutableTree, config))}</pre></div>
//       <div>SQL where: <pre>{JSON.stringify(QbUtils.sqlFormat(immutableTree, config))}</pre></div>
//       <div>JsonLogic: <pre>{JSON.stringify(QbUtils.jsonLogicFormat(immutableTree, config))}</pre></div>
//     </div>
//   )
  
  onChange = (immutableTree, config, isForDisplay) => {
  
    this.setState({tree: immutableTree, config: config});
    const jsonTree = QbUtils.getTree(immutableTree);
    console.log(jsonTree);
      // Call the function passed in through props
    this.props.onStateChange(JSON.stringify(QbUtils.queryString(immutableTree, config, isForDisplay = true )));
    
  }
}
export default DemoQueryBuilder;