import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  InputNumber,
  Checkbox,
  Icon,
  Upload,
  Col,
  Row,
  Spin,
  DatePicker,
  Radio,
} from "antd";
import _ from "lodash";
import moment from "moment";
import {
  getRolelist,
  getMarketerslist,
  getGenderlist,
  editEmployee,
} from "../../actions/employeeAction";
import {
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlist,
  getofficelistname,
  submitRadVerify,
  short_us_states,
  rad_pii_issue_status,
} from "../../actions/assetAction";
import ModalImage from "react-modal-image";
import document from "../../styles/img/undraw_add_files_re_v09g.svg";

const { Option } = Select;
const { Dragger } = Upload;

class VerifyEmployee extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      showcase: false,
      fileList: [],
      fileList2: [],
      shortstate: [],
      checkrad: false,
      rad_issues: [],
      currentpath:localStorage.getItem("currentpath") ?? "",
    };
  }

  onChange(value) {
    console.log(`selected ${value}`);
  }

  componentWillMount() {
    if (this.props.location.agent_info === undefined) {
      this.props.history.push(localStorage.getItem("currentpath") ?? "");
    } else {
      this.setState({ checkrad: this.props.location?.agent_info?.rad_pii_issues !== null ? "false":"true" });
      this.props
        .rad_pii_issue_status()
        .then((res) => {
          this.setState({
            rad_issues: res.data,
          });
        })
        .catch((err) => {
          this.setState({
            rad_issues: [],
          });
        });
      this.props.short_us_states().then((res) => {
        console.log(res);
        this.setState({
          shortstate: res.data,
        });
      });
      console.log(this.props.location.agent_info);
    }
  }

  disabledDOB(current) {
    return moment().add(0, "days") <= current;
  }
  disabledDOJ(current) {
    return moment().add(-1, "days") >= current;
  }

  mobilevalidation = (value) => {
    console.log(value);
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    if (value.test(phoneno)) {
      console.log(value.match(phoneno));
      this.props.form.setFieldsValue({
        MobileNo: value,
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
     
      if (!err) {
        console.log(values);
        this.setState({ loading: true, loadingSBTbtn: true });
        var obj_arr= {
          first_name: values?.first_name ?? "",
          last_name: values?.last_name ?? "",
          middle_name: values?.middle_name ?? "",
          street_address1: values?.address ?? "",
          street_address2: values?.street_address2 ?? "",
          city: values?.city ?? "",
          zip: values?.zip ?? "",
          state: values?.state ?? "",
          email: values.email,
          dob: moment(values?.dob ?? "").format("MM-DD-YYYY"),
         // ssn_number: values?.ssn_number ?? "",
          rad_id: values?.rad_id ?? "",
          shirt_size: values?.shirt_size ?? "",
          mobile_number: values.mobile_number.toString(),
          bgc_needed: values.bgc_needed === "true" ? 1 : 0,
          training_needed: values.training_needed === "true" ? 1 : 0,
          link_rad_needed: values.link_rad_needed === "true" ? 1 : 0,
          candidate_id: this.props.location.agent_info.id,
          rad_verification: values.is_rad_issue1 === "true" ? 1 : 0,
          //assign_telgoo_needed: values.assign_telgoo_needed === "true" ? 1 : 0,
          rad_pii_issues: values?.rad_pii_issues ?? [],
          telgoo_id: values?.telgoo_id?? "",
          gender_id: values?.gender_id ?? "",
        }       
       
        

        this.props
          .submitRadVerify(obj_arr)
          .then((res) => {
            console.log("res", res.data.message);
            message.success(res.data.message);
            this.setState({
              // visible1: false,
              loading: false,
              // loadingSBTbtn: false,
              // visibleedit: false,
              // activeedit: "",
            });
            // this.props.form.resetFields();
            // this.props.history.push("/OnboardingSummaryReport");
            this.props.history.push(localStorage.getItem("currentpath") ?? "");
            // this.getdesignation({
            //   results: 10,
            //   page: 1,
            // });
          })
          .catch((error) => {
            message.error(error);
            console.log(error);
            this.setState({ loading: false, loadingSBTbtn: false });
          });
      }
    });
  };
  renderMaster() {
    return _.map(this.state.master, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderDistributor() {
    return _.map(this.state.distributor, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      checkrad: e.target.value,
    });
  };
  render() {
    const { fileList, fileList2, uploading, superrole } = this.state;
    const propsthumb = {
      multiple: false,

      accept: ".jpg,.jpeg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    const propsthumb2 = {
      multiple: false,
      fileList: fileList2,
      accept: ".jpg,.jpeg",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file2) => {
        this.setState((state) => {
          const index = state.fileList2.indexOf(file2);
          const newFileList = state.fileList2.slice();
          newFileList.splice(index, 1);
          return {
            fileList2: newFileList,
          };
        });
        console.log(this.state.fileList2);
      },
      beforeUpload: (file2) => {
        console.log(file2);
        this.setState((state) => ({
          fileList2: [...state.fileList2, file2],
        }));
        return false;
      },
      fileList2,
    };
    // eslint-disable-next-line
    const mailformat = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    const { getFieldDecorator } = this.props.form;
    const { loading, showcase } = this.state;

    return (
      <Spin spinning={showcase}>
        <div className="container-fluid">
          <div class="row justify-content-between padd20 bg-grey">
            <div class="col-4">
              <h4 className="mt-1">Verify Candidate PII & RAD ID </h4>
            </div>
            <div class="col-4">
              <Link to={this.state.currentpath} className="float-right">
                <Button type="default">Back</Button>
              </Link>
            </div>
          </div>

          <div className=" bg-white padd15">
            <Form onSubmit={this.handleSubmit} className="addbookform row">
              <Form.Item
                label="First Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("first_name", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.first_name ?? "",
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input first name",
                    },
                  ],
                })(<Input placeholder="Enter First Name" />)}
              </Form.Item>
              <Form.Item
                label="Middle Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("middle_name", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.middle_name ?? "",
                  rules: [
                    {
                      required: false,
                      // pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input middle name",
                    },
                  ],
                })(<Input placeholder="Enter Middle Name" />)}
              </Form.Item>
              <Form.Item
                label="Last Name"
                className="col-md-4 col-sm-6 col-xs-12"
              >
                {getFieldDecorator("last_name", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.last_name ?? "",
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                      message: "Please input last name",
                    },
                  ],
                })(<Input placeholder="Enter Last Name" />)}
              </Form.Item>
              <Form.Item
                label="Email"
                className="col-md-4 col-sm-6 col-xs-12 mt-4 "
              >
                {getFieldDecorator("email", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.email ?? "",
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(mailformat),
                      message: "Enter valid email address",
                    },
                  ],
                })(
                  <Input
                    type="email"
                    placeholder="Enter Email Address"
                    autoComplete="new-password"
                    // onBlur={(e) => this.checkEmail(e.target.value)}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Mobile Number"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("mobile_number", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.mobile_number ?? "",
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]{10,10}$/),
                      message: "Please input mobile number",
                    },
                  ],
                })(
                  <InputNumber
                    placeholder="Enter Mobile Number"
                    minLength={10}
                    maxLength={10}
                    min={0}
                    step={0}
                  />
                )}
              </Form.Item>
              <Form.Item
                label="Date of birth"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("dob", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : moment(this.props.location?.agent_info?.dob ?? ""),
                  rules: [
                    {
                      required: true,
                      message: "Please input date of birth",
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: "100%" }}
                    format={"MM-DD-YYYY"}
                    placeholder="Date of Birth"
                    disabledDate={(current) =>
                      current.isAfter(moment().subtract(18, "year"))
                    }
                    showToday={false}
                  />
                )}
              </Form.Item>
            
              <Form.Item
                label="RAD ID"
                className="col-md-4 col-sm-6 col-xs-12 mt-4 "
              >
                {getFieldDecorator("rad_id", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.rad_id ?? "",
                  rules: [
                    {
                      pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                      required: false,
                      message: "Please enter Valid RAD ID",
                    },
                    // {
                    //   pattern: /^[A-Za-z0-9]+$/,
                    //   message: "Please enter Valid RAD ID",
                    // },
                  ],
                })(<Input maxLength={9} placeholder="Enter RAD ID" />)}
              </Form.Item>
              {this.state.checkrad === "true" && (   <Form.Item
                label="ETC Code"
                className="col-md-4 col-sm-6 col-xs-12 mt-4 "
              >
                {getFieldDecorator("telgoo_id", {
                  initialValue:
                  this.props.location.agent_info === undefined
                    ? ""
                    : this.props.location?.agent_info?.telgoo_id  ?? "",
                  rules: [{ required: true, message: "Please enter ETC code" }],
                })(<Input placeholder="Please enter ETC code" />)}
              </Form.Item>)}
              {/* {this.state.checkrad === "true" && (
                <Form.Item
                  label="SSN Number"
                  className="col-md-4 col-sm-6 col-xs-12 mt-4 "
                >
                  {getFieldDecorator("ssn_number", {
                    initialValue:
                      this.props.history.location.state?.agent_info ===
                      undefined
                        ? ""
                        : this.props.history.location.state?.agent_info
                            ?.masked_ssn_number ?? "",
                    rules: [
                      {
                        required: true,
                        pattern: new RegExp(
                          /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$|^(?!(000|666|9))\d{3}(?!00)\d{2}(?!0000)\d{4}$/
                        ),
                        message: "Please input valid SSN Number!",
                      },
                    ],
                  })(<Input placeholder="Please enter SSN number" />)}
                </Form.Item>
              )} */}
           
              <Form.Item
                label="Shirt Size"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("shirt_size", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.shirt_size ?? "",
                  rules: [
                    { required: true, message: "Please select shirt size" },
                  ],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select option"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="XS">XS</Option>
                    <Option value="S">S</Option>
                    <Option value="M">M</Option>
                    <Option value="L">L</Option>
                    <Option value="XL">XL</Option>
                    <Option value="XXL">XXL</Option>
                    <Option value="XXXL">XXXL</Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="Gender"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("gender_id", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.gender_id ?? "",
                  rules: [
                    { required: true, message: "Please select your gender" },
                  ],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select option"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                      <Radio value={1}>Male</Radio>
                              <Radio value={2}>Female</Radio>
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="Street Address"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("address", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.street_address1 ?? "",
                  rules: [{ required: true, message: "Please input Address" }],
                })(<Input placeholder="Address" />)}
              </Form.Item>
              <Form.Item
                label="Street Address2"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("street_address2", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.street_address2 ?? "",
                  rules: [{ required: false, message: "Please input 	Address" }],
                })(<Input placeholder="Address" />)}
              </Form.Item>
              <Form.Item
                label="City"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("city", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.city   ?? "",
                  rules: [{ required: true, message: "Please input 	City" }],
                })(<Input placeholder="City" />)}
              </Form.Item>
              <Form.Item
                label="State"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("state", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.state ?? [],
                  rules: [{ required: true, message: "Please input 	State" }],
                })(
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Select State"
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.renderstateshort()}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label="ZIP"
                className="col-md-4 col-sm-6 col-xs-12 mt-4"
              >
                {getFieldDecorator("zip", {
                  initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.zip ?? "",
                  rules: [
                    {
                      required: true,
                      pattern: new RegExp(/^[0-9]+$/),
                      message: "Please input 	ZIP",
                    },
                  ],
                })(<Input maxLength={5} placeholder="ZIP" />)}
              </Form.Item>
              <Form.Item
                label="Is PII & RAD ID Verified?"
                className="col-md-12 col-sm-12 col-xs-12 mt-4"
              >
                {getFieldDecorator("is_rad_issue1", {
                   initialValue:
                   this.props.location.agent_info === undefined
                     ? ""
                     : this.props.location?.agent_info?.rad_pii_issues !== null ? "false":"true",
                    
                  rules: [{ required: true, message: "Please select option" }],
                })(
                  <Radio.Group onChange={this.handleChange}>
                    <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                  </Radio.Group>
                )}
              </Form.Item>

              {this.state.checkrad === "false" && (
                <Form.Item
                  label="Please Check PII Issues"
                  className="col-md-12 col-sm-12 col-xs-12 mt-4"
                >
                  {getFieldDecorator("rad_pii_issues", {
                    initialValue:
                    this.props.location.agent_info === undefined
                      ? ""
                      : this.props.location?.agent_info?.rad_pii_issues
                      ?? [],
                   
                    rules: [
                      { required: true, message: "Please select option" },
                    ],
                  })(
                    <Checkbox.Group style={{ width: "100%", marginTop: 10 }}>
                      <Row>
                        {/* <Col span={8}>
                  <Checkbox value="A">A</Checkbox>
                </Col> */}
                        {this.state.rad_issues.map((data, index) => {
                          return (
                            <Col span={24} style={{ marginBottom: 10 }}>
                              <Checkbox value={data.id}>{data.name}</Checkbox>
                            </Col>
                          );
                        })}
                      </Row>
                    </Checkbox.Group>
                  )}
                </Form.Item>
              )}
               {this.state.checkrad === "true" && (
                <>
               <Form.Item
               label="Is BGC required?"
               className="col-md-4 col-sm-12 col-xs-12 mt-4"
             >
               {getFieldDecorator("bgc_needed", {
                 rules: [{ required: true, message: "Please select option" }],
               })(
                 <Radio.Group>
                   <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                 </Radio.Group>
               )}
             </Form.Item>
              <Form.Item
              label="Is Training required?"
              className="col-md-4 col-sm-12 col-xs-12 mt-4"
            >
              {getFieldDecorator("training_needed", {
                rules: [{ required: true, message: "Please select option" }],
              })(
                <Radio.Group>
                  <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item
              label="Is Link RAD ID required?"
              className="col-md-4 col-sm-12 col-xs-12 mt-4"
            >
              {getFieldDecorator("link_rad_needed", {
                rules: [{ required: true, message: "Please select option" }],
              })(
                <Radio.Group>
                  <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            {/* <Form.Item
              label="Is assign Telgoo ID required?"
              className="col-md-12 col-sm-12 col-xs-12 mt-4"
            >
              {getFieldDecorator("assign_telgoo_needed", {
                rules: [{ required: true, message: "Please select option" }],
              })(
                <Radio.Group>
                  <Radio value="true">Yes</Radio>
                    <Radio value="false">No</Radio>
                </Radio.Group>
              )}
            </Form.Item> */}
            </>
              )}
              <Form.Item
                label="Profile Picture"
                className="col-md-3 col-sm-12 col-xs-12 paddlr15 mt-4"
              >
                <ModalImage
                className="profile-pic"
                  small={this.props.location?.agent_info?.photo_copy_url ?? ""}
                  large={this.props.location?.agent_info?.photo_copy_url ?? ""}
                  alt="Profile Picture"
                />
              </Form.Item>
              <Form.Item
                label="Government ID"
                className="col-md-3 col-sm-12 col-xs-12 paddlr15 mt-4"
              >
                <ModalImage
                  className="profile-pic"
                  small={
                    this.props.location?.agent_info?.government_document_url ??
                    ""
                  }
                  large={
                    this.props.location?.agent_info?.government_document_url ??
                    ""
                  }
                  alt="Profile Picture"
                />
              </Form.Item>
              {this.props.location?.agent_info?.retailer_agent_agreement_url && (  <Form.Item
                label="Retailer Agreement"
                className="col-md-3 col-sm-12 col-xs-12 paddlr15 mt-4"
              >
                <a href={this.props.location?.agent_info?.retailer_agent_agreement_url} target="_blank" rel="noopener noreferrer">
                  <img
                    className="profile-pic"
                    src={document}
                    alt=""
                  />
                </a>
              </Form.Item>)}
            {this.props.location?.agent_info?.support_document_url && (   <Form.Item
                label="Support Document"
                className="col-md-3 col-sm-12 col-xs-12 paddlr15 mt-4"
              >
                 <a href={this.props.location?.agent_info?.support_document_url} target="_blank" rel="noopener noreferrer">
                  <img
                    className="profile-pic"
                    src={document}
                    alt=""
                  />
                </a>
              </Form.Item>)}
           
              <br />
              <br />
              <br />
              <br />
              <div className="col-md-12 col-sm-12 col-xs-12 padd15 mt-5">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                >
                  Update
                </Button>
                {/* <Link to="/OnboardingSummaryReport" style={{margin:10}}> */}
                <Link to={this.state.currentpath} style={{margin:10}}>
                <Button type="default">Cancel</Button>
              </Link>
              </div>
            </Form>
          </div>
        </div>
      </Spin>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user, data: state.data };
}
const WrappedEditEmployee = Form.create({ name: "VerifyEmployee" })(
  VerifyEmployee
);
export default connect(mapStateToProps, {
  getRolelist,
  getMarketerslist,
  getofficelistname,
  getGenderlist,
  editEmployee,
  getretailers,
  getdistributers,
  getmaster,
  getibomarketlist,
  submitRadVerify,
  short_us_states,
  rad_pii_issue_status,
})(WrappedEditEmployee);
