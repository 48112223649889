import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getinventoryallocationupload,
  getinventoryallocationuploadlist,
  getrerunjob,
} from "../../actions/inventoryAction";
import _ from "lodash";
import { Button, Table, message, Tag, Upload, Icon, Form, Modal } from "antd";

const { Dragger } = Upload;

class Inventoryupload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pagination: {},
      loading: true,
      uploading: false,
      fileList: [],
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
  }
  // componentDidMount() {
  //   const intervalId = setInterval(
  //     () =>
  //       this.getdesignation({
  //         results: 10,
  //         page: 1,
  //       }),
  //     5000
  //   );
  //   this.setState({ intervalId });
  //   //this.loadData(); // also load one immediately
  // }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }
  getdesignation(params = {}) {
    this.setState({
      loading:true,
    })
    this.props
      .getinventoryallocationuploadlist(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination) => {
    clearInterval(this.state.intervalId);
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
    });
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          uploading: true,
        });
        console.log("Received values of form: ", values);
        const { fileList } = this.state;
        console.log(fileList[0]);
        const formData = new FormData();
        fileList.forEach((file) => {
          formData.append("inventory_allocation_file", file);
        });
        console.log(formData);

        this.props
          .getinventoryallocationupload(formData)
          .then((res) => {
            this.props.form.resetFields();

            console.log(res);
            message.success(res.data.message);
            this.setState({
              uploading: false,
              fileList: [],
            });
            this.getdesignation({
              results: 10,
              page: 1,
            });
            const intervalId = setInterval(
              () =>
                this.getdesignation({
                  results: 10,
                  page: 1,
                }),
              5000
            );
            this.setState({ intervalId });
          })
          .catch((err) => {
            message.error("Something Went Wrong!");
            this.setState({
              uploading: false,
            });
          });
      }
    });
  };
  // handleUpload = () => {
  //   const { fileList } = this.state;
  //   const formData = new FormData();
  //   fileList.forEach((file) => {
  //     formData.append("files[]", file);
  //   });

  //   this.setState({
  //     uploading: true,
  //   });
  // };
  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      fileList: [],
    });
  };
  normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    console.log(this.state.fileList);
    return e && e.fileList;
  };
  handlererun = (e) => {
    console.log(e);
    Modal.confirm({
      title: "Warning",
      content: "Are you sure want to re-run this job?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        var obj_arr = {
          action: 1,
          job_id: e.id,
        };
        this.props
          .getrerunjob(obj_arr)
          .then((res) => {
            console.log(res);
            message.success(res.data.message);
            this.getdesignation({
              results: 10,
              page: 1,
            });
            const intervalId = setInterval(
              () =>
                this.getdesignation({
                  results: 10,
                  page: 1,
                }),
              5000
            );
            this.setState({ intervalId });
          })
          .catch((err) => {
            message.error("Something Went Wrong!");
            this.setState({
              loading: false,
            });
          });
      },
      onCancel: () => {
        console.log(`Ok`);
      },
    });
  };

  // ======================================================

  render() {
    const { getFieldDecorator } = this.props.form;
    const { fileList, uploading } = this.state;
    const propsthumb = {
      multiple: false,

      accept: ".xlsx",
      showUploadList: { showPreviewIcon: false, showRemoveIcon: true },
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
        // console.log(this.state.fileList);
      },
      beforeUpload: (file) => {
        console.log(file);
        this.setState((state) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const columns = [
      {
        title: "S#No",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "File",
        dataIndex: "original_file_name",
        key: "original_file_name",
        render: (text, record) => (
          <span>
            <a href={record.aws_file_path} target="_blank" rel="noreferrer">
              <Tag color="orange">{record.file_path}</Tag>
            </a>
          </span>
        ),
      },
      {
        title: "Uploaded Inventory Count",
        dataIndex: "uploaded_inventory_count",
        key: "uploaded_inventory_count",
      },
      {
        title: "Allocated Inventory Count ",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (text, record) => (
          <span>
            <Tag color={record.status === 1 ? "green" : "red"}>
              {record.status_text}
            </Tag>
          </span>
        ),
      },
      {
        title: "Error Description",
        dataIndex: "error",
        key: "error",
      },

      {
        title: "Error Log File",
        dataIndex: "aws_error_log_file_path",
        key: "aws_error_log_file_path",
        render: (text, record) => (
          <span>
            {record.aws_error_log_file_path !== null ? (
              <a
                href={record.aws_error_log_file_path}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="default">View</Button>
              </a>
            ) : null}
          </span>
        ),
      },

      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        render: (text, record) => (
          <span>
            {record.rerun_job === 1 ? (
              <Button type="primary" onClick={() => this.handlererun(record)}>
                Re-Run Job
              </Button>
            ) : (
              <Button disabled type="primary">
                Re-Run Job
              </Button>
            )}
          </span>
        ),
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Inventory Allocation{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
          </div>
          <div className="text-right">
            <a
              href="https://wieserp.s3.amazonaws.com/Sample+Template/Inventory_Allocation_Template.xlsx"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                icon="file-excel"
                type="default"
                className="samplefilebtn"
              >
                Sample Template
              </Button>
            </a>
          </div>
          <Form onSubmit={this.handleSubmit}>
            <Form.Item label="Upload Inventory">
              {getFieldDecorator("file", {
                rules: [
                  {
                    required: true,
                    message: "Please select file",
                  },
                ],
              })(
                <Dragger {...propsthumb}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from
                    uploading company data or other band files
                  </p>
                </Dragger>
              )}
            </Form.Item>

            <Form.Item>
              <Button  className="mt-3" type="primary" htmlType="submit" loading={uploading}>
                Upload
              </Button>
              <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                Clear
              </Button>
            </Form.Item>
          </Form>

          <div className="bg-white padd0">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

const WrappedHorizontalLoginForm = Form.create({ name: "horizontal_login" })(
  Inventoryupload
);

export default connect(mapStateToProps, {
  getinventoryallocationupload,
  getinventoryallocationuploadlist,
  getrerunjob,
})(WrappedHorizontalLoginForm);
