import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  detailed_sales_report_ibo,
  detailed_sales_report_ibo_search,
} from "../../actions/salesAction";
import { detailed_sales_export_ibo_marketer } from "../../actions/exportAction";
import _ from "lodash";
import { Button, Table, Select, Tag, message, Spin } from "antd";
import moment from "moment";
import { getibomarketlist } from "../../actions/assetAction";
import { getApiPath } from "../inventory/constants";
import { Line } from "peity-react";

const { Option } = Select;

class SalesMarketSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      btnLoading: false,
      loadingEPbtn:false,
      retailer: [],
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      inventorystatus: [],
      search_text: "",
      datefilter: [],
      startdate: "",
      enddate: "",
      dashdata: "",
      retailer_id:""
    };
  }

  componentWillMount() {
    const urlString = this.props.location.urlpath;
    console.log(this.props.location.urlpath);
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
    });
    var retailer_id = this.props.location.retailer_id ?? []
    this.setState({
      selectedfieldname: paramsobj["sort"] ?? [],
      selectedfilter: paramsobj["sort_by"] ?? [],
      rolepath: getApiPath(),
      retailer_id: this.props.location.retailer_id ?? ""
    });
    console.log(paramsobj)
    this.getdesignation({
      results: 10,
      page: 1,
      retailer_id:retailer_id ?? ""
    });
    // this.setColumns();
    this.props.getibomarketlist().then((res) => {
      console.log(res.data);
      this.setState({
        marketer: res.data,
      });
    });
  }
 
  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .detailed_sales_report_ibo(params)
      .then((res) => {
        this.setData(res.data);
        this.setState({
          dashdata: res.data.grand_totals,
        });
        // const pagination = { ...this.state.pagination };
        // pagination.total = res.data.items.total;
        // this.setState({
        //   data: res.data.items.data,
        //   loading: false,
        //   pagination,
        // });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };

  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };
  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.columns, (master, index) => {
      return (
        <Option value={master.key} key={index}>
          {master.title}
        </Option>
      );
    });
  }

  renderOffice() {
    return _.map(this.state.marketer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      retailer_id:""
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true, btnLoading: true });
    var market = this.state.selectedItems;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    this.props
      .detailed_sales_report_ibo_search({
        retailer_id: reset ? "" : this.state.retailer_id,
        market: market,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        this.setData(res.data);
        this.setState({
          dashdata: res.data.grand_totals,
          btnLoading: false,
          loading: false,
        });
        // const pagination = { ...this.state.pagination };
        // pagination.total = res.data.items.total;
        // pagination.current = res.data.items.current_page;
        // this.setState({
        //   data: res.data.items.data,
        //   loading: false,
        //   pagination,
        // });
      })
      .catch((err) => {
        this.setState({
          btnLoading: false,
          loading: false,
        });
      });
  };

  handleChange = (datefilter) => {
    console.log(datefilter);
    this.setState({
      datefilter,
      startdate: moment(datefilter[0]).format("MM/DD/YYYY"),
      enddate: moment(datefilter[1]).format("MM/DD/YYYY"),
    });
  };
  getexportbtn = () => {
    var market = this.state.selectedItems;
    this.setState({ loading: true, loadingEPbtn:true });
    this.props
      .detailed_sales_export_ibo_marketer({ market: market })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn:false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn:false });
      });
  };

  setData = (resData) => {
    const pagination = { ...this.state.pagination };
    pagination.total = resData.items.total;
    pagination.current = resData.items.current_page;
    this.setState({
      data: resData.items.data,
      loading: false,
      pagination,
    });
  };
  // ======================================================

  render() {
    const { datefilter, dashdata } = this.state;
    const columns = [
      {
        title: "IBOs / Markets",
        dataIndex: "ibo_market_name",
        key: "ibo_market_name",
        sorter: true,
        render: (name, record) => (
          <Link
            to={{
              pathname: "/salesofficeview",
              ibo_market_id: record.ibo_market_id,
              ibomarket: record,
            }}
          >
            {name}
          </Link>
        ),
      },
      {
        title: "Today",
        dataIndex: "today",
        key: "today",
        sorter: true,
      },
      {
        title: "WTD",
        dataIndex: "WTD",
        key: "WTD",
        sorter: true,
      },
      {
        title: "MTD",
        dataIndex: "MTD",
        key: "MTD",
        sorter: true,
      },
      {
        title: "7 Days",
        dataIndex: "last_7_days",
        key: "last_7_days",
        sorter: true,
      },
      {
        title: "30 Days",
        dataIndex: "last_30_days",
        key: "last_30_days",
        sorter: true,
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                {" "}
                By IBOs/Markets{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <div className="row">
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">Daily Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.today ?? 0}</h4>
                    <Line height={14} values={[5, 10]} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">WTD Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.WTD ?? 0}</h4>
                    <Line height={14} values={[5, 3, 9, 6, 5, 9, 15]} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">MTD Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.MTD ?? 0}</h4>
                    <Line height={14} values={[5, 3, 9, 6, 5, 9, 7, 3, 5, 2]} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">7 Days Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.last_7_days ?? 0}</h4>
                    <Line
                      height={14}
                      values={[9, 6, 5, 9, 5, 3, 2]}
                      fill={"#fff"}
                      stroke={"#ff000d"}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">30 Days Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.last_30_days ?? 0}</h4>
                    <Line
                      height={14}
                      values={[9, 6, 5, 9, 5, 3, 2]}
                      fill={"#fff"}
                      stroke={"#ff000d"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <div className="row padd20 bg-grey">
            <div className="col-4">
              <label for="exampleFormControlInput1">IBOs / Markets</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderOffice()}
              </Select>
            </div>
            {/* <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select field name"
                value={this.state.selectedfieldname}
                onChange={this.onchangefieldname}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderSorter()}
              </Select>
            </div>
            <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select filter order"
                value={this.state.selectedfilter}
                onChange={this.onchangefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="ASC">ASC</Option>
                <Option value="DESC">DESC</Option>
              </Select>
            </div> */}
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.btnLoading}
                onClick={() => this.searchMarketer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0 mt-4   ">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  detailed_sales_report_ibo,
  getibomarketlist,
  detailed_sales_report_ibo_search,
  detailed_sales_export_ibo_marketer,
})(SalesMarketSummary);
