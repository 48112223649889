import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Inventoryretail } from "../../actions/inventoryAction";
import {
  getsortlistinventory,
  getretailers,
  searchinventorysummary,
} from "../../actions/assetAction";
import { inventory_summary_retailer } from "../../actions/exportAction";
import _ from "lodash";
import { Button, Table, Select, Spin, message } from "antd";
import { getApiPath } from "./constants";

const { Option } = Select;

class InventoryByRetailer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn:false,
      loadingEPbtn:false,
      retailer: [],
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      rolepath: {},
      inventory_stats: [],
    };
  }

  componentWillMount() {
    this.props.getsortlistinventory().then((res) => {
      console.log(res.data);
      this.setState({
        sort_list: res.data,
        rolepath: getApiPath(),
      });
    });

    this.props.getretailers().then((res) => {
      this.setState({
        retailer: this.props.data.retailer,
      });
    });

    const urlString = this.props.location.urlpath;
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      paramsobj[element[0]] = element[1];
    });
    this.setState({
      selectedfieldname: paramsobj["sort"] ?? [],
      selectedfilter: paramsobj["sort_by"] ?? [],
    });
    this.getdesignation({
      url: this.props.location.urlpath ?? "",
      results: 10,
      page: 1,
    });
  }

  getdesignation(params = {}) {
    this.props
      .Inventoryretail(params)
      .then((res) => {
        console.log("dash response", res.data);
        this.setData(res.data);
        this.setState({
          inventory_stats: res.data.grand_totals,
          // loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true , loadingbtn:true});
    var retailer = this.state.selectedItems;
    console.log("retailer", retailer);
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;

    this.props
      .searchinventorysummary({
        retailer: retailer,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log(res);
        this.setData(res.data);
        this.setState({
          inventory_stats: res.data.grand_totals,
          loadingbtn:false,
        });
      })
      .catch((err) => {
        this.setState({loadingbtn:false})
      });
  };

  setData = (resData) => {
    const pagination = { ...this.state.pagination };
    pagination.total = resData.items.total;
    pagination.current = resData.items.current_page;

    this.setState({
      data: resData.items.data,
      loading: false,
      pagination,
    });
  };

  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.sort_list, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderRetailer() {
    return _.map(this.state.retailer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  getexportbtn = () => {
    var retailer = this.state.selectedItems;
    this.setState({ loading: true, loadingEPbtn:true });
    this.props
      .inventory_summary_retailer({ retailer })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn:false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn:false });
      });
  };
  // ======================================================

  render() {
    const { inventory_stats, rolepath } = this.state;
    const columns = [
      {
        title: "Retailer",
        dataIndex: "retailer_name",
        key: "retailer_name",
        sorter: true,
        render: (name, record) => (
          <Link
            to={{
              pathname: "/inventoryretailerview",
              retailer_id: record.retailer_id,
              retailer:record
            }}
          >
            {name}
          </Link>
        ),
      },
      {
        title: "In-Stock",
        dataIndex: "in_stock",
        key: "in_stock",
        sorter: true,
      },
      {
        title: "Sold(Last 30 days)",
        dataIndex: "sold_30_days",
        key: "sold_30_days",
        sorter: true,
      },
      {
        title: "Manager Pending",
        dataIndex: "manager_pending",
        key: "manager_pending",
        sorter: true,
      },
      {
        title: "Manager Received",
        dataIndex: "manager_assigned",
        key: "manager_assigned",
        sorter: true,
      },
      {
        title: "Agent Pending",
        dataIndex: "agent_pending",
        key: "agent_pending",
        sorter: true,
      },
      {
        title: "Agent Transfer",
        dataIndex: "agent_transfer",
        key: "agent_transfer",
        sorter: true,
      },
      {
        title: "Agent Received",
        dataIndex: "agent_assigned",
        key: "agent_assigned",
        sorter: true,
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                By Retailers{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
              <div className="col">
                <div className="card radius-10 border-start border-0 border-3 border-info">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="mb-0 text-secondary">In-Stock</p>
                        <h3 className="my-1 text-info">
                          {inventory_stats.in_stock}
                        </h3>
                        <p className="mb-0 font-13 visible-none">
                          +2.5% from last week
                        </p>
                      </div>
                      <div className="widgets-icons-2 rounded-circle bg-gradient-scooter text-white ms-auto">
                        <i className="fa fa-cubes"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 border-start border-0 border-3 border-danger">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="mb-0 text-secondary">Sold</p>
                        <h3 className="my-1 text-danger">
                          {inventory_stats.sold_30_days}
                        </h3>
                        <p className="mb-0 font-13 visible-none">
                          +2.5% from last week
                        </p>
                      </div>
                      <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                        <i className="fa fa-mobile font-55"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 border-start border-0 border-3 border-danger">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="mb-0 text-secondary">Manager Pending</p>
                        <h3 className="my-1 text-danger">
                          {" "}
                          {inventory_stats.manager_pending}
                        </h3>
                        <p className="mb-0 font-13 visible-none">
                          +5.4% from last week
                        </p>
                      </div>
                      <div className="widgets-icons-2 rounded-circle bg-gradient-bloody text-white ms-auto">
                        <i className="fa fa-clock-o font-55"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 border-start border-0 border-3 border-success">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="mb-0 text-secondary">Manager Received</p>
                        <h3 className="my-1 text-success">
                          {" "}
                          {inventory_stats.manager_assigned}
                        </h3>
                        <p className="mb-0 font-13 visible-none">
                          -4.5% from last week
                        </p>
                      </div>
                      <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                        <i className="fa fa-cubes font-55"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 border-start border-0 border-3 border-success">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="mb-0 text-secondary">Agent Pending</p>
                        <h3 className="my-1 text-success">
                          {inventory_stats.agent_pending}
                        </h3>
                        <p className="mb-0 font-13 visible-none">
                          -4.5% from last week
                        </p>
                      </div>
                      <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                        <i className="fa fa-cube"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 border-start border-0 border-3 border-success">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="mb-0 text-secondary">Agent Transfer</p>
                        <h3 className="my-1 text-success">
                          {inventory_stats.agent_transfer}
                        </h3>
                        <p className="mb-0 font-13 visible-none">
                          -4.5% from last week
                        </p>
                      </div>
                      <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                        <i className="fa fa-history font-55"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card radius-10 border-start border-0 border-3 border-success">
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="mb-0 text-secondary">Agent Received</p>
                        <h3 className="my-1 text-success">
                          {inventory_stats.agent_assigned}
                        </h3>
                        <p className="mb-0 font-13 visible-none">
                          -4.5% from last week
                        </p>
                      </div>
                      <div className="widgets-icons-2 rounded-circle bg-gradient-ohhappiness text-white ms-auto">
                        <i className="fa fa-cubes font-55"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <div className="row padd20 bg-grey">
            <div className="col-4">
              <label for="exampleFormControlInput1">Search Retailer</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderRetailer()}
              </Select>
            </div>

            {/* <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select field name"
                value={this.state.selectedfieldname}
                onChange={this.onchangefieldname}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderSorter()}
              </Select>
            </div>
            <div class="col">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select filter order"
                value={this.state.selectedfilter}
                onChange={this.onchangefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="ASC">ASC</Option>
                <Option value="DESC">DESC</Option>
              </Select>
            </div> */}
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchMarketer("reset")}
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              rowKey={Math.random().toString()}
              columns={columns}
              dataSource={this.state.data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  Inventoryretail,
  getsortlistinventory,
  getretailers,
  searchinventorysummary,
  inventory_summary_retailer,
})(InventoryByRetailer);
