import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Select,
  Button,
  Input,
  Form,
  message,
  Checkbox,
  InputNumber,
  Radio,
} from "antd";
// import moment from "moment";
import _ from "lodash";
import {} from "../../actions/assetAction";
const { Option } = Select;
const RadioGroup = Radio.Group;

class EditCustomer extends Component {
  constructor(props) {
    super(props);
    // state
    this.state = {
      hubs: [],
      designation: [],
      fileList: [],
      loading: false,
      iscitclient:
        this.props.location.customer === undefined
          ? ""
          : this.props.location.customer.IsCITClient,
      pincodestatus: "",
      Services: [],
      Access: [],
      emailexists: true,
    };
  }

  onChange = (e) => {
    console.log(e.target.checked);
    this.setState({
      iscitclient: e.target.checked,
    });
  };

  componentWillMount() {
    // console.log(this.props.user.uid);
    console.log(this.props.location.customer);
    this.props.getHub().then((res) => {
      this.setState({
        hubs: res,
      });
    });
    this.props.getDesignations().then((res) => {
      this.setState({
        designation: res,
      });
    });
    this.props.getServices().then((res) => {
      console.log(res);
      this.setState({
        Services: res,
      });
    });
    this.props.getAccess().then((res) => {
      console.log(res);
      this.setState({
        Access: res,
      });
    });
  }

  pincodechange = (e) => {
    // console.log(e);
    var pin = e.target.value;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (reg.test(pin) && pin.length === 6) {
      this.setState({
        pincodestatus: "validating",
      });
      this.props
        .checkPincode(pin)
        .then((res) => {
          var pinfo = res.info[0].info;
          console.log(pinfo);
          this.props.form.setFieldsValue({
            stateCode: pinfo.state,
            cityCode:
              pinfo.city === "" ||
              pinfo.city === undefined ||
              pinfo.city === null
                ? pinfo.district
                : pinfo.city,
          });
          this.setState({
            pincodestatus: "success",
          });
        })
        .catch((err) => {
          message.error("This Pincode is not under our serviceable area!");
          this.setState({
            pincodestatus: "error",
          });
          this.props.form.setFieldsValue({
            stateCode: "",
            cityCode: "",
          });
        });
    } else {
      this.setState({
        pincodestatus: "error",
      });
    }
  };

  onChangeRangeFrom = (e) => {
    const {
      form: { validateFields, setFields },
    } = this.props;
    setTimeout(() => {
      validateFields(["docketRangefrom", "docketRangeto"], (errors, values) => {
        console.log(errors, values);
        if (
          values.docketRangeto < values.docketRangefrom &&
          values.docketRangeto !== undefined
        ) {
          setFields({
            docketRangeto: {
              value: "",
              errors: [new Error("Select valid date")],
            },
          });
        }
      });
    }, 100);
  };
  onChangeRangeTo = (e) => {
    const {
      form: { validateFields, setFields },
    } = this.props;
    setTimeout(() => {
      validateFields(["docketRangefrom", "docketRangeto"], (errors, values) => {
        console.log(errors, values);
        if (values.docketRangeto < values.docketRangefrom) {
          setFields({
            docketRangeto: {
              value: "",
              errors: [new Error("Select valid date")],
            },
          });
        }
      });
    }, 100);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (this.props.location.customer.Email === values.Email) {
          this.setState({
            loading: true,
          });
          console.log("Received values of form: ", values, this.props.user.uid);
          this.props
            .updateCustomer(
              values,
              this.props.location.customer.key,
              this.props.user.uid
            )
            .then((res) => {
              message.success("Customer updated successfully!");
              this.props.history.push("/customers");
              this.props.form.resetFields();
              this.setState({
                loading: false,
              });
            })
            .catch((res) => {
              message.error(res.Message);
              this.setState({
                loading: false,
              });
            });
        } else {
          this.props
            .checkEmail(values.Email)
            .then((res) => {
              this.props.form.setFields({
                Email: {
                  value: values.Email,
                  errors: [new Error("Email already exists!")],
                },
              });
              return false;
            })
            .catch((err) => {
              //  updateEmployee(values)
              this.setState({
                loading: true,
              });
              console.log(
                "Received values of form: ",
                values,
                this.props.user.uid
              );
              this.props
                .updateCustomer(
                  values,
                  this.props.location.customer.key,
                  this.props.user.uid
                )
                .then((res) => {
                  message.success("Customer updated successfully!");
                  this.props.history.push("/customers");
                  this.props.form.resetFields();
                  this.setState({
                    loading: false,
                  });
                })
                .catch((res) => {
                  message.error(res.Message);
                  this.setState({
                    loading: false,
                  });
                });
            });
        }
      }
    });
  };

  checkEmail(email) {
    console.log(email);
    if (this.props.location.customer.Email === email) {
      this.setState({
        emailexists: true,
      });
    } else {
      this.props
        .checkEmail(email)
        .then((res) => {
          this.props.form.setFields({
            Email: {
              value: email,
              errors: [new Error("Email already exists!")],
            },
          });
          this.setState({
            emailexists: false,
          });
        })
        .catch((err) => {
          this.setState({
            emailexists: true,
          });
        });
    }
  }

  renderHubs() {
    return _.map(this.state.hubs, (hub, index) => {
      return (
        <Option value={hub.hubCode} key={index}>
          {hub.hubName}
        </Option>
      );
    });
  }

  renderServices() {
    return _.map(this.state.Services, (cus, index) => {
      return (
        <Option value={cus.serviceId} label={cus.serviceId} key={index}>
          {/* {cus.serviceId} -  */}
          {cus.Name}
        </Option>
      );
    });
  }

  renderDesignations() {
    return _.map(this.state.designation, (des, index) => {
      return (
        <Option value={des.desgId} key={index}>
          {des.desgRole}
        </Option>
      );
    });
  }

  renderAccess() {
    return _.map(this.state.Access, (cus, index) => {
      return (
        <Option value={cus.id} label={cus.Name} key={index}>
          {cus.Name}
        </Option>
      );
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading, iscitclient } = this.state;
    // eslint-disable-next-line
    const mailformat = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return (
      <div className="container-fluid">
        <div className="row padd20 bg-grey">
          <div className="col-xs-6">
            <h4>Edit Customer</h4>
          </div>
          <div className="col-xs-6 text-right">
            <Link to="/customers">
              <Button type="default">Back</Button>
            </Link>
          </div>
        </div>

        <div className="customerform bg-white">
          <Form onSubmit={this.handleSubmit} className="addbookform row">
            <Form.Item className="col-sm-12 col-xs-12 padd15 bg-beige">
              <h5>Customer Info</h5>
            </Form.Item>
            <Form.Item
              label="Customer Name"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("CompanyName", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.CompanyName,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                    message: "Please enter valid Customer Name!",
                  },
                ],
              })(<Input placeholder="Enter Customer Name" />)}
            </Form.Item>
            <Form.Item
              label="Product List"
              className="col-md-6 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("CompanyType", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.CompanyType,
                rules: [
                  {
                    required: true,
                    message: "Please input Product List!",
                  },
                ],
              })(
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Product List"
                  maxTagCount={2}
                  // optionFilterProp="children"
                  // optionLabelProp="label"
                  // filterOption={(input, option) =>
                  //     option.props.children
                  //         .toLowerCase()
                  //         .indexOf(input.toLowerCase()) >= 0
                  // }
                  // onChange={this.onChange}
                >
                  {this.renderServices()}
                </Select>
              )}
            </Form.Item>

            <Form.Item label="Email" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("Email", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.Email,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(mailformat),
                    message: "Please enter valid Email!",
                  },
                ],
              })(
                <Input
                  type="email"
                  placeholder="Enter Email"
                  autoComplete="new-password"
                  onBlur={(e) => this.checkEmail(e.target.value)}
                />
              )}
            </Form.Item>
            <Form.Item label="Address" className="col-md-3 col-sm-6 col-xs-12">
              {getFieldDecorator("Address", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.Address,
                rules: [
                  {
                    required: true,
                    message: "Please input Address!",
                  },
                ],
              })(<Input placeholder="Enter Address" />)}
            </Form.Item>
            <Form.Item
              label="Pincode"
              className="col-md-3 col-sm-4 col-xs-12"
              hasFeedback
              validateStatus={this.state.pincodestatus}
            >
              {getFieldDecorator("Pincode", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.Pincode,
                rules: [
                  {
                    required: false,
                    message: "Please input Pincode!",
                  },
                ],
              })(
                <Input
                  placeholder="Enter Pincode"
                  onChange={this.pincodechange}
                  maxLength={6}
                  minLength={6}
                  autoComplete="new-password"
                />
              )}
            </Form.Item>
            <Form.Item label="City" className="col-md-3 col-sm-4 col-xs-12">
              {getFieldDecorator("cityCode", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.cityCode,
                rules: [
                  {
                    required: true,
                    message: "Please input City Code!",
                  },
                ],
              })(<Input placeholder="Enter City Code" readOnly />)}
            </Form.Item>

            <Form.Item label="State" className="col-md-3 col-sm-4 col-xs-12">
              {getFieldDecorator("stateCode", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.stateCode,
                rules: [
                  {
                    required: true,
                    message: "Please input State!",
                  },
                ],
              })(<Input placeholder="Enter State" readOnly />)}
            </Form.Item>
            <Form.Item
              label="Mobile No"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("MobileNo", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.MobileNo,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,10}$/),
                    message: "Please enter valid MobileNo!",
                  },
                ],
              })(
                <InputNumber
                  placeholder="Enter MobileNo"
                  minLength={10}
                  maxLength={10}
                  min={0}
                  step={0}
                  autoComplete="new-password"
                />
              )}
            </Form.Item>
            <Form.Item
              label="PAN number"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("PANnumber", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.PANnumber,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-z0-9]+$/i),
                    message: "Please enter valid PANnumber!",
                  },
                ],
              })(<Input placeholder="Enter PANnumber" />)}
            </Form.Item>
            <Form.Item
              label="GST Number"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("GSTnumber", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.GSTnumber,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-z0-9]+$/i),
                    message: "Please enter valid GSTnumber!",
                  },
                ],
              })(<Input placeholder="Enter GSTnumber" />)}
            </Form.Item>

            <Form.Item
              label="Contact person name"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("contactpersonname", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.contactpersonname,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[a-zA-Z .]{2,30}$/),
                    message: "Please enter valid name!",
                  },
                ],
              })(<Input placeholder="Enter Contact person name" />)}
            </Form.Item>

            <Form.Item
              label="Contact person Email"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("contactpersonemail", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.contactpersonemail,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(mailformat),
                    message: "Please enter valid Contact person Email!",
                  },
                ],
              })(<Input placeholder="Enter Contact person email" />)}
            </Form.Item>
            <Form.Item
              label="Contact person Mobile"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("contactpersonmobile", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.contactpersonmobile,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{10,10}$/),
                    message: "Please input first name!",
                  },
                ],
              })(
                <InputNumber
                  placeholder="Enter Contact Person Number"
                  minLength={10}
                  maxLength={10}
                  min={0}
                  step={0}
                  autoComplete="new-password"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Is CIT Customer"
              className="col-md-2 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("IsCITClient", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.IsCITClient,
                valuePropName: "checked",
                rules: [
                  {
                    required: false,
                    message: "Please input Is CIT Customer!",
                  },
                ],
              })(<Checkbox onChange={this.onChange} />)}
            </Form.Item>
            {iscitclient ? (
              <div>
                <Form.Item
                  label="Docket Range From"
                  className="col-md-2 col-sm-3 col-xs-12 paddlr15"
                >
                  {getFieldDecorator("docketRangefrom", {
                    initialValue:
                      this.props.location.customer === undefined
                        ? ""
                        : this.props.location.customer.docketRangefrom,

                    rules: [
                      {
                        required: true,
                        message: "Please input From!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter From"
                      onChange={this.onChangeRangeFrom}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  label="To"
                  className="col-md-2 col-sm-3 col-xs-12 paddlr15"
                >
                  {getFieldDecorator("docketRangeto", {
                    initialValue:
                      this.props.location.customer === undefined
                        ? ""
                        : this.props.location.customer.docketRangeto,

                    rules: [
                      {
                        required: true,
                        message: "Please input To!",
                      },
                    ],
                  })(
                    <InputNumber
                      placeholder="Enter To"
                      onChange={this.onChangeRangeTo}
                    />
                  )}
                </Form.Item>
              </div>
            ) : null}

            <Form.Item className="col-sm-12 col-xs-12 padd15 bg-beige">
              <h5>AWB details</h5>
            </Form.Item>
            <Form.Item
              label="Max value allowed per AWB"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("ConnoteMaxvalue", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.ConnoteMaxvalue,
                rules: [
                  {
                    required: true,
                    message: "Please input AWB Maxvalue!",
                  },
                ],
              })(<InputNumber placeholder="Enter AWB Maxvalue" />)}
            </Form.Item>
            <Form.Item
              label="Lock Number Entry"
              className="col-md-4 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("Locknumberentrymandatory", {
                // initialValue: this.props.location.customer === undefined ? '' : this.props.location.customer.Locknumberentrymandatory,
                initialValue:
                  this.props.location.customer !== undefined &&
                  this.props.location.customer.Locknumberentrymandatory
                    ? "true"
                    : "false",
                rules: [
                  {
                    required: false,
                    message: "Please input Is Lock number entry mandatory!",
                  },
                ],
              })(
                <RadioGroup>
                  <Radio value="true">True</Radio>
                  <Radio value="false">False</Radio>
                </RadioGroup>
              )}
            </Form.Item>
            <Form.Item
              label="Email alert required to be sent on AWB creation/delivery?"
              className="col-md-4 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsEmailalert", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.IsEmailalert,
                valuePropName: "checked",
                rules: [
                  {
                    required: false,
                    message: "Please input IsEmailalert",
                  },
                ],
              })(<Checkbox />)}
            </Form.Item>
            <Form.Item
              label="SMS alert required to be sent on AWB creation/delivery?"
              className="col-md-6 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsSMSalert", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.IsSMSalert,
                valuePropName: "checked",
                rules: [
                  {
                    required: false,
                    message: "Please input SMSalert!",
                  },
                ],
              })(<Checkbox />)}
            </Form.Item>
            <Form.Item
              label="Is box number auto generation required?"
              className="col-md-6 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsboxnumAutoenerate", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.IsboxnumAutoenerate,
                valuePropName: "checked",
                rules: [
                  {
                    required: false,
                    message: "Please input Is auto generation required?",
                  },
                ],
              })(<Checkbox />)}
            </Form.Item>
            <Form.Item className="col-sm-12 col-xs-12 padd15 bg-beige">
              <h5>Preferences</h5>
            </Form.Item>
            <Form.Item
              label="Is Active"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsActive", {
                initialValue:
                  this.props.location.customer !== undefined &&
                  this.props.location.customer.IsActive
                    ? "true"
                    : "false",
                rules: [
                  {
                    required: true,
                    message: "Please input Is Active!",
                  },
                ],
              })(
                <RadioGroup>
                  <Radio value="true">Active</Radio>
                  <Radio value="false">Inactive</Radio>
                </RadioGroup>
              )}
            </Form.Item>

            <Form.Item
              label="Is Reverse Shipment"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("IsReverseShipment", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.IsReverseShipment,
                valuePropName: "checked",
                rules: [
                  {
                    required: false,
                    message: "Please input Is Reverse Shipment!",
                  },
                ],
              })(<Checkbox />)}
            </Form.Item>
            <Form.Item
              label="COD FLag"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("CODFLag", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.CODFLag,
                valuePropName: "checked",
                rules: [
                  {
                    required: false,
                    message: "Please input COD FLag!",
                  },
                ],
              })(<Checkbox />)}
            </Form.Item>
            <Form.Item
              label="OTP Flag"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("OTPFlag", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.OTPFlag,
                valuePropName: "checked",
                rules: [
                  {
                    required: false,
                    message: "Please input OTP Flag!",
                  },
                ],
              })(<Checkbox />)}
            </Form.Item>

            <Form.Item
              label="CFT Division value"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("CFTDivisionvalue", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.CFTDivisionvalue,
                rules: [
                  {
                    required: true,
                    pattern: new RegExp(/^[0-9]{1,1000}$/),
                    message: "Please enter valid Division value!",
                  },
                ],
              })(<InputNumber placeholder="Enter CFT Division value" />)}
            </Form.Item>
            <Form.Item
              label="CFT Division type"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("CFTDivisiontype", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.CFTDivisiontype,
                rules: [
                  {
                    required: false,
                    pattern: new RegExp(/^[0-9]{1,1000}$/),
                    message: "Please enter valid CFT Division type!",
                  },
                ],
              })(<Input placeholder="Enter CFT Division type" />)}
            </Form.Item>
            <Form.Item
              label="Customer API"
              className="col-md-3 col-sm-6 col-xs-12 paddlr15"
            >
              {getFieldDecorator("ClientAPI", {
                initialValue:
                  this.props.location.customer === undefined
                    ? ""
                    : this.props.location.customer.ClientAPI,
                rules: [
                  {
                    required: false,
                    message: "Please input Customer API!",
                  },
                ],
              })(<Input placeholder="Enter Customer API" />)}
            </Form.Item>
            <Form.Item
              label="Customer Type"
              className="col-md-3 col-sm-4 col-xs-12"
            >
              {getFieldDecorator("clientType", {
                rules: [
                  {
                    required: false,
                    message: "Please select Consignee Type..!",
                  },
                ],
              })(
                <Select
                  placeholder="select Customer Type"
                  style={{ width: "100%" }}
                  // onChange={this.handleChange}
                >
                  <Option value="B2B">B2B</Option>
                  <Option value="B2C">B2C</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
              label="Menu Access"
              className="col-md-3 col-sm-6 col-xs-12"
            >
              {getFieldDecorator("MenuAccess", {
                initialValue:
                  this.props.location.customer?.MenuAccess === undefined
                    ? [3]
                    : this.props.location.customer.MenuAccess,
                rules: [
                  {
                    required: false,
                    message: "Please input Menu Access!",
                  },
                ],
              })(
                <Select
                  mode="multiple"
                  style={{ width: "100%" }}
                  placeholder="Select Menu Access"
                  optionFilterProp="children"
                  optionLabelProp="label"
                  // filterOption={(input, option) =>
                  //   option.props.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                  // onChange={this.onChange}
                >
                  {this.renderAccess()}
                </Select>
              )}
            </Form.Item>
            <div className="padd15 col-xs-12">
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Update Customer
              </Button>
              <br />
              <br />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return { user: state.user };
}
const WrappedEditCustomer = Form.create({ name: "EditCustomer" })(EditCustomer);
export default connect(mapStateToProps, {})(WrappedEditCustomer);
