import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getrolelist } from "../../actions/assetAction";
// import _ from "lodash";
import { Button, Table, Tag, Icon } from "antd";
import nProgress from "nprogress";

class RoleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      designations: [],
      data: [],
      pagination: {},
      loading: false,
    };
  }

  componentWillMount() {
    this.getdesignation({
      results: 10,
      page: 1,
    });
  }

  getdesignation(params = {}) {
    this.setState({ loading: true });
    this.props.getrolelist(params).then((res) => {
      console.log(res);
      const pagination = { ...this.state.pagination };
      pagination.total = res.data.items.total;
      this.setState({
        designations: res.data.items.data,
        loading: false,
        pagination,
      });
      nProgress.done();
    });
  }
  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      results: pagination.pageSize,
      page: pagination.current,
    });
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };
  // ======================================================

  render() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Role Name",
        dataIndex: "name",
        key: "name",
      },

      {
        title: "Description",
        dataIndex: "desc",
        key: "desc",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (tag) => (
          <span>
            <Tag color={tag === 1 ? "green" : "volcano"}>
              {tag ? "Active" : "Inactive"}
            </Tag>
          </span>
        ),
      },
      {
        title: "Actions",
        dataIndex: "action",
        render: (text, record) => (
          <span>
            <Link
              className="padd5 mr5"
              style={{ marginRight: 10 }}
              to={{ pathname: "/editrole", userrole: record }}
            >
              <Icon type="edit" title="Edit role" />
            </Link>
          </span>
        ),
      },
    ];

    const data = this.state.designations;

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>Role List</h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* <Link to="/erpmodules">
                <Button type="default" className="mr5">
                  Modules
                </Button>
              </Link> */}
              <Button
                type="default"
                className="mr5"
                // onClick={() => this.getdesignation()}
              >
                <i className="uil uil-sync" />
              </Button>
              <Link to="/createrole">
                <Button type="primary">Create Role</Button>
              </Link>
            </div>
          </div>
          <div className="bg-white padd0">
            <Table
              className="components-table-demo-nested"
              columns={columns}
              dataSource={data}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              //   scroll={{ x: true }}
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    userinfo: state.userinfo,
  };
}

export default connect(mapStateToProps, { getrolelist })(RoleList);
