import React, { Component } from "react";
import { connect } from "react-redux";
import {
  inventory_dashboard,
  expired_inventory_dashboard,
  inventory_dashboard_agent_summary_count,
} from "../../actions/salesAction";
import _ from "lodash";
import { Spin, Typography, Badge, Divider } from "antd";
import { FunnelChart } from "react-funnel-pipeline";
import Chart from "react-apexcharts";
// import { Chart } from "react-google-charts";
import "react-funnel-pipeline/dist/index.css";
const { Text } = Typography;

//const rolepath = getApiPath();
class AgentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onboarding: [],
      agent_label: [],
      agent_value: [],
      loading: true,
      loadingAgent:true,
      expired_onboarding_name: [],
      expired_onboarding_label: [],
    };
  }

  componentWillMount() {
    sessionStorage.removeItem("onboarding_data");
    this.getdesignation();
    //console.log(rolepath);
  }

  getdesignation() {
    this.props
      .inventory_dashboard()
      .then((res) => {
        this.setState({
          onboarding: res.data.items.onboarding_summary,
         // agent_label: res.data.items.agent_label,
          // agent_value: res.data.items.agent_value,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
    this.props
      .expired_inventory_dashboard()
      .then((res) => {
        console.log(res);
       
        this.setState({
          expired_onboarding_name: res.data.items.onboarding_summary.map(item => item.name),
          expired_onboarding_label: res.data.items.onboarding_summary.map(item => item.value),
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
      this.props
      .inventory_dashboard_agent_summary_count()
      .then((res) => {
        
        this.setState({
          // onboarding: res.data.items.onboarding_summary,
          agent_label: res.data.items.agent_label,
          agent_value: res.data.items.agent_value,
          loadingAgent:false,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingAgent:false,
        });
      });
  }

  // ======================================================

  render() {
    const options = {
      labels: this.state.agent_label,
      legend: {
        position: "top",
      },
      chart: {
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const indexid = config.w.config.labels[config.dataPointIndex];
            if (indexid === "Dynamic Agent") {
              this.props.history.push({
                // no need
                pathname: "/agentsummaryreport",
                state: {
                  urlpath:
                    "/api/v1/agent_summary_report?redirect_key=active_agent",
                },
              });
            }
            if (indexid === "Passive Agent") {
              this.props.history.push({
                // no need
                pathname: "/agentsummaryreport",
                state: {
                  urlpath:
                    "/api/v1/agent_summary_report?redirect_key=passive_agent",
                },
              });
            }
            if (indexid === "Dormant Agent") {
              this.props.history.push({
                // no need
                pathname: "/agentsummaryreport",
                state: {
                  urlpath:
                    "/api/v1/agent_summary_report?redirect_key=inactive_agent",
                },
              });
            }
            if (indexid === "Onboarded Agent") {
              this.props.history.push({
                // no need
                pathname: "/agentsummaryreport",
                state: {
                  urlpath:
                    "/api/v1/agent_summary_report?redirect_key=onboarded",
                },
              });
            }
          },
        },
      },
    };
    const series = this.state.agent_value;

    const options1 = {
      
      labels: this.state.expired_onboarding_name,
      legend: {
        position: "top",
      },
      colors: [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#FF4560",
        "#775DD0",
        "#546E7A",
        "#26a69a",
        "#D10CE8", 
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
		  '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
      ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {},
              total: {
                show: true, // default false
                showAlways: true, // default false
                label: "Total",
                fontSize: "22px",
                fontFamily: "Heebo, Arial, sans-serif",
                fontWeight: 600,
                color: "#000000",
               
            },

            }
          }
        }
      },
      chart: {
        type: 'donut',
        events: {
          dataPointSelection: (event, chartContext, config) => {
            const indexid = config.w.config.labels[config.dataPointIndex];
            this.props.history.push({
              // no need
              pathname: "/OnboardingSummaryReport",
              state: {
                urlpath:
                  `/api/v1/onboarding_summary_report?redirect_key=${20}`,
              },
            });
          },
        },
      },
    };
    const series1 = this.state.expired_onboarding_label;

    return (
      <div>
        <Spin spinning={this.state.loading}>
          <div className="container-fluid">
            <div className="row padd20">
              <div className="col-sm-4">
                <h4>Dashboard</h4>
              </div>
            </div>

            <div className="padd0">
              <div className="container-fluid">
                <div className="row">
                  <div
                    className="col-6"
                    style={{ backgroundColor: "white", padding: "20px" }}
                  >
                    <h4 class="card-title">
                      <strong>Onboarding Summary </strong>
                    </h4>

                    <hr className="mb-3"></hr>
                    <div className="text-center mb-3">
                      <Badge color="#0da8f0" text="System" />
                      <Divider type="vertical" />{" "}
                      <Badge color="#f0a80d" text="Agent" />
                      <Divider type="vertical" />{" "}
                      <Badge color="#20c997" text="Onboarding Team" />
                    </div>
                    <FunnelChart
                      data={this.state.onboarding}
                      getToolTip={(row) => {
                        return row.name;
                      }}
                      getRowStyle={(row) => {
                        return row.name === "Invite Sent" ||
                          row.name === "BGC Pending"
                          ? { backgroundColor: "#0da8f0" }
                          : row.name === "Invite Sent"
                          ? { backgroundColor: "#00133e" }
                          : row.name === "Profile In Progress" ||
                            row.name === "Verification Failed" ||
                            row.name === "Training & BGC Pending" ||
                            row.name === "Training Pending" ||
                            row.name === "RAD Link Failed"
                          ? { backgroundColor: "#f0a80d" }
                          : row.name === "BGC Denied"
                          ? { backgroundColor: "red" }
                          : row.name === "Rejected"
                          ? { backgroundColor: "red" }
                          : row.name === "BGC Review"
                          ? { backgroundColor: "#20c997" }
                          : row.name === "Expired"
                          ? { backgroundColor: "#f0a80d" }
                          : row.name === "Telgoo ID"
                          ? { backgroundColor: "#20c997" }
                          : row.name === "RAD Updated"
                          ? { backgroundColor: "#20c997" }
                          : row.name === "Onboarded"
                          ? { backgroundColor: "#111f44" }
                          : { backgroundColor: "#20c997" };
                      }}
                      onRowClick={(row) => {
                        console.log(`row`, row);
                        console.log(
                          `this.state.onboarding`,
                          this.state.onboarding
                        );
                        if (row.name === "Profile Completed") {
                          this.props.history.push("/Profilecompleted");
                        } else if (row.name === "Profile Updated") {
                          this.props.history.push("/Profileupdate");
                        } else if (row.name === "BGC Review") {
                          this.props.history.push("/BgcReview");
                        } else if (row.name === "Link RAD ID") {
                          this.props.history.push("/LinkRadID");
                        } else if (row.name === "RAD Link Failed") {
                          this.props.history.push("/radlinkissues");
                        } else if (row.name === "RAD Updated") {
                          this.props.history.push("/RadIDFailedUpdate");
                        } else {
                          this.props.history.push({
                            pathname: `/OnboardingSummaryReport`,
                            state: {
                              urlpath: `/api/v1/onboarding_summary_report?redirect_key=${
                                row.name === "Invite Sent"
                                  ? "1"
                                  : row.name === "Profile In Progress"
                                  ? "2"
                                  : row.name === "Verification Failed"
                                  ? "24"
                                  : row.name === "Training & BGC Pending"
                                  ? "4"
                                  : row.name === "BGC Pending"
                                  ? "5"
                                  : row.name === "BGC Review"
                                  ? "6"
                                  : row.name === "BGC Denied"
                                  ? "22"
                                  : row.name === "Training Pending"
                                  ? "9"
                                  : row.name === "Expired"
                                  ? "20"
                                  : row.name === "Telgoo ID"
                                  ? "12"
                                  : row.name === "Onboarded"
                                  ? "13"
                                  : row.name === "Rejected"
                                  ? "27"
                                  : ""
                              }`,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  <div
                    className="col-6"
                    style={{ backgroundColor: "white", padding: "20px" }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <h4 class="card-title">
                          <strong>Expired Status Distribution</strong>
                        </h4>

                        <hr className="mb-3"></hr>
                        <div id="chart" style={{ cursor: "pointer" }}>
                          <Chart
                            options={options1}
                            series={series1}
                            type="donut"
                            width="100%"
                          />
                        </div>
                    
                      </div>
                      <Spin spinning={this.state.loadingAgent}>

                     
                        <div className="col-12">
                          <h4 class="card-title">
                            <strong>Agents Summary </strong>
                          </h4>

                          <hr className="mb-5"></hr>
                          <div id="chart" style={{ cursor: "pointer" }}>
                            <Chart
                              options={options}
                              series={series}
                              type="pie"
                              width="100%"
                            />
                          </div>
                          <div className="text-center">
                            <Text code>Dynamic Agent (1-14 Days)</Text>

                            <Text code>Passive Agent (15-30 Days)</Text>

                            <Text code>Dormant Agent (31-60 Days)</Text>
                          </div>
                        </div>
                      </Spin>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    ibomarket: state.data,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  inventory_dashboard,
  expired_inventory_dashboard,
  inventory_dashboard_agent_summary_count,
})(AgentDashboard);
