import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DetailInventoryView,
  getinventorystatus,
  getdevicelogstatus,
} from "../../actions/inventoryAction";
import { detailed_inventory_export } from "../../actions/exportAction";
import {
  getofficelistname,
  getagentlistname,
  searchdetailinventorylist,
  getretailers,
  getdevicetype,
  getibomarketlist,
} from "../../actions/assetAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Form,
  Select,
  Col,
  Tooltip,
  DatePicker,
  Drawer,
  List,
  message,
  Spin,
  Empty,
  Checkbox,
} from "antd";
import axiosInstance from "../../actions/AxiosInstance";
import moment from "moment";
import debounce from "lodash/debounce";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const CheckboxGroup1 = Checkbox.Group;
const CheckboxGroup2 = Checkbox.Group;

const defaultCheckedList = [""];
const defaultCheckedList1 = [""];
const defaultCheckedList2 = [""];
class DetailInventory extends Component {
  constructor(props) {
    super(props);
    this.lastFetchId = 0;
    this.fetchUser = debounce(this.fetchUser, 1000);
    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingEPbtn: false,
      retailer: [],
      selectedItems: [],
      selectedItemsAgent: [],
      selectedItemsStatus:[],
      inventorystatus: [],
      search_text: "",
      datefilter: [],
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      startValue11: null,
      endValue11: null,
      endOpen: false,
      endOpen11: false,
      office: [],
      agent: [],
      detailform: {},
      statusval: [],
      visible: false,
      logdata: [],
      superrole: false,
      retailview: false,
      retailers:[],
      orderid:"",
      enrollid:"",
      carton:"",
      imei:"",
      rma_order_id:"",
      device_type:[],
      device:[],

      agentstatus: [],
      checkedList: defaultCheckedList,
      checkedList1: defaultCheckedList1,
      indeterminate: false,
      checkAll: false,
      indeterminate1: false,
      checkAll1: false,
      checkedList2: defaultCheckedList2,
      indeterminate2: false,
      checkAll2: false,
      instock_status: [],
      lost_status: [],
      other_status: [],
      selectedIBOItems:[],
    };
  }

  componentWillMount() {
    this.props
      .getinventorystatus()
      .then((res) => {
        this.setState({
          agentstatus: res.data,
          instock_status: res.data.filter(
            (item) =>
              item.id === 9 ||
              item.id === 3 ||
              item.id === 4 ||
              item.id === 5 ||
              item.id === 6 ||
              item.id === 12
          ),
          lost_status: res.data.filter(
            (item) =>
              item.id === 10 ||
              item.id === 11 ||
              item.id === 15 ||
              item.id === 16 ||
              item.id === 18 ||
              item.id === 19
          ),
          other_status: res.data.filter(
            (item) =>
              (item.id !== 9) &
              (item.id !== 3) &
              (item.id !== 4) &
              (item.id !== 5) &
              (item.id !== 6) &
              (item.id !== 10) &
              (item.id !== 13) &
              (item.id !== 11) &
              (item.id !== 15) &
              (item.id !== 12) &
              (item.id !== 20) &
              (item.id !== 16) &
              (item.id !== 18) &
              (item.id !== 19) &
              (item.id !== 14)
          ),
        });
      })
      .catch((err) => {
        this.setState({
          agentstatus: [],
        });
      });
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }
    const urlString = this.props.location.urlpath;
    console.log("urlstring",urlString)
    const urlParams = urlString
      ?.split("?")[1]
      ?.split("&")
      ?.map((v) => v?.split("="));
    const paramsobj = {};
    urlParams?.forEach((element) => {
      console.log("elements",element)
      paramsobj[element[0]] = element[1];
    });
    console.log("urlparams",urlParams)
    // this.setState({
    //   statusval: parseInt(paramsobj["status"]) || [],
    // });
    // console.log("params", parseInt(paramsobj["status"]));

    //Instock redirect
    if(parseInt(paramsobj["status"]) === 4 ||
       parseInt(paramsobj["status"]) === 5 ||
       parseInt(paramsobj["status"]) === 6 ||
       parseInt(paramsobj["status"]) === 9 ||
       parseInt(paramsobj["status"]) === 3 ||
       parseInt(paramsobj["status"]) === 12 ){
      this.onChangeCheck([parseInt(paramsobj["status"])])
      setTimeout(()=>{this.searchDetailInventory(1)},200)
    }

    if(parseInt(paramsobj["status"]) === 13){
      this.onChangeCheck([4,5,6,9,3,12])
      setTimeout(()=>{this.searchDetailInventory(1)},200)
    }
    if(parseInt(paramsobj["status"]) === 14){
      this.onChangeCheck([4,5,6,9,3,12])
      this.onChangeCheck2([0])
      setTimeout(()=>{this.searchDetailInventory(1)},200)
    }
    // if (parseInt(paramsobj["status"]) === 13) {
    //   const instockIds =this.state.instock_status.map((option) => option.id);
    //   this.onChangeCheck(instockIds);
    //   setTimeout(() => {
    //     this.searchDetailInventory(1);
    //   }, 200);
    // }
    // if (parseInt(paramsobj["status"]) === 13) {
    //   const instockIds = this.state.instock_status.map((option) => option.id);
    //   const additionalIds = ["4", "5", "6", "9", "3", "12"];
    //   const selectedIds = [...instockIds, ...additionalIds];
    //   this.onChangeCheck(selectedIds);
    //   setTimeout(() => {
    //     this.searchDetailInventory(1);
    //   }, 100);
    // }

    //LOST/DEFECTIVE redirect
    if(parseInt(paramsobj["status"]) === 11 ||
       parseInt(paramsobj["status"]) === 15 ||
       parseInt(paramsobj["status"]) === 10){
      this.onChangeCheck1([parseInt(paramsobj["status"])])
      setTimeout(()=>{this.searchDetailInventory(1)},200)
     
    }
    // if(parseInt(paramsobj["status"]) === 15){
    //   this.onChangeCheck1([parseInt(paramsobj["status"])])
    //   setTimeout(()=>{this.searchDetailInventory(1)},200)
     
    // }
    
    if(parseInt(paramsobj["status"]) === 0){
      this.onChangeCheck2([parseInt(paramsobj["status"])])
      setTimeout(()=>{this.searchDetailInventory(1)},200)
     
    }

    
    this.props
      .getinventorystatus()
      .then((res) => {
        console.log(res);
        this.setState({
          inventorystatus: res.data,
        });
      })
      .catch((err) => {});
    this.getdesignation({
      url: this.props.location.urlpath ?? "",
      results: 10,
      page: 1,
    });
    const Retailer =
      this.props?.user?.role_id?.filter((word) => word === 5) ?? [];
    if (Retailer?.length !== 0) {
      this.setState({
        retailview: true,
      });
    }
    this.props.getibomarketlist().then((res) => {
      console.log(res.data);
      this.setState({
        marketer: res.data,
      });
    });
  }

  componentDidMount() {
    console.log(this.state.mergedArray);
    this.props.form.setFieldsValue({
      status: this.state.mergedArray === "" ? [] : parseInt(this.state.mergedArray) || [],
    });
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .DetailInventoryView(params)
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        this.setState({
          data: res.data.items.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
      this.props.getdevicetype().then((res) => {
        this.setState({
          device: res.data,
        });
      });
      this.props.getretailers().then((res) => {
        this.setState({
          retailer: res.data,
        });
      });
     
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchDetailInventory();
    }, 100);
  };

  // renderStatus() {
  //   return _.map(this.state.inventorystatus, (master, index) => {
  //     // console.log(master.id);
  //     return (
  //       <Option value={master.id} key={index}>
  //         {master.name}
  //       </Option>
  //     );
  //   });
  // }
  renderOffice() {
    return _.map(this.state.office, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  renderagent() {
    return _.map(this.state.agent, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  // onchangeRole = (selectedItems) => {
  //   this.setState({ selectedItems });
  //   console.log(this.state.selectedItems);
  // };

  // onchangeRoleAgent = (selectedItemsAgent) => {
  //   this.setState({ selectedItemsAgent });
  //   console.log(this.state.selectedItemsAgent);
  // };

  disabledStartDate = (startValue) => {
    // const { endValue } = this.state;
    // if (!startValue || !endValue) {
    //   return false;
    // }
    return startValue && startValue > moment().endOf("day");
  };

  disabledEndDate = (endValue) => {
    // const { startValue } = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    return endValue && endValue > moment().endOf("day");
  };
  //Activation start date
  disabledStartDate11 = (startValue11) => {
    // const { endValue } = this.state;
    // if (!startValue || !endValue) {
    //   return false;
    // }
    return startValue11 && startValue11 > moment().endOf("day");
  };
//Activation end date
  disabledEndDate11 = (endValue11) => {
    // const { startValue } = this.state;
    // if (!endValue || !startValue) {
    //   return false;
    // }
    return endValue11 && endValue11 > moment().endOf("day");
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    console.log("startValue",value)
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };
  //Activation date
  onStartChange11 = (value11) => {
    this.onChange("startValue11", value11);
  };
//Activation date
  onEndChange11 = (value11) => {
    this.onChange("endValue11", value11);
  };
  handleStartOpenChange11 = (open1) => {
    if (!open1) {
      this.setState({ endOpen11: true });
    }
  };

  handleEndOpenChange11 = (open1) => {
    this.setState({ endOpen11: open1 });
  };
  

  handleReset = () => {
    this.props.form.resetFields();
    this.setState({
      detailform: {},
      selectedIBOItems:[],
      name: "",
      selectedItems: [],
      selectedItemsAgent: [],
      statusval:[],
      fdata: [],
      fAdata: [],
      startValue: null,
      endValue: null,
      startValue11: null,
      endValue11: null,
      retailers:[],
      orderid:"",
      enrollid:"",
      carton:"",
      imei:"",
      rma_order_id:"",
      device_type:[],
      checkedList: [],//instock
      checkedList1: [],
      checkedList2: [],
      checkAll: false,//instock
      checkAll1: false,
      checkAll2: false,
      indeterminate: false,
      indeterminate1: false,
      indeterminate2: false,
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({
          loading: true,
          loadingbtn: true,
        });
        console.log("Received values of form: ", values);
        var page = 1;
        this.setState({
          detailform: values,
        });
        console.log(page);
        this.props
          .searchdetailinventorylist(values, page, "", "")
          .then((res) => {
            const pagination = { ...this.state.pagination };
            pagination.total = res.data.items.total;
            pagination.current = res.data.items.current_page;
            this.setState({
              data: res.data.items.data,
              loading: false,
              loadingbtn: false,
              pagination,
            });
          })
          .catch((err) => {
            this.setState({
              loading: false,
              loadingbtn: false,
            });
          });
      }
    });
  };

  searchDetailInventory = (reset) => {
    this.setState({
      loading: true,
      loadingbtn:true,
    });
    const mergedArray = this.state.checkedList
      .concat(this.state.checkedList1, this.state.checkedList2)
      .filter((obj) => Object(obj).length !== 0);
    //const resultString = JSON.stringify(mergedArray);
    var page = reset ? 1 : this.state.pagination?.current ?? 1;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var office_id = this.state.selectedItems.key;
    var agent = this.state.selectedItemsAgent.key;
    // var status = this.state.statusval;
    var search_text = this.state.name;
    var activation_date_range_start = this.state.startValue11;
    var activation_date_range_end =this.state.endValue11;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var retailer_id = this.state.retailers;
    var order_id = this.state.orderid;
    var enroll_id = this.state.enrollid;
    var carton = this.state.carton;
    var imei = this.state.imei;
    var device_type = this.state.device_type;
    var status = mergedArray.toString();
    var market = this.state.selectedIBOItems;
    var rma_order_id = this.state.rma_order_id;

    this.props
      .searchdetailinventorylist(
        this.state.detailform,
        page, 
        sort, 
        sort_by,
        office_id,
        agent,
        // status,
        search_text,
        activation_date_range_start,
        activation_date_range_end,
        date_range_start,
        date_range_end,
        retailer_id,
        order_id,
          enroll_id,
          carton,
          imei,
          device_type,
          status,
          market,
          rma_order_id,
      )
      .then((res) => {
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.items.total;
        pagination.current = res.data.items.current_page;
        this.setState({
          data: res.data.items.data,
          loading: false,
          loadingbtn:false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          loadingbtn:false,
        });
      });
  };
  showDrawer = (record) => {
    this.props
      .getdevicelogstatus(record.id)
      .then((res) => {
        console.log(res);
        this.setState({
          visible: true,
          logdata: res.data.items,
        });
      })
      .catch((err) => {});
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.form.resetFields();
  };
  getexportbtn = () => {
    this.setState({ loading: true, loadingEPbtn: true });
    const mergedArray = this.state.checkedList
      .concat(this.state.checkedList1, this.state.checkedList2)
      .filter((obj) => Object(obj).length !== 0);

    console.log("query param", this.state.detailform);
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var formitem = this.state.detailform;
    // var status = this.state.statusval;
    var office_id = this.state.selectedItems.key;
    var agent = this.state.selectedItemsAgent.key;
    // var status = this.state.statusval;
    var search_text = this.state.name;
    var activation_date_range_start = this.state.startValue11;
    var activation_date_range_end =this.state.endValue11;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var retailer_id = this.state.retailers;
    var order_id = this.state.orderid;
    var enroll_id = this.state.enrollid;
    var carton = this.state.carton;
    var imei = this.state.imei;
    var device_type = this.state.device_type;
    var status = mergedArray.toString();
    var market = this.state.selectedIBOItems;
    var rma_order_id = this.state.rma_order_id;



    this.props
      .detailed_inventory_export(
        formitem,
         sort,
          sort_by,
          search_text,
          agent,office_id,
          // status,
          date_range_start,
          date_range_end,
          activation_date_range_start,
          activation_date_range_end,
          retailer_id,
          order_id,
          enroll_id,
          carton,
          imei,
          device_type,
          status,
          market,
          rma_order_id,
         )
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        console.log("statusval",this.state.statusval)
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };

  fetchUser = (value) => {
    console.log("fetching user", value);
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          if (fetchId !== this.lastFetchId) {
            // for fetch callback order
            return;
          }
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  fetchAgentUser = (value) => {
    console.log("Afetching user", value);
    if (value.length > 1) {
      this.setState({ fAdata: [], Afetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fAdata = response.data.map((user) => ({
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fAdata, Afetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    this.setState({ selectedItems });
  };
  onchangeAgentRole = (selectedItemsAgent) => {
    console.log(selectedItemsAgent);
    this.setState({ selectedItemsAgent });
  };
  onchangeStatusRole = (statusval) => {
    console.log(statusval);
    this.setState({ statusval });
  };

  onChangeCheck = (checkedList) => {
    console.log( this.state.instock_status)
    console.log(checkedList.length );
    this.setState({
      checkedList,
      indeterminate:
        !!checkedList.length &&
        checkedList.length < this.state.instock_status.length,
      checkAll: checkedList.length === 6,
    });
  };

  onCheckAllChange = (e) => {
    console.log(e.target.checked);
    console.log("instock",this.state.instock_status)
    this.setState({
      checkedList: e.target.checked
        ? this.state.instock_status.map((option) => option.id)
        : [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  };

  onChangeCheck1 = (checkedList1) => {
    this.setState({
      checkedList1,
      indeterminate1:
        !!checkedList1.length &&
        checkedList1.length < this.state.lost_status.length,
      checkAll1: checkedList1.length === this.state.lost_status.length,
    });
  };

  onCheckAllChange1 = (e) => {
    this.setState({
      checkedList1: e.target.checked
        ? this.state.lost_status.map((option) => option.id)
        : [],
      indeterminate1: false,
      checkAll1: e.target.checked,
    });
  };

  onChangeCheck2 = (checkedList2) => {
    this.setState({
      checkedList2,
      indeterminate2:
        !!checkedList2.length &&
        checkedList2.length < this.state.other_status.length,
      checkAll2: checkedList2.length === this.state.other_status.length,
    });
  };

  onCheckAllChange2 = (e) => {
    this.setState({
      checkedList2: e.target.checked
        ? this.state.other_status.map((option) => option.id)
        : [],
      indeterminate2: false,
      checkAll2: e.target.checked,
    });
  };

  handledevicechange = (value) => {
    console.log(value);
    this.setState({ device_type: value });
  };
  handleretailerchange = (value) => { 
    console.log(value)
    this.setState({ retailers: value }); 
  };
  onchangeIBORole = (selectedIBOItems) => {
    this.setState({ selectedIBOItems });
  };
  renderIBOOffice() {
    return _.map(this.state.marketer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  // ======================================================

  render() {
    const { endValue, fetching, fdata,endOpen,endOpen11, fAdata, Afetching,endValue11,startValue,startValue11 ,retailview,superrole } = this.state;
    // const { getFieldDecorator } = this.props.form;
    const columns = [
      {
        title: "Retailer Name",
        dataIndex: "retailer_name",
        key: "retailer_name",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "IBO / Markets ",
        dataIndex: "ibo_name",
        key: "ibo_name",
        sorter: true,
      },
      {
        title: "Office ",
        dataIndex: "office_name",
        key: "office_name",
        sorter: true,
      },
      {
        title: "Enrollment Number",
        dataIndex: "enroll_id",
        key: "enroll_id",
      },
      {
        title: "ETC Code",
        dataIndex: "excess_code",
        key: "excess_code",
        sorter: true,
        // hidden: !retailview,
      },
      {
        title: "Agent Name",
        dataIndex: "agent_name",
        key: "agent_name",
        sorter: true,
      },

      {
        title: "Manufacturer",
        dataIndex: "make",
        key: "make",
        sorter: true,
      },
      {
        title: "Model",
        dataIndex: "model",
        key: "model",
        sorter: true,
      },
      {
        title: "Pallet",
        dataIndex: "pallet",
        key: "pallet",
        sorter: true,
      },
      {
        title: "Carton",
        dataIndex: "carton",
        key: "carton",
        sorter: true,
      },
      {
        title: "Enrollment Type",
        dataIndex: "enroll_type",
        key: "enroll_type",
      },
      {
        title: "ESN",
        dataIndex: "esn",
        key: "esn",
      },
      // {
      //   title: "MDN",
      //   dataIndex: "mdn",
      //   key: "mdn",
      // },
      {
        title: "IMEI",
        dataIndex: "imei",
        key: "imei",
        sorter: true,
      },
      {
        title: "Device Type",
        dataIndex: "device_type",
        key: "device_type",
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        render: (text, record) => (
          <span>
            <Tag color="orange">{record.status_name}</Tag>
          </span>
        ),
      },
      // {
      //   title: "Received Date",
      //   dataIndex: "received_date",
      //   key: "received_date",
      //   sorter: true,
      //   hidden: superrole === true ? false : true,
      // },
      {
        title: "Allocation Date",
        dataIndex: "allocation_date",
        key: "allocation_date",
        sorter: true,
      },
      {
        title: "Age",
        dataIndex: "device_age",
        key: "device_age",
        sorter: true,
      },
      {
        title: "Order ID",
        dataIndex: "order_id",
        key: "order_id",
        // hidden: retailview,
      },
      {
        title: "RMA ID",
        dataIndex: "rma_order_id",
        key: "rma_order_id",
        // hidden: retailview,
      },
      {
        title: "Activation Date",
        dataIndex: "activation_date",
        key: "activation_date",
        sorter: true,
      },
      {
        title: "Action",
        key: "action",
        width: 100,
        fixed: "right",
        render: (text, record) => (
          <>
            <Tooltip title="View Device Log">
              <Button
              onClick={() => this.showDrawer(record)} 
              shape="circle"
              icon="eye"
              type="primary">
              </Button>
            </Tooltip>
          </>
      )},
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Detailed Report{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
            <div className="row mb-3">
            {this.state.superrole && ( <div className="col-3 mb-3">
                  <label for="exampleFormControlInput1">Select Retailer</label>

                  <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  
                    placeholder="Select retailer"
                    style={{ width: "100%" }}
                    onChange={this.handleretailerchange}
                    value={this.state.retailers}
                  >
                    {this.state.retailer.map((device, index) => {
                      return (
                        <Option key={index} value={device.id}>
                          {device.name}
                        </Option>
                      );
                    })}
                    
                  </Select>
                </div>)}
                <div className="col-3">
              <label for="exampleFormControlInput1">Select IBOs / Markets</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedIBOItems}
                onChange={this.onchangeIBORole}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderIBOOffice()}
              </Select>
            </div>
            <div className="col-3 ">
                  <label for="exampleFormControlInput1">Select Office</label>
                 
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      labelInValue
                      placeholder="Type 2 characters to search..!"
                      notFoundContent={
                        fetching ? <Spin size="small" /> : <Empty />
                      }
                      filterOption={false}
                      onSearch={this.fetchUser}
                      defaultActiveFirstOption={false}
                      value={this.state.selectedItems}
                      onChange={this.onchangeRole}
                    >
                      {fdata?.map((d) => (
                        <Option key={d.value}>{d.text}</Option>
                      ))}
                    </Select>
              </div>
              <div className="col-3">
                  <label for="exampleFormControlInput1">Select Agent</label>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      labelInValue
                      placeholder="Type 2 characters to search..!"
                      notFoundContent={
                        Afetching ? <Spin size="small" /> : <Empty />
                      }
                      filterOption={false}
                      onSearch={this.fetchAgentUser}
                      defaultActiveFirstOption={false}
                      value={this.state.selectedItemsAgent}
                      onChange={this.onchangeAgentRole}
                    >
                      {fAdata?.map((d) => (
                        <Option key={d.value}>{d.text}</Option>
                      ))}
                    </Select>
              </div>
              {/* <div className="col-3">
                  <label for="exampleFormControlInput1">Select Status</label>
                    <Select
                      showSearch
                      // mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select Option"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      value={this.state.statusval}
                      onChange={this.onchangeStatusRole}
                    >
                      {this.renderStatus()}
                    </Select>
              </div> */}
              <div className="col-3 mb-3">
                  <label for="exampleFormControlInput1">Search By Order ID</label>
                  <Input
                    placeholder="Input Order ID"
                    value={this.state.orderid}
                    // ref={(node) => {
                    //   this.searchInput = node;
                    // }}
                    onChange={(e) =>
                      this.setState({
                        orderid: e.target.value,
                      })
                    }
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
                <div className="col-3 ">
                  <label for="exampleFormControlInput1">Search By Enrollment ID</label>
                  <Input
                    placeholder="Input Enrollment ID"
                    value={this.state.enrollid}
                    // ref={(node) => {
                    //   this.searchInput = node;
                    // }}
                    onChange={(e) =>
                      this.setState({
                        enrollid: e.target.value,
                      })
                    }
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
                <div className="col-3 ">
                  <label for="exampleFormControlInput1">Search By Carton</label>
                  <Input
                    placeholder="Type Carton Number"
                    value={this.state.carton}
                    // ref={(node) => {
                    //   this.searchInput = node;
                    // }}
                    onChange={(e) =>
                      this.setState({
                        carton: e.target.value,
                      })
                    }
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
                <div className="col-3 ">
                  <label for="exampleFormControlInput1">Search By IMEI</label>
                  <Input
                    placeholder="Type IMEI"
                    value={this.state.imei}
                    // ref={(node) => {
                    //   this.searchInput = node;
                    // }}
                    onChange={(e) =>
                      this.setState({
                        imei: e.target.value,
                      })
                    }
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
                <div className="col-3 ">
                  <label for="exampleFormControlInput1">Search By RMA ID</label>
                  <Input
                    placeholder="Type RMA ID"
                    value={this.state.rma_order_id}
                    // ref={(node) => {
                    //   this.searchInput = node;
                    // }}
                    onChange={(e) =>
                      this.setState({
                        rma_order_id: e.target.value,
                      })
                    }
                    style={{ width: "100%", display: "block" }}
                  />
                </div>
                <div className="col-3 mb-3">
                  <label for="exampleFormControlInput1">Device Type</label>
                  <Select
                  showSearch
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={this.state.device_type}
                    placeholder="Select device type"
                    style={{ width: "100%" }}
                    onChange={this.handledevicechange}
                  >
                    {this.state.device.map((device, index) => {
                      return (
                        <Option key={index} value={device.id}>
                          {device.name}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
             
              <div className="col-3">
                  <label for="exampleFormControlInput1">Allocation From</label>
                 
                    <DatePicker
                     
                       value={startValue}
                      disabledDate={this.disabledStartDate}
                      format="MM-DD-YYYY"
                      placeholder="Select Date"
                      onChange={this.onStartChange}
                      onOpenChange={this.handleStartOpenChange}
                    />
              </div>
              <div className="col-3">
                  <label for="exampleFormControlInput1">Allocation To</label>
                 
                    <DatePicker
                       
                      value={endValue}
                      disabledDate={this.disabledEndDate}
                      format="MM-DD-YYYY"
                      placeholder="Select Date"
                      onChange={this.onEndChange}
                      open={endOpen}
                      onOpenChange={this.handleEndOpenChange}
                    />
                  
              </div>
              
              {/* //  Activation date  */}
              <div className="col-3">
                  <label for="exampleFormControlInput1">Activation From</label>
                 
                    <DatePicker
                      value={startValue11}
                      disabledDate={this.disabledStartDate11}
                      format="MM-DD-YYYY"
                      placeholder="Select Date"
                      onChange={this.onStartChange11}
                      onOpenChange={this.handleStartOpenChange11}
                    />
              </div>
              <div className="col-3">
                  <label for="exampleFormControlInput1">Activation To</label>
                 
                    <DatePicker
                      value={endValue11}
                      disabledDate={this.disabledEndDate11}
                      format="MM-DD-YYYY"
                      placeholder="Select Date"
                      onChange={this.onEndChange11}
                      open1={endOpen11}
                      onOpenChange={this.handleEndOpenChange11}
                    /> 
              </div>
              <div className="col-3">
                  <label for="exampleFormControlInput1">Search By keyword</label>
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Type Keyword"
                      value={this.state.name}
                      onChange={(e) =>
                        this.setState({
                          name: e.target.value,
                        })
                      }
                    />
              </div>

            </div>
            <div className="row">
                <div className="col-4 mt-4">
                  {/* <div
                    class="card  mb-4"
                    style={{ borderRadius: "1.00rem", height: "200px" }}
                  > */}
                    <div class="card-body">
                      <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                        <Checkbox
                          indeterminate={this.state.indeterminate}
                          onChange={this.onCheckAllChange}
                          checked={this.state.checkAll}
                        >
                          INSTOCK
                        </Checkbox>
                      </div>
                      <br />
                      <CheckboxGroup
                        //options={this.state.instock_status}
                        value={this.state.checkedList}
                        onChange={this.onChangeCheck}
                      >
                        {this.state.instock_status.map((status, index) => {
                          return (
                            <Checkbox key={index} value={status.id}>
                              {status.name}
                            </Checkbox>
                          );
                        })}
                      </CheckboxGroup>
                    </div>
                  {/* </div> */}
                </div>

                <div className="col-4 mt-4">
                  {/* <div
                    class="card  mb-4"
                    style={{ borderRadius: "1.00rem", height: "200px" }}
                  > */}
                    <div class="card-body">
                      <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                        <Checkbox
                          indeterminate={this.state.indeterminate1}
                          onChange={this.onCheckAllChange1}
                          checked={this.state.checkAll1}
                        >
                          LOST/DEFECTIVE
                        </Checkbox>
                      </div>
                      <br />
                      <CheckboxGroup1
                        // options={plainOptions1}
                        value={this.state.checkedList1}
                        onChange={this.onChangeCheck1}
                      >
                        {this.state.agentstatus.map((status, index) => {
                          if (
                            status.id === 10 ||
                            status.id === 11 ||
                            status.id === 15 ||
                            status.id === 16 ||
                            status.id === 18 ||
                            status.id === 19
                          ) {
                            // replace 'condition' with your condition
                            return (
                              <Checkbox key={index} value={status.id}>
                                {status.name}
                              </Checkbox>
                            );
                          }
                          return null; // Add this line to return null if the condition is not met
                        })}
                      </CheckboxGroup1>
                    </div>
                  {/* </div> */}
                </div>

                <div className="col-4 mt-4">
                  {/* <div
                    class="card  mb-4"
                    style={{ borderRadius: "1.00rem", height: "200px" }}
                  > */}
                  {this.state.superrole && ( 

                 
                    <div class="card-body">
                      <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                        <Checkbox
                          indeterminate={this.state.indeterminate2}
                          onChange={this.onCheckAllChange2}
                          checked={this.state.checkAll2}
                        >
                          OTHERS
                        </Checkbox>
                      </div>
                      <br />
                      <CheckboxGroup1
                        // options={plainOptions2}
                        value={this.state.checkedList2}
                        onChange={this.onChangeCheck2}
                      >
                        {this.state.agentstatus.map((status, index) => {
                          if (
                            status.id !== 9 &&
                            status.id !== 3 &&
                            status.id !== 4 &&
                            status.id !== 5 &&
                            status.id !== 6 &&
                            status.id !== 10 &&
                            status.id !== 13 &&
                            status.id !== 11 &&
                            status.id !== 15 &&
                            status.id !== 12 &&
                            status.id !== 20 &&
                            status.id !== 21 &&
                            status.id !== 22 &&
                            status.id !== 16 &&
                            status.id !== 18 &&
                            status.id !== 19 &&
                            status.id !== 14
                          ) {
                            // replace 'condition' with your condition
                            return (
                              <Checkbox key={index} value={status.id}>
                                {status.name}
                              </Checkbox>
                            );
                          }
                          return null; // Add this line to return null if the condition is not met
                        })}
                      </CheckboxGroup1>
                    </div>
                     )}
                      {this.state.retailview && ( 

                                      
                      <div class="card-body">
                        <div style={{ borderBottom: "1px solid #E9E9E9" }}>
                          <Checkbox
                            indeterminate={this.state.indeterminate2}
                            onChange={this.onCheckAllChange2}
                            checked={this.state.checkAll2}
                          >
                            OTHERS
                          </Checkbox>
                        </div>
                        <br />
                        <CheckboxGroup1
                          // options={plainOptions2}
                          value={this.state.checkedList2}
                          onChange={this.onChangeCheck2}
                        >
                          {this.state.agentstatus.map((status, index) => {
                            if (
                              status.id !== 9 &&
                              status.id !== 3 &&
                              status.id !== 4 &&
                              status.id !== 5 &&
                              status.id !== 6 &&
                              status.id !== 10 &&
                              status.id !== 13 &&
                              status.id !== 11 &&
                              status.id !== 15 &&
                              status.id !== 12 &&
                              status.id !== 20 &&
                              status.id !== 21 &&
                              status.id !== 22 &&
                              status.id !== 16 &&
                              status.id !== 18 &&
                              status.id !== 19 &&
                              status.id !== 14 &&
                              status.id !==0
                            ) {
                              // replace 'condition' with your condition
                              return (
                                <Checkbox key={index} value={status.id}>
                                  {status.name}
                                </Checkbox>
                              );
                            }
                            return null; // Add this line to return null if the condition is not met
                          })}
                        </CheckboxGroup1>
                      </div>
                      )}
                  {/* </div> */}
                </div>
              </div>
            <div className="row">
              <div className="col mt-6">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={this.state.loadingbtn}
                  onClick={this.searchDetailInventory}
                >
                  Search
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: 8 }}
                  onClick={this.handleReset}
                >
                  Clear
                </Button>
              </div>
            </div>
         
          <div className="bg-white padd0 mt-4">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
          <Drawer
            title={`Device Log`}
            width={520}
            onClose={this.onClose}
            visible={this.state.visible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.logdata}
              renderItem={(item) => (
                <List.Item>
                  <div className="row">
                    <div className="col-12">
                      <List.Item.Meta
                        title={`IMEI Number : ${item.imei}`}
                        description={item.short_description}
                      />
                    </div>
                    <div className="col-4 mt-2y">
                      <Tag>{item.created_at}</Tag>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

const WrappedAdvancedSearchForm = Form.create({ name: "advanced_search" })(
  DetailInventory
);

export default connect(mapStateToProps, {
  DetailInventoryView,
  getinventorystatus,
  getofficelistname,
  getagentlistname,
  searchdetailinventorylist,
  getdevicelogstatus,
  detailed_inventory_export,
  getretailers,
  getdevicetype,
  getibomarketlist,
})(WrappedAdvancedSearchForm);
