import React, { Component } from "react";
import { connect } from "react-redux";
import {
  onboarding_status,
  onboarding_email_status,
  onboarding_status_sorce,
  searchonboardingreport,
  Profile_completed_list,
  searchprofile_completed_List,
  resendinviteuser,
  getofficelistname,
  onboardinglog,
  resendreminderuser,
  rad_issues_status,
  bgc_issues_status,
  submitRadVerify,
  linkRadVerify,
  submitBgcVerify,
  resendinviteusernew,
  updatecandidateuser,
  viewcommentsuseraction,
  addcommentsuseraction,
  addtrackbgc,
  export_profil_completed,
  send_custom_email_user,
  viewcomments_user_Onboarding_candidates_action,
  addcomments_user_Onboarding_candidates_action,
  onboarding_email_status_text,
  change_status_for_candidate,
  short_us_states,
} from "../../actions/assetAction";
import _ from "lodash";
import {
  Button,
  Table,
  Tag,
  Input,
  Form,
  Select,
  DatePicker,
  message,
  Tooltip,
  Drawer,
  Divider,
  List,
  Col,
  Row,
  Radio,
  Avatar,
  Spin,
  Empty,
  Checkbox,
} from "antd";
import axiosInstance from "../../actions/AxiosInstance";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import moment from "moment";
import createHistory from "history/createBrowserHistory";

const { Option } = Select;

class Profilecompleted extends Component {
  constructor(props) {
    super(props);
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      onboarding: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn: false,
      loadingSBTbtn: false,
      startdate: "",
      enddate: "",
      startValue: null,
      endValue: null,
      endOpen: false,
      selectedItems: [],
      inventorystatus: [],
      search_text: "",
      selectedfieldname: [],
      selectedfilter: [],
      selectedstatus: [],
      selectedoffice: [],
      office: [],
      agent: [],
      detailform: {},
      statusval: [],
      visible: false,
      visible1: false,
      visibleBgc: false,
      activeagent: [],
      radIssues: [],
      bgcIssues: [],
      dynamicRadValue: 1,
      superrole: "",
      visibleedit: false,
      activeedit: "",
      cvisible: false,
      Listdata1: [],
      comvisible: false,
      fetching: false,
      fdata: [],
      sendmailview: false,
      emailstatus: [],
      customemail: false,
      loadingCMdbtn: false,
      statusemailtext: [],
      activcandidate: [],
      statusvisible: false,
      linkradvisible: false,
      checkrad:false,
      shortstate: [],
      selectedsorcestatus:[],
    };
  }

  componentWillMount() {
    const history = createHistory();
    const location = history.location;
    console.log(location.pathname);
    localStorage.setItem("currentpath", location.pathname);
    const masteruser = this.props.user.role_id.filter((word) => word === 1);
    if (masteruser.length !== 0) {
      this.setState({
        superrole: true,
      });
    }

    this.props.short_us_states().then((res) => {
      console.log(res);
      this.setState({
        shortstate: res.data,
      });
    });
   
    

    this.props.onboarding_status().then((res) => {
      console.log(res.data);
      this.setState({
        onboarding: res.data,
      });
    });
    this.props.onboarding_email_status().then((res) => {
      console.log(res.data);
      this.setState({
        emailstatus: res.data,
      });
    });
    this.props.rad_issues_status().then((res) => {
      console.log("rad issues", res.data);
      this.setState({
        radIssues: res.data,
      });
    });
    // get bgc issues
    this.props.bgc_issues_status().then((res) => {
      console.log("bgc issues", res.data);
      this.setState({
        bgcIssues: res.data,
      });
    });
    this.props.onboarding_email_status_text().then((res) => {
      console.log(res.data);
      this.setState({
        statusemailtext: res.data,
      });
    });
     //Onboarding source status
     this.props.onboarding_status_sorce().then((res) => {
      console.log(res.data);
      this.setState({
        onboardingSorce: res.data,
      });
    });

    this.props
      .getofficelistname()
      .then((res) => {
        console.log(res.data);
        this.setState({
          office: res.data,
        });
      })
      .catch((err) => {});

    let onboarding_data = JSON.parse(sessionStorage.getItem("onboarding_data"));
    
    this.setState({
      
      startValue:
        onboarding_data === null || onboarding_data.date_range_start === undefined
          ? null
          : onboarding_data.date_range_start === null
          ? null
          : moment(onboarding_data.date_range_start) ?? null,
      endValue:
        onboarding_data === null || onboarding_data.date_range_end === undefined
          ? null
          : onboarding_data.date_range_end === null
          ? null
          : moment(onboarding_data.date_range_end) ?? null,
      sortField: onboarding_data === null ? "" : onboarding_data.sort ?? "",
      sortOrder: onboarding_data === null ? "" : onboarding_data.sort_by ?? "",
    });
    this.getdesignation({
      results: 10,
      page: onboarding_data === null ? 1 : onboarding_data.currentpage ?? 1,
      selectedstatus: onboarding_data === null ? [] : onboarding_data.status,
     
      startValue:
      onboarding_data === null || onboarding_data.date_range_start === undefined
          ? null
          : onboarding_data.date_range_start === null
          ? null
          : moment(onboarding_data.date_range_start) ?? null,
      endValue:
      onboarding_data === null || onboarding_data.date_range_end === undefined
          ? null
          : onboarding_data.date_range_end === null
          ? null
          : moment(onboarding_data.date_range_end) ?? null,
      selectedoffice:
        onboarding_data === null ? [] : onboarding_data.office_id ?? [],
      sortField: onboarding_data === null ? "" : onboarding_data.sort ?? "",
      sortOrder: onboarding_data === null ? "" : onboarding_data.sort_by ?? "",
    });
    if (!onboarding_data) {
      onboarding_data = {};
    }
   
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .Profile_completed_list(params)
      .then((res) => {
        console.log(res.data.data.data);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.data.total;
        pagination.current = res.data.data.current_page;
        this.setState({
          data: res.data.data.data,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    console.log(pager);

    const order =
      sorter.order === "ascend"
        ? "ASC"
        : sorter.order === undefined
        ? ""
        : "DESC";
    const fieldName = sorter.order === undefined ? "" : sorter.field;
    this.setState({
      pagination: pager,
      sortField: fieldName,
      sortOrder: order,
    });
    setTimeout(() => {
      this.searchonboardingreport(fieldName, order, pager);
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };
  renderonboarding() {
    return _.map(this.state.onboarding, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  
  renderonboardingemail() {
    return _.map(this.state.emailstatus, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  // render rad issues options
  renderOptions = () => {
    return _.map(this.state.radIssues, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };

  // render bgc issues options
  renderbgcOptions = () => {
    return _.map(this.state.bgcIssues, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  };
  onchangeRole = (selectedItems) => {
    this.setState({ selectedItems });
    console.log(this.state.selectedItems);
  };

  searchonboardingreport = (sortName, order, pager, reset) => {
    this.setState({ loading: true, loadingbtn: true });
    var status = this.state.selectedstatus;
    var source_status = this.state.selectedsorcestatus;
    var search_text = this.state.name;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var office_id = this.state.selectedoffice;
    var sort = sortName ?? this.state.sortField;
    var sort_by = order ?? this.state.sortOrder;
    var page =
      reset === "reset"
        ? 1
        : pager?.current ?? this.state.pagination?.current ?? 1;
    sessionStorage.setItem(
      "onboarding_data",
      JSON.stringify({
        status: status,
        search_text: search_text,
        currentpage: page ?? 1,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        office_id,
        source_status,
      })
    );

    this.props
      .searchprofile_completed_List({
        status: status,
        search_text: search_text,
        page: page ?? 1,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        office_id,
        source_status,
      })
      .then((res) => {
        console.log(res);
        const pagination = { ...this.state.pagination };
        pagination.total = res.data.data.total;
        pagination.current = res.data.data.current_page;
        this.setState({
          data: res.data.data.data,
          loading: false,
          loadingbtn: false,
          pagination,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          loadingbtn: false,
        });
      });
  };

  clearSearch = () => {
    sessionStorage.removeItem("onboarding_data");
    this.setState({
      status: undefined,
      name: "",
      startValue: null,
      endValue: null,
      selectedoffice: [],
      selectedstatus: [],
      selectedItems: [],
      fdata: [],
      selectedsorcestatus:[],
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };
  clearSearchFields = () => {
    this.setState({
      status: undefined,
      name: "",
      startValue: null,
      endValue: null,
      selectedoffice: [],
      selectedstatus: [],
      selectedItems: [],
      fdata: [],
      selectedsorcestatus:[],
    });
  };
  onchangefilter = (selectedstatus) => {
    this.setState({ selectedstatus });
  };
  onchangeoffice = (selectedoffice) => {
    this.setState({ selectedoffice });
  };
  disabledStartDate = (startValue) => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChangerad = (e) => {
    this.setState({
      dynamicRadValue: e.target.value,
    });
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  onStartChange = (value) => {
    this.onChange("startValue", value);
  };

  onEndChange = (value) => {
    this.onChange("endValue", value);
  };
  handleStartOpenChange = (open) => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };
  onchangesorcefilter = (selectedsorcestatus) => {
    this.setState({ selectedsorcestatus });
  };
  handleEndOpenChange = (open) => {
    this.setState({ endOpen: open });
  };

  resendoption = (params) => {
    this.setState({ loading: true });
    this.props
      .resendinviteuser(params)
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        this.setState({ loading: false });
        this.searchonboardingreport();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };

  edituseroption = (params) => {
    console.log(params);
    this.setState({ visibleedit: true, activeedit: params.id });
    this.props.form.setFieldsValue({
      address1: params.street_address1,
      address2: params.street_address2,
      fname: params.first_name,
      lname: params.last_name,
      mname: params.middle_name,
      city: params.city,
      state: params.state,
      zip: params.zip,
    });
  };

  resendoptionnew = (params) => {
    this.setState({ loading: true });
    this.props
      .resendinviteusernew(params)
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        this.setState({ loading: false });
       
        this.searchonboardingreport();
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        this.setState({ loading: false });
      });
  };
  resendreminder = (params) => {
    this.setState({ loading: true });
    this.props
      .resendreminderuser(params)
      .then((res) => {
        console.log(res);
        message.success(res.data.message);
        this.setState({ loading: false });
       
      })
      .catch((err) => {
        console.log(err);
        message.error(err);
        this.setState({ loading: false });
      });
  };
  renderOffice() {
    return _.map(this.state.office, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  rendercanlog = (params) => {
    this.setState({ loading: true });
    this.props
      .onboardinglog(params)
      .then((res) => {
        console.log(res.data.items);
        this.setState({ Listdata: res.data.items });
        message.success(res.data.message);
        this.setState({ loading: false, visible: true });
       
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };

  handleRadSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      [
        "name",
        "rad_comments",
        "link_rad_verification",
        "rad_issue_status",
        "rad_id",
      ],
      (err, values) => {
        console.log(values);
        if (!err) {
          this.setState({ loading: true, radbtn: true });
          var obj_arr = {
            candidate_id: this.state.activeagent.id,
            link_rad_verification: values?.link_rad_verification ?? "",
            rad_issue_status: values?.rad_issue_status ?? "",
            rad_comments: values?.rad_comments ?? "",
            rad_id: values?.rad_id ?? "",
          };

          this.props
            .linkRadVerify(obj_arr)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                linkradvisible: false,
                dynamicRadValue: 1,
                loading: false,
                activeagent: "",
                radbtn: false,
              });
              this.props.form.resetFields();
             
              this.searchonboardingreport();
            })
            .catch((error) => {
              message.error(error);
              console.log(error);
              this.setState({ loading: false, radbtn: false });
            });
        }
      }
    );
  };

  handleverifyRadSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["name1", "rad_comments1", "is_rad_issue1", "rad_id1", "telgoo_id"],
      (err, values) => {
        console.log(values);
        if (!err) {
          this.setState({ loading: true, radbtn: true });
          var obj_arr = {
            candidate_id: this.state.activeagent.id,
            rad_verification: values.is_rad_issue1,
            rad_comments: values.rad_comments1,
            rad_id: values.rad_id1,
            telgoo_id: values.telgoo_id ?? "",
          };

          this.props
            .submitRadVerify(obj_arr)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                verifyradvisible: false,
                dynamicRadValue: 1,
                loading: false,
                activeagent: "",
                radbtn: false,
              });
              this.props.form.resetFields();
             
              this.searchonboardingreport();
            })
            .catch((error) => {
              message.error(error);
              console.log(error);
              this.setState({ loading: false, radbtn: false });
            });
        }
      }
    );
  };
  handleBgcSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["bgc_comments", "bgc_issue_status", "bname"],
      (err, values) => {
        this.setState({ loading: true });
        console.log(values);
        if (!err) {
          var obj_arr1 = {
            candidate_id: this.state.activeagent.id,
            bgc_issue_status: values.bgc_issue_status,
            bgc_comments: values.bgc_comments,
          };

          this.props
            .submitBgcVerify(obj_arr1)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                visibleBgc: false,
                loading: false,
              });
             
              this.props.form.resetFields();
              this.searchonboardingreport();
            })
            .catch((error) => {
              message.error(error.data.message);
              console.log(error);
              this.setState({ loading: false });
            });
        }
      }
    );
  };

  handleUpdateUser = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      [
        "fname",
        "lname",
        "address1",
        "address2",
        "mname",
        "city",
        "state",
        "zip",
      ],
      (err, values) => {
        console.log(values);
        if (!err) {
          this.setState({ loading: true, loadingSBTbtn: true });
          var obj_arr = {
            first_name: values.fname,
            last_name: values.lname,
            middle_name: values.mname,
            street_address1: values.address1,
            street_address2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zip,
          };

          this.props
            .updatecandidateuser(obj_arr, this.state.activeedit)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                visible1: false,
                loading: false,
                loadingSBTbtn: false,
                visibleedit: false,
                activeedit: "",
              });
              this.props.form.resetFields();
          
              this.searchonboardingreport();
            })
            .catch((error) => {
              message.error(error.data.message);
              console.log(error);
              this.setState({ loading: false, loadingSBTbtn: false });
            });
        }
      }
    );
  };
  handleSendEmail = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["emailcontent", "email_type"],
      (err, values) => {
        console.log(values);
        if (!err) {
          this.setState({ loading: true, loadingSBTbtn: true });
          var obj_arr = {
            onboarding_status: values.email_type,
            email_text: values?.emailcontent ?? "",
          };

          this.props
            .send_custom_email_user(obj_arr, this.state.activeedit)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                sendmailview: false,
                loading: false,
                loadingSBTbtn: false,
                activeedit: "",
              });
              this.props.form.resetFields();
            
              this.searchonboardingreport();
            })
            .catch((error) => {
              message.error(error.data.message);
              console.log(error);
              this.setState({ loading: false, loadingSBTbtn: false });
            });
        }
      }
    );
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onClose1 = () => {
    this.setState({
      cvisible: false,
    });
  };
  onClose2 = () => {
    this.setState({
      visible1: false,
    });
  };

  renderlinkverify = (params) => {
    this.setState({ linkradvisible: true, activeagent: params });
    this.props.form.setFieldsValue({
      name: params.first_name + " " + params.last_name,
      rad_id: params.rad_id,
    });
  };

  renderradverify = (params) => {
    console.log(params);
    this.setState({ verifyradvisible: true, activeagent: params });
    this.props.form.setFieldsValue({
      name1: params.first_name + " " + params.last_name,
      rad_id1: params.rad_id,
    });
  };

  renderbgcverify = (params) => {
    this.setState({ visibleBgc: true, activeagent: params });
    this.props.form.setFieldsValue({
      bname: params.first_name + " " + params.last_name,
    });
  };
  // onboarding source status
  renderonboarding_source_status() {
    return _.map(this.state.onboardingSorce, (master, index) => {
      //console.log(master.id);
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }

  onCloseBgc = () => {
    this.setState({
      visibleBgc: false,
      activeagent: [],
    });
  };
  onCloseEdit = () => {
    this.setState({
      visibleedit: false,
    });
  };
  getrefreshbtn = () => {
    this.getdesignation({
      results: 10,
      page: 1,
    });
    this.setState({
      status: undefined,
      name: "",
      startValue: null,
      endValue: null,
      selectedoffice: [],
      selectedstatus: [],
      selectedsorcestatus:[],
    });
  };
  addtrackbtn = () => {
    this.setState({ loading: true });
    this.props
      .addtrackbgc()
      .then((res) => {
        this.setState({ loading: false, loadingTBTbtn: false });
        message.success(res.data.message);
      })
      .catch((err) => {
        this.setState({ loading: false, loadingTBTbtn: false });
        message.error(err.data.message);
      });
  };
  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/reference/offices?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: user.name,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  getexportbtn = () => {
    var status = this.state.selectedstatus;
    var source_status = this.state.selectedsorcestatus;
    var search_text = this.state.name;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var date_range_start = this.state.startValue;
    var date_range_end = this.state.endValue;
    var office_id = this.state.selectedoffice;
    this.setState({ loading: true, loadingEPbtn: true });
    this.props
      .export_profil_completed({
        status: status,
        search_text: search_text,
        sort,
        sort_by,
        date_range_start,
        date_range_end,
        office_id,
        source_status,
      })
      .then((res) => {
        this.setState({ loading: false, loadingEPbtn: false });
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn: false });
      });
  };
  sendemailuser = (params) => {
    console.log(params);
    this.setState({
      sendmailview: true,
      activcandidate: params,
      activeedit: params.id,
    });
  };
  onCloseEmail = () => {
    this.setState({ sendmailview: false });
    this.props.form.resetFields();
  };
  // UserCommand Function
  editcommentsuser = (params) => {
    this.setState({ loading: true });
    this.props
      .viewcomments_user_Onboarding_candidates_action(params)
      .then((res) => {
        console.log(res.data.items);
        this.setState({ Listdata1: res.data.items });
        message.success(res.data.message);
        this.setState({ loading: false, cvisible: true });
      })
      .catch((err) => {
        console.log(err);
        message.error(err.data.message);
        this.setState({ loading: false });
      });
  };
  onCloseEdit = () => {
    this.setState({
      visibleedit: false,
      comvisible: false,
    });
  };
  onClose1 = () => {
    this.setState({
      cvisible: false,
    });
  };
  onClose2 = () => {
    this.setState({
      comvisible: false,
    });
  };
  onClose3 = () => {
    this.props.form.resetFields();
    this.setState({
      statusvisible: false,
      cand_id: "",
    });
  };
  onClose4 = () => {
    this.setState({
      linkradvisible: false,
      verifyradvisible: false,
      activeagent: "",
      checkrad:false
    });
    this.props.form.resetFields();
  };
  addcommentsuser = (params) => {
    this.setState({ comvisible: true, cand_id: params });
  };
  handleCommentSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["admin_comments"],
      (err, values) => {
        console.log(values);
        console.log("first", this.state.cand_id.id);
        if (!err) {
          this.setState({ loading: true, loadingCMdbtn: true });
          var obj_arr1 = {
            cand_id: this.state.cand_id.id,
            comments: values.admin_comments,
          };

          this.props
            .addcomments_user_Onboarding_candidates_action(obj_arr1)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                comvisible: false,
                loading: false,
                loadingCMdbtn: false,
                cand_id: "",
              });
              this.props.form.resetFields();
              this.searchonboardingreport();
            })
            .catch((error) => {
              message.error(error);
              console.log(error);
              this.setState({ loading: false, loadingCMdbtn: false });
            });
        }
      }
    );
  };
  Changeemailtype = (params) => {
    console.log(params);
    const templatetext = this.state.statusemailtext.filter(
      (txt) => txt.id === params
    );
    this.props.form.setFieldsValue({ emailcontent: templatetext[0].name });
  };
  changestatususer = (params) => {
    console.log(params);
    this.setState({
      statusvisible: true,
      cand_id: params,
    });
  };
  handleStatusSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(
      ["status_admin_comments"],
      (err, values) => {
        if (!err) {
          this.setState({ loading: true, loadingCMdbtn: true });
          var obj_arr1 = {
            cand_id: this.state.cand_id.id,
            comments: values.status_admin_comments,
          };

          this.props
            .change_status_for_candidate(obj_arr1)
            .then((res) => {
              console.log("res", res.data.message);
              message.success(res.data.message);
              this.setState({
                statusvisible: false,
                loading: false,
                loadingCMdbtn: false,
                cand_id: "",
              });
              this.props.form.resetFields();
              this.searchonboardingreport();
            })
            .catch((error) => {
              message.error(error);
              console.log(error);
              this.setState({ loading: false, loadingCMdbtn: false });
            });
        }
      }
    );
  };
  handleChange = (e) => {
    console.log(e.target.checked);
    this.setState({
      checkrad: e.target.checked,
    });
  };

  renderstateshort() {
    return _.map(this.state.shortstate, (master, index) => {
      return (
        <Option value={master.short_name} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  // ======================================================

  render() {
    const { endOpen, startValue, endValue, superrole, fetching, fdata } =
      this.state;
    const { getFieldDecorator } = this.props.form;

    const columns = [
      {
        title: "RAD ID",
        dataIndex: "rad_id",
        key: "rad_id",
      },
      {
        title: "RAD Action",
        dataIndex: "radaction",
        key: "radaction",
        render: (_, record) => (
          <span>
            {record.rad_verification === 0 ? (
               <Link
               to={{
                 pathname: "/verifyagent",
                 agent_info: record,
               }}
             >
              <Button
                // onClick={() => this.renderradverify(record)}
                type="primary"
              >
                Verify RAD ID & PII
              </Button>
              </Link>
            ) : (
              <Button disabled type="primary">
              Verify RAD ID & PII
              </Button>
            )}
          </span>
        ),
        hidden: superrole === true ? false : true,
      },
      {
        title: "Status",
        dataIndex: "status_name",
        key: "status_name",
        render: (text, record) => (
          <span>
            <Tag color="orange">{record.status_name}</Tag>
          </span>
        ),
      },
      {
        title: "Source",
        dataIndex: "onboarding_source_name",
        key: "onboarding_source_name",
        render: (text, record) => (
          <span>
            <Tag color="orange">{record.onboarding_source_name}</Tag>
          </span>
        ),
      },
      {
        title: "Retailer Name",
        dataIndex: "retailer_name",
        key: "retailer_name",
        hidden: superrole === true ? false : true,
      },
      {
        title: "IBOs / Markets",
        dataIndex: "ibo_market_name",
        key: "ibo_market_name",
      },
      {
        title: "Office",
        dataIndex: "office_name",
        key: "office_name",
      },
      {
        title: "ETC Code",
        dataIndex: "telgoo_id",
        key: "telgoo_id",
      },
      {
        title: "First Name",
        dataIndex: "first_name",
        key: "first_name",
        sorter: true,
      },
      {
        title: "Middle Name",
        dataIndex: "middle_name",
        key: "middle_name",
        sorter: true,
      },
      {
        title: "Last Name",
        dataIndex: "last_name",
        key: "last_name",
        sorter: true,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: true,
      },
      {
        title: "Date Of Birth",
        dataIndex: "dob",
        key: "dob",
        sorter: true,
        hidden: superrole === true ? false : true,
      },
      {
        title: "SSN Number",
        dataIndex: "masked_ssn_number",
        key: "masked_ssn_number",
        sorter: true,
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile_number",
        key: "mobile_number",
        sorter: true,
      },
      {
        title: "Address1",
        dataIndex: "street_address1",
        key: "street_address1",
      },
      {
        title: "Address2",
        dataIndex: "street_address2",
        key: "street_address2",
      },
      {
        title: "City",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "State",
        dataIndex: "state",
        key: "state",
      },
      {
        title: "Zip",
        dataIndex: "zip",
        key: "zip",
      },

      {
        title: "Profile Picture",
        dataIndex: "photo_copy_url",
        key: "photo_copy_url",
        render: (_, record) => (
          <span>
            {record.photo_copy_url !== null && (
              <a
                href={record?.photo_copy_url ?? ""}
                target="_blank"
                rel="noreferrer"
              >
                <Avatar
                  shape="square"
                  size={64}
                  src={record?.photo_copy_url ?? ""}
                />
              </a>
            )}
          </span>
        ),
      },
      {
        title: "Government ID",
        dataIndex: "government_document_url",
        key: "government_document_url",
        render: (_, record) => (
          <span>
            {record.government_document_url !== null && (
              <a
                href={record?.government_document_url ?? ""}
                target="_blank"
                rel="noreferrer"
              >
                <Avatar
                  shape="square"
                  size={64}
                  src={record?.government_document_url ?? ""}
                />
              </a>
            )}
          </span>
        ),
      },
      {
        title: "Comments",
        dataIndex: "status_change_comments",
        key: "status_change_comments",
      },
      {
        title: "BGC File",
        dataIndex: "bgc_file",
        key: "bgc_file",
        hidden: superrole === true ? false : true,
        render: (_, record) => (
          <span>
            {record.bgc_file_url !== null ? (
              <span>
                {/* <Tooltip title="Click Here Download"> */}
                {/* <a
                    href={record?.bgc_file_url ?? ""}
                    target="_blank"
                    rel="noreferrer"
                  > */}
                {record.bgc_file}
                {/* </a> */}
                {/* </Tooltip> */}
              </span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        title: "BGC Acceptance Form",
        dataIndex: "candidate_signed_bgc_url",
        key: "candidate_signed_bgc_url",
        headerAlign: "center",
        align: "center",
        render: (_, record) => (
          <span>
            {record.candidate_signed_bgc_url !== null ? (
              <span>
                <Tooltip title="Click Here Download">
                  <Button
                    type="primary"
                    shape="circle"
                    icon="download"
                    onClick={(e) => {
                      window.open(
                        record?.candidate_signed_bgc_url ?? "",
                        "_blank"
                      );
                    }}
                  />
                </Tooltip>
              </span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        title: "Retailer Agent Agreement",
        dataIndex: "retailer_agent_agreement_url",
        key: "retailer_agent_agreement_url",
        headerAlign: "center",
        align: "center",
        render: (_, record) => (
          <span>
            {record.retailer_agent_agreement_url !== null ? (
              <span>
                <Tooltip title="Click Here Download">
                  <Button
                    type="primary"
                    shape="circle"
                    icon="download"
                    onClick={(e) => {
                      window.open(
                        record?.retailer_agent_agreement_url ?? "",
                        "_blank"
                      );
                    }}
                  />
                </Tooltip>
              </span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        title: "Supporting Document",
        dataIndex: "support_document_url",
        key: "support_document_url",
        headerAlign: "center",
        align: "center",
        render: (_, record) => (
          <span>
            {record.support_document_url !== null ? (
              <span>
                <Tooltip title="Click Here Download">
                  <Button
                    type="primary"
                    shape="circle"
                    icon="download"
                    onClick={(e) => {
                      window.open(record?.support_document_url ?? "", "_blank");
                    }}
                  />
                </Tooltip>
              </span>
            ) : (
              ""
            )}
          </span>
        ),
      },
      {
        title: "A01",
        dataIndex: "course_one",
        key: "course_one",
      },

      {
        title: "A02",
        dataIndex: "course_two",
        key: "course_two",
      },

      {
        title: "A03",
        dataIndex: "course_three",
        key: "course_three",
      },

      {
        title: "A04",
        dataIndex: "course_four",
        key: "course_four",
      },

      {
        title: "A05",
        dataIndex: "course_five",
        key: "course_five",
      },
      {
        title: "Lifeline / ACP",
        dataIndex: "course_six",
        key: "course_six",
      },

      {
        title: "Created Date",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
      },
      {
        title: "Last Updated",
        dataIndex: "updated_at",
        key: "updated_at",
        sorter: true,
      },
      {
        title: "Shirt Size",
        dataIndex: "shirt_size",
        key: "shirt_size",
      },
      {
        title: "BGC Action Status",
        dataIndex: "bgc_issue_status_text",
        key: "bgc_issue_status_text",
        render: (text, record) => (
          <span>
            {record.bgc_issue_status_text === null ? (
              ""
            ) : (
              <span>
                <Tag color="orange">{record.bgc_issue_status_text}</Tag>
              </span>
            )}
          </span>
        ),
      },
      {
        title: "BGC Action",
        dataIndex: "bgcaction",
        key: "bgcaction",
        render: (_, record) => (
          <span>
            {record.bgc_issue_status === 6 ? (
              <Button
                onClick={() => this.renderbgcverify(record)}
                type="primary"
              >
                Verify BGC
              </Button>
            ) : (
              <Button disabled type="primary">
                Verify BGC
              </Button>
            )}
          </span>
        ),
        hidden: superrole === true ? false : true,
      },
      // {
      //   title: "Comments Action",
      //   dataIndex: "caction",
      //   key: "caction",
      //   align: "center",
      //   render: (_, record) => (
      //     <span>
      //       <Tooltip title="View Comments">
      //         <Button
      //           shape="circle"
      //           icon="eye"
      //           onClick={() => this.editcommentsuser(record)}
      //           type="primary"
      //         ></Button>
      //       </Tooltip>
      //       <Divider type="vertical" />
      //       {superrole && (
      //         <>
      //           <Tooltip title="Add Comments">
      //             <Button
      //               shape="circle"
      //               icon="message"
      //               onClick={() => this.addcommentsuser(record)}
      //               type="primary"
      //             ></Button>
      //           </Tooltip>
      //         </>
      //       )}
      //     </span>
      //   ),
      //   // hidden: superrole === true ? false : true,
      // },
      // {
      //   title: "Status Action",
      //   dataIndex: "caction1",
      //   key: "caction1",
      //   align: "center",
      //   render: (_, record) => (
      //     <span>
      //       {record.status === 20 ? (
      //         <>
      //           <Tooltip title="Change Status">
      //             <Button
      //               shape="circle"
      //               icon="user"
      //               onClick={() => this.changestatususer(record)}
      //               type="primary"
      //             ></Button>
      //           </Tooltip>
      //         </>
      //       ) : (
      //         <>
      //           <Tooltip title="Change Status">
      //             <Button
      //               shape="circle"
      //               icon="user"
      //               disabled
      //               type="primary"
      //             ></Button>
      //           </Tooltip>
      //         </>
      //       )}
      //     </span>
      //   ),
      //   hidden: superrole === true ? false : true,
      // },
      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        fixed: "right",
        width: 430,
        render: (_, record) => (
          <span>
            {record.comments_exists === 0 ? (
              <>
                <Tooltip title="View Comments">
                  {/* {console.log("View Comments",record)} */}
                  <Button
                    shape="circle"
                    icon="eye"
                    disabled
                    type="primary"
                  ></Button>
                </Tooltip>
              </>
            ) : (
              <Tooltip title="View Comments">
                <Button
                  shape="circle"
                  icon="eye"
                  onClick={() => this.editcommentsuser(record)}
                  type="primary"
                ></Button>
              </Tooltip>
            )}

            {superrole && (
              <>
                <Divider type="vertical" />
                <Tooltip title="Add Comments">
                  <Button
                    shape="circle"
                    icon="message"
                    onClick={() => this.addcommentsuser(record)}
                    type="primary"
                  ></Button>
                </Tooltip>
              </>
            )}
            {record.status === 20 ? (
              <>
                <Divider type="vertical" />
                <Tooltip title="Change Status">
                  <Button
                    shape="circle"
                    icon="user"
                    onClick={() => this.changestatususer(record)}
                    type="primary"
                  ></Button>
                </Tooltip>
              </>
            ) : (
              <>
                <Divider type="vertical" />
                <Tooltip title="Change Status">
                  <Button
                    shape="circle"
                    icon="user"
                    disabled
                    type="primary"
                  ></Button>
                </Tooltip>
              </>
            )}
            {superrole === true ? (
              <>
                <Divider type="vertical" />{" "}
                <Tooltip title="Send Email">
                  <Button
                    shape="circle"
                    icon="notification"
                    theme="filled"
                    onClick={() => this.sendemailuser(record)}
                    type="primary"
                  ></Button>
                </Tooltip>
                <Divider type="vertical" />{" "}
              </>
            ) : (
              <>
                <Divider type="vertical" />{" "}
                <Tooltip title="Send Email">
                  <Button
                    shape="circle"
                    icon="notification"
                    theme="filled"
                    disabled
                    type="primary"
                  ></Button>
                </Tooltip>
                <Divider type="vertical" />{" "}
              </>
            )}
            {record.status >= 3 ? (
              record.status !== 13 ? (
                <Tooltip title="Edit Candidate">
                  <Link
                    to={{
                      pathname: "/editagent",
                      agent_info: record,
                    }}
                  >
                    <Button shape="circle" icon="edit" type="primary"></Button>
                  </Link>
                </Tooltip>
              ) : (
                <Tooltip title="Edit Candidate">
                  <Button
                    disabled
                    shape="circle"
                    icon="edit"
                    type="primary"
                  ></Button>
                </Tooltip>
              )
            ) : (
              <Tooltip title="Edit Candidate">
                <Button
                  disabled
                  shape="circle"
                  icon="edit"
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            <Divider type="vertical" />
            {record.status === 1 ? (
              <Tooltip title="Resend Invite">
                <Button
                  shape="circle"
                  icon="mail"
                  onClick={() => this.resendoption(record)}
                  type="primary"
                ></Button>
              </Tooltip>
            ) : (
              <Tooltip title="Email Reminder">
                <Button
                  shape="circle"
                  icon="mail"
                  onClick={() => this.resendoptionnew(record)}
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            <Divider type="vertical" />
            {record.status !== 13 ? (
              <Tooltip title="SMS Reminder">
                <Button
                  shape="circle"
                  icon="mobile"
                  onClick={() => this.resendreminder(record)}
                  type="primary"
                ></Button>
              </Tooltip>
            ) : (
              <Tooltip title="SMS Reminder">
                <Button
                  shape="circle"
                  icon="mobile"
                  disabled
                  type="primary"
                ></Button>
              </Tooltip>
            )}
            <Divider type="vertical" />
            <Tooltip title="View log">
              <Button
                shape="circle"
                icon="history"
                onClick={() => this.rendercanlog(record)}
                type="primary"
              ></Button>
            </Tooltip>
          </span>
        ),
      },
    ].filter((item) => !item.hidden);

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                Profile Completed{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              <Button
                type="default"
                className="mr5"
                onClick={() => this.getrefreshbtn()}
              >
                <i className="uil uil-sync" /> Refresh
              </Button>
              {/* {superrole && (
                <Button
                  type="primary"
                  className="mr5"
                  onClick={() => this.addtrackbtn()}
                >
                  Track BGC & Training
                </Button>
              )} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <div className="row padd20 bg-grey">
                {/* <div className="col-3 mt-1">
                <label for="exampleFormControlInput1">Select Office</label>
                <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Type 2 characters to search..!"
                    notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                    value={this.state.selectedoffice}
                    onSearch={this.fetchUser}
                    onChange={this.onchangeoffice}
                    defaultActiveFirstOption={false}
                    filterOption={(input, option) =>
                    option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {fdata.map((d) => (
                    <Option key={d.value}>{d.text}</Option>
                    ))}
                   
                </Select>
                </div> */}
            {/* <div className="col-3  mt-1">
              <label for="exampleFormControlInput1">
                Select Onboarding Status
              </label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedstatus}
                onChange={this.onchangefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderonboarding()}
              </Select>
            </div> */}
            <div className="col-3 mt-1">
              <label for="exampleFormControlInput1">From</label>
              <DatePicker
                value={startValue}
                disabledDate={this.disabledStartDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onStartChange}
                onOpenChange={this.handleStartOpenChange}
              />
            </div>
            <div className="col-3 mt-1">
              <label for="exampleFormControlInput1">To</label>
              <DatePicker
                value={endValue}
                disabledDate={this.disabledEndDate}
                format="MM-DD-YYYY"
                placeholder="Select Date"
                onChange={this.onEndChange}
                open={endOpen}
                onOpenChange={this.handleEndOpenChange}
              />
            </div>

            <div className="col-3  mt-1">
              <label for="exampleFormControlInput1">
                Select Source Status
              </label>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Select Option"
                value={this.state.selectedsorcestatus}
                onChange={this.onchangesorcefilter}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.renderonboarding_source_status()}
              </Select>
            </div>
            <div className="col-3 mt-1">
              <label for="exampleFormControlInput1">Search By keyword</label>
              <Input
                placeholder="Type keyword"
                value={this.state.name}
                // ref={(node) => {
                //   this.searchInput = node;
                // }}
                onChange={(e) =>
                  this.setState({
                    name: e.target.value,
                  })
                }
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="col-3 mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() =>
                  this.searchonboardingreport("", null, null, "reset")
                }
              >
                Search
              </Button>
              <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button>
            </div>
          </div>
          <div className="bg-white padd0 mt-3">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
          <Drawer
            title="View Log"
            placement="right"
            closable={true}
            width={420}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.Listdata}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.created_at}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Drawer>
          <Drawer
            title="Link RAD ID"
            placement="right"
            closable={true}
            width={420}
            onClose={this.onClose4}
            visible={this.state.linkradvisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleRadSubmit}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Candidate Name">
                    {getFieldDecorator("name", {
                      rules: [
                        { required: true, message: "Please enter user name" },
                      ],
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="RAD ID">
                    {getFieldDecorator("rad_id", {
                      rules: [
                        {
                          // pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                          required: true,
                          message: "Please input RAD ID",
                        },
                        {
                          pattern: /^[A-Za-z0-9]+$/,
                          message: "Please enter a valid RAD ID",
                        },
                      ],
                    })(<Input maxLength={9} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Is RAD ID Linked ?">
                    {getFieldDecorator("link_rad_verification", {
                      rules: [
                        { required: true, message: "Please select option" },
                      ],
                    })(
                      <Radio.Group onChange={this.onChangerad}>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                </Col>
                {this.state.dynamicRadValue === 0 && (
                  <Col span={24}>
                    <Form.Item label="RAD Issue Type">
                      {getFieldDecorator("rad_issue_status", {
                        rules: [
                          { required: true, message: "Please choose option" },
                        ],
                      })(
                        <Select
                          filterOption={(input, option) =>
                            option.props.children
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toString().toLowerCase()) >= 0
                          }
                          placeholder="Please Choose the type"
                          // mode="multiple"
                        >
                          {this.renderOptions()}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                )}
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Comments">
                    {getFieldDecorator("rad_comments", {
                      rules: [
                        {
                          required: false,
                          message: "please enter comments",
                        },
                      ],
                    })(
                      <Input.TextArea
                        rows={4}
                        placeholder="please enter  description"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onClose4} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  loading={this.state.radbtn}
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>
          <Drawer
            title="Verify RAD ID"
            placement="right"
            closable={true}
            width={420}
            onClose={this.onClose4}
            visible={this.state.verifyradvisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleverifyRadSubmit}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Candidate Name">
                    {getFieldDecorator("name1", {
                      rules: [
                        { required: true, message: "Please enter user name" },
                      ],
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} className="mt-3">
                  <Form.Item label="RAD ID">
                    {getFieldDecorator("rad_id1", {
                      rules: [
                        {
                          // pattern: new RegExp("^[a-zA-Z0-9]{0,9}$"),
                          required: true,
                          message: "Please input RAD ID",
                        },
                        {
                          pattern: /^[A-Za-z0-9]+$/,
                          message: "Please enter a valid RAD ID",
                        },
                      ],
                    })(<Input maxLength={9} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24} className="mt-3">
                  <Form.Item>
                    {getFieldDecorator("is_rad_issue1", {
                      valuePropName: "checked",
                      rules: [
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("Please check option")
                                ),
                        },
                      ],
                    })(
                      <Checkbox onChange={this.handleChange}>
                        Is RAD ID Verified?
                      </Checkbox>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {this.state.checkrad === true && (
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item label="ETC Code">
                      {getFieldDecorator("telgoo_id", {
                        rules: [
                          { required: true, message: "Please enter ETC code" },
                        ],
                      })(<Input placeholder="Please enter ETC code" />)}
                    </Form.Item>
                  </Col>
                </Row>
              )}

          
              <Row gutter={16}>
                <Col span={24} className="mt-3">
                  <Form.Item label="Comments">
                    {getFieldDecorator("rad_comments1", {
                      rules: [
                        {
                          required: false,
                          message: "please enter comments",
                        },
                      ],
                    })(
                      <Input.TextArea
                        rows={4}
                        placeholder="please enter  description"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onClose4} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  loading={this.state.radbtn}
                  type="primary"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>
          <Drawer
            title="BGC ID Verification"
            placement="right"
            closable={true}
            width={420}
            onClose={this.onCloseBgc}
            visible={this.state.visibleBgc}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleBgcSubmit}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Candidate Name">
                    {getFieldDecorator("bname", {
                      rules: [
                        { required: true, message: "Please enter user name" },
                      ],
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="BGC Issue Type">
                    {getFieldDecorator("bgc_issue_status", {
                      rules: [
                        { required: true, message: "Please choose option" },
                      ],
                    })(
                      <Select
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toString().toLowerCase()) >= 0
                        }
                        placeholder="Please Choose the type"
                        // mode="multiple"
                      >
                        {this.renderbgcOptions()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Comments">
                    {getFieldDecorator("bgc_comments", {
                      rules: [
                        {
                          required: true,
                          message: "please enter comments",
                        },
                      ],
                    })(
                      <Input.TextArea
                        rows={4}
                        placeholder="please enter url description"
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onCloseBgc} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button htmlType="submit" type="primary">
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>
          <Drawer
            title="Edit Candidate Information"
            placement="right"
            closable={true}
            width={540}
            onClose={this.onCloseEdit}
            visible={this.state.visibleedit}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleUpdateUser}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="First Name">
                    {getFieldDecorator("fname", {
                      rules: [
                        { required: true, message: "Please enter first name" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Middle Name">
                    {getFieldDecorator("mname", {
                      rules: [
                        {
                          required: false,
                          message: "Please enter middle name",
                        },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Last Name">
                    {getFieldDecorator("lname", {
                      rules: [
                        { required: true, message: "Please enter last name" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Address1">
                    {getFieldDecorator("address1", {
                      rules: [
                        { required: true, message: "Please enter address" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Address2">
                    {getFieldDecorator("address2", {
                      rules: [
                        { required: false, message: "Please enter address" },
                      ],
                    })(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="City">
                    {getFieldDecorator("city", {
                      rules: [{ required: true, message: "Please enter city" }],
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="State">
                    {getFieldDecorator("state", {
                      rules: [
                        {
                          required: true,
                          message: "Please select State",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select State"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {/* <Option value="AL">Alabama</Option>
                        <Option value="AK">Alaska</Option>
                        <Option value="AZ">Arizona</Option>
                        <Option value="AR">Arkansas</Option>
                        <Option value="CA">California</Option>
                        <Option value="CO">Colorado</Option>
                        <Option value="CT">Connecticut</Option>
                        <Option value="DE">Delaware</Option>
                        <Option value="DC">District Of Columbia</Option>
                        <Option value="FL">Florida</Option>
                        <Option value="GA">Georgia</Option>
                        <Option value="HI">Hawaii</Option>
                        <Option value="ID">Idaho</Option>
                        <Option value="IL">Illinois</Option>
                        <Option value="IN">Indiana</Option>
                        <Option value="IA">Iowa</Option>
                        <Option value="KS">Kansas</Option>
                        <Option value="KY">Kentucky</Option>
                        <Option value="LA">Louisiana</Option>
                        <Option value="ME">Maine</Option>
                        <Option value="MD">Maryland</Option>
                        <Option value="MA">Massachusetts</Option>
                        <Option value="MI">Michigan</Option>
                        <Option value="MN">Minnesota</Option>
                        <Option value="MS">Mississippi</Option>
                        <Option value="MO">Missouri</Option>
                        <Option value="MT">Montana</Option>
                        <Option value="NE">Nebraska</Option>
                        <Option value="NV">Nevada</Option>
                        <Option value="NH">New Hampshire</Option>
                        <Option value="NJ">New Jersey</Option>
                        <Option value="NM">New Mexico</Option>
                        <Option value="NY">New York</Option>
                        <Option value="NC">North Carolina</Option>
                        <Option value="ND">North Dakota</Option>
                        <Option value="OH">Ohio</Option>
                        <Option value="OK">Oklahoma</Option>
                        <Option value="OR">Oregon</Option>
                        <Option value="PA">Pennsylvania</Option>
                        <Option value="RI">Rhode Island</Option>
                        <Option value="SC">South Carolina</Option>
                        <Option value="SD">South Dakota</Option>
                        <Option value="TN">Tennessee</Option>
                        <Option value="TX">Texas</Option>
                        <Option value="UT">Utah</Option>
                        <Option value="VT">Vermont</Option>
                        <Option value="VA">Virginia</Option>
                        <Option value="WA">Washington</Option>
                        <Option value="WV">West Virginia</Option>
                        <Option value="WI">Wisconsin</Option>
                        <Option value="WY">Wyoming</Option> */}
                         {this.renderstateshort()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="ZIP">
                    {getFieldDecorator("zip", {
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: "Please input ZIP",
                        },
                      ],
                    })(<Input maxLength={5} placeholder="ZIP" />)}
                  </Form.Item>
                </Col>
              </Row>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onCloseEdit} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={this.state.loadingSBTbtn}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>
          <Drawer
            title={`Send Email Candidate - ${this.state.activcandidate.first_name} ${this.state.activcandidate.last_name}`}
            placement="right"
            closable={true}
            width={540}
            onClose={this.onCloseEmail}
            visible={this.state.sendmailview}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleSendEmail}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Email Type">
                    {getFieldDecorator("email_type", {
                      rules: [
                        {
                          required: true,
                          message: "Please select type",
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        style={{ width: "100%" }}
                        placeholder="Select type"
                        onChange={this.Changeemailtype}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {this.renderonboardingemail()}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16} className="mt-5">
                <Col span={24}>
                  <Form.Item label="Email Content">
                    {getFieldDecorator("emailcontent", {
                      rules: [
                        {
                          required: true,

                          message: "Please input Email Text ",
                        },
                      ],
                    })(<ReactQuill theme="snow" />)}
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onCloseEmail} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={this.state.loadingSBTbtn}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>

          <Drawer
            title="View Comments"
            placement="right"
            closable={true}
            width={420}
            onClose={this.onClose1}
            visible={this.state.cvisible}
          >
            <List
              itemLayout="horizontal"
              dataSource={this.state.Listdata1}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <Tag color="orange">
                        {item.created_at} - {item.commented_by_name}
                      </Tag>
                    }
                    description={item.comments}
                  />
                </List.Item>
              )}
            />
          </Drawer>

          <Drawer
            title="Add Comments"
            placement="right"
            closable={true}
            width={540}
            onClose={this.onClose2}
            visible={this.state.comvisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleCommentSubmit}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Comments">
                    {getFieldDecorator("admin_comments", {
                      rules: [
                        {
                          required: true,
                          message: "Please input comments",
                        },
                      ],
                    })(
                      <Input.TextArea
                        rows={8}
                        placeholder="Write something..."
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onCloseEdit} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={this.state.loadingCMdbtn}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>
          <Drawer
            title="Change Candidate Status "
            placement="right"
            closable={true}
            width={540}
            onClose={this.onClose3}
            visible={this.state.statusvisible}
            bodyStyle={{ paddingBottom: 80 }}
          >
            <Form layout="vertical" onSubmit={this.handleStatusSubmit}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item label="Comments">
                    {getFieldDecorator("status_admin_comments", {
                      rules: [
                        {
                          required: true,
                          message: "Please input comments",
                        },
                      ],
                    })(
                      <Input.TextArea
                        rows={8}
                        placeholder="Write something..."
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right",
                }}
              >
                <Button onClick={this.onClose3} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={this.state.loadingCMdbtn}
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Drawer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

const WrappedAdvancedSearchForm = Form.create({ name: "advanced_search" })(
    Profilecompleted
);

export default connect(mapStateToProps, {
  onboarding_status,
  onboarding_email_status,
  searchonboardingreport,
  Profile_completed_list,
  searchprofile_completed_List,
  resendinviteuser,
  getofficelistname,
  onboardinglog,
  resendreminderuser,
  rad_issues_status,
  bgc_issues_status,
  submitBgcVerify,
  submitRadVerify,
  resendinviteusernew,
  updatecandidateuser,
  viewcommentsuseraction,
  addcommentsuseraction,
  addtrackbgc,
  export_profil_completed,
  send_custom_email_user,
  viewcomments_user_Onboarding_candidates_action,
  addcomments_user_Onboarding_candidates_action,
  onboarding_email_status_text,
  change_status_for_candidate,
  linkRadVerify,
  short_us_states,
  onboarding_status_sorce,
})(WrappedAdvancedSearchForm);
