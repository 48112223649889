import React from "react";
import ReactDOM from "react-dom";
import Login from "./component/login/Login";
import ForgotPassword from "./component/forgotpassword";
import ResetPassword from "./component/resetpassword";
import LoadingComponent from "./component/LoadingComponent";
import AuthenticatedCompoment from "./component/AuthenticatedComponent";


import * as serviceWorker from "./serviceWorker";
import { Layout } from "antd";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Router } from "react-router-dom";
import history from "./history";
import Sidemenu from "./routes/Header";
import TopHeader from "./routes/topheader";
import Dashboard from "./component/dashboard/portalDashboard";
import changepassword from "./component/dashboard/changepassword";
import errorDenied from "./component/errorDenied";
import { store } from "./store/store";
import Routes from "./routes";
import { Link } from "react-router-dom/cjs/react-router-dom";
// import DisableDevtool from 'disable-devtool';
// const options = {
//   timeOutUrl: "/accessdenied",
//   disableCopy: false, // Whether to disable copying, default is false
//   disableCut: false, // Whether to disable cutting, default is false
//   disablePaste: false, // Whether to disable paste, default is false
//   //url:"/accessdenied"
// }
// DisableDevtool(options);

const { Content } = Layout;

// Hide all console in production
// console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === "production") {
  console.log = function () {};
 
}


ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <BrowserRouter>
        <LoadingComponent>
          <div>
            <Switch>
              <Route path="/" component={Login} exact={true} />
              <Route path="/dashboard" component={Dashboard} exact={true} />
              <Route path="/accessdenied" component={errorDenied} exact={true} />
              <Route
                path="/changepassword"
                component={changepassword}
                exact={true}
              />
              <Route
                path="/forgotpassword"
                component={ForgotPassword}
                exact={true}
              />
              <Route
                path="/resetpassword:id"
                component={ResetPassword}
                exact={true}
              />
              <AuthenticatedCompoment>
                <Layout>
                  <Sidemenu />
                  <Layout>
                    <TopHeader />
                    <Content>
                      <Routes />
                    </Content>
                  </Layout>
                </Layout>
              </AuthenticatedCompoment>
            </Switch>
          </div>
        </LoadingComponent>
      </BrowserRouter>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
