import { useSelector } from "react-redux";
import { approutes } from "./AppRoutes";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
function hasMatchingValues(array1, array2) {
 // console.log(array1);
  // Create a Set from array2 for efficient lookup
  const set2 = new Set(array2);
  //console.log(set2);
  // Iterate through array1
  for (const value of array1) {
    // Check if the value is in set2
    if (set2.has(value)) {
      return true; // Matching value found
    }
  }
  return false; // No matching values found
}

function Routes() {
  const user = useSelector((state) => state.user);
  return approutes
    .filter((route) => hasMatchingValues(route.roles, user.role_id))
    .map((app) => {
      return <Route path={app.path} component={app.component} exact={true} />;
    });
}

export default Routes;
