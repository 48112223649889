import React, { Component } from "react";
import { connect } from "react-redux";
import {
  detailed_sales_report_agent,
  detailed_sales_report_agent_search,
} from "../../actions/salesAction";
import { detailed_sales_export_agent } from "../../actions/exportAction";
import _ from "lodash";
import { Button, Table, Select, message, Spin, Empty } from "antd";
import moment from "moment";
import { getagentlistname } from "../../actions/userListAction";

import { Line } from "peity-react";
import axiosInstance from "../../actions/AxiosInstance";
import debounce from "lodash/debounce";

const { Option } = Select;

class SalesAgentView extends Component {
  constructor(props) {
    super(props);
    this.fetchUser = debounce(this.fetchUser, 800);
    this.state = {
      marketer: [],
      data: [],
      pagination: {},
      loading: true,
      loadingbtn:false,
      loadingEPbtn:false,
      retailer: [],
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      sort_list: [],
      inventorystatus: [],
      search_text: "",
      datefilter: [],
      startdate: "",
      enddate: "",
      dashdata: "",
      fetching: false,
      fdata: [],
      office_id:""
    };
  }

  componentWillMount() {
    if(this.props.location.office_id === undefined || this.props.location.office_id === null )
    {
      this.props.history.push("/salesofficereport")
    }
    else
    {
      this.getdesignation({
        office_id: this.props.location.office_id ?? "",
        results: 10,
        page: 1,
      });
  
      this.setState({
        office_id: this.props.location.office_id ?? ""
      });
    }
  
  }

  getdesignation(params = {}) {
    this.setState({
      loading: true,
    });
    this.props
      .detailed_sales_report_agent(params)
      .then((res) => {
        console.log(res);
        this.setData(res.data);
        this.setState({
          dashdata: res.data.grand_totals,
        });
        // const pagination = { ...this.state.pagination };
        // pagination.total = res.data.items.total;
        // this.setState({
        //   data: res.data.items.data,
        //   loading: false,
        //   pagination,
        // });
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  }
  handleTableChange = (pagination, filter, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(sorter.order);

    pager.current = pagination.current;
    this.setState({
      pagination: pager,
      sortField: sorter.order === undefined ? "" : sorter.field,
      sortOrder:
        sorter.order === "ascend"
          ? "ASC"
          : sorter.order === undefined
          ? ""
          : "DESC",
    });
    setTimeout(() => {
      this.searchMarketer();
    }, 100);
  };
  fetch = (params = {}) => {
    console.log("params:", params);
    this.getdesignation(params);
  };

  onchangeRole = (selectedItems) => {
    console.log(selectedItems);
    this.setState({ selectedItems });
  };
  onchangefieldname = (selectedfieldname) => {
    this.setState({ selectedfieldname });
    console.log(this.state.selectedfieldname);
  };
  onchangefilter = (selectedfilter) => {
    this.setState({ selectedfilter });
    console.log(this.state.selectedfilter);
  };
  renderSorter() {
    return _.map(this.state.columns, (master, index) => {
      return (
        <Option value={master.key} key={index}>
          {master.title}
        </Option>
      );
    });
  }

  renderOffice() {
    return _.map(this.state.marketer, (master, index) => {
      return (
        <Option value={master.id} key={index}>
          {master.name}
        </Option>
      );
    });
  }
  clearSearch = () => {
    this.setState({ loading: true });
    this.setState({
      selectedItems: [],
      selectedfieldname: [],
      selectedfilter: [],
      fdata:[],
      office_id:""
    });
    this.getdesignation({
      results: 10,
      page: 1,
    });
  };

  searchMarketer = (reset) => {
    this.setState({ loading: true,loadingbtn : true });
    var agent = this.state.selectedItems.key;
    var sort = this.state.sortField;
    var sort_by = this.state.sortOrder;
    var status = this.state.status;
    var search_text = this.state.name;

    this.props
      .detailed_sales_report_agent_search({
        office_id: reset ? "" : this.state.office_id,
        agent: agent,
        status: status,
        search_text: search_text,
        page: reset ? 1 : this.state.pagination?.current ?? 1,
        sort,
        sort_by,
      })
      .then((res) => {
        console.log("res", res.data);
        this.setData(res.data);
        this.setState({
          dashdata: res.data.grand_totals,
          loadingbtn:false,
        });
        // const pagination = { ...this.state.pagination };
        // pagination.total = res.data.items.total;
        // pagination.current = res.data.items.current_page;
        // this.setState({
        //   data: res.data.items.data,
        //   loading: false,
        //   pagination,
        // });
      })
      .catch((err) => {
        this.setState({loadingbtn:false})
      });
  };

  handleChange = (datefilter) => {
    console.log(datefilter);
    this.setState({
      datefilter,
      startdate: moment(datefilter[0]).format("MM/DD/YYYY"),
      enddate: moment(datefilter[1]).format("MM/DD/YYYY"),
    });
  };
  getexportbtn = () => {
    var agent = this.state.selectedItems.key;
    var office_id= this.state.office_id;
    this.setState({ loading: true, loadingEPbtn:true });
    this.props
      .detailed_sales_export_agent({ agent,office_id })
      .then((res) => {
        this.setState({ loading: false , loadingEPbtn:false});
        if (res.data.download === 0) {
          message.success(res.data.message);
        } else {
          window.open(res.data.url, "_blank");
        }
      })
      .catch((err) => {
        this.setState({ loading: false, loadingEPbtn:false });
      });
  };

  setData = (resData) => {
    const pagination = { ...this.state.pagination };
    pagination.total = resData.items.total;
    pagination.current = resData.items.current_page;
    this.setState({
      data: resData.items.data,
      loading: false,
      pagination,
    });
  };
  fetchUser = (value) => {
    console.log("fetching user", value);
    if (value.length > 1) {
      this.setState({ fdata: [], fetching: true });
      console.log(this);
      axiosInstance
        .get("/api/v1/userlist_based_on_role/agent?term=" + value)
        .then((response) => {
          console.log(response.data);
          const fdata = response.data.map((user) => ({
            text: `${user.first_name} ${user.last_name}`,
            value: user.id,
          }));
          console.log(this);
          this.setState({ fdata, fetching: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  // ===
  // ======================================================

  render() {
    const { dashdata } = this.state;
    const { fetching, fdata } = this.state;
    const columns = [
      {
        title: "Agent",
        dataIndex: "agent_name",
        key: "agent_name",
        sorter: true,
      },
      {
        title: "Today",
        dataIndex: "today",
        key: "today",
        sorter: true,
      },
      {
        title: "WTD",
        dataIndex: "WTD",
        key: "WTD",
        sorter: true,
      },
      {
        title: "MTD",
        dataIndex: "MTD",
        key: "MTD",
        sorter: true,
      },
      {
        title: "7 Days",
        dataIndex: "last_7_days",
        key: "last_7_days",
        sorter: true,
      },
      {
        title: "30 Days",
        dataIndex: "last_30_days",
        key: "last_30_days",
        sorter: true,
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <div className="row padd20 bg-grey">
            <div className="col-sm-4">
              <h4>
                
                By Agents- {this.props.location.office?.office_name ?? ""}{" "}
                {this.state.pagination.total &&
                  `(${this.state.pagination.total})`}
              </h4>
            </div>
            <div className="col-sm-8 text-right">
              {/* {this.rendercsvformat(data)} */}
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingEPbtn}
                onClick={() => this.getexportbtn()}
              >
                <i className="uil uil-export" /> Export Report
              </Button>
            </div>
          </div>
          <Spin spinning={this.state.loading}>
            <div className="row">
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">Daily Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.today ?? 0}</h4>
                    <Line height={14} values={[5, 10]} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">WTD Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.WTD ?? 0}</h4>
                    <Line height={14} values={[5, 3, 9, 6, 5, 9, 15]} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">MTD Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.MTD ?? 0}</h4>
                    <Line height={14} values={[5, 3, 9, 6, 5, 9, 7, 3, 5, 2]} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">7 Days Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.last_7_days ?? 0}</h4>
                    <Line
                      height={14}
                      values={[9, 6, 5, 9, 5, 3, 2]}
                      fill={"#fff"}
                      stroke={"#ff000d"}
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 bg-white border mb-2">
                  <p className="mb-1 text-bold">30 Days Activations</p>
                  <div className="d-flex align-items-center justify-content-between mb-1">
                    <h4 className="mb-0">{dashdata?.last_30_days ?? 0}</h4>
                    <Line
                      height={14}
                      values={[9, 6, 5, 9, 5, 3, 2]}
                      fill={"#fff"}
                      stroke={"#ff000d"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          <div className="row padd20 bg-grey">
            <div className="col-4">
              <label for="exampleFormControlInput1">Search Agent</label>
              <Select
                showSearch
                style={{ width: "100%" }}
                labelInValue
                placeholder="Type 2 characters to search..!"
                notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
                filterOption={false}
                onSearch={this.fetchUser}
                defaultActiveFirstOption={false}
                value={this.state.selectedItems}
                onChange={this.onchangeRole}
              >
                {fdata.map((d) => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
            </div>
            
            <div className="col mt-6">
              <Button
                type="primary"
                className="mr5"
                loading={this.state.loadingbtn}
                onClick={() => this.searchMarketer("reset")}
                disabled={this.state.selectedItems.length === 0 ? true : false}
              >
                Search
              </Button>
              {/* <Button type="default" onClick={() => this.clearSearch()}>
                Clear
              </Button> */}
            </div>
          </div>
          <div className="bg-white padd0 mt-4">
            <Table
              columns={columns}
              dataSource={this.state.data}
              scroll={{ x: true }}
              pagination={this.state.pagination}
              loading={this.state.loading}
              onChange={this.handleTableChange}
              // size="small"
            />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user,
    data: state.data,
  };
}

export default connect(mapStateToProps, {
  detailed_sales_report_agent,
  getagentlistname,
  detailed_sales_report_agent_search,
  detailed_sales_export_agent,
})(SalesAgentView);
