export const GET_USER = "GET_USER";
export const USER_STATUS = "USER_STATUS";
export const GET_MASTER = "GET_MASTER";
export const GET_RETAILER = "GET_RETAILER";
export const GET_DISTRIBUTOR = "GET_DISTRIBUTOR";
export const GET_IBO_MARKET = "GET_IBO_MARKET";
export const GET_OFFICE = "GET_OFFICE";
export const GET_INVENTORY_ORDER = "GET_INVENTORY_ORDER";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_RMA_PENDING = "GET_RMA_PENDING";
export const GET_RMA_REPORT = "GET_RMA_REPORT";
export const API_URL = "https://training-api.wiessolution.com";
//export const API_URL = "https://training-api.wiessolution.com"
